import { DataType } from "../types";
export const countStatus = (all: DataType[], passCount: number, underReviewCount: number)=>{
    all.forEach((application: DataType | any) => {
      if (application.isPassed) {
        passCount++;
      }
      if (application.approvalDate === null) {
        underReviewCount++;
      }
    });
    return ({
        passCount: passCount,
        underReviewCount: underReviewCount,
    })
}