import { VersionData } from "../types"
import { removeHttpsProtocol } from "./urlProcessing"
export const getPlaceholder = (versionData: VersionData[])=>{
    const Android = versionData?.find((i)=>i.osVersion==="Android")
    const iOS = versionData?.find((i)=>i.osVersion==="iOS")
    return {
        number: Android?.appVersion,
        iOS: removeHttpsProtocol(iOS?.appStore as string),
        Android: removeHttpsProtocol(Android?.appStore as string)
    }
}