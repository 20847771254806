/* eslint-disable react-hooks/exhaustive-deps */
import { Button, DatePicker, Drawer, Form, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import DrawerFooter from "../../../../components/layout/drawer/DrawerFooter";
import InputCustom from "../../../../components/custom/InputCustom";
import { useUploadFileMutation } from "../../../../graphql/operations/upload-file";
import { AfBlobPath } from "../../../../graphql/operations/@types";
import dayjs from "dayjs";
import { renderAntDMessageConfig } from "../../../../utils/renderAntDMessageConfig";

interface GoGoLiveDrawerProps {
  show: boolean;
  form: any;
  setShow: any;
  handleConfirm: (values: any) => void;
  setHkPdfFileName: any;
  btnLoading: boolean;
}

const OpenHouseCreateDrawer = ({
  show,
  form,
  setShow,
  handleConfirm,
  setHkPdfFileName,
  btnLoading,
}: GoGoLiveDrawerProps) => {
  const [uploadFile] = useUploadFileMutation();
  // const videoProps: UploadProps = {
  //   name: "file",
  //   action: "https://httpbin.org/post",
  //   headers: {
  //     authorization: "authorization-text",
  //   },
  //   beforeUpload: (file) => {
  //     const isVideo = file.type.startsWith("video");
  //     if (!isVideo) {
  //       message.error("Only upload video file!");
  //     }
  //     return isVideo || Upload.LIST_IGNORE;
  //   },
  //   onChange(info) {
  //     setUploadedVideoFileName(info.file.name);
  //     if (info.file.status !== "uploading") {
  //       console.log(info.file, info.fileList);
  //     }
  //     if (info.file.status === "done") {
  //       message.success(`${info.file.name} file uploaded successfully`);
  //     } else if (info.file.status === "error") {
  //       message.error(`${info.file.name} file upload failed.`);
  //     }
  //   },
  //   maxCount: 1,
  //   onRemove: () => setUploadedVideoFileName(""),
  // };

  const pdfProps: UploadProps = {
    name: "file",
    action: "https://httpbin.org/post",
    headers: {
      authorization: "authorization-text",
    },
    beforeUpload: (file) => {
      const isPdf = file.type.split("/")[1] === "pdf";
      if (!isPdf) {
        message.error(renderAntDMessageConfig("Only upload pdf file!"));
      }
      return isPdf || Upload.LIST_IGNORE;
    },
    onChange: (info) => {
      console.log(info);
      if (info.fileList.length === 0) {
        setHkPdfFileName(undefined);
        return;
      }
      if (info.file.status === "done") {
        setHkPdfFileName(info.file.name);
        uploadFile({
          variables: {
            blobs: [
              {
                file: info.file.originFileObj,
                path: AfBlobPath.VesPdf,
              },
            ],
          },
        }).catch((e) => console.log(e));
        message.success(
          renderAntDMessageConfig(
            `${info.file.name} file uploaded successfully`
          )
        );
      } else if (info.file.status === "error") {
        message.error(
          renderAntDMessageConfig(`${info.file.name} file upload failed.`)
        );
      }
    },
    maxCount: 1,
  };

  return (
    <Drawer
      open={show}
      onClose={() => {
        form.resetFields();
        setShow(false);
        setHkPdfFileName(undefined);
      }}
      title="Create new Open House content"
      width={500}
    >
      <Form
        name="oh-form"
        form={form}
        onFinish={handleConfirm}
        layout="vertical"
        className="ggl-form"
      >
        <Form.Item
          label="Year month"
          name="yearMonth"
          rules={[
            {
              required: true,
              message: "This field is required!",
            },
          ]}
        >
          <DatePicker
            style={{ width: "100%", marginTop: 8 }}
            format="YYYY-MMM"
            picker="month"
            disabledDate={(current) => {
              return (
                current && current < dayjs().endOf("month").subtract(1, "month")
              );
            }}
          />
        </Form.Item>
        <Form.Item
          label="Video"
          name="videoFile"
          // valuePropName="fileList"
          // getValueFromEvent={(e) => {
          //   if (Array.isArray(e)) {
          //     return e;
          //   }
          //   return e && e.fileList;
          // }}
        >
          {/* <Upload {...videoProps}>
            <Button icon={<UploadOutlined />} style={{ marginTop: 8 }}>
              Click to Upload
            </Button>
          </Upload> */}
          <InputCustom placeholder="Vimeo ID" />
        </Form.Item>
        <Form.Item>
          <p
            style={{
              color: "rgba(0,0,0,0.45)",
              lineHeight: "22px",
              marginTop: -40,
            }}
          >
            Enter the ID number of the video uploaded on Vimeo
          </p>
        </Form.Item>
        <Form.Item
          label="Parent's guide"
          name="hkPdf"
          valuePropName="fileList"
          getValueFromEvent={(e) => {
            if (Array.isArray(e)) {
              return e;
            }
            return e && e.fileList;
          }}
          rules={[
            {
              required: true,
              message: "This field is required!",
            },
          ]}
          style={{ marginTop: -40 }}
        >
          <Upload {...pdfProps} onRemove={() => setHkPdfFileName("")}>
            <Button icon={<UploadOutlined />} style={{ marginTop: 8 }}>
              Click to Upload
            </Button>
          </Upload>
        </Form.Item>
        <DrawerFooter
          handleCancel={() => {
            form.resetFields();
            setShow(false);
          }}
          isLoading={btnLoading}
        />
      </Form>
    </Drawer>
  );
};

export default OpenHouseCreateDrawer;
