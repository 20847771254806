import { Market } from "../graphql/operations/@types";

export const renderMarketValue = (market: string | null | undefined) => {
  switch (market) {
    case "HK":
      return Market.HongKong;
    case "KR":
      return Market.Korea;
    case "TW":
      return Market.Taiwan;
    default:
      break;
  }
};
