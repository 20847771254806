// @ts-nocheck
/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useReactiveVar } from "@apollo/client";
import { Button } from "antd";
import fileDownload from "js-file-download";
import "./AdExclusionList.scss";
import {
  __currentUser__,
  __jwtToken__,
  __projectKey__,
} from "../../../graphql/policies";
import { useGetUserProgramsByServiceQuery } from "../../../graphql/operations/get-user-program-by-service";
import { renderDecodedId } from "../../../utils/renderDecodedId";
import Spinner from "../../../components/layout/spinner/Spinner";
import NoPermission from "../../../components/others/NoPermission";
import { Colors } from "../../../constants/Colors";
import { ServiceTypes } from "../../../types/types";
import { apiCall } from "../../../utils/apiCall";

interface AdExclusionListProps {
  setSelectedService: Dispatch<SetStateAction<ServiceTypes>>;
  setSelectSubItem: Dispatch<SetStateAction<string | undefined>>;
}

const AdExclusionList = ({
  setSelectedService,
  setSelectSubItem,
}: AdExclusionListProps) => {
  const currentUser = useReactiveVar(__currentUser__);
  const jwtToken = useReactiveVar(__jwtToken__);
  const projectKey = useReactiveVar(__projectKey__);
  const [isLoading, setIsLoading] = useState(false);
  const {
    data: userPrograms,
    loading: userProgramsLoading,
    error: userProgramsError,
  } = useGetUserProgramsByServiceQuery({
    variables: {
      userId: parseInt(renderDecodedId(currentUser?.id)),
      serviceId: 4,
    },
  });

  const handleDownload = async (fileName: string[]) => {
    setIsLoading(true);
    try {
      const downloadPromises = fileName.map(async (item) => {
        const endPoint =
          item === "email"
            ? process.env.REACT_APP_AD_EXCLUSION_EMAIL
            : process.env.REACT_APP_AD_EXCLUSION_PHONE;
        const header = {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "X-Project-Id": "AF",
            "X-Authorization-Provider": "Anonymous",
            "X-Project-Key": projectKey,
          },
          responseType: "blob",
        };
        try {
          const data = await apiCall({
            method: "GET",
            header: header,
            endPoint: endPoint + `/${item}`,
          });
          fileDownload(data, `${item}.xlsx`);
          return data;
        } catch (error) {
          console.log(error);
          throw error; // Re-throw error to be caught by Promise.all
        }
      });

      // Wait for all downloadPromises to resolve
      await Promise.all(downloadPromises);

      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setSelectedService(ServiceTypes.MKT);
    setSelectSubItem("Ad Exclusion List");
  }, []);

  if (userProgramsError) {
    message.error(
      renderAntDMessageConfig(
        "Something went wrong. Please contact your system administrator."
      )
    );
  }

  if (userProgramsLoading) {
    return <Spinner />;
  }

  if (
    userPrograms &&
    !userPrograms.wf_adminFirst_afUserProgram?.edges?.find(
      (item) => item.node.afProgram?.programName === "Ad Exclusion List"
    )
  ) {
    return <NoPermission />;
  }

  return (
    <div id="adExclusionListWrapper">
      <div className="header" style={{ backgroundColor: Colors.white }}>
        <p className="header-title" style={{ color: Colors.darkGrey }}>
          Ad Exclusion list
        </p>
      </div>
      <div className="content-container">
        <div className="title-container">
          <p>會員及近8個月內有申請過回函的客戶手機與email</p>
        </div>

        <div className="button-container">
          <Button
            htmlType="submit"
            type="primary"
            style={{ marginRight: 8 }}
            loading={isLoading}
            onClick={() => {
              handleDownload(["email", "phone"]);
            }}
          >
            Download excel
          </Button>
          <p>Excel檔案內含個資，已經過加密，請小心使用。</p>
        </div>
      </div>
    </div>
  );
};

export default AdExclusionList;
