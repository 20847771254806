import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteSongAndMarketsMutationVariables = Types.Exact<{
  productSongAndMarkets?: Types.InputMaybe<Array<Types.Wf_TrialModeLeads_ProductSongAndMarketInput> | Types.Wf_TrialModeLeads_ProductSongAndMarketInput>;
}>;


export type DeleteSongAndMarketsMutation = { __typename?: 'Mutation', wf_trialModeLeads: { __typename?: 'WF_TrialModeLeads_Mutation_Payload', count: number } };


export const DeleteSongAndMarketsDocument = gql`
    mutation DeleteSongAndMarkets($productSongAndMarkets: [WF_TrialModeLeads_ProductSongAndMarketInput!]) {
  wf_trialModeLeads(removeRange: {productSongAndMarkets: $productSongAndMarkets}) {
    count
  }
}
    `;
export type DeleteSongAndMarketsMutationFn = Apollo.MutationFunction<DeleteSongAndMarketsMutation, DeleteSongAndMarketsMutationVariables>;

/**
 * __useDeleteSongAndMarketsMutation__
 *
 * To run a mutation, you first call `useDeleteSongAndMarketsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSongAndMarketsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSongAndMarketsMutation, { data, loading, error }] = useDeleteSongAndMarketsMutation({
 *   variables: {
 *      productSongAndMarkets: // value for 'productSongAndMarkets'
 *   },
 * });
 */
export function useDeleteSongAndMarketsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSongAndMarketsMutation, DeleteSongAndMarketsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSongAndMarketsMutation, DeleteSongAndMarketsMutationVariables>(DeleteSongAndMarketsDocument, options);
      }
export type DeleteSongAndMarketsMutationHookResult = ReturnType<typeof useDeleteSongAndMarketsMutation>;
export type DeleteSongAndMarketsMutationResult = Apollo.MutationResult<DeleteSongAndMarketsMutation>;
export type DeleteSongAndMarketsMutationOptions = Apollo.BaseMutationOptions<DeleteSongAndMarketsMutation, DeleteSongAndMarketsMutationVariables>;