import "./NormalTag.css";
import { Colors } from "../../constants/Colors";
import { NormalTagTypes } from "../../types/types";

interface NormalTagProps {
  type: NormalTagTypes | undefined;
}

const NormalTag = ({ type }: NormalTagProps) => {
  const renderBgColor = () => {
    switch (type) {
      case NormalTagTypes.EVENT:
      case NormalTagTypes.CAP:
      case NormalTagTypes.GO_GO_LIVE:
      case NormalTagTypes.TELEPHONE_ENGLISH:
      case NormalTagTypes.CHRISTMAS:
      case NormalTagTypes.HALLOWEEN:
      case NormalTagTypes.NEW_YEAR:
      case NormalTagTypes.NEW:
      case NormalTagTypes.CLUB_APP:
        return Colors.lightOrange;
      case NormalTagTypes.OTHERS:
      case NormalTagTypes.SENT:
      case NormalTagTypes.SUCCESS:
        return Colors.tagBackground;
      case NormalTagTypes.WARRANTY_SERVICE:
      case NormalTagTypes.SALES_188:
        return Colors.lightIndigo;
      case NormalTagTypes.SCHEDULED:
      case NormalTagTypes.ADMIN:
      case NormalTagTypes.TECHPRESSO:
      case NormalTagTypes.PENDING:
        return Colors.menuSubItemSelected;
      case NormalTagTypes.CLUB_SERVICE:
        return Colors.lightRed;
      case NormalTagTypes.MKT:
        return Colors.lightGreen;
      case NormalTagTypes.CHECK_IN_APP:
        return Colors.checkInBg;
      case NormalTagTypes.TRIAL_MODE:
        return Colors.trialModeTheme;
      default:
        break;
    }
  };

  const renderTextColor = () => {
    switch (type) {
      case NormalTagTypes.EVENT:
      case NormalTagTypes.CAP:
      case NormalTagTypes.GO_GO_LIVE:
      case NormalTagTypes.TELEPHONE_ENGLISH:
      case NormalTagTypes.CHRISTMAS:
      case NormalTagTypes.HALLOWEEN:
      case NormalTagTypes.NEW_YEAR:
      case NormalTagTypes.NEW:
      case NormalTagTypes.CLUB_APP:
        return Colors.orange;
      case NormalTagTypes.OTHERS:
      case NormalTagTypes.SENT:
      case NormalTagTypes.SUCCESS:
        return Colors.grey;
      case NormalTagTypes.WARRANTY_SERVICE:
      case NormalTagTypes.SALES_188:
        return Colors.indigo;
      case NormalTagTypes.SCHEDULED:
      case NormalTagTypes.ADMIN:
      case NormalTagTypes.TECHPRESSO:
      case NormalTagTypes.PENDING:
        return Colors.blue;
      case NormalTagTypes.CLUB_SERVICE:
        return Colors.red;
      case NormalTagTypes.MKT:
        return Colors.green;
      case NormalTagTypes.CHECK_IN_APP:
        return Colors.checkInText;
      case NormalTagTypes.TRIAL_MODE:
        return Colors.trialModeTagText;
      default:
        break;
    }
  };

  const renderBorderColor = () => {
    switch (type) {
      case NormalTagTypes.SCHEDULED:
      case NormalTagTypes.ADMIN:
      case NormalTagTypes.TECHPRESSO:
      case NormalTagTypes.PENDING:
        return Colors.tagBorder;
      case NormalTagTypes.SENT:
      case NormalTagTypes.SUCCESS:
        return Colors.tagBorder2;
      case NormalTagTypes.CLUB_APP:
        return Colors.menuSelectedOrange;
      case NormalTagTypes.CLUB_SERVICE:
        return Colors.redBorder;
      case NormalTagTypes.MKT:
        return Colors.greenBorder;
      case NormalTagTypes.SALES_188:
        return Colors.blueBorder;
      case NormalTagTypes.CHECK_IN_APP:
        return Colors.checkInBorder;
      case NormalTagTypes.TRIAL_MODE:
        return Colors.trialModeSelectedBg;
      default:
        break;
    }
  };

  return (
    <p
      className="normal-tag-container"
      style={{
        color: renderTextColor(),
        backgroundColor: renderBgColor(),
        border: `1px solid ${renderBorderColor()}`,
      }}
    >
      {type}
    </p>
  );
};

export default NormalTag;
