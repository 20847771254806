import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllNotReviewedGraduationApplyQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllNotReviewedGraduationApplyQuery = { __typename?: 'Query', afGraduationApplication: Array<{ __typename?: 'Club_WorldFamilyClub_GraduationApplication', id: string, age?: string | null, applicationDate?: any | null, audioFile?: string | null, childEnglishName?: string | null, childName?: string | null, childNo?: string | null, isExcellent?: boolean | null, market?: string | null, memberNo?: string | null, taskId?: number | null, videoUrl?: string | null, isPassed?: boolean | null, approvalDate?: any | null, overallComment?: string | null, yearCount?: string | null, graduationSubApplications?: Array<{ __typename?: 'Club_WorldFamilyClub_GraduationSubApplication', id: string, applicationId?: number | null, subTaskId?: number | null, subTask?: { __typename?: 'Club_WorldFamilyClub_GraduationApplicationSubTask', graduationSubTaskMarketDescriptions?: Array<{ __typename?: 'Club_WorldFamilyClub_GraduationSubTaskMarketDescription', market?: string | null, subTaskId?: number | null, subTaskName?: string | null } | null> | null } | null } | null> | null }> };


export const GetAllNotReviewedGraduationApplyDocument = gql`
    query GetAllNotReviewedGraduationApply {
  afGraduationApplication(filter: {approvalDate: {eq: null}}) {
    id
    age
    applicationDate
    audioFile
    childEnglishName
    childName
    childNo
    isExcellent
    market
    memberNo
    taskId
    videoUrl
    graduationSubApplications {
      id
      applicationId
      subTaskId
      subTask {
        graduationSubTaskMarketDescriptions {
          market
          subTaskId
          subTaskName
        }
      }
    }
    isPassed
    approvalDate
    overallComment
    yearCount
  }
}
    `;

/**
 * __useGetAllNotReviewedGraduationApplyQuery__
 *
 * To run a query within a React component, call `useGetAllNotReviewedGraduationApplyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllNotReviewedGraduationApplyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllNotReviewedGraduationApplyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllNotReviewedGraduationApplyQuery(baseOptions?: Apollo.QueryHookOptions<GetAllNotReviewedGraduationApplyQuery, GetAllNotReviewedGraduationApplyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllNotReviewedGraduationApplyQuery, GetAllNotReviewedGraduationApplyQueryVariables>(GetAllNotReviewedGraduationApplyDocument, options);
      }
export function useGetAllNotReviewedGraduationApplyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllNotReviewedGraduationApplyQuery, GetAllNotReviewedGraduationApplyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllNotReviewedGraduationApplyQuery, GetAllNotReviewedGraduationApplyQueryVariables>(GetAllNotReviewedGraduationApplyDocument, options);
        }
export type GetAllNotReviewedGraduationApplyQueryHookResult = ReturnType<typeof useGetAllNotReviewedGraduationApplyQuery>;
export type GetAllNotReviewedGraduationApplyLazyQueryHookResult = ReturnType<typeof useGetAllNotReviewedGraduationApplyLazyQuery>;
export type GetAllNotReviewedGraduationApplyQueryResult = Apollo.QueryResult<GetAllNotReviewedGraduationApplyQuery, GetAllNotReviewedGraduationApplyQueryVariables>;