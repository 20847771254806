import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllGraduationTaskNameAndDescriptionQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllGraduationTaskNameAndDescriptionQuery = { __typename?: 'Query', club_worldFamilyClub_graduationTaskMarketDescription?: { __typename?: 'Club_WorldFamilyClub_GraduationTaskMarketDescriptionConnection', nodes?: Array<{ __typename?: 'Club_WorldFamilyClub_GraduationTaskMarketDescription', market?: string | null, taskDescription?: string | null, taskId?: number | null, taskName?: string | null }> | null } | null };


export const GetAllGraduationTaskNameAndDescriptionDocument = gql`
    query getAllGraduationTaskNameAndDescription {
  club_worldFamilyClub_graduationTaskMarketDescription(first: 100) {
    nodes {
      market
      taskDescription
      taskId
      taskName
    }
  }
}
    `;

/**
 * __useGetAllGraduationTaskNameAndDescriptionQuery__
 *
 * To run a query within a React component, call `useGetAllGraduationTaskNameAndDescriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllGraduationTaskNameAndDescriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllGraduationTaskNameAndDescriptionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllGraduationTaskNameAndDescriptionQuery(baseOptions?: Apollo.QueryHookOptions<GetAllGraduationTaskNameAndDescriptionQuery, GetAllGraduationTaskNameAndDescriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllGraduationTaskNameAndDescriptionQuery, GetAllGraduationTaskNameAndDescriptionQueryVariables>(GetAllGraduationTaskNameAndDescriptionDocument, options);
      }
export function useGetAllGraduationTaskNameAndDescriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllGraduationTaskNameAndDescriptionQuery, GetAllGraduationTaskNameAndDescriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllGraduationTaskNameAndDescriptionQuery, GetAllGraduationTaskNameAndDescriptionQueryVariables>(GetAllGraduationTaskNameAndDescriptionDocument, options);
        }
export type GetAllGraduationTaskNameAndDescriptionQueryHookResult = ReturnType<typeof useGetAllGraduationTaskNameAndDescriptionQuery>;
export type GetAllGraduationTaskNameAndDescriptionLazyQueryHookResult = ReturnType<typeof useGetAllGraduationTaskNameAndDescriptionLazyQuery>;
export type GetAllGraduationTaskNameAndDescriptionQueryResult = Apollo.QueryResult<GetAllGraduationTaskNameAndDescriptionQuery, GetAllGraduationTaskNameAndDescriptionQueryVariables>;