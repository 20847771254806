import { DatePicker, Form, Input } from "antd";
import dayjs from "dayjs";

import InputCustom from "../../../../../components/custom/InputCustom";
import requiredRule from "../../../../../utils/formRules/requiredRule";

export function FormItemTitle() {
  return (
    <Form.Item name="title" label="Title">
      <InputCustom
        placeholder="Shown as bold text in the club app"
        className="w-[484px]"
      />
    </Form.Item>
  );
}

export function FormItemMessage() {
  return (
    <Form.Item name="templateMessage" label="Message" rules={[requiredRule]}>
      <Input.TextArea
        showCount
        maxLength={300}
        placeholder="Autosize height based on content lines"
        className="w-[484px]"
      />
    </Form.Item>
  );
}

export function FormItemButtonText() {
  return (
    <Form.Item name="buttonText" label="Button text" rules={[requiredRule]}>
      <InputCustom className="w-[484px]" />
    </Form.Item>
  );
}

export function FormItemPushDateAndTime() {
  return (
    <Form.Item
      name="scheduleTime"
      label="Push date and time"
      help="Send it immediately when not select date and time"
    >
      <DatePicker
        className="w-[484px]"
        placeholder="Select date and time"
        showTime={{ format: "HH:mm" }}
        format="YYYY-MM-DD HH:mm"
        disabledDate={(current) => current && current < dayjs().startOf("day")}
        disabledTime={(current) => {
          if (current && current.isSame(dayjs(), "day")) {
            return {
              disabledHours: () => [...Array(dayjs().hour()).keys()],
              disabledMinutes: () => {
                if (dayjs().hour() === current.hour()) {
                  return [...Array(dayjs().minute()).keys()];
                }
                return [];
              },
            };
          }

          return {};
        }}
      />
    </Form.Item>
  );
}
