import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateKrGraduationStatusMutationVariables = Types.Exact<{
  memberNumber: Types.Scalars['String']['input'];
  childNumber: Types.Scalars['Int']['input'];
  yearGraduated: Types.Scalars['DateTime']['input'];
}>;


export type UpdateKrGraduationStatusMutation = { __typename?: 'Mutation', afUpdateChildGraduationOfKorea: { __typename?: 'Payload', count: number } };


export const UpdateKrGraduationStatusDocument = gql`
    mutation updateKRGraduationStatus($memberNumber: String!, $childNumber: Int!, $yearGraduated: DateTime!) {
  afUpdateChildGraduationOfKorea(
    graduationOfKorea: {memberNumber: $memberNumber, childNumber: $childNumber, yearGraduated: $yearGraduated}
  ) {
    count
  }
}
    `;
export type UpdateKrGraduationStatusMutationFn = Apollo.MutationFunction<UpdateKrGraduationStatusMutation, UpdateKrGraduationStatusMutationVariables>;

/**
 * __useUpdateKrGraduationStatusMutation__
 *
 * To run a mutation, you first call `useUpdateKrGraduationStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKrGraduationStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKrGraduationStatusMutation, { data, loading, error }] = useUpdateKrGraduationStatusMutation({
 *   variables: {
 *      memberNumber: // value for 'memberNumber'
 *      childNumber: // value for 'childNumber'
 *      yearGraduated: // value for 'yearGraduated'
 *   },
 * });
 */
export function useUpdateKrGraduationStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateKrGraduationStatusMutation, UpdateKrGraduationStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateKrGraduationStatusMutation, UpdateKrGraduationStatusMutationVariables>(UpdateKrGraduationStatusDocument, options);
      }
export type UpdateKrGraduationStatusMutationHookResult = ReturnType<typeof useUpdateKrGraduationStatusMutation>;
export type UpdateKrGraduationStatusMutationResult = Apollo.MutationResult<UpdateKrGraduationStatusMutation>;
export type UpdateKrGraduationStatusMutationOptions = Apollo.BaseMutationOptions<UpdateKrGraduationStatusMutation, UpdateKrGraduationStatusMutationVariables>;