import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateUploadSongMutationVariables = Types.Exact<{
  songTitle: Types.Scalars['String']['input'];
  productCategoryId: Types.Scalars['Int']['input'];
  audioFileName: Types.Scalars['String']['input'];
  lyricsFileName: Types.Scalars['String']['input'];
}>;


export type CreateUploadSongMutation = { __typename?: 'Mutation', wf_trialModeLeads: { __typename?: 'WF_TrialModeLeads_Mutation_Payload', count: number } };


export const CreateUploadSongDocument = gql`
    mutation CreateUploadSong($songTitle: String!, $productCategoryId: Int!, $audioFileName: String!, $lyricsFileName: String!) {
  wf_trialModeLeads(
    add: {productSong: {songTitle: $songTitle, productCategoryId: $productCategoryId, audioFileName: $audioFileName, lyricsFileName: $lyricsFileName}}
  ) {
    count
  }
}
    `;
export type CreateUploadSongMutationFn = Apollo.MutationFunction<CreateUploadSongMutation, CreateUploadSongMutationVariables>;

/**
 * __useCreateUploadSongMutation__
 *
 * To run a mutation, you first call `useCreateUploadSongMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUploadSongMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUploadSongMutation, { data, loading, error }] = useCreateUploadSongMutation({
 *   variables: {
 *      songTitle: // value for 'songTitle'
 *      productCategoryId: // value for 'productCategoryId'
 *      audioFileName: // value for 'audioFileName'
 *      lyricsFileName: // value for 'lyricsFileName'
 *   },
 * });
 */
export function useCreateUploadSongMutation(baseOptions?: Apollo.MutationHookOptions<CreateUploadSongMutation, CreateUploadSongMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUploadSongMutation, CreateUploadSongMutationVariables>(CreateUploadSongDocument, options);
      }
export type CreateUploadSongMutationHookResult = ReturnType<typeof useCreateUploadSongMutation>;
export type CreateUploadSongMutationResult = Apollo.MutationResult<CreateUploadSongMutation>;
export type CreateUploadSongMutationOptions = Apollo.BaseMutationOptions<CreateUploadSongMutation, CreateUploadSongMutationVariables>;