import * as Types from '../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetNotificationByIdQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetNotificationByIdQuery = { __typename?: 'Query', sales188Notification: Array<{ __typename?: 'Sales188Notification', notificationId?: number | null, market?: string | null, sendingDepartment?: string | null, title?: string | null, templateMessage?: string | null, scheduleTime?: any | null, createdTime: any, creator?: string | null, removeNotificationId?: number | null, mutationOperation?: string | null, jobStatus?: string | null, error?: string | null, target?: string | null }> };


export const GetNotificationByIdDocument = gql`
    query getNotificationById($id: Int) {
  sales188Notification(filter: {notificationId: {eq: $id}}) {
    notificationId
    market
    sendingDepartment
    title
    templateMessage
    scheduleTime
    createdTime
    creator
    removeNotificationId
    mutationOperation
    jobStatus
    error
    target
  }
}
    `;

/**
 * __useGetNotificationByIdQuery__
 *
 * To run a query within a React component, call `useGetNotificationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetNotificationByIdQuery(baseOptions?: Apollo.QueryHookOptions<GetNotificationByIdQuery, GetNotificationByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationByIdQuery, GetNotificationByIdQueryVariables>(GetNotificationByIdDocument, options);
      }
export function useGetNotificationByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationByIdQuery, GetNotificationByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationByIdQuery, GetNotificationByIdQueryVariables>(GetNotificationByIdDocument, options);
        }
export type GetNotificationByIdQueryHookResult = ReturnType<typeof useGetNotificationByIdQuery>;
export type GetNotificationByIdLazyQueryHookResult = ReturnType<typeof useGetNotificationByIdLazyQuery>;
export type GetNotificationByIdQueryResult = Apollo.QueryResult<GetNotificationByIdQuery, GetNotificationByIdQueryVariables>;