import { MarketType, SegmentationType } from "../../types";
import { segmentationOrder } from "../constants";
import { ShelfType } from "../types";

export default function isChecked(
  selectedMarket: MarketType,
  shelfData: ShelfType[],
  segmentationColumn: SegmentationType,
  isEditing: boolean,
  editingSegmentation: number
) {
  if (isEditing)
    return segmentationOrder[segmentationColumn] >= editingSegmentation;

  return (
    shelfData &&
    shelfData.findIndex(
      ({ market, segmentation }) =>
        market === selectedMarket && segmentation === segmentationColumn
    ) > -1
  );
}
