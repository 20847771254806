import * as Types from './@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteFaceCallPerformerMutationVariables = Types.Exact<{
  performerId: Types.Scalars['Int']['input'];
}>;


export type DeleteFaceCallPerformerMutation = { __typename?: 'Mutation', club_worldFamilyClub: { __typename?: 'Club_WorldFamilyClub_Mutation_Payload', count: number } };


export const DeleteFaceCallPerformerDocument = gql`
    mutation DeleteFaceCallPerformer($performerId: Int!) {
  club_worldFamilyClub(
    remove: {faceCallPerformer: {faceCallPerformerId: $performerId}}
  ) {
    count
  }
}
    `;
export type DeleteFaceCallPerformerMutationFn = Apollo.MutationFunction<DeleteFaceCallPerformerMutation, DeleteFaceCallPerformerMutationVariables>;

/**
 * __useDeleteFaceCallPerformerMutation__
 *
 * To run a mutation, you first call `useDeleteFaceCallPerformerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFaceCallPerformerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFaceCallPerformerMutation, { data, loading, error }] = useDeleteFaceCallPerformerMutation({
 *   variables: {
 *      performerId: // value for 'performerId'
 *   },
 * });
 */
export function useDeleteFaceCallPerformerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFaceCallPerformerMutation, DeleteFaceCallPerformerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFaceCallPerformerMutation, DeleteFaceCallPerformerMutationVariables>(DeleteFaceCallPerformerDocument, options);
      }
export type DeleteFaceCallPerformerMutationHookResult = ReturnType<typeof useDeleteFaceCallPerformerMutation>;
export type DeleteFaceCallPerformerMutationResult = Apollo.MutationResult<DeleteFaceCallPerformerMutation>;
export type DeleteFaceCallPerformerMutationOptions = Apollo.BaseMutationOptions<DeleteFaceCallPerformerMutation, DeleteFaceCallPerformerMutationVariables>;