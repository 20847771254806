import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateGraduationCountMutationVariables = Types.Exact<{
  childApplicationId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  market?: Types.InputMaybe<Types.Scalars['String']['input']>;
  memberNo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  childNo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  applicationYear?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  approvedCount?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  rejectedCount?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  lastApprovalDate?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  isExcellent?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  graduationDate?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  applicationCount?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type UpdateGraduationCountMutation = { __typename?: 'Mutation', club_worldFamilyClub: { __typename?: 'Club_WorldFamilyClub_Mutation_Payload', count: number } };


export const UpdateGraduationCountDocument = gql`
    mutation updateGraduationCount($childApplicationId: Int, $market: String, $memberNo: String, $childNo: String, $applicationYear: Int, $approvedCount: Int, $rejectedCount: Int, $lastApprovalDate: DateTime, $isExcellent: Boolean, $graduationDate: DateTime, $applicationCount: Int) {
  club_worldFamilyClub(
    update: {childGraduationApplication: {childApplicationId: $childApplicationId, market: $market, memberNo: $memberNo, childNo: $childNo, applicationCount: $applicationCount, applicationYear: $applicationYear, approvedCount: $approvedCount, rejectedCount: $rejectedCount, lastApprovalDate: $lastApprovalDate, isExcellent: $isExcellent, graduationDate: $graduationDate}}
  ) {
    count
  }
}
    `;
export type UpdateGraduationCountMutationFn = Apollo.MutationFunction<UpdateGraduationCountMutation, UpdateGraduationCountMutationVariables>;

/**
 * __useUpdateGraduationCountMutation__
 *
 * To run a mutation, you first call `useUpdateGraduationCountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGraduationCountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGraduationCountMutation, { data, loading, error }] = useUpdateGraduationCountMutation({
 *   variables: {
 *      childApplicationId: // value for 'childApplicationId'
 *      market: // value for 'market'
 *      memberNo: // value for 'memberNo'
 *      childNo: // value for 'childNo'
 *      applicationYear: // value for 'applicationYear'
 *      approvedCount: // value for 'approvedCount'
 *      rejectedCount: // value for 'rejectedCount'
 *      lastApprovalDate: // value for 'lastApprovalDate'
 *      isExcellent: // value for 'isExcellent'
 *      graduationDate: // value for 'graduationDate'
 *      applicationCount: // value for 'applicationCount'
 *   },
 * });
 */
export function useUpdateGraduationCountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGraduationCountMutation, UpdateGraduationCountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGraduationCountMutation, UpdateGraduationCountMutationVariables>(UpdateGraduationCountDocument, options);
      }
export type UpdateGraduationCountMutationHookResult = ReturnType<typeof useUpdateGraduationCountMutation>;
export type UpdateGraduationCountMutationResult = Apollo.MutationResult<UpdateGraduationCountMutation>;
export type UpdateGraduationCountMutationOptions = Apollo.BaseMutationOptions<UpdateGraduationCountMutation, UpdateGraduationCountMutationVariables>;