import { Form, FormInstance, Radio, RadioChangeEvent, UploadFile } from "antd";

import requiredRule from "../../../../../utils/formRules/requiredRule";
import { TemplateType } from "../../types";
import { FormItemImage } from "./FormItemImage";
import FormItemPhoto from "./FormItemPhoto";
import { FormItemVideoUrl } from "./FormItems";

export default function FormTemplate({
  form,
  fileList,
  setFileList,
  selectedTemplate,
  setSelectedTemplate,
  setPhotoUrl,
}: {
  form: FormInstance<any>;
  fileList: any[];
  setFileList: React.Dispatch<React.SetStateAction<UploadFile<any>[]>>;
  selectedTemplate: TemplateType;
  setSelectedTemplate: React.Dispatch<React.SetStateAction<TemplateType>>;
  setPhotoUrl: React.Dispatch<React.SetStateAction<string>>;
}) {
  return (
    <>
      <Form.Item name="template" label="Template" rules={[requiredRule]}>
        <Radio.Group
          optionType="button"
          options={[
            { label: "A - Image", value: "Image" },
            { label: "B - Photo", value: "Photo" },
            { label: "C - Video", value: "Video" },
            { label: "D - Feature", value: "Feature" },
          ]}
          onChange={({ target: { value } }: RadioChangeEvent) => {
            setSelectedTemplate(value);
          }}
        />
      </Form.Item>

      <FormItemImage selectedTemplate={selectedTemplate} />
      <FormItemPhoto
        form={form}
        fileList={fileList}
        setFileList={setFileList}
        setPhotoUrl={setPhotoUrl}
        selectedTemplate={selectedTemplate}
      />
      <FormItemVideoUrl selectedTemplate={selectedTemplate} />
    </>
  );
}
