import { Button, DatePicker, Form } from "antd";
import InputCustom from "../../../components/custom/InputCustom";
import "./dev.scss";
import dayjs from "dayjs";
import { useState } from "react";
import { apiCall } from "../../../utils/apiCall";
import { getPlaceholder } from "./utils/getPlaceholder";
import { addHttpsProtocol } from "./utils/urlProcessing";
import requiredRule from "../../../utils/formRules/requiredRule";
interface DevProps {
  data: any;
}
const Dev = ({ data }: DevProps) => {
  const placeholderObject = getPlaceholder(data?.test);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const onFinish = (values: any) => {
    const template = [...data.test];
    const selectedDate = values.date;
    const formattedDate = dayjs(selectedDate).format("YYYY-MM-DD");
    const payloadObject = template.map((info) => {
      return {
        ...info,
        appVersion: values.number,
        releaseTime: formattedDate,
        appStore:
          info.osVersion === "Android"
            ? addHttpsProtocol(values.Android)
            : addHttpsProtocol(values.iOS),
      };
    });
    const header = {
      headers: {
        accept: "text/plain",
        "Content-Type": "application/json",
        "User-Code": "P725",
      },
    };
    const payload = {
      appVersions: payloadObject,
    };
    apiCall({
      method: "PATCH",
      payload: payload,
      header: header,
      endPoint: process.env.REACT_APP_RELEASE_VERSION_DEV_UPDATE_ENDPOINT,
    })
      .then(() => {
        window.location.reload();
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const [form] = Form.useForm();
  return (
    <div id="devWrapper">
      <div className="dev-content-container">
        <div className="wrapper">
          <Form
            form={form}
            onFinish={onFinish}
            name="dev-version"
            onValuesChange={(one, all) => {
              if (!all.date) {
                form.setFieldsValue({
                  date: dayjs(),
                });
              }
              if (
                Object.values(all).some(
                  (value) => value === "" || value === null
                )
              ) {
                setSubmitEnabled(false);
              } else {
                setSubmitEnabled(true);
              }
            }}
            labelCol={{
              span: 6,
            }}
            wrapperCol={{
              span: 12,
            }}
          >
            <Form.Item
              label="Dev version number"
              name="number"
              rules={[requiredRule]}
            >
              <InputCustom placeholder={placeholderObject.number} />
            </Form.Item>
            <Form.Item label="Build date" name="date" rules={[requiredRule]}>
              <DatePicker defaultValue={dayjs()} />
            </Form.Item>
            <Form.Item label="iOS link" name="iOS" rules={[requiredRule]}>
              <InputCustom
                placeholder={placeholderObject.iOS}
                addonBefore="https://"
              />
            </Form.Item>
            <Form.Item
              label="Android link"
              name="Android"
              rules={[requiredRule]}
            >
              <InputCustom
                placeholder={placeholderObject.Android}
                addonBefore="https://"
              />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={!submitEnabled}
              className="submit-button"
            >
              Update
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Dev;
