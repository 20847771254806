import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCtAsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCtAsQuery = { __typename?: 'Query', wf_trialModeLeads_callToAction?: { __typename?: 'WF_TrialModeLeads_CallToActionConnection', nodes?: Array<{ __typename?: 'WF_TrialModeLeads_CallToAction', id: string, market?: string | null, segmentation?: string | null, ctaExperienceTypeId?: number | null, template?: string | null, imageCharacter?: string | null, photoFileName?: string | null, videoUrl?: string | null, buttonLinksTo?: string | null, buttonText?: string | null, photoUrl?: string | null, ctaExperienceType?: { __typename?: 'WF_TrialModeLeads_CallToActionExperienceType', experienceName?: string | null } | null, callToActionContents?: Array<{ __typename?: 'WF_TrialModeLeads_CallToActionContent', id: string, ctaContent?: string | null, ctaTitle?: string | null } | null> | null }> | null } | null };


export const GetCtAsDocument = gql`
    query GetCTAs {
  wf_trialModeLeads_callToAction(first: 100) {
    nodes {
      id
      market
      segmentation
      ctaExperienceTypeId
      ctaExperienceType {
        experienceName
      }
      template
      imageCharacter
      photoFileName
      photoUrl: photoFileName @wf_blobSignature(name: "TRIAL_MODE_CALL_TO_ACTION_ENCOURAGE_PHOTOS_SIGNATURE")
      videoUrl
      callToActionContents {
        id
        ctaContent
        ctaTitle
      }
      buttonLinksTo
      buttonText
    }
  }
}
    `;

/**
 * __useGetCtAsQuery__
 *
 * To run a query within a React component, call `useGetCtAsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCtAsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCtAsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCtAsQuery(baseOptions?: Apollo.QueryHookOptions<GetCtAsQuery, GetCtAsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCtAsQuery, GetCtAsQueryVariables>(GetCtAsDocument, options);
      }
export function useGetCtAsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCtAsQuery, GetCtAsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCtAsQuery, GetCtAsQueryVariables>(GetCtAsDocument, options);
        }
export type GetCtAsQueryHookResult = ReturnType<typeof useGetCtAsQuery>;
export type GetCtAsLazyQueryHookResult = ReturnType<typeof useGetCtAsLazyQuery>;
export type GetCtAsQueryResult = Apollo.QueryResult<GetCtAsQuery, GetCtAsQueryVariables>;