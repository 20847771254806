import * as Types from './@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdatePerformerMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  name: Types.Scalars['String']['input'];
  market: Types.Scalars['String']['input'];
  avatarFileName: Types.Scalars['String']['input'];
  isZippy: Types.Scalars['Boolean']['input'];
}>;


export type UpdatePerformerMutation = { __typename?: 'Mutation', club_worldFamilyClub: { __typename?: 'Club_WorldFamilyClub_Mutation_Payload', count: number } };


export const UpdatePerformerDocument = gql`
    mutation updatePerformer($id: Int!, $name: String!, $market: String!, $avatarFileName: String!, $isZippy: Boolean!) {
  club_worldFamilyClub(
    update: {faceCallPerformer: {faceCallPerformerId: $id, performerName: $name, market: $market, avatarFileName: $avatarFileName, zippyFlag: $isZippy}}
  ) {
    count
  }
}
    `;
export type UpdatePerformerMutationFn = Apollo.MutationFunction<UpdatePerformerMutation, UpdatePerformerMutationVariables>;

/**
 * __useUpdatePerformerMutation__
 *
 * To run a mutation, you first call `useUpdatePerformerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePerformerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePerformerMutation, { data, loading, error }] = useUpdatePerformerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      market: // value for 'market'
 *      avatarFileName: // value for 'avatarFileName'
 *      isZippy: // value for 'isZippy'
 *   },
 * });
 */
export function useUpdatePerformerMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePerformerMutation, UpdatePerformerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePerformerMutation, UpdatePerformerMutationVariables>(UpdatePerformerDocument, options);
      }
export type UpdatePerformerMutationHookResult = ReturnType<typeof useUpdatePerformerMutation>;
export type UpdatePerformerMutationResult = Apollo.MutationResult<UpdatePerformerMutation>;
export type UpdatePerformerMutationOptions = Apollo.BaseMutationOptions<UpdatePerformerMutation, UpdatePerformerMutationVariables>;