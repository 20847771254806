import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetFeaturedEventsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetFeaturedEventsQuery = { __typename?: 'Query', wf_trialModeLeads_featuredEvent?: { __typename?: 'WF_TrialModeLeads_FeaturedEventConnection', nodes?: Array<{ __typename?: 'WF_TrialModeLeads_FeaturedEvent', id: string, sortId?: number | null, market?: string | null, imageFileName?: string | null, navigationUrl?: string | null, startDate?: any | null, endDate?: any | null, imageUrl?: string | null }> | null } | null };


export const GetFeaturedEventsDocument = gql`
    query GetFeaturedEvents {
  wf_trialModeLeads_featuredEvent(first: 100) {
    nodes {
      id
      sortId
      market
      imageFileName
      imageUrl: imageFileName @wf_blobSignature(name: "TRIAL_MODE_LOCAL_EVENT_FEATURED_EVENT_BANNERS_SIGNATURE")
      navigationUrl
      startDate
      endDate
    }
  }
}
    `;

/**
 * __useGetFeaturedEventsQuery__
 *
 * To run a query within a React component, call `useGetFeaturedEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeaturedEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeaturedEventsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFeaturedEventsQuery(baseOptions?: Apollo.QueryHookOptions<GetFeaturedEventsQuery, GetFeaturedEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFeaturedEventsQuery, GetFeaturedEventsQueryVariables>(GetFeaturedEventsDocument, options);
      }
export function useGetFeaturedEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeaturedEventsQuery, GetFeaturedEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFeaturedEventsQuery, GetFeaturedEventsQueryVariables>(GetFeaturedEventsDocument, options);
        }
export type GetFeaturedEventsQueryHookResult = ReturnType<typeof useGetFeaturedEventsQuery>;
export type GetFeaturedEventsLazyQueryHookResult = ReturnType<typeof useGetFeaturedEventsLazyQuery>;
export type GetFeaturedEventsQueryResult = Apollo.QueryResult<GetFeaturedEventsQuery, GetFeaturedEventsQueryVariables>;