export default function getLineBreakRule(count: number) {
  return {
    validator(rule: any, value: string) {
      const lineCount = (value.match(/\n/g) || []).length;

      return lineCount <= count
        ? Promise.resolve()
        : Promise.reject(`Maximum of ${count} line breaks allowed`);
    },
  };
}
