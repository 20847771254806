import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteCtaContentsMutationVariables = Types.Exact<{
  CTAContents?: Types.InputMaybe<Array<Types.Wf_TrialModeLeads_CallToActionContentInput> | Types.Wf_TrialModeLeads_CallToActionContentInput>;
}>;


export type DeleteCtaContentsMutation = { __typename?: 'Mutation', wf_trialModeLeads: { __typename?: 'WF_TrialModeLeads_Mutation_Payload', count: number } };


export const DeleteCtaContentsDocument = gql`
    mutation DeleteCTAContents($CTAContents: [WF_TrialModeLeads_CallToActionContentInput!]) {
  wf_trialModeLeads(removeRange: {callToActionContents: $CTAContents}) {
    count
  }
}
    `;
export type DeleteCtaContentsMutationFn = Apollo.MutationFunction<DeleteCtaContentsMutation, DeleteCtaContentsMutationVariables>;

/**
 * __useDeleteCtaContentsMutation__
 *
 * To run a mutation, you first call `useDeleteCtaContentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCtaContentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCtaContentsMutation, { data, loading, error }] = useDeleteCtaContentsMutation({
 *   variables: {
 *      CTAContents: // value for 'CTAContents'
 *   },
 * });
 */
export function useDeleteCtaContentsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCtaContentsMutation, DeleteCtaContentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCtaContentsMutation, DeleteCtaContentsMutationVariables>(DeleteCtaContentsDocument, options);
      }
export type DeleteCtaContentsMutationHookResult = ReturnType<typeof useDeleteCtaContentsMutation>;
export type DeleteCtaContentsMutationResult = Apollo.MutationResult<DeleteCtaContentsMutation>;
export type DeleteCtaContentsMutationOptions = Apollo.BaseMutationOptions<DeleteCtaContentsMutation, DeleteCtaContentsMutationVariables>;