import cn from "../../utils/cn";

type TagColorType = "grey" | "lightBlue" | "blue" | "green" | "gold" | "orange";

const tagStyle: {
  [key in TagColorType]: string;
} = {
  grey: "text-textPrimary bg-grey1 border-grey3",
  lightBlue: "text-lightBlue6 bg-lightBlue1 border-lightBlue3",
  blue: "text-primary6 bg-primary1 border-primary3",
  green: "text-green8 bg-green1 border-green3",
  gold: "text-gold6 bg-gold2 border-gold3",
  orange: "text-morning6 bg-morning1 border-morning3",
};

export default function Tag({
  color,
  children,
}: {
  color: TagColorType;
  children: React.ReactNode;
}) {
  const style = tagStyle[color];

  return (
    <div
      className={cn(
        "w-fit rounded-sm leading-5 border border-solid text-nowrap",
        style
      )}
    >
      <p className="mx-2 my-1">{children}</p>
    </div>
  );
}
