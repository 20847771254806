import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateSongsSortIdMutationVariables = Types.Exact<{
  SongData?: Types.InputMaybe<Array<Types.Wf_TrialModeLeads_ProductSongInput> | Types.Wf_TrialModeLeads_ProductSongInput>;
}>;


export type UpdateSongsSortIdMutation = { __typename?: 'Mutation', wf_trialModeLeads: { __typename?: 'WF_TrialModeLeads_Mutation_Payload', count: number } };


export const UpdateSongsSortIdDocument = gql`
    mutation UpdateSongsSortId($SongData: [WF_TrialModeLeads_ProductSongInput!]) {
  wf_trialModeLeads(updateRange: {productSongs: $SongData}) {
    count
  }
}
    `;
export type UpdateSongsSortIdMutationFn = Apollo.MutationFunction<UpdateSongsSortIdMutation, UpdateSongsSortIdMutationVariables>;

/**
 * __useUpdateSongsSortIdMutation__
 *
 * To run a mutation, you first call `useUpdateSongsSortIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSongsSortIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSongsSortIdMutation, { data, loading, error }] = useUpdateSongsSortIdMutation({
 *   variables: {
 *      SongData: // value for 'SongData'
 *   },
 * });
 */
export function useUpdateSongsSortIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSongsSortIdMutation, UpdateSongsSortIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSongsSortIdMutation, UpdateSongsSortIdMutationVariables>(UpdateSongsSortIdDocument, options);
      }
export type UpdateSongsSortIdMutationHookResult = ReturnType<typeof useUpdateSongsSortIdMutation>;
export type UpdateSongsSortIdMutationResult = Apollo.MutationResult<UpdateSongsSortIdMutation>;
export type UpdateSongsSortIdMutationOptions = Apollo.BaseMutationOptions<UpdateSongsSortIdMutation, UpdateSongsSortIdMutationVariables>;