import MarketDropdown from "../../../../components/ui/MarketDropdown";
import { MarketType } from "../../types";
import { TrialModeNotificationModifyData } from "../types";
import ButtonCreate from "./ButtonCreate";

export default function TableMenu({
  selectedMarket,
  setSelectedMarket,
  setModifyingData,
}: {
  selectedMarket: MarketType;
  setSelectedMarket: (value: React.SetStateAction<MarketType>) => void;
  setModifyingData: (
    value: React.SetStateAction<TrialModeNotificationModifyData | null>
  ) => void;
}) {
  return (
    <div className="flex flex-row-reverse px-2 py-4">
      <div className="flex gap-4">
        <MarketDropdown
          selectedMarket={selectedMarket}
          setSelectedMarket={setSelectedMarket}
        />

        <ButtonCreate setModifyingData={setModifyingData} />
      </div>
    </div>
  );
}
