export default function isDisabled(
  segmentationOrder: number,
  isEditing: boolean,
  editingSegmentation: number
) {
  return (
    !isEditing ||
    (editingSegmentation < segmentationOrder &&
      editingSegmentation !== Infinity)
  );
}
