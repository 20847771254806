import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteVideoAndMarketsMutationVariables = Types.Exact<{
  productVideoAndMarkets?: Types.InputMaybe<Array<Types.Wf_TrialModeLeads_ProductVideoAndMarketInput> | Types.Wf_TrialModeLeads_ProductVideoAndMarketInput>;
}>;


export type DeleteVideoAndMarketsMutation = { __typename?: 'Mutation', wf_trialModeLeads: { __typename?: 'WF_TrialModeLeads_Mutation_Payload', count: number } };


export const DeleteVideoAndMarketsDocument = gql`
    mutation DeleteVideoAndMarkets($productVideoAndMarkets: [WF_TrialModeLeads_ProductVideoAndMarketInput!]) {
  wf_trialModeLeads(
    removeRange: {productVideoAndMarkets: $productVideoAndMarkets}
  ) {
    count
  }
}
    `;
export type DeleteVideoAndMarketsMutationFn = Apollo.MutationFunction<DeleteVideoAndMarketsMutation, DeleteVideoAndMarketsMutationVariables>;

/**
 * __useDeleteVideoAndMarketsMutation__
 *
 * To run a mutation, you first call `useDeleteVideoAndMarketsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVideoAndMarketsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVideoAndMarketsMutation, { data, loading, error }] = useDeleteVideoAndMarketsMutation({
 *   variables: {
 *      productVideoAndMarkets: // value for 'productVideoAndMarkets'
 *   },
 * });
 */
export function useDeleteVideoAndMarketsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteVideoAndMarketsMutation, DeleteVideoAndMarketsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteVideoAndMarketsMutation, DeleteVideoAndMarketsMutationVariables>(DeleteVideoAndMarketsDocument, options);
      }
export type DeleteVideoAndMarketsMutationHookResult = ReturnType<typeof useDeleteVideoAndMarketsMutation>;
export type DeleteVideoAndMarketsMutationResult = Apollo.MutationResult<DeleteVideoAndMarketsMutation>;
export type DeleteVideoAndMarketsMutationOptions = Apollo.BaseMutationOptions<DeleteVideoAndMarketsMutation, DeleteVideoAndMarketsMutationVariables>;