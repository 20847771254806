import dayjs from "dayjs";

import { MarketType } from "../../types";
import {
  FeaturedEventsFormDataType,
  RegularEventsFormDataType,
} from "../types";
import { FutureTime } from "../constants";

export function denormalizeFeaturedEventsData(
  data: FeaturedEventsFormDataType
): {
  market: MarketType;
  imageFileName: string;
  navigationUrl: string;
  endDate: string | null;
  startDate: string | null;
} {
  const { market, image, navigationUrl, publishDate, stopDate } = data;
  const { name } = image[0] || {};

  return {
    market,
    imageFileName: name,
    navigationUrl: `https://${navigationUrl}`,
    startDate: publishDate
      ? dayjs(publishDate).startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSSZ")
      : FutureTime,
    endDate: stopDate
      ? dayjs(stopDate).startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSSZ")
      : FutureTime,
  };
}

export function denormalizeRegularEventsData(data: RegularEventsFormDataType): {
  market: MarketType;
  eventTitle: string;
  eventDescription: string;
  imageFileName: string;
  navigationUrl: string;
  endDate: string | null;
  startDate: string | null;
} {
  const {
    market,
    title,
    description,
    image,
    navigationUrl,
    publishDate,
    stopDate,
  } = data;
  const { name } = image[0] || {};

  return {
    market,
    eventTitle: title,
    eventDescription: description,
    imageFileName: name,
    navigationUrl: `https://${navigationUrl}`,
    startDate: publishDate
      ? dayjs(publishDate).startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSSZ")
      : FutureTime,
    endDate: stopDate
      ? dayjs(stopDate).startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSSZ")
      : FutureTime,
  };
}
