import isInUse from "./isInUse";

type EventDataType = {
  sortId: number;
  publishDate?: string;
  stopDate?: string;
};

export default function splitDataByIsInUse<T extends EventDataType>(data: T[]) {
  return data.reduce(
    (resultObj, item) => {
      const { publishDate, stopDate } = item;

      if (isInUse(publishDate, stopDate)) {
        resultObj.data1.push(item);
      } else {
        resultObj.data2.push(item);
      }
      return resultObj;
    },
    {
      data1: [] as T[],
      data2: [] as T[],
    }
  );
}
