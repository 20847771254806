/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Drawer,
  Form,
  Input,
  List,
  Select,
  message,
  Upload,
  Divider,
  Modal,
} from "antd";
import {
  PlusOutlined,
  UploadOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import type { UploadProps } from "antd";
import "./PerformerTabContent.css";
import { Colors } from "../../../../constants/Colors";
import { data } from "../../../../data/data";
import DrawerFooter from "../../../../components/layout/drawer/DrawerFooter";
import InputCustom from "../../../../components/custom/InputCustom";
import { FaceCallPerformerTypes } from "../../../../types/types";
import { useGetFaceCallPerformersQuery } from "../../../../graphql/operations/get-facecall-performers";
import Spinner from "../../../../components/layout/spinner/Spinner";
import { useGetBlobSasQuery } from "../../../../graphql/operations/get-blob-info";
import { useUploadFileMutation } from "../../../../graphql/operations/upload-file";
import { AfBlobPath } from "../../../../graphql/operations/@types";
import { useCreatePerformerMutation } from "../../../../graphql/operations/create-performer";
import { useUpdatePerformerMutation } from "../../../../graphql/operations/update-performer";
import { useDeleteFaceCallPerformerMutation } from "../../../../graphql/operations/delete-facecall-performer";
import { renderDecodedId } from "../../../../utils/renderDecodedId";
import { useReactiveVar } from "@apollo/client";
import { __currentUser__ } from "../../../../graphql/policies";
import { renderAntDMessageConfig } from "../../../../utils/renderAntDMessageConfig";

const { Search } = Input;
const { Meta } = Card;
const { regions } = data;

interface PerformerTabContentProps {
  type: FaceCallPerformerTypes;
  setActiveKey: Function;
  setFilterKey: Function;
}

interface DataType {
  id: string;
  name: string;
  imageUrl: string;
  countOfSong: number;
  region: string;
  fileName: string;
  publishedSongs: number;
}

const PerformerTabContent = ({
  type,
  setActiveKey,
  setFilterKey,
}: PerformerTabContentProps) => {
  const [allPerformers, setAllPerformers] = useState<DataType[] | any>([]);
  const [displayList, setDisplayList] = useState<DataType[] | any>([]);
  const [searchText, setSearchText] = useState("");
  const [showDrawer, setShowDrawer] = useState(false);
  const [selectPerformer, setSelectPerformer] = useState<
    DataType | undefined
  >();
  // const [overlayWidthAndHeight, setOverlayWidthAndHeight] = useState<
  //   { width: number; height: number } | undefined
  // >();
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [form] = Form.useForm();
  const coverRef = useRef<any>();
  const { data, loading, error, refetch } = useGetFaceCallPerformersQuery({
    variables: {
      isZippy: type === FaceCallPerformerTypes.ZIPPY_FAMILY ? true : false,
    },
  });
  const {
    data: blobInfo,
    loading: blobLoading,
    error: blobError,
  } = useGetBlobSasQuery();
  const [uploadFile] = useUploadFileMutation();
  const [createPerformer, { loading: createLoading }] =
    useCreatePerformerMutation();
  const [updatePerformer, { loading: updateLoading }] =
    useUpdatePerformerMutation();
  const [deletePerformer, { loading: deleteLoading }] =
    useDeleteFaceCallPerformerMutation();
  const currentUser = useReactiveVar(__currentUser__);

  const drawerTitle =
    type === FaceCallPerformerTypes.PERFORMER
      ? "performer"
      : "Zippy and his friends";

  const props: UploadProps = {
    name: "file",
    action: "https://httpbin.org/post",
    headers: {
      authorization: "authorization-text",
    },
    beforeUpload: (file) => {
      const newFileName =
        form.getFieldValue("name").replace(/ /g, "_") +
        Date.now() +
        file.name.substring(file.name.lastIndexOf("."));
      const newFile = new File([file], newFileName, { type: file.type });
      const isImage = file.type.startsWith("image");
      if (!isImage) {
        message.error(renderAntDMessageConfig("Only upload image file!"));
      }
      return (isImage && newFile) || Upload.LIST_IGNORE;
    },
    onChange(info) {
      setUploadedFileName(info.file.name);
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(
          renderAntDMessageConfig(
            `${info.file.name} file uploaded successfully`
          )
        );
      } else if (info.file.status === "error") {
        message.error(
          renderAntDMessageConfig(`${info.file.name} file upload failed.`)
        );
      }
      uploadFile({
        variables: {
          blobs: [
            {
              file: info.file.originFileObj,
              path: AfBlobPath.FaceCallPerformerAvatar,
            },
          ],
        },
      }).catch((e) => console.log(e));
    },
    maxCount: 1,
    listType: "picture",
    onRemove: () => setUploadedFileName(""),
  };

  const handleSearch = () => {
    const filteredData = displayList.filter((performer: any) =>
      performer.name.toLowerCase().includes(searchText.toLowerCase())
    );
    setDisplayList(filteredData);
  };

  const handleEditClick = (performer: DataType) => {
    setSelectPerformer(performer);
    setShowDrawer(true);
  };

  const handleListClick = (performer: DataType) => {
    setActiveKey("1");
    setFilterKey(performer.name);
  };

  const handleDelete = (performer: DataType) => {
    if (performer.countOfSong > 0) {
      setModalOpen(true);
    } else {
      deletePerformer({
        variables: {
          performerId: parseInt(renderDecodedId(performer.id)),
        },
      })
        .then(() => {
          refetch();
          setShowDrawer(false);
          setSelectPerformer(undefined);
          form.resetFields();
        })
        .catch((e) => console.log(e));
    }
  };

  const handleSubmit = (values: any) => {
    const isZippy = type === FaceCallPerformerTypes.PERFORMER ? false : true;
    const formData = {
      name: values.name,
      market: values.region ? values.region : "",
      avatarFileName: uploadedFileName,
      isZippy,
    };
    console.log(formData);
    if (!selectPerformer) {
      createPerformer({ variables: formData })
        .then(() => {
          refetch();
          setShowDrawer(false);
          setSelectPerformer(undefined);
          form.resetFields();
        })
        .catch((e) => console.log(e));
    } else {
      updatePerformer({
        variables: {
          ...formData,
          id: parseInt(renderDecodedId(selectPerformer.id)),
        },
      })
        .then(() => {
          refetch();
          setShowDrawer(false);
          setSelectPerformer(undefined);
          form.resetFields();
        })
        .catch((e) => console.log(e));
    }
  };

  useEffect(() => {
    if (data) {
      const transformData = data.afFaceCallPerformer.map((p) => ({
        id: p.id,
        name: p.performerName,
        imageUrl: `${blobInfo?.afBlobContainerSignature[1].uri}/${p.avatarFileName}${blobInfo?.afBlobContainerSignature[1].token}`,
        countOfSong: p.faceCallSongAndPerformers?.length,
        region: p.market,
        fileName: p.avatarFileName,
        publishedSongs: p.publishdSongs,
      }));
      setAllPerformers(transformData);
      setDisplayList(transformData);
    }
  }, [data]);

  useEffect(() => {
    if (selectPerformer) {
      setUploadedFileName(selectPerformer.fileName);
      form.setFieldsValue({
        name: selectPerformer.name,
        region: selectPerformer.region,
        imageFile: [
          {
            uid: "0",
            name: selectPerformer.fileName,
            status: "done",
            url: selectPerformer?.imageUrl,
            thumbUrl: selectPerformer?.imageUrl,
          },
        ],
      });
    }
  }, [selectPerformer, form]);

  // useEffect(() => {
  //   let cancelled = false;
  //   const getHeight = () => {
  //     const { current } = coverRef;
  //     if (!current || !current.clientHeight) {
  //       if (!cancelled) {
  //         requestAnimationFrame(getHeight);
  //       }
  //     } else {
  //       setOverlayWidthAndHeight({
  //         width: current.clientWidth,
  //         height: current.clientHeight,
  //       });
  //     }
  //   };
  //   getHeight();
  //   return () => {
  //     cancelled = true;
  //   };
  // }, [coverRef.current]);

  if (error || blobError) {
    message.error(
      renderAntDMessageConfig(
        "Something went wrong. Please contact your system administrator."
      )
    );
  }

  if (loading || blobLoading) {
    return <Spinner />;
  }

  return (
    <>
      <Modal
        centered
        open={modalOpen}
        onOk={() => setModalOpen(false)}
        closeIcon={null}
        footer={[
          <Button
            type="primary"
            onClick={() => setModalOpen(false)}
            style={{ marginRight: 8, marginLeft: 8, marginBottom: 4 }}
          >
            Got it
          </Button>,
        ]}
        width={417}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            height: 76,
            marginBottom: 24,
            marginRight: 8,
            marginLeft: 8,
            marginTop: 12,
          }}
        >
          <img
            alt="wrong circle icon"
            src={require("../../../../assets/icons/close-circle.png")}
            style={{ width: 22, height: 22, marginRight: 16 }}
          />
          <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
            <p style={{ fontSize: 16, fontWeight: 500 }}>
              The performer cannot be deleted
            </p>
            <p style={{ fontSize: 14, fontWeight: 400, lineHeight: 1.5 }}>
              There are more than 1 songs associated with the performer.
            </p>
          </div>
        </div>
      </Modal>
      <div className="performer-tab-content-container">
        <div
          className="performer-tab-content-inner-container"
          style={{ backgroundColor: Colors.white }}
        >
          <div className="performer-tab-content-header-container">
            <Search
              placeholder={
                type === FaceCallPerformerTypes.PERFORMER
                  ? "input performer's name"
                  : "input Zippy's or his friends' name"
              }
              onChange={(e) => {
                setSearchText(e.target.value);
                if (e.target.value === "") {
                  setDisplayList(allPerformers);
                }
              }}
              onSearch={handleSearch}
              style={{ width: 264 }}
              allowClear
            />
            {currentUser?.isAdministrator ? (
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setShowDrawer(true)}
              >
                Create New
              </Button>
            ) : null}
          </div>
          <List
            grid={{
              gutter: 16,
              xs: 1,
              sm: 2,
              md: 4,
              lg: 4,
              xl: 6,
              xxl: 8,
            }}
            dataSource={displayList}
            renderItem={(item: any) => (
              <List.Item>
                <Card
                  cover={
                    <img alt="avatar" src={item.imageUrl} ref={coverRef} />
                  }
                  style={{ padding: 0 }}
                  bodyStyle={{ padding: 0 }}
                >
                  <Meta
                    style={{ padding: "8px 12px", margin: 0 }}
                    title={item.name}
                    description={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: 4,
                        }}
                      >
                        <p style={{ color: "#000000" }}>
                          {item.publishedSongs} videos
                        </p>
                        <p>{`(${item.countOfSong})`}</p>
                      </div>
                    }
                  />
                  <Meta
                    style={{
                      padding: "12px 0",
                      margin: 0,
                      borderTop: "1px solid #F0F0F0",
                    }}
                    description={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          style={{ border: 0, padding: 0 }}
                          onClick={() =>
                            !currentUser?.isAdministrator ||
                            handleEditClick(item)
                          }
                        >
                          <img
                            alt="edit icon"
                            src={require("../../../../assets/icons/edit1.png")}
                            style={{ width: 16, height: 16 }}
                          />
                        </Button>
                        <Divider type="vertical" />
                        <Button
                          style={{
                            border: 0,
                            padding: 0,
                            display: "flex",
                            alignItems: "center",
                          }}
                          onClick={() => handleListClick(item)}
                        >
                          <UnorderedListOutlined style={{ color: "#000000" }} />
                        </Button>
                      </div>
                    }
                  />
                </Card>
              </List.Item>
            )}
          />
        </div>
      </div>
      <Drawer
        title={
          selectPerformer ? `Edit ${drawerTitle}` : `Create new ${drawerTitle}`
        }
        open={showDrawer}
        onClose={() => {
          form.resetFields();
          setSelectPerformer(undefined);
          setShowDrawer(false);
        }}
        width={500}
      >
        <Form
          name="performer-info-form"
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <Form.Item
            label="Performer name"
            name="name"
            rules={[{ required: true, message: "This field is required!" }]}
          >
            <InputCustom />
          </Form.Item>
          <Form.Item label="Region from" name="region">
            <Select
              placeholder="- Select -"
              options={regions.map((region) => ({
                label: region.region,
                value: region.region,
              }))}
            />
          </Form.Item>
          <Form.Item
            label="Performer photo"
            name="imageFile"
            rules={[{ required: true, message: "This field is required!" }]}
            valuePropName="fileList"
            getValueFromEvent={(e) => {
              if (Array.isArray(e)) {
                return e;
              }
              return e && e.fileList;
            }}
          >
            <Upload {...props} className="performer-upload">
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
          <DrawerFooter
            handleCancel={() => {
              form.resetFields();
              setSelectPerformer(undefined);
              setShowDrawer(false);
            }}
            handleDelete={handleDelete}
            performer={selectPerformer}
            isLoading={createLoading || updateLoading}
            isDeleteLoading={deleteLoading}
          />
        </Form>
      </Drawer>
    </>
  );
};

export default PerformerTabContent;
