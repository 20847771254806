import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateTwGraduationStatusMutationVariables = Types.Exact<{
  memberNumber: Types.Scalars['String']['input'];
  childNumber: Types.Scalars['String']['input'];
  registrationDate: Types.Scalars['DateTime']['input'];
  registrationAge: Types.Scalars['String']['input'];
  creator?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type UpdateTwGraduationStatusMutation = { __typename?: 'Mutation', afUpdateChildGraduationOfTaiwan: { __typename?: 'Payload', count: number } };


export const UpdateTwGraduationStatusDocument = gql`
    mutation updateTWGraduationStatus($memberNumber: String!, $childNumber: String!, $registrationDate: DateTime!, $registrationAge: String!, $creator: String) {
  afUpdateChildGraduationOfTaiwan(
    graduationOfTaiwan: {memberNumber: $memberNumber, childNumber: $childNumber, registrationDate: $registrationDate, registrationAge: $registrationAge, creator: $creator}
  ) {
    count
  }
}
    `;
export type UpdateTwGraduationStatusMutationFn = Apollo.MutationFunction<UpdateTwGraduationStatusMutation, UpdateTwGraduationStatusMutationVariables>;

/**
 * __useUpdateTwGraduationStatusMutation__
 *
 * To run a mutation, you first call `useUpdateTwGraduationStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTwGraduationStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTwGraduationStatusMutation, { data, loading, error }] = useUpdateTwGraduationStatusMutation({
 *   variables: {
 *      memberNumber: // value for 'memberNumber'
 *      childNumber: // value for 'childNumber'
 *      registrationDate: // value for 'registrationDate'
 *      registrationAge: // value for 'registrationAge'
 *      creator: // value for 'creator'
 *   },
 * });
 */
export function useUpdateTwGraduationStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTwGraduationStatusMutation, UpdateTwGraduationStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTwGraduationStatusMutation, UpdateTwGraduationStatusMutationVariables>(UpdateTwGraduationStatusDocument, options);
      }
export type UpdateTwGraduationStatusMutationHookResult = ReturnType<typeof useUpdateTwGraduationStatusMutation>;
export type UpdateTwGraduationStatusMutationResult = Apollo.MutationResult<UpdateTwGraduationStatusMutation>;
export type UpdateTwGraduationStatusMutationOptions = Apollo.BaseMutationOptions<UpdateTwGraduationStatusMutation, UpdateTwGraduationStatusMutationVariables>;