import dayjs from "dayjs";

export default function publishDateRule({
  getFieldValue,
}: {
  getFieldValue: any;
}) {
  return {
    validator(rule: any, value: any) {
      const stopDate = getFieldValue("stopDate");

      if (stopDate && !value)
        return Promise.reject(
          "Publish date is required when stop date is selected"
        );

      if (stopDate && value && dayjs(value).isAfter(dayjs(stopDate)))
        return Promise.reject("Publish date must be before stop date");

      return Promise.resolve();
    },
  };
}
