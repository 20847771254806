import { ReactNode } from "react";
import "./ContentSection.css";
import { Colors } from "../../../constants/Colors";

interface ContentSectionProps {
  children: ReactNode;
  title: string;
  showTitle?: boolean;
}

const ContentSection = ({
  children,
  title,
  showTitle,
}: ContentSectionProps) => {
  return (
    <div className="section-container">
      <div
        className="section-title-container"
        style={{
          backgroundColor: Colors.white,
          display: showTitle ? "flex" : "none",
        }}
      >
        <p>{title}</p>
      </div>
      <div
        className="section-content-container"
        style={{ backgroundColor: Colors.white }}
      >
        {children}
      </div>
    </div>
  );
};

export default ContentSection;
