// @ts-nocheck
import { Dispatch, SetStateAction, useEffect, useState, useRef } from "react";
import { Button, Table, message, Select, Space, Input, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import Highlighter from "react-highlight-words";
import { ServiceTypes } from "../../../types/types";
import { Colors } from "../../../constants/Colors";
import type { ColumnsType } from "antd/es/table";
import { useReactiveVar } from "@apollo/client";
import { __currentUser__ } from "../../../graphql/policies";
import { useGetAllNotReviewedGraduationApplyQuery } from "../../../graphql/operations/clubApp/graduation/get-all-not-reviewed-graduation-apply";
import { useGetAllGraduationTaskNameAndDescriptionQuery } from "../../../graphql/operations/clubApp/graduation/get-graduation-all-task";
import "./WaitingForReview.scss";
import { SearchOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useGetUserProgramsByServiceQuery } from "../../../graphql/operations/get-user-program-by-service";
import { useUpdateGraduationCountMutation } from "../../../graphql/operations/clubApp/graduation/update-graduation-count";
import { useGetGraduationProcessQuery } from "../../../graphql/operations/clubApp/graduation/get-graduation-process";
import { useGetGraduationProcessTwQuery } from "../../../graphql/operations/clubApp/graduation/get-graduation-process-tw";
import { renderDecodedId } from "../../../utils/renderDecodedId";
import Spinner from "../../../components/layout/spinner/Spinner";
import NoPermission from "../../../components/others/NoPermission";
import { renderAntDMessageConfig } from "../../../utils/renderAntDMessageConfig";
import { DataType, TaskDataType, FilterType } from "./types";
import { useDeleteGraduationApplicationMutation } from "../../../graphql/operations/clubApp/graduation/delete-graduation-application";
import { currentYear } from "./utils/yearPeriod";

interface GraduationProps {
  setSelectedService: Dispatch<SetStateAction<ServiceTypes>>;
  setSelectSubItem: Dispatch<SetStateAction<string | undefined>>;
}

const WaitingForReview = ({
  setSelectedService,
  setSelectSubItem,
}: GraduationProps) => {
  const [displayData, setDisplayData] = useState<DataType[] | any>([]);
  const [taskDataList, setTaskDataList] = useState<TaskDataType[] | any>([]);
  const [currentMarket, setCurrentMarket] = useState("");
  const [taskFilter, setTaskFilter] = useState<FilterType[] | any>([]);
  const [dateFilter, setDateFilter] = useState([]);
  const [dateArray, setDateArray] = useState([]);
  const [total, setTotal] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [toBeDeletedRecord, setToBeDeletedRecord] = useState(null);
  const [toBeModifiedProcess, setToBeModifiedProcess] = useState(null);
  const searchInput = useRef(null);
  const navigate = useNavigate();
  const handleSearch = (
    selectedKeys: string,
    confirm: Function,
    dataIndex: string
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters: Function) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const currentUser = useReactiveVar(__currentUser__);
  const {
    data: userPrograms,
    loading: userProgramsLoading,
    error: userProgramsError,
  } = useGetUserProgramsByServiceQuery({
    variables: {
      userId: parseInt(renderDecodedId(currentUser?.id)),
      serviceId: 2,
    },
  });

  const numberIconList = ["➊", "➋", "➌", "➍", "➎", "➏", "➐", "➑"];

  const columns: ColumnsType<DataType> = [
    {
      title: "Applied date",
      dataIndex: "applicationDate",
      key: "applicationDate",
      width: 200,
      render: (value: string) =>
        value ? dayjs(value).format("YYYY-MM-DD") : "",
      sorter: (a, b) =>
        dayjs(a.applicationDate).valueOf() - dayjs(b.applicationDate).valueOf(),
      defaultSortOrder: "ascend",
      filters: dateFilter,
      onFilter: (value, record) =>
        dayjs(record.applicationDate).format("YYYY-MM-DD") === value,
    },
    {
      title: "Review deadline",
      dataIndex: "applicationDate",
      key: "applicationDate",
      width: 200,
      sorter: (a, b) =>
        dayjs(a.applicationDate).valueOf() - dayjs(b.applicationDate).valueOf(),
      render: (applicationDate, record) => {
        const now = dayjs();
        const dateObj = dayjs(applicationDate);
        const daysDiff = -dateObj.diff(now, "day");
        const newDate = dateObj.add(13, "day");
        return (
          <span className="deadline-container">
            {newDate ? dayjs(newDate).format("YYYY-MM-DD") : ""}
            {" 23:59"}
            {daysDiff < 7 ? null : daysDiff < 13 ? (
              <img
                className="warning-icon"
                alt="warning icon"
                src={require("../../../assets/icons/clubApp/graduation/warning-icon.png")}
              />
            ) : (
              <img
                className="warning-icon"
                alt="expired icon"
                src={require("../../../assets/icons/clubApp/graduation/expired-icon.png")}
              />
            )}
          </span>
        );
      },
    },
    {
      title: "Member ID",
      dataIndex: "memberNo",
      key: "memberNo",
      width: 150,
      ...getColumnSearchProps("memberNo"),
    },
    {
      title: "Kid (age)",
      dataIndex: "childName",
      key: "kidAge",
      width: 110,
      ...getColumnSearchProps("childName"),
      render: (text, record) => {
        const years = record.age.substring(0, record.age.indexOf("Y"));
        return (
          <span>
            {text} ({years})
          </span>
        );
      },
    },
    {
      title: "English name",
      dataIndex: "childEnglishName",
      key: "childEnglishName",
      width: 150,
    },
    {
      title: "Task name",
      dataIndex: "taskId",
      key: "taskId",
      width: 300,
      filters: taskFilter,
      onFilter: (value, record) => record.taskId === value,
      render: (id, record) => {
        const task = taskDataList.find(
          (task: TaskDataType) =>
            task.taskId === id && task.market === currentMarket
        );
        if (task) {
          return (
            <span>
              {numberIconList[id - 1] + " "}
              {task.taskName}
            </span>
          );
        } else {
          return <></>;
        }
      },
    },
    {
      title: "Actions",
      key: "actions",
      width: 100,
      render: (_, record) => (
        <div className="action-box">
          <Button
            type="link"
            onClick={() => {
              const id = renderDecodedId(record.id);
              navigate(`/club-app/review-graduation-cap/${id}`);
            }}
            className="p0"
          >
            Review
          </Button>
          <Button
            type="link"
            onClick={() => {
              setModalOpen(true);
              setToBeDeletedRecord(record);
            }}
            className="p0"
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];
  const { data, loading, refetch } = useGetAllNotReviewedGraduationApplyQuery();
  const { data: taskData } = useGetAllGraduationTaskNameAndDescriptionQuery();
  const [updateGraduationCount] = useUpdateGraduationCountMutation();
  const [deleteGraduationApplication] =
    useDeleteGraduationApplicationMutation();
  const { data: twProcess, refetch: twProcessRefetch } =
    useGetGraduationProcessTwQuery({
      variables: {
        childNo: toBeDeletedRecord?.childNo,
        memberNo: toBeDeletedRecord?.memberNo,
        applicationYear: currentYear,
      },
    });
  const { data: process, refetch: processRefetch } =
    useGetGraduationProcessQuery({
      variables: {
        childNo: toBeDeletedRecord?.childNo,
        memberNo: toBeDeletedRecord?.memberNo,
      },
    });
  const handleChange = (value: string) => {
    setCurrentMarket(value);
  };
  const handleDelete = () => {
    const deleteGraduationApplicationPromise = deleteGraduationApplication({
      variables: {
        applicationId: parseInt(renderDecodedId(toBeDeletedRecord.id)),
      },
    });
    const updateGraduationProcessPromise = updateGraduationCount({
      variables: {
        childApplicationId: parseInt(renderDecodedId(toBeModifiedProcess.id)),
        market: toBeModifiedProcess.market,
        memberNo: toBeModifiedProcess.memberNo,
        childNo: toBeModifiedProcess.childNo,
        applicationCount: toBeModifiedProcess.applicationCount - 1,
        applicationYear: toBeModifiedProcess.applicationYear,
        approvedCount: toBeModifiedProcess.approvedCount,
        rejectedCount: toBeModifiedProcess.rejectedCount,
        lastApprovalDate: toBeModifiedProcess.lastApprovalDate,
        isExcellent: false,
        graduationDate: null,
      },
    });
    Promise.all([
      deleteGraduationApplicationPromise,
      updateGraduationProcessPromise,
    ])
      .then(() => {
        refetch();
        setToBeDeletedRecord(null);
        setToBeModifiedProcess(null);
        setModalOpen(false);
      })
      .catch((e) => console.log(e));
  };
  useEffect(() => {
    setSelectedService(ServiceTypes.CLUB_APP);
    setSelectSubItem("Waiting for Review");
    refetch();
  }, []);
  useEffect(() => {
    const uniqueSet = new Set(dateArray);
    const uniqueArray = Array.from(uniqueSet);
    const filterFormArray = uniqueArray.map((date) => ({
      text: date,
      value: date,
    }));
    setDateFilter(filterFormArray);
  }, [dateArray]);
  useEffect(() => {
    setDateArray(
      displayData.map((data) =>
        dayjs(data.applicationDate).format("YYYY-MM-DD")
      )
    );
  }, [displayData]);
  useEffect(() => {
    if (data) {
      setDisplayData(
        data.afGraduationApplication.filter(
          (data) => data.market === currentMarket
        )
      );
    }
    if (taskDataList.length > 0) {
      const marketDataList = taskDataList.filter(
        (task: TaskDataType) => task.market === currentMarket
      );
      const replica = [];
      for (let i = 0; i < marketDataList.length; i++) {
        replica.push({
          text:
            numberIconList[i] +
            " " +
            marketDataList.filter(
              (task: TaskDataType) => task.taskId === i + 1
            )[0].taskName,
          value: i + 1,
        });
      }
      setTaskFilter(replica);
    }
  }, [currentMarket, taskDataList, data]);
  useEffect(() => {
    if (currentMarket === "TW") {
      twProcessRefetch();
    } else {
      processRefetch();
    }
  }, [toBeDeletedRecord]);
  useEffect(() => {
    if (currentMarket === "TW") {
      setToBeModifiedProcess(
        twProcess?.club_worldFamilyClub_childGraduationApplication?.nodes[0]
      );
    } else {
      setToBeModifiedProcess(
        process?.club_worldFamilyClub_childGraduationApplication?.nodes.find(
          (item) =>
            item.applicationYear?.toString() ===
            toBeDeletedRecord?.yearCount.split("-")[0]
        )
      );
    }
  }, [process, twProcess]);

  useEffect(() => {
    setTotal(displayData.length);
  }, [displayData]);
  useEffect(() => {
    if (taskData) {
      setTaskDataList(
        taskData.club_worldFamilyClub_graduationTaskMarketDescription?.nodes
      );
    }
  }, [taskData]);
  useEffect(() => {
    if (currentUser?.afUserMarkets && currentUser?.afUserMarkets[0]?.market) {
      if (currentUser?.afUserMarkets.length === 1) {
        setCurrentMarket(currentUser?.afUserMarkets[0]?.market);
      } else {
        setCurrentMarket("TW");
      }
    }
  }, [currentUser]);
  if (userProgramsError) {
    message.error(
      renderAntDMessageConfig(
        "Something went wrong. Please contact your system administrator."
      )
    );
  }

  if (userProgramsLoading) {
    return <Spinner />;
  }
  if (loading) {
    return <Spinner />;
  }

  if (
    userPrograms &&
    !userPrograms.wf_adminFirst_afUserProgram?.edges?.find(
      (item) => item.node.afProgram?.programName === "Waiting for Review"
    )
  ) {
    return <NoPermission />;
  }
  return (
    <div id="waitingForReviewWrapper">
      <div className="header" style={{ backgroundColor: Colors.white }}>
        <p className="header-title" style={{ color: Colors.darkGrey }}>
          Waiting for Review
        </p>
      </div>
      <div className="table-container">
        <div className="select">
          <div className="total-container">Total {total} items</div>
          <div
            style={
              currentUser?.afUserMarkets?.length === 1
                ? { display: "none" }
                : {}
            }
          >
            <span>Market:</span>
            <Select
              onChange={handleChange}
              defaultValue="TW"
              options={[
                {
                  value: "TW",
                  label: "TW",
                },
                {
                  value: "HK",
                  label: "HK",
                },
                {
                  value: "KR",
                  label: "KR",
                },
              ]}
            ></Select>
          </div>
        </div>
        <Table
          className="table"
          columns={columns}
          dataSource={displayData}
          onChange={(pagination, filters, sorter, extra) => {
            setTotal(extra.currentDataSource.length);
          }}
          scroll={{
            x: true,
            y: 500,
          }}
          pagination={false}
        ></Table>
      </div>
      <Modal
        centered
        open={modalOpen}
        closeIcon={null}
        footer={[
          <div className="modal-button-group">
            <Button
              onClick={() => {
                setModalOpen(false);
                setToBeDeletedRecord(null);
                setToBeModifiedProcess(null);
              }}
              style={{ marginRight: 8, marginLeft: 8, marginBottom: 4 }}
            >
              No
            </Button>
            <Button
              danger
              onClick={handleDelete}
              style={{ marginRight: 8, marginLeft: 8, marginBottom: 4 }}
            >
              Delete
            </Button>
          </div>,
        ]}
        width={417}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            height: 76,
            marginBottom: 24,
            marginRight: 8,
            marginLeft: 8,
            marginTop: 12,
          }}
        >
          <img
            alt="warning circle icon"
            src={require("../../../assets/icons/warning-circle.png")}
            style={{ width: 22, height: 22, marginRight: 16 }}
          />
          <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
            <p style={{ fontSize: 16, fontWeight: 500 }}>
              Are you sure you want to delete the submission record of the kid ‘
              {toBeDeletedRecord?.childName} {toBeDeletedRecord?.memberNo}’?
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default WaitingForReview;
