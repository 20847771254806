import { Form, Select } from "antd";

import requiredRule from "../../../../../utils/formRules/requiredRule";
import { TrialModeServiceType } from "../../types";

interface OptionType {
  value: TrialModeServiceType;
  label: string;
}

const options: OptionType[] = [
  { value: TrialModeServiceType.ClubNews, label: "Club News" },
  { value: TrialModeServiceType.News, label: "News" },
  { value: TrialModeServiceType.Survey, label: "Survey" },
  { value: TrialModeServiceType.GoGoLive, label: "Go, Go Live!" },
];

export default function FormItemServiceType() {
  return (
    <Form.Item name="serviceType" label="Service Type" rules={[requiredRule]}>
      <Select
        options={options}
        className="w-[484px]"
        placeholder="- Please Select -"
      />
    </Form.Item>
  );
}
