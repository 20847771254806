import React from "react";

const NoPermission = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: 300,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: 60,
            height: 60,
            borderRadius: "50%",
            overflow: "hidden",
            backgroundColor: "#C7894B",
            marginBottom: 24,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={require("../../assets/icons/lock.png")}
            alt="lock"
            style={{ width: 40, height: 40 }}
          />
        </div>
        <p
          style={{
            textAlign: "center",
            fontSize: 16,
            fontWeight: 500,
            lineHeight: "24px",
          }}
        >
          Sorry, you have no permission with the program
        </p>
      </div>
    </div>
  );
};

export default NoPermission;
