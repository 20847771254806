import { Form, Select } from "antd";

import InputCustom from "../../../../../components/custom/InputCustom";
import requiredRule from "../../../../../utils/formRules/requiredRule";
import {
  NotificationActionButtonType,
  NotificationButtonLinkType,
} from "../../types";

interface OptionType {
  value: NotificationButtonLinkType;
  label: string;
}

const options: OptionType[] = [
  { value: NotificationButtonLinkType.ABCChannel, label: "ABC Channel" },
  { value: NotificationButtonLinkType.FaceCall, label: "Face Call" },
  {
    value: NotificationButtonLinkType.ParentsSupport,
    label: "Parents Support",
  },
];

export default function FormItemButtonLinksTo({
  actionButton,
}: {
  actionButton: NotificationActionButtonType;
}) {
  if (actionButton === NotificationActionButtonType.ClubAppScreen)
    return (
      <Form.Item
        name="buttonLinksTo"
        label="Button links to"
        rules={[requiredRule]}
      >
        <Select
          options={options}
          className="w-[484px]"
          placeholder="- Please Select -"
        />
      </Form.Item>
    );

  return (
    <Form.Item
      name="buttonLinksToUrl"
      label="Button links to"
      rules={[requiredRule]}
    >
      <InputCustom addonBefore="https://" />
    </Form.Item>
  );
}
