import { GetSegmentationQuery } from "../../../graphql/operations/trialMode/get-segmentation";

export default function convertToSegMapping(data: GetSegmentationQuery) {
  const result: { [key: string]: string } = {};

  data?.wf_trialModeLeads_trialSegmentation?.nodes?.forEach(
    ({ segmentation, segmentationDescription }) => {
      if (segmentation) result[segmentation] = segmentationDescription || "";
    }
  );

  return result;
}
