import { Upload, message } from "antd";

const duration = 5;

export default function imageValidation(maxSize: number, unit: "MB" | "KB") {
  const maxSizeInBytes = unit === "MB" ? maxSize * 1024 * 1024 : maxSize * 1024;

  return function (file: any) {
    const isImage =
      file.type === "image/jpeg" ||
      file.type === "image/jpg" ||
      file.type === "image/png" ||
      file.type === "image/gif";
    const isSizeValid = file.size <= maxSizeInBytes;

    if (!isImage) {
      message.error(`${file.name} is not a JPEG/JPG/PNG/GIF file`, duration);
    }
    if (!isSizeValid) {
      const maxSizeDisplay = unit === "MB" ? maxSize + "MB" : maxSize + "KB";

      message.error(
        `Please upload an image that is less than ${maxSizeDisplay}.`,
        duration
      );
    }

    return (isImage && isSizeValid) || Upload.LIST_IGNORE;
  };
}
