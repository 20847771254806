import { ReactNode } from "react";
import { Drawer, Form, FormInstance } from "antd";

import DrawerFooter from "../layout/drawer/DrawerFooter";

export default function DrawerCustom({
  title,
  name,
  form,
  onFinish,
  children,
  openDrawer,
  setOpenDrawer,
  isLoading,
  disabled = false,
}: {
  title: string;
  name: string;
  form: FormInstance<any>;
  onFinish: (values: any) => void;
  children: ReactNode;
  openDrawer: boolean;
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  disabled?: boolean;
}) {
  return (
    <Drawer
      title={title}
      className="lengthy-drawer"
      width={500}
      open={openDrawer}
      onClose={() => {
        form.resetFields();
        setOpenDrawer(false);
      }}
    >
      <Form name={name} layout="vertical" form={form} onFinish={onFinish}>
        {children}

        <DrawerFooter
          handleCancel={() => {
            form.resetFields();
            setOpenDrawer(false);
          }}
          isLoading={isLoading}
          disabled={disabled}
        />
      </Form>
    </Drawer>
  );
}
