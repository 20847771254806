import * as Types from './@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUserProgramsByServiceQueryVariables = Types.Exact<{
  userId: Types.Scalars['Int']['input'];
  serviceId: Types.Scalars['Int']['input'];
}>;


export type GetUserProgramsByServiceQuery = { __typename?: 'Query', wf_adminFirst_afUserProgram?: { __typename?: 'WF_AdminFirst_AfUserProgramConnection', edges?: Array<{ __typename?: 'WF_AdminFirst_AfUserProgramEdge', node: { __typename?: 'WF_AdminFirst_AfUserProgram', id: string, afUserId?: number | null, afProgram?: { __typename?: 'WF_AdminFirst_AfProgram', programName?: string | null, afServiceId?: number | null } | null } }> | null } | null };


export const GetUserProgramsByServiceDocument = gql`
    query GetUserProgramsByService($userId: Int!, $serviceId: Int!) {
  wf_adminFirst_afUserProgram(
    first: 100
    filter: {afUserId: {eq: $userId}, afProgram: {afServiceId: {eq: $serviceId}}}
  ) {
    edges {
      node {
        id
        afUserId
        afProgram {
          programName
          afServiceId
        }
      }
    }
  }
}
    `;

/**
 * __useGetUserProgramsByServiceQuery__
 *
 * To run a query within a React component, call `useGetUserProgramsByServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProgramsByServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProgramsByServiceQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      serviceId: // value for 'serviceId'
 *   },
 * });
 */
export function useGetUserProgramsByServiceQuery(baseOptions: Apollo.QueryHookOptions<GetUserProgramsByServiceQuery, GetUserProgramsByServiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserProgramsByServiceQuery, GetUserProgramsByServiceQueryVariables>(GetUserProgramsByServiceDocument, options);
      }
export function useGetUserProgramsByServiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserProgramsByServiceQuery, GetUserProgramsByServiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserProgramsByServiceQuery, GetUserProgramsByServiceQueryVariables>(GetUserProgramsByServiceDocument, options);
        }
export type GetUserProgramsByServiceQueryHookResult = ReturnType<typeof useGetUserProgramsByServiceQuery>;
export type GetUserProgramsByServiceLazyQueryHookResult = ReturnType<typeof useGetUserProgramsByServiceLazyQuery>;
export type GetUserProgramsByServiceQueryResult = Apollo.QueryResult<GetUserProgramsByServiceQuery, GetUserProgramsByServiceQueryVariables>;