import { Dispatch, SetStateAction } from "react";
import { Form } from "antd";

import { useCreateTrialModeNotificationMutation } from "../../../../graphql/operations/trialMode/pushNotification/create-trial-mode-notification";
import { useUploadFileMutation } from "../../../../graphql/operations/upload-file";

import Spinner from "../../../../components/layout/spinner/Spinner";
import getSegmentationDesc from "../../utils/getSegmentationDesc";
import ViewAction from "../components/ViewAction";
import { actionButtonMapping, buttonLinkMapping } from "../mapping";
import { TrialModeNotificationModifyData } from "../types";
import handleSubmit from "./handleSubmit";

export default function View({
  data,
  setViewData,
  refetch,
}: {
  data: TrialModeNotificationModifyData;
  setViewData: Dispatch<SetStateAction<TrialModeNotificationModifyData | null>>;
  refetch: any;
}) {
  const {
    actionButton,
    buttonLinksTo,
    buttonText,
    imageFileName,
    imageUrl,
    market,
    notificationName,
    segmentations,
    templateMessage,
    title,
    scheduleTime,
    jobStatus,
  } = data;
  const segmentationsText = segmentations
    .map((value) => getSegmentationDesc(value))
    .join(", ");

  const [createNotification, { loading: loadingOfCreate }] =
    useCreateTrialModeNotificationMutation();
  const [uploadFile] = useUploadFileMutation();

  return (
    <div className="flex justify-center py-6">
      <Form
        name="repushNotification"
        className="form-align-center"
        initialValues={data}
        onFinish={(values) => {
          handleSubmit({
            values,
            createNotification,
            uploadFile,
            refetch,
            setViewData,
            isRePush: true,
          });
        }}
      >
        <Form.Item name="market" label="Market">
          <p>{market}</p>
        </Form.Item>

        <Form.Item
          name="segmentations"
          label="Segmentation"
          className="w-[300px]"
        >
          <div className="w-[484px]">{segmentationsText}</div>
        </Form.Item>

        <Form.Item name="serviceType" label="Service Type">
          <p>{notificationName}</p>
        </Form.Item>

        {title ? (
          <Form.Item name="title" label="Title">
            <p>{title}</p>
          </Form.Item>
        ) : null}

        <Form.Item name="templateMessage" label="Message">
          <div className="w-[484px]">{templateMessage}</div>
        </Form.Item>

        {imageFileName ? (
          <Form.Item name="imageFileName" label="Image">
            <div className="border border-solid border-grey3 p-2 rounded-sm w-fit">
              <div className="size-[84px] flex items-center justify-center">
                <img
                  src={imageUrl}
                  alt={imageFileName}
                  className="object-contain"
                />
              </div>
            </div>
          </Form.Item>
        ) : null}

        <Form.Item name="actionButton" label="Action Button">
          <p>{actionButtonMapping[actionButton] || actionButton}</p>
        </Form.Item>

        {actionButton ? (
          <>
            <Form.Item name="buttonLinksTo" label="Button links to">
              <p>{buttonLinkMapping[buttonLinksTo] || buttonLinksTo}</p>
            </Form.Item>

            <Form.Item name="buttonText" label="Button text">
              <p>{buttonText}</p>
            </Form.Item>
          </>
        ) : null}

        <Form.Item name="scheduleTime" label="Push date and time">
          <p>{scheduleTime}</p>
        </Form.Item>

        <ViewAction jobStatus={jobStatus} />
      </Form>

      {loadingOfCreate ? <Spinner /> : null}
    </div>
  );
}
