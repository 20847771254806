import * as Types from './@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateFaceCallVideoMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  startDate?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  endDate?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  songId: Types.Scalars['Int']['input'];
  performerId: Array<Types.Club_WorldFamilyClub_FaceCallSongAndPerformerInput> | Types.Club_WorldFamilyClub_FaceCallSongAndPerformerInput;
  vimeoId: Types.Scalars['String']['input'];
  themeId: Types.Scalars['Int']['input'];
}>;


export type UpdateFaceCallVideoMutation = { __typename?: 'Mutation', club_worldFamilyClub: { __typename?: 'Club_WorldFamilyClub_Mutation_Payload', count: number } };


export const UpdateFaceCallVideoDocument = gql`
    mutation UpdateFaceCallVideo($id: Int!, $startDate: DateTime, $endDate: DateTime, $songId: Int!, $performerId: [Club_WorldFamilyClub_FaceCallSongAndPerformerInput!]!, $vimeoId: String!, $themeId: Int!) {
  club_worldFamilyClub(
    update: {faceCallSong: {faceCallSongId: $id, startDate: $startDate, endDate: $endDate, teSongId: $songId, faceCallSongAndPerformers: $performerId, vimeoVideoId: $vimeoId, faceCallSeasonHolidayId: $themeId}}
  ) {
    count
  }
}
    `;
export type UpdateFaceCallVideoMutationFn = Apollo.MutationFunction<UpdateFaceCallVideoMutation, UpdateFaceCallVideoMutationVariables>;

/**
 * __useUpdateFaceCallVideoMutation__
 *
 * To run a mutation, you first call `useUpdateFaceCallVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFaceCallVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFaceCallVideoMutation, { data, loading, error }] = useUpdateFaceCallVideoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      songId: // value for 'songId'
 *      performerId: // value for 'performerId'
 *      vimeoId: // value for 'vimeoId'
 *      themeId: // value for 'themeId'
 *   },
 * });
 */
export function useUpdateFaceCallVideoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFaceCallVideoMutation, UpdateFaceCallVideoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFaceCallVideoMutation, UpdateFaceCallVideoMutationVariables>(UpdateFaceCallVideoDocument, options);
      }
export type UpdateFaceCallVideoMutationHookResult = ReturnType<typeof useUpdateFaceCallVideoMutation>;
export type UpdateFaceCallVideoMutationResult = Apollo.MutationResult<UpdateFaceCallVideoMutation>;
export type UpdateFaceCallVideoMutationOptions = Apollo.BaseMutationOptions<UpdateFaceCallVideoMutation, UpdateFaceCallVideoMutationVariables>;