import ContentTitle from "../../../components/ui/ContentTitle";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Form,
  Button,
  message,
  Radio,
  Select,
  Input,
  DatePicker,
  Divider,
} from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import "./CreateNotification.scss";
import { ServiceTypes } from "../../../types/types";
import { useNavigate } from "react-router-dom";
import { useAddSalesNotificationMutation } from "../../../graphql/operations/sales188/addSalesNotification";
import { useGetUserProgramsByServiceQuery } from "../../../graphql/operations/get-user-program-by-service";
import NoPermission from "../../../components/others/NoPermission";
import Spinner from "../../../components/layout/spinner/Spinner";
import { renderAntDMessageConfig } from "../../../utils/renderAntDMessageConfig";
import { renderDecodedId } from "../../../utils/renderDecodedId";
import requiredRule from "../../../utils/formRules/requiredRule";
import { useReactiveVar } from "@apollo/client";
import { __currentUser__ } from "../../../graphql/policies";
import TextArea from "antd/es/input/TextArea";

interface SalesPushNotificationProps {
  setSelectedService: Dispatch<SetStateAction<ServiceTypes>>;
  setSelectSubItem: Dispatch<SetStateAction<string | undefined>>;
}
const CreateNotification = ({
  setSelectedService,
  setSelectSubItem,
}: SalesPushNotificationProps) => {
  const [isValidUserCode, setIsValidUserCode] = useState(false);
  const [userCode, setUserCode] = useState("");
  useEffect(() => {
    setSelectedService(ServiceTypes.SALES_188);
    setSelectSubItem("Push Notification");
  }, []);
  const currentUser = useReactiveVar(__currentUser__);
  const {
    data: userPrograms,
    loading: userProgramsLoading,
    error: userProgramsError,
  } = useGetUserProgramsByServiceQuery({
    variables: {
      userId: parseInt(renderDecodedId(currentUser?.id)),
      serviceId: 5,
    },
  });

  const dayjs = require("dayjs");
  var utc = require("dayjs/plugin/utc");
  var timezone = require("dayjs-timezone-iana-plugin");
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const [form] = Form.useForm();
  const [minorForm] = Form.useForm();
  const navigate = useNavigate();
  const { Option } = Select;
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 11 },
  };
  const [addSalesNotification] = useAddSalesNotificationMutation();
  const validateString = (_: any, value: any) => {
    const fourDigitPattern = /^\d{4}$/;
    const letterAndThreeDigitPattern = /^[A-Za-z]\d{3}$/;
    if (
      fourDigitPattern.test(value) ||
      letterAndThreeDigitPattern.test(value)
    ) {
      setIsValidUserCode(true);
      return Promise.resolve(); // 格式正確，不顯示錯誤
    }
    setIsValidUserCode(false);
    if (value) {
      return Promise.reject(
        "Please enter the employee number in the correct format."
      );
    }
  };
  const handleTestSubmit = () => {
    const testPromise = addSalesNotification({
      variables: {
        market: form.getFieldValue("market"),
        sendingDepartment: form.getFieldValue("department"),
        testEmployeeCodes: [minorForm.getFieldValue("userCode")],
        title: form.getFieldValue("title"),
        templateMessage: form.getFieldValue("content"),
        createdTime: dayjs().utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        creator: currentUser?.userName,
      },
    });
    testPromise
      .then(() => {
        message.success(
          "Notification for test has been submitted. This can sometimes take a few minutes to arrive."
        );
      })
      .catch((e) => {
        console.log("error", e);
      });
  };
  const handleSubmit = () => {
    const submitPromise = addSalesNotification({
      variables: {
        market: form.getFieldValue("market"),
        sendingDepartment: form.getFieldValue("department"),
        target: form.getFieldValue("target"),
        title: form.getFieldValue("title"),
        templateMessage: form.getFieldValue("content"),
        createdTime: dayjs().utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        scheduleTime: form.getFieldValue("time")
          ? dayjs(form.getFieldValue("time"))
              .utc()
              .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
          : null,
        creator: currentUser?.userName,
      },
    });
    submitPromise
      .then(() => {
        message.success("Scheduled Notification has been submitted.");
        navigate("/sales188/push-notification");
      })
      .catch((e) => {
        console.log("error", e);
      });
  };

  if (userProgramsError) {
    message.error(
      renderAntDMessageConfig(
        "Something went wrong. Please contact your system administrator."
      )
    );
  }

  if (userProgramsLoading) {
    return <Spinner />;
  }

  if (
    userPrograms &&
    !userPrograms.wf_adminFirst_afUserProgram?.edges?.find(
      (item) => item.node.afProgram?.programName === "Sales Notification"
    )
  ) {
    return <NoPermission />;
  }
  return (
    <div id="sales188CreateNotificationWrapper">
      <ContentTitle
        title="Notification detail"
        returnTitle="Notification detail"
        onReturn={() => {
          navigate(-1);
        }}
      />
      <div className="wrapper">
        <div className="sales-create-notification-content-container">
          <Form
            form={form}
            name="create-sales-notification"
            {...layout}
            initialValues={{
              market: "TW",
              target: "All advisors",
            }}
          >
            <Form.Item label="Market" name="market">
              <Radio.Group>
                <Radio.Button value="TW">TW</Radio.Button>
                <Radio.Button value="KR" disabled>
                  KR
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Form.Item rules={[requiredRule]} label="Target" name="target">
              <Select>
                <Option value="All users">All users</Option>
                <Option value="All advisors">All advisors</Option>
                <Option value="Managers">Managers</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Sending department"
              name="department"
              rules={[requiredRule]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Title" name="title" rules={[requiredRule]}>
              <Input placeholder="Shown as bold text in the club app" />
            </Form.Item>
            <Form.Item label="Content" name="content" rules={[requiredRule]}>
              <TextArea
                showCount
                maxLength={300}
                placeholder="Autosize height based on content lines"
              />
            </Form.Item>
            <Form.Item label="Push date and time" name="time" className="no-mb">
              <DatePicker showTime placeholder="Select date and time" />
            </Form.Item>
            <Form.Item className="form-reminder">
              <p>Send it immediately when not select date and time</p>
            </Form.Item>
            <div className="divider-wrapper">
              <div className="divider">
                <Divider />
              </div>
            </div>
            <Form form={minorForm} name="test" {...layout}>
              <Form.Item
                name="userCode"
                label="Test employee No."
                rules={[
                  {
                    validator: validateString, // 使用自定義的驗證規則
                  },
                ]}
              >
                <Input
                  placeholder="Enter the employee number for testing"
                  onChange={(e) => {
                    setUserCode(e.target.value);
                  }}
                />
              </Form.Item>
            </Form>
            <Form.Item>
              <Button
                type="primary"
                className="button"
                disabled={!isValidUserCode}
                onClick={() => {
                  Promise.all([
                    form.validateFields(),
                    minorForm.validateFields(),
                  ])
                    .then(() => {
                      handleTestSubmit();
                    })
                    .catch((e) => console.log(e));
                }}
              >
                Submit to test
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <div className="footer">
        {userCode && (
          <div className="warning">
            <InfoCircleFilled style={{ color: "#394CF6", marginRight: 10 }} />
            <p>
              To send it officially, please clear the "Test member No." field
              first
            </p>
          </div>
        )}
        <Button
          size="large"
          style={{
            paddingLeft: 20,
            paddingRight: 20,
          }}
          onClick={() => {
            navigate(-1);
          }}
        >
          Cancel
        </Button>
        <Button
          size="large"
          type="primary"
          style={{
            marginLeft: 8,
            textAlign: "center",
            paddingLeft: 20,
            paddingRight: 20,
          }}
          disabled={userCode !== ""}
          onClick={() => {
            form
              .validateFields()
              .then(() => {
                handleSubmit();
              })
              .catch((errorInfo) => {
                console.log("Validation failed:", errorInfo);
              });
          }}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};
export default CreateNotification;
