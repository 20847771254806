import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddEvaluationMutationVariables = Types.Exact<{
  evaluationData?: Types.InputMaybe<Array<Types.Club_WorldFamilyClub_GraduationApplicationEvaluationInput> | Types.Club_WorldFamilyClub_GraduationApplicationEvaluationInput>;
}>;


export type AddEvaluationMutation = { __typename?: 'Mutation', club_worldFamilyClub: { __typename?: 'Club_WorldFamilyClub_Mutation_Payload', count: number } };


export const AddEvaluationDocument = gql`
    mutation addEvaluation($evaluationData: [Club_WorldFamilyClub_GraduationApplicationEvaluationInput!]) {
  club_worldFamilyClub(
    addRange: {graduationApplicationEvaluations: $evaluationData}
  ) {
    count
  }
}
    `;
export type AddEvaluationMutationFn = Apollo.MutationFunction<AddEvaluationMutation, AddEvaluationMutationVariables>;

/**
 * __useAddEvaluationMutation__
 *
 * To run a mutation, you first call `useAddEvaluationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEvaluationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEvaluationMutation, { data, loading, error }] = useAddEvaluationMutation({
 *   variables: {
 *      evaluationData: // value for 'evaluationData'
 *   },
 * });
 */
export function useAddEvaluationMutation(baseOptions?: Apollo.MutationHookOptions<AddEvaluationMutation, AddEvaluationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddEvaluationMutation, AddEvaluationMutationVariables>(AddEvaluationDocument, options);
      }
export type AddEvaluationMutationHookResult = ReturnType<typeof useAddEvaluationMutation>;
export type AddEvaluationMutationResult = Apollo.MutationResult<AddEvaluationMutation>;
export type AddEvaluationMutationOptions = Apollo.BaseMutationOptions<AddEvaluationMutation, AddEvaluationMutationVariables>;