/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Button, Drawer, Form, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import DrawerFooter from "../../../../components/layout/drawer/DrawerFooter";
import InputCustom from "../../../../components/custom/InputCustom";
import dayjs from "dayjs";
import { Colors } from "../../../../constants/Colors";
import { useUploadFileMutation } from "../../../../graphql/operations/upload-file";
import { AfBlobPath } from "../../../../graphql/operations/@types";
import { renderPosterCAPLevel } from "./GoGoLiveCreateDrawer";
import { renderAntDMessageConfig } from "../../../../utils/renderAntDMessageConfig";

interface GoGoLiveDrawerProps {
  show: boolean;
  form: any;
  setShow: any;
  setSelectVideo: any;
  selectVideo?: any;
  handleConfirm: (values: any) => void;
  setHkPdfFileName: any;
  setKrPdfFileName: any;
  setTwPdfFileName: any;
  setPosterFileName: any;
  btnLoading: boolean;
}

const GoGoLiveEditDrawer = ({
  show,
  form,
  setShow,
  setSelectVideo,
  selectVideo,
  handleConfirm,
  setHkPdfFileName,
  setKrPdfFileName,
  setTwPdfFileName,
  setPosterFileName,
  btnLoading,
}: GoGoLiveDrawerProps) => {
  const [uploadFile] = useUploadFileMutation();
  // const videoProps: UploadProps = {
  //   name: "file",
  //   action: "https://httpbin.org/post",
  //   headers: {
  //     authorization: "authorization-text",
  //   },
  //   beforeUpload: (file) => {
  //     const isVideo = file.type.startsWith("video");
  //     if (!isVideo) {
  //       message.error("Only upload video file!");
  //     }
  //     let reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => {
  //       console.log(URL.createObjectURL(file));
  //     };
  //     return isVideo || Upload.LIST_IGNORE;
  //   },
  //   onChange(info) {
  //     setUploadedVideoFileName(info.file.name);
  //     if (info.file.status !== "uploading") {
  //       console.log(info.file, info.fileList);
  //     }
  //     if (info.file.status === "done") {
  //       message.success(`${info.file.name} file uploaded successfully`);
  //     } else if (info.file.status === "error") {
  //       message.error(`${info.file.name} file upload failed.`);
  //     }
  //   },
  //   maxCount: 1,
  //   onRemove: () => setUploadedVideoFileName(""),
  // };

  const pdfProps: UploadProps = {
    name: "file",
    action: "https://httpbin.org/post",
    headers: {
      authorization: "authorization-text",
    },
    beforeUpload: (file) => {
      const isPdf = file.type.split("/")[1] === "pdf";
      if (!isPdf) {
        message.error(renderAntDMessageConfig("Only upload pdf file!"));
      }
      return isPdf || Upload.LIST_IGNORE;
    },
    maxCount: 1,
  };

  const posterProps: UploadProps = {
    name: "file",
    action: "https://httpbin.org/post",
    headers: {
      authorization: "authorization-text",
    },
    beforeUpload: (file) => {
      const newFileName = `${dayjs(selectVideo.yearMonth).format(
        "YYYYMM"
      )}_${renderPosterCAPLevel(selectVideo.capLevel)}.png`;
      const newFile = new File([file], newFileName, { type: file.type });
      const isPNG = file.type.split("/")[1] === "png";
      const isLt200K = file.size / 1024 <= 200;
      if (!isPNG) {
        message.error(renderAntDMessageConfig("Only upload PNG file!"));
      }
      if (!isLt200K) {
        message.error(
          renderAntDMessageConfig(
            "Please upload an image that is less than 200KB."
          )
        );
      }
      return (isPNG && isLt200K && newFile) || Upload.LIST_IGNORE;
    },
    onChange: (info) => {
      console.log(info);
      if (info.fileList.length === 0) {
        setPosterFileName(undefined);
        return;
      }
      if (info.file.status === "done") {
        setPosterFileName(info.file.name);
        uploadFile({
          variables: {
            blobs: [
              {
                file: info.file.originFileObj,
                path: AfBlobPath.GoGoLiveImage,
              },
            ],
          },
        }).catch((e) => console.log(e));
        message.success(
          renderAntDMessageConfig(
            `${info.file.name} file uploaded successfully`
          )
        );
      } else if (info.file.status === "error") {
        message.error(
          renderAntDMessageConfig(`${info.file.name} file upload failed.`)
        );
      }
    },
    maxCount: 1,
    onRemove: () => setPosterFileName(""),
  };

  useEffect(() => {
    if (selectVideo) {
      form.setFieldsValue({
        videoFile: selectVideo.vimeoId && selectVideo.vimeoId,
        hkPdf: selectVideo.fileNameHK && [
          {
            uid: "0",
            name: selectVideo.fileNameHK,
            status: "done",
          },
        ],
        krPdf: selectVideo.fileNameKR && [
          {
            uid: "0",
            name: selectVideo.fileNameKR,
            status: "done",
          },
        ],
        twPdf: selectVideo.fileNameTW && [
          {
            uid: "0",
            name: selectVideo.fileNameTW,
            status: "done",
          },
        ],
        posterFile: selectVideo.fileNamePoster && [
          {
            uid: "0",
            name: selectVideo.fileNamePoster,
            status: "done",
          },
        ],
      });
    }
  }, [selectVideo, form]);

  return (
    <Drawer
      open={show}
      onClose={() => {
        form.resetFields();
        setShow(false);
        setSelectVideo(undefined);
        setHkPdfFileName(undefined);
        setKrPdfFileName(undefined);
        setTwPdfFileName(undefined);
        setPosterFileName(undefined);
      }}
      title="Edit Go, Go Live! content"
      width={500}
    >
      <Form
        name="ggl-form"
        form={form}
        onFinish={handleConfirm}
        layout="vertical"
        className="ggl-form"
      >
        <Form.Item
          label="Year month"
          name="yearMonth"
          rules={[
            {
              required: selectVideo ? false : true,
              message: "This field is required!",
            },
          ]}
        >
          <p style={{ color: Colors.notEditableText, lineHeight: "22px" }}>
            {dayjs(selectVideo?.yearMonth).format("YYYY-MMM")}
          </p>
        </Form.Item>
        <Form.Item
          label="CAP level"
          name="capLevel"
          rules={[
            {
              required: selectVideo ? false : true,
              message: "This field is required!",
            },
          ]}
        >
          <p style={{ color: Colors.notEditableText, lineHeight: "22px" }}>
            {selectVideo?.capLevel}
          </p>
        </Form.Item>
        <Form.Item
          label="Video"
          name="videoFile"
          // valuePropName="fileList"
          // getValueFromEvent={(e) => {
          //   if (Array.isArray(e)) {
          //     return e;
          //   }
          //   return e && e.fileList;
          // }}
        >
          {/* <Upload {...videoProps}>
            <Button icon={<UploadOutlined />} style={{ marginTop: 8 }}>
              Click to Upload
            </Button>
          </Upload> */}
          <InputCustom placeholder="Vimeo ID" />
        </Form.Item>
        <Form.Item>
          <p
            style={{
              color: "rgba(0,0,0,0.45)",
              lineHeight: "22px",
              marginTop: -40,
            }}
          >
            Enter the ID number of the video uploaded on Vimeo
          </p>
        </Form.Item>
        <Form.Item
          label="HK Parent's guide"
          name="hkPdf"
          valuePropName="fileList"
          getValueFromEvent={(e) => {
            if (Array.isArray(e)) {
              return e;
            }
            return e && e.fileList;
          }}
          style={{ marginTop: -40 }}
        >
          <Upload
            {...pdfProps}
            onChange={(info) => {
              console.log(info);
              if (info.fileList.length === 0) {
                setHkPdfFileName(undefined);
                return;
              }
              if (info.file.status === "done") {
                setHkPdfFileName(info.file.name);
                uploadFile({
                  variables: {
                    blobs: [
                      {
                        file: info.file.originFileObj,
                        path: AfBlobPath.VesPdf,
                      },
                    ],
                  },
                }).catch((e) => console.log(e));
                message.success(
                  renderAntDMessageConfig(
                    `${info.file.name} file uploaded successfully`
                  )
                );
              } else if (info.file.status === "error") {
                message.error(
                  renderAntDMessageConfig(
                    `${info.file.name} file upload failed.`
                  )
                );
              }
            }}
            onRemove={() => setHkPdfFileName("")}
          >
            <Button icon={<UploadOutlined />} style={{ marginTop: 8 }}>
              Click to Upload
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item
          label="KR Parent's guide"
          name="krPdf"
          valuePropName="fileList"
          getValueFromEvent={(e) => {
            if (Array.isArray(e)) {
              return e;
            }
            return e && e.fileList;
          }}
        >
          <Upload
            {...pdfProps}
            onChange={(info) => {
              console.log(info);
              if (info.fileList.length === 0) {
                setKrPdfFileName(undefined);
                return;
              }
              if (info.file.status === "done") {
                setKrPdfFileName(info.file.name);
                uploadFile({
                  variables: {
                    blobs: [
                      {
                        file: info.file.originFileObj,
                        path: AfBlobPath.VesPdf,
                      },
                    ],
                  },
                }).catch((e) => console.log(e));
                message.success(
                  renderAntDMessageConfig(
                    `${info.file.name} file uploaded successfully`
                  )
                );
              } else if (info.file.status === "error") {
                message.error(
                  renderAntDMessageConfig(
                    `${info.file.name} file upload failed.`
                  )
                );
              }
            }}
            onRemove={() => setKrPdfFileName("")}
          >
            <Button icon={<UploadOutlined />} style={{ marginTop: 8 }}>
              Click to Upload
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item
          label="TW Parent's guide"
          name="twPdf"
          valuePropName="fileList"
          getValueFromEvent={(e) => {
            if (Array.isArray(e)) {
              return e;
            }
            return e && e.fileList;
          }}
        >
          <Upload
            {...pdfProps}
            onChange={(info) => {
              console.log(info);
              if (info.fileList.length === 0) {
                setTwPdfFileName(undefined);
                return;
              }
              if (info.file.status === "done") {
                setTwPdfFileName(info.file.name);
                uploadFile({
                  variables: {
                    blobs: [
                      {
                        file: info.file.originFileObj,
                        path: AfBlobPath.VesPdf,
                      },
                    ],
                  },
                }).catch((e) => console.log(e));
                message.success(
                  renderAntDMessageConfig(
                    `${info.file.name} file uploaded successfully`
                  )
                );
              } else if (info.file.status === "error") {
                message.error(
                  renderAntDMessageConfig(
                    `${info.file.name} file upload failed.`
                  )
                );
              }
            }}
            onRemove={() => setTwPdfFileName("")}
          >
            <Button icon={<UploadOutlined />} style={{ marginTop: 8 }}>
              Click to Upload
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item
          label="Review poster"
          name="posterFile"
          valuePropName="fileList"
          getValueFromEvent={(e) => {
            if (Array.isArray(e)) {
              return e;
            }
            return e && e.fileList;
          }}
        >
          <Upload {...posterProps}>
            <Button icon={<UploadOutlined />} style={{ marginTop: 8 }}>
              Click to Upload
            </Button>
            <p style={{ color: "rgba(0,0,0,0.45)", lineHeight: "22px" }}>
              Formats: PNG only. Max file size: 200KB
            </p>
          </Upload>
        </Form.Item>
        <DrawerFooter
          handleCancel={() => {
            form.resetFields();
            setSelectVideo(undefined);
            setHkPdfFileName(undefined);
            setKrPdfFileName(undefined);
            setTwPdfFileName(undefined);
            setPosterFileName(undefined);
            setShow(false);
          }}
          isLoading={btnLoading}
        />
      </Form>
    </Drawer>
  );
};

export default GoGoLiveEditDrawer;
