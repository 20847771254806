import { Button } from "antd";
import { DataType } from "./types";

interface Props {
  showDeleteButton: boolean;
  onView?: (id: Number) => void;
  onDelete?: (id: any) => void;
  refetch: any;
  record: DataType;
}

const TableActions = ({
  showDeleteButton,
  onView,
  refetch,
  record,
  onDelete,
}: Props) => {
  return (
    <div className="flex gap-4">
      <Button
        type="link"
        className="w-9 p-0"
        onClick={() => {
          if (onView) {
            onView(record.notificationId);
          }
        }}
      >
        View
      </Button>
      {showDeleteButton ? (
        <Button
          type="link"
          className="w-12 p-0"
          onClick={() => {
            if (onDelete) {
              onDelete(record.notificationId);
            }
          }}
        >
          Delete
        </Button>
      ) : (
        <div />
      )}
    </div>
  );
};

export default TableActions;
