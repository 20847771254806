import { Button, Tooltip } from "antd";
import { ArrowLeftOutlined, QuestionOutlined } from "@ant-design/icons";

export default function ContentTitle({
  title,
  returnTitle,
  onReturn,
  guideUrl,
}: {
  title: string;
  returnTitle?: string;
  onReturn?: () => void;
  guideUrl?: string;
}) {
  return (
    <div className="flex justify-between items-center px-6 py-4 bg-white">
      <div className="flex items-center gap-2 text-xl font-semibold text-textPrimary">
        {onReturn ? (
          <Button type="text" icon={<ArrowLeftOutlined />} onClick={onReturn} />
        ) : null}

        <h2>{onReturn ? returnTitle : title}</h2>
      </div>

      {guideUrl ? (
        <Tooltip title="Function guide">
          <a href={guideUrl} target="_blank" rel="noreferrer">
            <Button shape="circle" icon={<QuestionOutlined />} />
          </a>
        </Tooltip>
      ) : null}
    </div>
  );
}
