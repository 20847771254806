import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import React, { useEffect, useState } from "react";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";

import DraggableTableRow from "../../components/DraggableTableRow";
import sortIdUpdator from "../utils/sortIdUpdator";
import { ContentType } from "../types";

interface DraggableTableDataType {
  id: string;
  key: string;
  productCategoryId: number;
}

export default function DraggableTable<T extends DraggableTableDataType>({
  contentType,
  data,
  setData,
  columns,
  updateSortId,
  refetch,
}: {
  contentType: ContentType;
  data: T[];
  setData: React.Dispatch<React.SetStateAction<T[]>>;
  columns: ColumnsType<any>;
  updateSortId: any;
  refetch: any;
}) {
  const [isDragged, setIsDragged] = useState<boolean>(false);

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setIsDragged(true);
      setData((previous) => {
        const activeIndex = previous.findIndex((i) => i.key === active.id);
        const overIndex = previous.findIndex((i) => i.key === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  useEffect(() => {
    if (isDragged && data.length > 0) {
      setData([]);

      sortIdUpdator({
        contentType,
        data,
        updateSortId,
      })
        .then(() => refetch())
        .catch((e: any) => console.log(e))
        .finally(() => setIsDragged(false));
    }
  }, [contentType, data, isDragged, refetch, updateSortId, setData]);

  return (
    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
      <SortableContext
        // rowKey array
        items={data.map((i) => i.key)}
        strategy={verticalListSortingStrategy}
      >
        <Table
          className="custom-table-style"
          dataSource={data}
          columns={columns}
          pagination={false}
          components={{
            body: {
              row: DraggableTableRow,
            },
          }}
          rowKey="key"
        />
      </SortableContext>
    </DndContext>
  );
}
