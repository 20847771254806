import { useEffect, useState } from "react";

import TabsCustom from "../../../components/custom/TabsCustom";
import Spinner from "../../../components/layout/spinner/Spinner";
import ContentTitle from "../../../components/ui/ContentTitle";
import { useGetProductCategoryQuery } from "../../../graphql/operations/trialMode/trialContentUpload/get-product-category";
import errorMessage from "../utils/errorMessage";
import withUserPrograms from "../withUserPrograms";
import ContentSong from "./contentSong/ContentSong";
import ContentVideo from "./contentVideo/ContentVideo";
import convertToCategoryOptions from "./utils/convertToCategoryOptions";
import { productCategoryOptions } from "./constants";

function TrialContentUpload() {
  const [isOptionsReady, setIsOptionsReady] = useState(false);

  const { data, loading, error } = useGetProductCategoryQuery();

  useEffect(() => {
    if (data) {
      if (!productCategoryOptions())
        productCategoryOptions(convertToCategoryOptions(data));

      setIsOptionsReady(true);
    }
  }, [data]);

  if (error) errorMessage();
  if (loading || !isOptionsReady) return <Spinner />;

  return (
    <div>
      <ContentTitle title="Trial Content Upload" />

      <TabsCustom
        items={[
          {
            key: "1",
            label: "Song",
            children: <ContentSong />,
          },
          {
            key: "2",
            label: "Video",
            children: <ContentVideo />,
          },
        ]}
      />
    </div>
  );
}

export default withUserPrograms(TrialContentUpload, "Trial Content Upload");
