import * as Types from './@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetTeSongsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetTeSongsQuery = { __typename?: 'Query', afTeSong: Array<{ __typename?: 'Club_WorldFamilyClub_TeSong', id: string, songName?: string | null, teSongNumber?: string | null, product?: string | null }> };


export const GetTeSongsDocument = gql`
    query GetTESongs {
  afTeSong {
    id
    songName
    teSongNumber
    product
  }
}
    `;

/**
 * __useGetTeSongsQuery__
 *
 * To run a query within a React component, call `useGetTeSongsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeSongsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeSongsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTeSongsQuery(baseOptions?: Apollo.QueryHookOptions<GetTeSongsQuery, GetTeSongsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeSongsQuery, GetTeSongsQueryVariables>(GetTeSongsDocument, options);
      }
export function useGetTeSongsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeSongsQuery, GetTeSongsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeSongsQuery, GetTeSongsQueryVariables>(GetTeSongsDocument, options);
        }
export type GetTeSongsQueryHookResult = ReturnType<typeof useGetTeSongsQuery>;
export type GetTeSongsLazyQueryHookResult = ReturnType<typeof useGetTeSongsLazyQuery>;
export type GetTeSongsQueryResult = Apollo.QueryResult<GetTeSongsQuery, GetTeSongsQueryVariables>;