import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetGraduationProcessTwQueryVariables = Types.Exact<{
  childNo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  memberNo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  applicationYear?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetGraduationProcessTwQuery = { __typename?: 'Query', club_worldFamilyClub_childGraduationApplication?: { __typename?: 'Club_WorldFamilyClub_ChildGraduationApplicationConnection', totalCount: number, nodes?: Array<{ __typename?: 'Club_WorldFamilyClub_ChildGraduationApplication', id: string, applicationCount?: number | null, applicationYear?: number | null, approvedCount?: number | null, childNo?: string | null, graduationDate?: any | null, isExcellent?: boolean | null, lastApprovalDate?: any | null, market?: string | null, memberNo?: string | null, rejectedCount?: number | null }> | null } | null };


export const GetGraduationProcessTwDocument = gql`
    query getGraduationProcessTw($childNo: String, $memberNo: String, $applicationYear: Int) {
  club_worldFamilyClub_childGraduationApplication(
    filter: {childNo: {eq: $childNo}, memberNo: {eq: $memberNo}, applicationYear: {eq: $applicationYear}}
  ) {
    totalCount
    nodes {
      id
      applicationCount
      applicationYear
      approvedCount
      childNo
      graduationDate
      isExcellent
      lastApprovalDate
      market
      memberNo
      rejectedCount
    }
  }
}
    `;

/**
 * __useGetGraduationProcessTwQuery__
 *
 * To run a query within a React component, call `useGetGraduationProcessTwQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGraduationProcessTwQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGraduationProcessTwQuery({
 *   variables: {
 *      childNo: // value for 'childNo'
 *      memberNo: // value for 'memberNo'
 *      applicationYear: // value for 'applicationYear'
 *   },
 * });
 */
export function useGetGraduationProcessTwQuery(baseOptions?: Apollo.QueryHookOptions<GetGraduationProcessTwQuery, GetGraduationProcessTwQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGraduationProcessTwQuery, GetGraduationProcessTwQueryVariables>(GetGraduationProcessTwDocument, options);
      }
export function useGetGraduationProcessTwLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGraduationProcessTwQuery, GetGraduationProcessTwQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGraduationProcessTwQuery, GetGraduationProcessTwQueryVariables>(GetGraduationProcessTwDocument, options);
        }
export type GetGraduationProcessTwQueryHookResult = ReturnType<typeof useGetGraduationProcessTwQuery>;
export type GetGraduationProcessTwLazyQueryHookResult = ReturnType<typeof useGetGraduationProcessTwLazyQuery>;
export type GetGraduationProcessTwQueryResult = Apollo.QueryResult<GetGraduationProcessTwQuery, GetGraduationProcessTwQueryVariables>;