import { Modal } from "antd";

export default function VideoModel<
  T extends {
    videoTitle: string;
    videoUrl: string;
  }
>({
  modalData,
  setModalData,
  videoRef,
}: {
  modalData: T | undefined;
  setModalData: React.Dispatch<React.SetStateAction<T | undefined>>;
  videoRef: React.RefObject<HTMLVideoElement>;
}) {
  return (
    <Modal
      title={modalData?.videoTitle}
      open={Boolean(modalData)}
      onCancel={() => setModalData(undefined)}
      footer={null}
    >
      <video ref={videoRef} controls className="w-full">
        <source src={modalData?.videoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </Modal>
  );
}
