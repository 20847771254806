import { Spin } from "antd";

const Spinner = () => {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0,0,0,0.4)",
        position: "fixed",
        left: 0,
        top: 0,
        zIndex: 99999,
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin size="large" />
      </div>
    </div>
  );
};

export default Spinner;
