import * as Types from './@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateFaceCallThemeMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  themeName: Types.Scalars['String']['input'];
  fileName: Types.Scalars['String']['input'];
  dateFrom: Types.Scalars['DateTime']['input'];
  dateTo: Types.Scalars['DateTime']['input'];
}>;


export type UpdateFaceCallThemeMutation = { __typename?: 'Mutation', club_worldFamilyClub: { __typename?: 'Club_WorldFamilyClub_Mutation_Payload', count: number } };


export const UpdateFaceCallThemeDocument = gql`
    mutation UpdateFaceCallTheme($id: Int!, $themeName: String!, $fileName: String!, $dateFrom: DateTime!, $dateTo: DateTime!) {
  club_worldFamilyClub(
    update: {faceCallSeasonHoliday: {faceCallSeasonHolidayId: $id, name: $themeName, iconFileName: $fileName, dateFrom: $dateFrom, dateTo: $dateTo}}
  ) {
    count
  }
}
    `;
export type UpdateFaceCallThemeMutationFn = Apollo.MutationFunction<UpdateFaceCallThemeMutation, UpdateFaceCallThemeMutationVariables>;

/**
 * __useUpdateFaceCallThemeMutation__
 *
 * To run a mutation, you first call `useUpdateFaceCallThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFaceCallThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFaceCallThemeMutation, { data, loading, error }] = useUpdateFaceCallThemeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      themeName: // value for 'themeName'
 *      fileName: // value for 'fileName'
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *   },
 * });
 */
export function useUpdateFaceCallThemeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFaceCallThemeMutation, UpdateFaceCallThemeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFaceCallThemeMutation, UpdateFaceCallThemeMutationVariables>(UpdateFaceCallThemeDocument, options);
      }
export type UpdateFaceCallThemeMutationHookResult = ReturnType<typeof useUpdateFaceCallThemeMutation>;
export type UpdateFaceCallThemeMutationResult = Apollo.MutationResult<UpdateFaceCallThemeMutation>;
export type UpdateFaceCallThemeMutationOptions = Apollo.BaseMutationOptions<UpdateFaceCallThemeMutation, UpdateFaceCallThemeMutationVariables>;