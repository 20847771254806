import ContentTitle from "../../../components/ui/ContentTitle";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Form, Button, message } from "antd";
import { useGetNotificationByIdQuery } from "../../../graphql/operations/sales188/getNotificationById";
import { useAddSalesNotificationMutation } from "../../../graphql/operations/sales188/addSalesNotification";
import { useNavigate, useParams } from "react-router-dom";
import { DataType } from "./types";
import { useReactiveVar } from "@apollo/client";
import { useGetUserProgramsByServiceQuery } from "../../../graphql/operations/get-user-program-by-service";
import NoPermission from "../../../components/others/NoPermission";
import Spinner from "../../../components/layout/spinner/Spinner";
import { renderAntDMessageConfig } from "../../../utils/renderAntDMessageConfig";
import { renderDecodedId } from "../../../utils/renderDecodedId";
import { __currentUser__ } from "../../../graphql/policies";
import { ServiceTypes } from "../../../types/types";
import "./ViewNotificationDetail.scss";

interface SalesPushNotificationProps {
  setSelectedService: Dispatch<SetStateAction<ServiceTypes>>;
  setSelectSubItem: Dispatch<SetStateAction<string | undefined>>;
}

const ViewNotificationDetail = ({
  setSelectedService,
  setSelectSubItem,
}: SalesPushNotificationProps) => {
  const navigate = useNavigate();
  const dayjs = require("dayjs");
  const currentUser = useReactiveVar(__currentUser__);
  var utc = require("dayjs/plugin/utc");
  var timezone = require("dayjs-timezone-iana-plugin");
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 11 },
  };
  const [form] = Form.useForm();
  const { id } = useParams();
  const [selectedNotification, setSelectedNotification] = useState<
    DataType | any
  >(undefined);
  const { data } = useGetNotificationByIdQuery({
    variables: {
      id: parseInt(id as string),
    },
  });
  const [addSalesNotification] = useAddSalesNotificationMutation();
  const handleRePush = () => {
    const addPromise = addSalesNotification({
      variables: {
        market: selectedNotification.market,
        sendingDepartment: selectedNotification.sendingDepartment,
        target: selectedNotification.target,
        title: selectedNotification.title,
        templateMessage: selectedNotification.templateMessage,
        scheduleTime: null,
        createdTime: dayjs().utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        jobStatus: selectedNotification.jobStatus,
        creator: currentUser?.userName,
        mutationOperation: selectedNotification.mutationOperation,
        removeNotificationId: selectedNotification.removeNotificationId,
        error: selectedNotification.error,
      },
    });
    addPromise
      .then(() => {
        message.success(
          "Notification has been submitted. This can sometimes take a few minutes to arrive."
        );
        navigate("/sales188/push-notification");
      })
      .catch((e) => {
        console.log("error", e);
      });
  };
  useEffect(() => {
    setSelectedService(ServiceTypes.SALES_188);
    setSelectSubItem("Push Notification");
  }, []);
  useEffect(() => {
    if (data) {
      setSelectedNotification(data?.sales188Notification[0]);
    }
  }, [data]);

  const {
    data: userPrograms,
    loading: userProgramsLoading,
    error: userProgramsError,
  } = useGetUserProgramsByServiceQuery({
    variables: {
      userId: parseInt(renderDecodedId(currentUser?.id)),
      serviceId: 5,
    },
  });

  if (userProgramsError) {
    message.error(
      renderAntDMessageConfig(
        "Something went wrong. Please contact your system administrator."
      )
    );
  }

  if (userProgramsLoading) {
    return <Spinner />;
  }

  if (
    userPrograms &&
    !userPrograms.wf_adminFirst_afUserProgram?.edges?.find(
      (item) => item.node.afProgram?.programName === "Sales Notification"
    )
  ) {
    return <NoPermission />;
  }

  return (
    <div id="sales188ViewNotificationDetailWrapper">
      <ContentTitle
        title="View notification details"
        returnTitle="View notification details"
        onReturn={() => {
          navigate(-1);
        }}
      />
      <div className="wrapper">
        <div className="sales-notification-detail-content-container">
          <Form form={form} name="detail" {...layout}>
            <Form.Item label="Market">
              <p className="content">{selectedNotification?.market}</p>
            </Form.Item>
            <Form.Item label="Title">
              <p className="content">{selectedNotification?.title}</p>
            </Form.Item>
            <Form.Item label="Message">
              <p className="content">{selectedNotification?.templateMessage}</p>
            </Form.Item>
            <Form.Item label="Push date and time">
              <p className="content">
                {selectedNotification?.scheduleTime
                  ? dayjs(selectedNotification?.scheduleTime).format(
                      "YYYY-MM-DD HH:mm"
                    )
                  : dayjs(selectedNotification?.createdTime).format(
                      "YYYY-MM-DD HH:mm"
                    )}
              </p>
            </Form.Item>
          </Form>
        </div>
      </div>
      <div className="footer">
        <Button
          size="large"
          style={{
            marginLeft: 8,
            textAlign: "center",
            paddingLeft: 20,
            paddingRight: 20,
          }}
          onClick={handleRePush}
          disabled={
            selectedNotification?.scheduleTime
              ? dayjs(selectedNotification?.scheduleTime).isAfter(dayjs())
              : dayjs(selectedNotification?.createdTime).isAfter(dayjs())
          }
        >
          Re-push
        </Button>
      </div>
    </div>
  );
};

export default ViewNotificationDetail;
