import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateRegularEventsSortIdMutationVariables = Types.Exact<{
  RegularEventData?: Types.InputMaybe<Array<Types.Wf_TrialModeLeads_RegularEventInput> | Types.Wf_TrialModeLeads_RegularEventInput>;
}>;


export type UpdateRegularEventsSortIdMutation = { __typename?: 'Mutation', wf_trialModeLeads: { __typename?: 'WF_TrialModeLeads_Mutation_Payload', count: number } };


export const UpdateRegularEventsSortIdDocument = gql`
    mutation UpdateRegularEventsSortId($RegularEventData: [WF_TrialModeLeads_RegularEventInput!]) {
  wf_trialModeLeads(updateRange: {regularEvents: $RegularEventData}) {
    count
  }
}
    `;
export type UpdateRegularEventsSortIdMutationFn = Apollo.MutationFunction<UpdateRegularEventsSortIdMutation, UpdateRegularEventsSortIdMutationVariables>;

/**
 * __useUpdateRegularEventsSortIdMutation__
 *
 * To run a mutation, you first call `useUpdateRegularEventsSortIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRegularEventsSortIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRegularEventsSortIdMutation, { data, loading, error }] = useUpdateRegularEventsSortIdMutation({
 *   variables: {
 *      RegularEventData: // value for 'RegularEventData'
 *   },
 * });
 */
export function useUpdateRegularEventsSortIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRegularEventsSortIdMutation, UpdateRegularEventsSortIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRegularEventsSortIdMutation, UpdateRegularEventsSortIdMutationVariables>(UpdateRegularEventsSortIdDocument, options);
      }
export type UpdateRegularEventsSortIdMutationHookResult = ReturnType<typeof useUpdateRegularEventsSortIdMutation>;
export type UpdateRegularEventsSortIdMutationResult = Apollo.MutationResult<UpdateRegularEventsSortIdMutation>;
export type UpdateRegularEventsSortIdMutationOptions = Apollo.BaseMutationOptions<UpdateRegularEventsSortIdMutation, UpdateRegularEventsSortIdMutationVariables>;