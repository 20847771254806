import { Button } from "antd";

export default function TableActions({
  isInUse,
  onEdit,
  onDelete,
}: {
  isInUse: boolean;
  onEdit: () => void;
  onDelete: () => void;
}) {
  return (
    <div className="flex gap-4">
      <Button type="link" className="w-9 p-0" onClick={onEdit}>
        Edit
      </Button>

      {!isInUse ? (
        <Button type="link" className="w-12 p-0" onClick={onDelete}>
          Delete
        </Button>
      ) : null}
    </div>
  );
}
