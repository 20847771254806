import "./RegionTag.css";
import { RegionTypes } from "../../types/types";
import { Colors } from "../../constants/Colors";

interface RegionTagProps {
  region: RegionTypes | string;
}

const RegionTag = ({ region }: RegionTagProps) => {
  return (
    <div
      className="region-tag-container"
      style={{
        backgroundColor: Colors.tagBackground,
        border: `1px solid ${Colors.tagBorder2}`,
      }}
    >
      <p>{region}</p>
    </div>
  );
};

export default RegionTag;
