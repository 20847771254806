import { SegmentationType } from "../../types";

const order = { Awareness: 1, Interest: 2, Decision: 3 };

export default function sortSegmentations(
  segmentations: SegmentationType[]
): SegmentationType[] {
  segmentations.sort((a, b) => {
    return order[a] - order[b];
  });

  return segmentations;
}
