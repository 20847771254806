import { Form, Select } from "antd";

import requiredRule from "../../../../utils/formRules/requiredRule";
import getCharacterSrc from "../utils/getCharacterSrc";
import { CharacterType } from "../types";

interface OptionType {
  value: CharacterType;
  label: string;
  image: CharacterType;
}

export function FormItemCharacter() {
  const options: OptionType[] = [
    { value: "Zippy", label: "Zippy", image: "Zippy" },
    { value: "Elvis", label: "Elvis", image: "Elvis" },
    { value: "Bunny", label: "Bunny", image: "Bunny" },
    { value: "Coco", label: "Coco", image: "Coco" },
    {
      value: "None",
      label: `No "Zippy and Friend" character required`,
      image: "None",
    },
  ];

  const selectedOptionRenderer = (option: OptionType) => {
    return option.image && option.image !== "None" ? (
      <div className="h-full flex items-center gap-2">
        <img
          src={getCharacterSrc(option.image)}
          alt={option.label}
          className="w-6 h-6"
        />
        <p>{option.label}</p>
      </div>
    ) : (
      <div className="h-full flex items-center">
        <p>{option.label}</p>
      </div>
    );
  };

  const customOptionRenderer = (option: OptionType) => {
    return option.image && option.image !== "None" ? (
      <div className="flex items-center gap-4">
        <img
          src={getCharacterSrc(option.image)}
          alt={option.label}
          className="w-11 h-11"
        />
        <p>{option.label}</p>
      </div>
    ) : (
      <div className="flex items-center h-11">
        <p>{option.label}</p>
      </div>
    );
  };

  return (
    <Form.Item
      name="experienceCharacter"
      label="Character"
      rules={[requiredRule]}
    >
      <Select
        optionLabelProp="custom"
        dropdownRender={(menu) => <div>{menu}</div>}
      >
        {options.map((option) => (
          <Select.Option
            key={option.value}
            value={option.value}
            custom={selectedOptionRenderer(option)}
            children={customOptionRenderer(option)}
          />
        ))}
      </Select>
    </Form.Item>
  );
}
