export const renderServiceTypeName = (id: string) => {
  switch (id) {
    case "0001":
      return "Club News";
    case "0002":
      return "Go, Go Live!";
    default:
      break;
  }
};
