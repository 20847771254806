function getYoutubeId(url: string): string {
  const match = url.match(
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/|v\/|shorts\/)|youtu\.be\/)([a-zA-Z0-9_-]+)/
  );

  return match ? match[1] : "";
}

function getVimeoId(url: string): string {
  const match = url.match(
    /(?:https?:\/\/)?(?:www\.)?(?:vimeo\.com\/|player\.vimeo\.com\/video\/)(\d+)/
  );

  return match ? match[1] : "";
}

export function getEmbedUrl(url: string): string {
  let id = getYoutubeId(url);

  if (id) return `https://www.youtube.com/embed/${id}`;

  id = getVimeoId(url);

  return id ? `https://player.vimeo.com/video/${id}` : "";
}

export function getStandardUrl(url: string): string {
  let id = getYoutubeId(url);

  if (id) return `https://www.youtube.com/watch?v=${id}`;

  id = getVimeoId(url);

  return id ? `https://vimeo.com/${id}` : "";
}
