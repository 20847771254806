import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteScheduledServiceBannerMutationVariables = Types.Exact<{
  seqs: Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input'];
}>;


export type DeleteScheduledServiceBannerMutation = { __typename?: 'Mutation', deleteScheduledServiceBanner: { __typename?: 'Payload', count: number, error?: Types.Error | null } };


export const DeleteScheduledServiceBannerDocument = gql`
    mutation deleteScheduledServiceBanner($seqs: [Int!]!) {
  deleteScheduledServiceBanner(seqs: $seqs) {
    count
    error
  }
}
    `;
export type DeleteScheduledServiceBannerMutationFn = Apollo.MutationFunction<DeleteScheduledServiceBannerMutation, DeleteScheduledServiceBannerMutationVariables>;

/**
 * __useDeleteScheduledServiceBannerMutation__
 *
 * To run a mutation, you first call `useDeleteScheduledServiceBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScheduledServiceBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScheduledServiceBannerMutation, { data, loading, error }] = useDeleteScheduledServiceBannerMutation({
 *   variables: {
 *      seqs: // value for 'seqs'
 *   },
 * });
 */
export function useDeleteScheduledServiceBannerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteScheduledServiceBannerMutation, DeleteScheduledServiceBannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteScheduledServiceBannerMutation, DeleteScheduledServiceBannerMutationVariables>(DeleteScheduledServiceBannerDocument, options);
      }
export type DeleteScheduledServiceBannerMutationHookResult = ReturnType<typeof useDeleteScheduledServiceBannerMutation>;
export type DeleteScheduledServiceBannerMutationResult = Apollo.MutationResult<DeleteScheduledServiceBannerMutation>;
export type DeleteScheduledServiceBannerMutationOptions = Apollo.BaseMutationOptions<DeleteScheduledServiceBannerMutation, DeleteScheduledServiceBannerMutationVariables>;