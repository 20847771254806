import { Button } from "antd";
import type { FormInstance } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import getInitialMarket from "../../../utils/getInitialMarket";
import { DrawerActionType } from "../types";

export default function ButtonCreate({
  form,
  setOpenDrawer,
  setDrawerAction,
}: {
  form?: FormInstance<any>;
  setOpenDrawer: (value: React.SetStateAction<boolean>) => void;
  setDrawerAction: (value: React.SetStateAction<DrawerActionType>) => void;
}) {
  return (
    <Button
      type="primary"
      style={{ marginLeft: 8 }}
      icon={<PlusOutlined />}
      onClick={() => {
        if (form)
          form.setFieldsValue({
            market: getInitialMarket(),
          });

        setDrawerAction("Create");
        setOpenDrawer(true);
      }}
    >
      Create New
    </Button>
  );
}
