import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateFeaturedEventMutationVariables = Types.Exact<{
  market: Types.Scalars['String']['input'];
  imageFileName: Types.Scalars['String']['input'];
  navigationUrl: Types.Scalars['String']['input'];
  startDate?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  endDate?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
}>;


export type CreateFeaturedEventMutation = { __typename?: 'Mutation', wf_trialModeLeads: { __typename?: 'WF_TrialModeLeads_Mutation_Payload', count: number } };


export const CreateFeaturedEventDocument = gql`
    mutation CreateFeaturedEvent($market: String!, $imageFileName: String!, $navigationUrl: String!, $startDate: DateTime, $endDate: DateTime) {
  wf_trialModeLeads(
    add: {featuredEvent: {market: $market, imageFileName: $imageFileName, navigationUrl: $navigationUrl, startDate: $startDate, endDate: $endDate}}
  ) {
    count
  }
}
    `;
export type CreateFeaturedEventMutationFn = Apollo.MutationFunction<CreateFeaturedEventMutation, CreateFeaturedEventMutationVariables>;

/**
 * __useCreateFeaturedEventMutation__
 *
 * To run a mutation, you first call `useCreateFeaturedEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFeaturedEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFeaturedEventMutation, { data, loading, error }] = useCreateFeaturedEventMutation({
 *   variables: {
 *      market: // value for 'market'
 *      imageFileName: // value for 'imageFileName'
 *      navigationUrl: // value for 'navigationUrl'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useCreateFeaturedEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateFeaturedEventMutation, CreateFeaturedEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFeaturedEventMutation, CreateFeaturedEventMutationVariables>(CreateFeaturedEventDocument, options);
      }
export type CreateFeaturedEventMutationHookResult = ReturnType<typeof useCreateFeaturedEventMutation>;
export type CreateFeaturedEventMutationResult = Apollo.MutationResult<CreateFeaturedEventMutation>;
export type CreateFeaturedEventMutationOptions = Apollo.BaseMutationOptions<CreateFeaturedEventMutation, CreateFeaturedEventMutationVariables>;