import { ServiceItemsType } from "../types";

export default function getMenuItemRoute(
  keyPath: string[],
  serviceData: ServiceItemsType
) {
  const { serviceRoute, items } = serviceData;
  const isSecendMenu = keyPath.length > 1;

  if (isSecendMenu) {
    const item = items.find(({ category }) => category === keyPath[1]);
    const subItem = item?.subItems?.find(
      ({ subItemName }) => subItemName === keyPath[0]
    );

    return `/${serviceRoute}/${subItem?.routeName}`;
  } else {
    const item = items.find(({ category }) => category === keyPath[0]);

    return `/${serviceRoute}/${item?.categoryRoute}`;
  }
}
