import { renderDecodedId } from "../../../../utils/renderDecodedId";
import { ContentType } from "../types";

export default function sortIdUpdator<
  T extends {
    id: string;
    productCategoryId: number;
  }
>({
  contentType,
  data,
  updateSortId,
  increment,
}: {
  contentType: ContentType;
  data: T[];
  updateSortId: any;
  increment?: number;
}) {
  const variables =
    contentType === "song"
      ? {
          SongData: data.map(({ id, productCategoryId }, index) => ({
            productSongId: parseInt(renderDecodedId(id)),
            productCategoryId,
            sortId: increment ? index + increment : index,
          })),
        }
      : {
          VideoData: data.map(({ id, productCategoryId }, index) => ({
            productVideoId: parseInt(renderDecodedId(id)),
            productCategoryId,
            sortId: increment ? index + increment : index,
          })),
        };

  return updateSortId({ variables });
}
