import { message } from "antd";

import { renderAntDMessageConfig } from "../../../utils/renderAntDMessageConfig";

export default function errorMessage(msg?: string) {
  message.error(
    renderAntDMessageConfig(
      msg || "Something went wrong. Please contact your system administrator."
    )
  );
}
