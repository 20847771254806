import { getEmbedUrl } from "../../utils/getVideoUrl";
import { JourneyCardType } from "../types";

export default function denormalizeJourneyCardData(data: JourneyCardType) {
  const {
    greeting,
    experiencePromotionWording,
    experienceNavigationWording,
    experienceCharacter,
    isDisplayVideo,
    videoUrl,
    videoWording,
  } = data;

  return {
    greeting,
    experiencePromotionWording,
    experienceNavigationWording,
    experienceCharacter,
    isDisplayVideo,
    ...(isDisplayVideo
      ? { videoUrl: getEmbedUrl(videoUrl), videoWording }
      : {}),
  };
}
