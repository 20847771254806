import froggyBunny from "../../../../assets/images/trialMode/froggyBunny-conversation.png";
import coco from "../../../../assets/images/trialMode/coco-encourage.png";
import zippy from "../../../../assets/images/trialMode/zippy-crown.png";

import { CharacterName } from "../types";

const characterImages: Record<CharacterName, string> = {
  FroggyBunny: froggyBunny,
  Coco: coco,
  Zippy: zippy,
};

export default function getCharacterSrc(name: CharacterName | ""): string {
  return name ? characterImages[name] : "";
}
