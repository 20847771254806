import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetScheduledServiceBannerListQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetScheduledServiceBannerListQuery = { __typename?: 'Query', scheduledServiceBannerList: Array<{ __typename?: 'ScheduledServiceBannerInfo', seqs: Array<number>, service: string, image: string, markets: Array<string>, publishDate?: string | null, stopDate?: string | null, status: string, imageUrl: string }> };


export const GetScheduledServiceBannerListDocument = gql`
    query getScheduledServiceBannerList {
  scheduledServiceBannerList {
    seqs
    service
    image
    imageUrl: image @wf_blobSignature(name: "APP_SERVICE_BANNERS_SIGNATURE")
    markets
    publishDate
    stopDate
    status
  }
}
    `;

/**
 * __useGetScheduledServiceBannerListQuery__
 *
 * To run a query within a React component, call `useGetScheduledServiceBannerListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduledServiceBannerListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduledServiceBannerListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetScheduledServiceBannerListQuery(baseOptions?: Apollo.QueryHookOptions<GetScheduledServiceBannerListQuery, GetScheduledServiceBannerListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScheduledServiceBannerListQuery, GetScheduledServiceBannerListQueryVariables>(GetScheduledServiceBannerListDocument, options);
      }
export function useGetScheduledServiceBannerListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScheduledServiceBannerListQuery, GetScheduledServiceBannerListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScheduledServiceBannerListQuery, GetScheduledServiceBannerListQueryVariables>(GetScheduledServiceBannerListDocument, options);
        }
export type GetScheduledServiceBannerListQueryHookResult = ReturnType<typeof useGetScheduledServiceBannerListQuery>;
export type GetScheduledServiceBannerListLazyQueryHookResult = ReturnType<typeof useGetScheduledServiceBannerListLazyQuery>;
export type GetScheduledServiceBannerListQueryResult = Apollo.QueryResult<GetScheduledServiceBannerListQuery, GetScheduledServiceBannerListQueryVariables>;