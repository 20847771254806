import * as Types from '../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddSalesNotificationMutationVariables = Types.Exact<{
  market?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sendingDepartment?: Types.InputMaybe<Types.Scalars['String']['input']>;
  target?: Types.InputMaybe<Types.Scalars['String']['input']>;
  title?: Types.InputMaybe<Types.Scalars['String']['input']>;
  templateMessage?: Types.InputMaybe<Types.Scalars['String']['input']>;
  scheduleTime?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  createdTime: Types.Scalars['DateTime']['input'];
  creator?: Types.InputMaybe<Types.Scalars['String']['input']>;
  removeNotificationId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  mutationOperation?: Types.InputMaybe<Types.Scalars['String']['input']>;
  jobStatus?: Types.InputMaybe<Types.Scalars['String']['input']>;
  error?: Types.InputMaybe<Types.Scalars['String']['input']>;
  testEmployeeCodes?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
}>;


export type AddSalesNotificationMutation = { __typename?: 'Mutation', addSalesNotification: { __typename?: 'Payload', count: number, error?: Types.Error | null } };


export const AddSalesNotificationDocument = gql`
    mutation AddSalesNotification($market: String, $sendingDepartment: String, $target: String, $title: String, $templateMessage: String, $scheduleTime: DateTime, $createdTime: DateTime!, $creator: String, $removeNotificationId: Int, $mutationOperation: String, $jobStatus: String, $error: String, $testEmployeeCodes: [String!]) {
  addSalesNotification(
    sales188Notification: {market: $market, sendingDepartment: $sendingDepartment, target: $target, title: $title, templateMessage: $templateMessage, scheduleTime: $scheduleTime, createdTime: $createdTime, creator: $creator, jobStatus: $jobStatus, mutationOperation: $mutationOperation, removeNotificationId: $removeNotificationId, error: $error}
    testEmployeeCodes: $testEmployeeCodes
  ) {
    count
    error
  }
}
    `;
export type AddSalesNotificationMutationFn = Apollo.MutationFunction<AddSalesNotificationMutation, AddSalesNotificationMutationVariables>;

/**
 * __useAddSalesNotificationMutation__
 *
 * To run a mutation, you first call `useAddSalesNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSalesNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSalesNotificationMutation, { data, loading, error }] = useAddSalesNotificationMutation({
 *   variables: {
 *      market: // value for 'market'
 *      sendingDepartment: // value for 'sendingDepartment'
 *      target: // value for 'target'
 *      title: // value for 'title'
 *      templateMessage: // value for 'templateMessage'
 *      scheduleTime: // value for 'scheduleTime'
 *      createdTime: // value for 'createdTime'
 *      creator: // value for 'creator'
 *      removeNotificationId: // value for 'removeNotificationId'
 *      mutationOperation: // value for 'mutationOperation'
 *      jobStatus: // value for 'jobStatus'
 *      error: // value for 'error'
 *      testEmployeeCodes: // value for 'testEmployeeCodes'
 *   },
 * });
 */
export function useAddSalesNotificationMutation(baseOptions?: Apollo.MutationHookOptions<AddSalesNotificationMutation, AddSalesNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSalesNotificationMutation, AddSalesNotificationMutationVariables>(AddSalesNotificationDocument, options);
      }
export type AddSalesNotificationMutationHookResult = ReturnType<typeof useAddSalesNotificationMutation>;
export type AddSalesNotificationMutationResult = Apollo.MutationResult<AddSalesNotificationMutation>;
export type AddSalesNotificationMutationOptions = Apollo.BaseMutationOptions<AddSalesNotificationMutation, AddSalesNotificationMutationVariables>;