import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCtaMutationVariables = Types.Exact<{
  ctaId: Types.Scalars['Int']['input'];
  ctaExperienceTypeId: Types.Scalars['Int']['input'];
  template: Types.Scalars['String']['input'];
  imageCharacter?: Types.InputMaybe<Types.Scalars['String']['input']>;
  photoFileName?: Types.InputMaybe<Types.Scalars['String']['input']>;
  videoUrl?: Types.InputMaybe<Types.Scalars['String']['input']>;
  buttonLinksTo: Types.Scalars['String']['input'];
  buttonText: Types.Scalars['String']['input'];
}>;


export type UpdateCtaMutation = { __typename?: 'Mutation', wf_trialModeLeads: { __typename?: 'WF_TrialModeLeads_Mutation_Payload', count: number } };


export const UpdateCtaDocument = gql`
    mutation UpdateCTA($ctaId: Int!, $ctaExperienceTypeId: Int!, $template: String!, $imageCharacter: String, $photoFileName: String, $videoUrl: String, $buttonLinksTo: String!, $buttonText: String!) {
  wf_trialModeLeads(
    update: {callToAction: {ctaId: $ctaId, ctaExperienceTypeId: $ctaExperienceTypeId, template: $template, imageCharacter: $imageCharacter, photoFileName: $photoFileName, videoUrl: $videoUrl, buttonLinksTo: $buttonLinksTo, buttonText: $buttonText}}
  ) {
    count
  }
}
    `;
export type UpdateCtaMutationFn = Apollo.MutationFunction<UpdateCtaMutation, UpdateCtaMutationVariables>;

/**
 * __useUpdateCtaMutation__
 *
 * To run a mutation, you first call `useUpdateCtaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCtaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCtaMutation, { data, loading, error }] = useUpdateCtaMutation({
 *   variables: {
 *      ctaId: // value for 'ctaId'
 *      ctaExperienceTypeId: // value for 'ctaExperienceTypeId'
 *      template: // value for 'template'
 *      imageCharacter: // value for 'imageCharacter'
 *      photoFileName: // value for 'photoFileName'
 *      videoUrl: // value for 'videoUrl'
 *      buttonLinksTo: // value for 'buttonLinksTo'
 *      buttonText: // value for 'buttonText'
 *   },
 * });
 */
export function useUpdateCtaMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCtaMutation, UpdateCtaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCtaMutation, UpdateCtaMutationVariables>(UpdateCtaDocument, options);
      }
export type UpdateCtaMutationHookResult = ReturnType<typeof useUpdateCtaMutation>;
export type UpdateCtaMutationResult = Apollo.MutationResult<UpdateCtaMutation>;
export type UpdateCtaMutationOptions = Apollo.BaseMutationOptions<UpdateCtaMutation, UpdateCtaMutationVariables>;