import { Button } from "antd";

import Alert from "../../../components/ui/Alert";

export default function EditFormAction({
  isModified,
  setEditingData,
  loadingOfUpdate,
  handleReset,
  submitDisabled,
  alertMessage,
}: {
  isModified: boolean;
  setEditingData: React.Dispatch<React.SetStateAction<any>>;
  loadingOfUpdate: boolean;
  handleReset?: () => void;
  submitDisabled?: boolean;
  alertMessage?: string;
}) {
  return (
    <div
      className="fixed z-[1] left-[268px] bottom-0 flex justify-between px-6 py-4 bg-white border-t border-solid border-grey3"
      style={{ width: "calc(100% - 268px)" }}
    >
      {isModified && handleReset ? (
        <Button size="large" onClick={handleReset}>
          Reset to default
        </Button>
      ) : (
        <div />
      )}

      <div className="flex gap-2">
        {alertMessage && submitDisabled ? (
          <Alert message={alertMessage} />
        ) : null}

        <Button size="large" onClick={() => setEditingData(null)}>
          Cancel
        </Button>

        <Button
          type="primary"
          htmlType="submit"
          size="large"
          loading={loadingOfUpdate}
          disabled={!isModified || submitDisabled}
        >
          Submit
        </Button>
      </div>
    </div>
  );
}
