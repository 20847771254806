// @ts-nocheck
import { useRef, useState } from "react";
import { Input, Space, type InputRef, Button, Table, message } from "antd";
import type { FilterConfirmProps } from "antd/es/table/interface";
import type { ColumnType, ColumnsType } from "antd/es/table";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import "./SongTabContent.css";
import { Colors } from "../../../../constants/Colors";
import { useGetTeSongsQuery } from "../../../../graphql/operations/get-te-songs";
import Spinner from "../../../../components/layout/spinner/Spinner";

interface DataType {
  __typename?: "Club_WorldFamilyClub_TeSong";
  id: string;
  songName?: string | null;
  teSongNumber?: string | null;
  product?: string | null;
}

type DataIndex = keyof DataType;

const SongTabContent = () => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);
  const { data, loading, error } = useGetTeSongsQuery();

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Clear
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns: ColumnsType<DataType> = [
    {
      title: "Song title",
      dataIndex: "songName",
      key: "songName",
      ...getColumnSearchProps("songName"),
      sorter: (a, b) => a.songName.localeCompare(b.songName),
    },
    {
      title: "Category",
      dataIndex: "product",
      key: "product",
      ...getColumnSearchProps("product"),
      width: 232,
      sorter: (a, b) => a.product.localeCompare(b.product),
    },
    {
      title: "Song number",
      dataIndex: "teSongNumber",
      key: "teSongNumber",
      width: 232,
    },
  ];

  if (error) {
    message.error(
      renderAntDMessageConfig(
        "Something went wrong. Please contact your system administrator."
      )
    );
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="song-tab-content-container">
      <div
        className="song-tab-table-container"
        style={{ backgroundColor: Colors.white }}
      >
        <Table
          rowKey={(record: DataType) => record.id}
          columns={columns}
          dataSource={data?.afTeSong}
          pagination={{ hideOnSinglePage: true }}
        />
      </div>
    </div>
  );
};

export default SongTabContent;
