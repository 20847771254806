import journeyCardPreview from "../../../../assets/images/trialMode/journeyCardPreview.png";
import { TemplatePreview } from "../TemplatePreview";
import { JourneyCardType } from "../types";

export default function Preview({ formData }: { formData: JourneyCardType }) {
  return (
    <div className="relative">
      <img src={journeyCardPreview} alt="journey card preview" />

      <div className="absolute top-14 left-0">
        <TemplatePreview data={formData} className="bg-none" />
      </div>
    </div>
  );
}
