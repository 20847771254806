import { Form, FormListFieldData, Input, Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import InputCustom from "../../../../../components/custom/InputCustom";
import videoUrlRule from "../../../../../utils/formRules/videoUrlRule";
import requiredRule from "../../../../../utils/formRules/requiredRule";
import { TemplateType } from "../../types";

export function FormItemVideoUrl({
  selectedTemplate,
}: {
  selectedTemplate: TemplateType;
}) {
  return (
    <Form.Item
      name="videoUrl"
      label="Video URL"
      className={selectedTemplate === "Video" ? "" : "hidden"}
      rules={
        selectedTemplate === "Video" ? [requiredRule, videoUrlRule] : undefined
      }
    >
      <InputCustom addonBefore="https://" />
    </Form.Item>
  );
}

export function Title({ subField }: { subField: FormListFieldData }) {
  return (
    <Form.Item
      name={[subField.name, "ctaTitle"]}
      label="Title"
      className="sub-form-item"
      rules={[requiredRule]}
    >
      <InputCustom />
    </Form.Item>
  );
}

export function Content({ subField }: { subField: FormListFieldData }) {
  return (
    <Form.Item
      name={[subField.name, "ctaContent"]}
      label="Content"
      className="sub-form-item"
      rules={[requiredRule]}
    >
      <Input.TextArea showCount maxLength={200} />
    </Form.Item>
  );
}

export function DeleteButton({
  subField,
  remove,
  index,
  setIndex,
}: {
  subField: FormListFieldData;
  remove: (index: number | number[]) => void;
  index: number;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
}) {
  return (
    <Button
      className="bg-white ml-20"
      icon={<DeleteOutlined />}
      onClick={() => {
        remove(subField.name);
        setIndex(index > 0 ? index - 1 : 0);
      }}
    >
      Delete
    </Button>
  );
}
