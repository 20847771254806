import * as Types from './@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateGoGoLiveMutationVariables = Types.Exact<{
  GGLData?: Types.InputMaybe<Array<Types.Club_WorldFamilyClub_GoGoLivePromotionInput> | Types.Club_WorldFamilyClub_GoGoLivePromotionInput>;
}>;


export type UpdateGoGoLiveMutation = { __typename?: 'Mutation', club_worldFamilyClub: { __typename?: 'Club_WorldFamilyClub_Mutation_Payload', count: number } };


export const UpdateGoGoLiveDocument = gql`
    mutation UpdateGoGoLive($GGLData: [Club_WorldFamilyClub_GoGoLivePromotionInput!]) {
  club_worldFamilyClub(updateRange: {goGoLivePromotions: $GGLData}) {
    count
  }
}
    `;
export type UpdateGoGoLiveMutationFn = Apollo.MutationFunction<UpdateGoGoLiveMutation, UpdateGoGoLiveMutationVariables>;

/**
 * __useUpdateGoGoLiveMutation__
 *
 * To run a mutation, you first call `useUpdateGoGoLiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGoGoLiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGoGoLiveMutation, { data, loading, error }] = useUpdateGoGoLiveMutation({
 *   variables: {
 *      GGLData: // value for 'GGLData'
 *   },
 * });
 */
export function useUpdateGoGoLiveMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGoGoLiveMutation, UpdateGoGoLiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGoGoLiveMutation, UpdateGoGoLiveMutationVariables>(UpdateGoGoLiveDocument, options);
      }
export type UpdateGoGoLiveMutationHookResult = ReturnType<typeof useUpdateGoGoLiveMutation>;
export type UpdateGoGoLiveMutationResult = Apollo.MutationResult<UpdateGoGoLiveMutation>;
export type UpdateGoGoLiveMutationOptions = Apollo.BaseMutationOptions<UpdateGoGoLiveMutation, UpdateGoGoLiveMutationVariables>;