import { Form, Select } from "antd";

import requiredRule from "../../../../../utils/formRules/requiredRule";
import getCharacterSrc from "../../utils/getCharacterSrc";
import { CharacterName, TemplateType } from "../../types";

interface OptionType {
  value: CharacterName;
  label: string;
  image: CharacterName;
}

export function FormItemImage({
  selectedTemplate,
}: {
  selectedTemplate: TemplateType;
}) {
  const options: OptionType[] = [
    {
      value: "FroggyBunny",
      label: "Froggy and Bunny",
      image: "FroggyBunny",
    },
    { value: "Zippy", label: "Zippy", image: "Zippy" },
    { value: "Coco", label: "Coco", image: "Coco" },
  ];

  const selectedOptionRenderer = (option: OptionType) => {
    return (
      <div className="flex items-center gap-2 mt-[1px]">
        <img
          src={getCharacterSrc(option.image)}
          alt={option.label}
          className="w-7 h-7"
        />
        <p>{option.label}</p>
      </div>
    );
  };

  const customOptionRenderer = (option: OptionType) => {
    return (
      <div className="flex items-center gap-4">
        <img
          src={getCharacterSrc(option.image)}
          alt={option.label}
          className="w-16 h-16"
        />
        <p>{option.label}</p>
      </div>
    );
  };

  return (
    <Form.Item
      name="imageCharacter"
      label="Image"
      className={selectedTemplate === "Image" ? "" : "hidden"}
      rules={selectedTemplate === "Image" ? [requiredRule] : undefined}
    >
      <Select
        optionLabelProp="custom"
        dropdownRender={(menu) => <div>{menu}</div>}
      >
        {options.map((option) => (
          <Select.Option
            key={option.value}
            value={option.value}
            custom={selectedOptionRenderer(option)}
            children={customOptionRenderer(option)}
          />
        ))}
      </Select>
    </Form.Item>
  );
}
