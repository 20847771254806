import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateHkGraduationStatusMutationVariables = Types.Exact<{
  memberNumber: Types.Scalars['String']['input'];
  childNumber: Types.Scalars['Int']['input'];
  graduationDate: Types.Scalars['DateTime']['input'];
}>;


export type UpdateHkGraduationStatusMutation = { __typename?: 'Mutation', afUpdateChildGraduationOfHongKong: { __typename?: 'Payload', count: number } };


export const UpdateHkGraduationStatusDocument = gql`
    mutation updateHKGraduationStatus($memberNumber: String!, $childNumber: Int!, $graduationDate: DateTime!) {
  afUpdateChildGraduationOfHongKong(
    graduationOfHongKong: {memberNumber: $memberNumber, childNumber: $childNumber, graduationDate: $graduationDate}
  ) {
    count
  }
}
    `;
export type UpdateHkGraduationStatusMutationFn = Apollo.MutationFunction<UpdateHkGraduationStatusMutation, UpdateHkGraduationStatusMutationVariables>;

/**
 * __useUpdateHkGraduationStatusMutation__
 *
 * To run a mutation, you first call `useUpdateHkGraduationStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHkGraduationStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHkGraduationStatusMutation, { data, loading, error }] = useUpdateHkGraduationStatusMutation({
 *   variables: {
 *      memberNumber: // value for 'memberNumber'
 *      childNumber: // value for 'childNumber'
 *      graduationDate: // value for 'graduationDate'
 *   },
 * });
 */
export function useUpdateHkGraduationStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateHkGraduationStatusMutation, UpdateHkGraduationStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateHkGraduationStatusMutation, UpdateHkGraduationStatusMutationVariables>(UpdateHkGraduationStatusDocument, options);
      }
export type UpdateHkGraduationStatusMutationHookResult = ReturnType<typeof useUpdateHkGraduationStatusMutation>;
export type UpdateHkGraduationStatusMutationResult = Apollo.MutationResult<UpdateHkGraduationStatusMutation>;
export type UpdateHkGraduationStatusMutationOptions = Apollo.BaseMutationOptions<UpdateHkGraduationStatusMutation, UpdateHkGraduationStatusMutationVariables>;