import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import { ApolloProvider } from "@apollo/client";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import "./index.css";
import { apolloClient } from "./graphql/client";
import App from "./App";
import { Colors } from "./constants/Colors";
import { msalConfig } from "./auth/authConfig";

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ApolloProvider client={apolloClient}>
    <BrowserRouter>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: "Lexend",
            colorText: Colors.textPrimary,
            colorTextHeading: Colors.textPrimary,
            colorTextLabel: Colors.textPrimary,
            borderRadius: 2,
            colorPrimary: Colors.blue,
            colorTextDisabled: "rgba(0, 0, 0, 0.45)",
          },
          components: {
            Tabs: {
              inkBarColor: Colors.blue,
              itemActiveColor: Colors.blue,
              itemSelectedColor: Colors.blue,
              itemHoverColor: Colors.blue,
            },
          },
        }}
      >
        <MsalProvider instance={msalInstance}>
          <App />
        </MsalProvider>
      </ConfigProvider>
    </BrowserRouter>
  </ApolloProvider>
);
