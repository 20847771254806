import { useState } from "react";
import { Form, Input, Checkbox } from "antd";

import { useUpdateJourneyCardMutation } from "../../../../graphql/operations/trialMode/journeyCard/update-journey-card";
import Spinner from "../../../../components/layout/spinner/Spinner";
import InputCustom from "../../../../components/custom/InputCustom";
import videoUrlRule from "../../../../utils/formRules/videoUrlRule";
import getLineBreakRule from "../../../../utils/formRules/getLineBreakRule";
import requiredRule from "../../../../utils/formRules/requiredRule";
import EditFormAction from "../../components/EditFormAction";
import getSegmentationDesc from "../../utils/getSegmentationDesc";
import { JourneyCardType } from "../types";
import Preview from "./Preview";
import { FormItemCharacter } from "./FormItemCharacter";
import handleSubmit from "./handleSubmit";

export default function EditForm({
  data,
  setEditingData,
  refetch,
}: {
  data: JourneyCardType;
  setEditingData: React.Dispatch<React.SetStateAction<JourneyCardType | null>>;
  refetch: any;
}) {
  const { market, segmentation, experienceName } = data;
  const [form] = Form.useForm();

  const [formData, setFormData] = useState<JourneyCardType>(data);
  const { isDisplayVideo } = formData;

  const [updateJourneyCard, { loading: loadingOfUpdate }] =
    useUpdateJourneyCardMutation();

  return (
    <div className="flex justify-center gap-6 flex-none">
      <div className="w-[622px]">
        <Form
          name="edit-caringCTA"
          className="form-align-center"
          form={form}
          initialValues={data}
          onValuesChange={(changedValues, allValues) => {
            setFormData(allValues);
          }}
          onFinish={(values: JourneyCardType) => {
            handleSubmit({
              values,
              data,
              updateJourneyCard,
              refetch,
              setEditingData,
            });
          }}
        >
          <Form.Item label="Market">
            <p>{market}</p>
          </Form.Item>

          <Form.Item label="Segmentation" className="w-[300px]">
            <p>{getSegmentationDesc(segmentation)}</p>
          </Form.Item>

          <Form.Item label="Experience Type">
            <p>{experienceName}</p>
          </Form.Item>

          <Form.Item
            name="greeting"
            label="Greeting"
            rules={[requiredRule]}
            extra="If you want to display the user in the sentence, please type in {User's Name}"
          >
            <InputCustom />
          </Form.Item>

          <Form.Item
            name="experiencePromotionWording"
            label="Promotion Wording"
            rules={[requiredRule, getLineBreakRule(1)]}
          >
            <Input.TextArea showCount maxLength={30} />
          </Form.Item>

          <Form.Item
            name="experienceNavigationWording"
            label="Navigation Wording"
            rules={[requiredRule, getLineBreakRule(1)]}
          >
            <Input.TextArea showCount maxLength={20} />
          </Form.Item>

          <FormItemCharacter />

          <Form.Item
            name="isDisplayVideo"
            label="Video introduction"
            valuePropName="checked"
          >
            <Checkbox>Display video</Checkbox>
          </Form.Item>

          <Form.Item
            name="videoUrl"
            label="Video url"
            rules={isDisplayVideo ? [requiredRule, videoUrlRule] : undefined}
          >
            <InputCustom addonBefore="https://" disabled={!isDisplayVideo} />
          </Form.Item>

          <Form.Item
            name="videoWording"
            label="Video wording"
            rules={isDisplayVideo ? [requiredRule] : undefined}
          >
            <InputCustom disabled={!isDisplayVideo} />
          </Form.Item>

          <EditFormAction
            isModified={true}
            setEditingData={setEditingData}
            loadingOfUpdate={false}
          />
        </Form>
      </div>

      <Preview formData={formData} />

      {loadingOfUpdate ? <Spinner /> : null}
    </div>
  );
}
