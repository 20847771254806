import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetServiceListOfServiceBannerQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetServiceListOfServiceBannerQuery = { __typename?: 'Query', serviceListOfDefaultBanner: Array<{ __typename?: 'BannerServiceInfo', serviceName: string }> };


export const GetServiceListOfServiceBannerDocument = gql`
    query getServiceListOfServiceBanner {
  serviceListOfDefaultBanner {
    serviceName
  }
}
    `;

/**
 * __useGetServiceListOfServiceBannerQuery__
 *
 * To run a query within a React component, call `useGetServiceListOfServiceBannerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceListOfServiceBannerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceListOfServiceBannerQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetServiceListOfServiceBannerQuery(baseOptions?: Apollo.QueryHookOptions<GetServiceListOfServiceBannerQuery, GetServiceListOfServiceBannerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetServiceListOfServiceBannerQuery, GetServiceListOfServiceBannerQueryVariables>(GetServiceListOfServiceBannerDocument, options);
      }
export function useGetServiceListOfServiceBannerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetServiceListOfServiceBannerQuery, GetServiceListOfServiceBannerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetServiceListOfServiceBannerQuery, GetServiceListOfServiceBannerQueryVariables>(GetServiceListOfServiceBannerDocument, options);
        }
export type GetServiceListOfServiceBannerQueryHookResult = ReturnType<typeof useGetServiceListOfServiceBannerQuery>;
export type GetServiceListOfServiceBannerLazyQueryHookResult = ReturnType<typeof useGetServiceListOfServiceBannerLazyQuery>;
export type GetServiceListOfServiceBannerQueryResult = Apollo.QueryResult<GetServiceListOfServiceBannerQuery, GetServiceListOfServiceBannerQueryVariables>;