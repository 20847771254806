/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Tabs, TabsProps } from "antd";
import { useReactiveVar } from "@apollo/client";
import { FaceCallPerformerTypes, ServiceTypes } from "../../../types/types";
import { Colors } from "../../../constants/Colors";
import VideoTabContent from "./components/VideoTabContent";
import SongTabContent from "./components/SongTabContent";
import PerformerTabContent from "./components/PerformerTabContent";
import ThemeTabContent from "./components/ThemeTabContent";
import { useGetUserProgramsByServiceQuery } from "../../../graphql/operations/get-user-program-by-service";

import { __currentUser__ } from "../../../graphql/policies";
import { renderDecodedId } from "../../../utils/renderDecodedId";
import NoPermission from "../../../components/others/NoPermission";
import Spinner from "../../../components/layout/spinner/Spinner";

interface FaceCallProps {
  setSelectedService: Dispatch<SetStateAction<ServiceTypes>>;
  setSelectSubItem: Dispatch<SetStateAction<string | undefined>>;
}

const FaceCall = ({ setSelectedService, setSelectSubItem }: FaceCallProps) => {
  const currentUser = useReactiveVar(__currentUser__);
  const [activeKey, setActiveKey] = useState("1");
  const [filterKey, setFilterKey] = useState("");
  const {
    data: userPrograms,
    loading: userProgramsLoading,
    error: userProgramsError,
  } = useGetUserProgramsByServiceQuery({
    variables: {
      userId: parseInt(renderDecodedId(currentUser?.id)),
      serviceId: 2,
    },
  });

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Face Call Videos",
      children: <VideoTabContent filterKey={filterKey} />,
    },
    {
      key: "2",
      label: "Songs",
      children: <SongTabContent />,
    },
    {
      key: "3",
      label: "Performers",
      children: (
        <PerformerTabContent
          type={FaceCallPerformerTypes.PERFORMER}
          setActiveKey={setActiveKey}
          setFilterKey={setFilterKey}
        />
      ),
    },
    {
      key: "4",
      label: "Zippy and His Friends",
      children: (
        <PerformerTabContent
          type={FaceCallPerformerTypes.ZIPPY_FAMILY}
          setActiveKey={setActiveKey}
          setFilterKey={setFilterKey}
        />
      ),
    },
    {
      key: "5",
      label: "Theme",
      children: <ThemeTabContent />,
    },
  ];

  const onChange = (key: string) => {
    console.log(key);
    setActiveKey(key);
  };

  useEffect(() => {
    setSelectedService(ServiceTypes.CLUB_APP);
    setSelectSubItem("Face Call");
  }, []);

  if (userProgramsError) {
    message.error(
      renderAntDMessageConfig(
        "Something went wrong. Please contact your system administrator."
      )
    );
  }

  if (userProgramsLoading) {
    return <Spinner />;
  }

  if (
    userPrograms &&
    !userPrograms.wf_adminFirst_afUserProgram?.edges?.find(
      (item) => item.node.afProgram?.programName === "Face Call"
    )
  ) {
    return <NoPermission />;
  }

  return (
    <div>
      <div className="header" style={{ backgroundColor: Colors.white }}>
        <p className="header-title" style={{ color: Colors.darkGrey }}>
          Face Call
        </p>
      </div>
      <Tabs
        activeKey={activeKey}
        items={items}
        onChange={onChange}
        size="small"
        tabBarStyle={{
          paddingLeft: 24,
          backgroundColor: Colors.white,
          height: 50,
        }}
      />
    </div>
  );
};

export default FaceCall;
