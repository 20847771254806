import ButtonCreate from "../../components/ButtonCreate";
import { DrawerActionType } from "../../types";

export default function TableMenu({
  count,
  setOpenDrawer,
  setDrawerAction,
}: {
  count: number;
  setOpenDrawer: (value: React.SetStateAction<boolean>) => void;
  setDrawerAction: (value: React.SetStateAction<DrawerActionType>) => void;
}) {
  return (
    <div className="flex justify-between items-center px-2 py-4">
      <p className="text-base font-medium">Total {count} items</p>

      <ButtonCreate
        setDrawerAction={setDrawerAction}
        setOpenDrawer={setOpenDrawer}
      />
    </div>
  );
}
