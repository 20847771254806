import * as Types from './@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllPerformersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllPerformersQuery = { __typename?: 'Query', afFaceCallPerformer: Array<{ __typename?: 'Club_WorldFamilyClub_FaceCallPerformer', id: string, performerName?: string | null, avatarFileName?: string | null, zippyFlag?: boolean | null, market?: string | null, faceCallSongAndPerformers?: Array<{ __typename?: 'Club_WorldFamilyClub_FaceCallSongAndPerformer', faceCallSong?: { __typename?: 'Club_WorldFamilyClub_FaceCallSong', id: string } | null } | null> | null }> };


export const GetAllPerformersDocument = gql`
    query GetAllPerformers {
  afFaceCallPerformer {
    id
    performerName
    avatarFileName
    zippyFlag
    market
    faceCallSongAndPerformers {
      faceCallSong {
        id
      }
    }
  }
}
    `;

/**
 * __useGetAllPerformersQuery__
 *
 * To run a query within a React component, call `useGetAllPerformersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPerformersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPerformersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllPerformersQuery(baseOptions?: Apollo.QueryHookOptions<GetAllPerformersQuery, GetAllPerformersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllPerformersQuery, GetAllPerformersQueryVariables>(GetAllPerformersDocument, options);
      }
export function useGetAllPerformersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllPerformersQuery, GetAllPerformersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllPerformersQuery, GetAllPerformersQueryVariables>(GetAllPerformersDocument, options);
        }
export type GetAllPerformersQueryHookResult = ReturnType<typeof useGetAllPerformersQuery>;
export type GetAllPerformersLazyQueryHookResult = ReturnType<typeof useGetAllPerformersLazyQuery>;
export type GetAllPerformersQueryResult = Apollo.QueryResult<GetAllPerformersQuery, GetAllPerformersQueryVariables>;