import { FormInstance } from "antd";

import { AfBlobPath } from "../../../../graphql/operations/@types";
import { renderDecodedId } from "../../../../utils/renderDecodedId";
import { DrawerActionType } from "../../types";
import { denormalizeRegularEventsData } from "../utils/denormalizeData";
import { RegularEventsFormDataType } from "../types";

export default function handleSubmit({
  values,
  form,
  drawerAction,
  uploadFile,
  createRegularEvent,
  updateRegularEvent,
  refetch,
  setOpenDrawer,
}: {
  values: RegularEventsFormDataType;
  form: FormInstance<any>;
  drawerAction: DrawerActionType;
  uploadFile: any;
  createRegularEvent: any;
  updateRegularEvent: any;
  refetch: any;
  setOpenDrawer: (value: React.SetStateAction<boolean>) => void;
}) {
  const id = form.getFieldValue("id");
  const { originFileObj } = values.image[0] || {};

  if (originFileObj)
    uploadFile({
      variables: {
        blobs: [
          {
            file: originFileObj,
            path: AfBlobPath.TrialModeLocalEventRegularEventBanners,
          },
        ],
      },
    }).catch((e: any) => console.log(e));

  if (drawerAction === "Create")
    createRegularEvent({ variables: denormalizeRegularEventsData(values) })
      .then(() => {
        refetch()
          .then(() => {
            form.resetFields();
            setOpenDrawer(false);
          })
          .catch((e: any) => console.log(e));
      })
      .catch((e: any) => console.log(e));

  if (drawerAction === "Edit")
    updateRegularEvent({
      variables: {
        regularEventId: parseInt(renderDecodedId(id)),
        ...denormalizeRegularEventsData(values),
      },
    })
      .then(() => {
        refetch();
        form.resetFields();
        setOpenDrawer(false);
      })
      .catch((e: any) => console.log(e));
}
