import { MarketType, SegmentationType } from "../types";

export enum TrialModeServiceType {
  ClubNews = "clubNews",
  News = "news",
  Survey = "survey",
  GoGoLive = "GGL",
}

export enum NotificationActionButtonType {
  None = "None",
  ClubAppScreen = "AppScreen",
  EmbedURL = "AppEmbedURL",
  OpenBrowser = "OpenBrowser",
}

export enum NotificationButtonLinkType {
  ABCChannel = "on-line-video",
  FaceCall = "fc",
  ParentsSupport = "parent-support",
}

export type JobStatusType = "pending" | "success";

export interface TrialModeNotificationDataType {
  key: string;
  id: string;
  market: MarketType;
  notificationTypeId: TrialModeServiceType;
  notificationName: string;
  title: string;
  templateMessage: string;
  imageFileName: string;
  imageUrl: string;
  actionButton: NotificationActionButtonType;
  buttonLinksTo: string;
  buttonText: string;
  scheduleTime: string;
  jobStatus: JobStatusType;
  segmentations: SegmentationType[];
}

export interface TrialModeNotificationModifyData {
  market: MarketType;
  segmentations: SegmentationType[];
  serviceType: TrialModeServiceType | null;
  notificationName: string;
  title: string;
  templateMessage: string;
  imageFileName: string;
  imageUrl: string;
  actionButton: NotificationActionButtonType;
  buttonLinksTo: string;
  buttonLinksToUrl: string;
  buttonText: string;
  scheduleTime: string;
  jobStatus: JobStatusType;
  testMemberNo: string;
}
