import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetGraduationApplyByIdQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetGraduationApplyByIdQuery = { __typename?: 'Query', club_worldFamilyClub_graduationApplication?: { __typename?: 'Club_WorldFamilyClub_GraduationApplicationConnection', totalCount: number, nodes?: Array<{ __typename?: 'Club_WorldFamilyClub_GraduationApplication', id: string, age?: string | null, applicationDate?: any | null, audioFile?: string | null, childEnglishName?: string | null, childName?: string | null, childNo?: string | null, isExcellent?: boolean | null, market?: string | null, memberNo?: string | null, taskId?: number | null, videoUrl?: string | null, isPassed?: boolean | null, approvalDate?: any | null, overallComment?: string | null, yearCount?: string | null, audioUrl?: string | null, graduationSubApplications?: Array<{ __typename?: 'Club_WorldFamilyClub_GraduationSubApplication', id: string, applicationId?: number | null, subTaskId?: number | null, subTask?: { __typename?: 'Club_WorldFamilyClub_GraduationApplicationSubTask', graduationSubTaskMarketDescriptions?: Array<{ __typename?: 'Club_WorldFamilyClub_GraduationSubTaskMarketDescription', market?: string | null, subTaskId?: number | null, subTaskName?: string | null } | null> | null } | null } | null> | null, graduationApplicationEvaluations?: Array<{ __typename?: 'Club_WorldFamilyClub_GraduationApplicationEvaluation', id: string, applicationId?: number | null, evaluationId?: number | null } | null> | null }> | null } | null };


export const GetGraduationApplyByIdDocument = gql`
    query getGraduationApplyById($id: Int) {
  club_worldFamilyClub_graduationApplication(filter: {applicationId: {eq: $id}}) {
    totalCount
    nodes {
      id
      age
      applicationDate
      audioFile
      audioUrl: audioFile @wf_blobSignature(name: "GRADUATION_AUDIO_SIGNATURE")
      childEnglishName
      childName
      childNo
      isExcellent
      market
      memberNo
      taskId
      videoUrl
      graduationSubApplications {
        id
        applicationId
        subTaskId
        subTask {
          graduationSubTaskMarketDescriptions {
            market
            subTaskId
            subTaskName
          }
        }
      }
      isPassed
      approvalDate
      overallComment
      graduationApplicationEvaluations {
        id
        applicationId
        evaluationId
      }
      yearCount
    }
  }
}
    `;

/**
 * __useGetGraduationApplyByIdQuery__
 *
 * To run a query within a React component, call `useGetGraduationApplyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGraduationApplyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGraduationApplyByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGraduationApplyByIdQuery(baseOptions?: Apollo.QueryHookOptions<GetGraduationApplyByIdQuery, GetGraduationApplyByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGraduationApplyByIdQuery, GetGraduationApplyByIdQueryVariables>(GetGraduationApplyByIdDocument, options);
      }
export function useGetGraduationApplyByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGraduationApplyByIdQuery, GetGraduationApplyByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGraduationApplyByIdQuery, GetGraduationApplyByIdQueryVariables>(GetGraduationApplyByIdDocument, options);
        }
export type GetGraduationApplyByIdQueryHookResult = ReturnType<typeof useGetGraduationApplyByIdQuery>;
export type GetGraduationApplyByIdLazyQueryHookResult = ReturnType<typeof useGetGraduationApplyByIdLazyQuery>;
export type GetGraduationApplyByIdQueryResult = Apollo.QueryResult<GetGraduationApplyByIdQuery, GetGraduationApplyByIdQueryVariables>;