import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetTrialCampaignChannelUrlQueryVariables = Types.Exact<{
  market: Types.Scalars['String']['input'];
  channel: Types.Scalars['String']['input'];
  device: Types.Scalars['String']['input'];
}>;


export type GetTrialCampaignChannelUrlQuery = { __typename?: 'Query', trialCampaignChannelUrl: Array<{ __typename?: 'TrialCampaignChannelUrl', url?: string | null }> };


export const GetTrialCampaignChannelUrlDocument = gql`
    query getTrialCampaignChannelUrl($market: String!, $channel: String!, $device: String!) {
  trialCampaignChannelUrl(market: $market, channel: $channel, device: $device) {
    url
  }
}
    `;

/**
 * __useGetTrialCampaignChannelUrlQuery__
 *
 * To run a query within a React component, call `useGetTrialCampaignChannelUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrialCampaignChannelUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrialCampaignChannelUrlQuery({
 *   variables: {
 *      market: // value for 'market'
 *      channel: // value for 'channel'
 *      device: // value for 'device'
 *   },
 * });
 */
export function useGetTrialCampaignChannelUrlQuery(baseOptions: Apollo.QueryHookOptions<GetTrialCampaignChannelUrlQuery, GetTrialCampaignChannelUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTrialCampaignChannelUrlQuery, GetTrialCampaignChannelUrlQueryVariables>(GetTrialCampaignChannelUrlDocument, options);
      }
export function useGetTrialCampaignChannelUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTrialCampaignChannelUrlQuery, GetTrialCampaignChannelUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTrialCampaignChannelUrlQuery, GetTrialCampaignChannelUrlQueryVariables>(GetTrialCampaignChannelUrlDocument, options);
        }
export type GetTrialCampaignChannelUrlQueryHookResult = ReturnType<typeof useGetTrialCampaignChannelUrlQuery>;
export type GetTrialCampaignChannelUrlLazyQueryHookResult = ReturnType<typeof useGetTrialCampaignChannelUrlLazyQuery>;
export type GetTrialCampaignChannelUrlQueryResult = Apollo.QueryResult<GetTrialCampaignChannelUrlQuery, GetTrialCampaignChannelUrlQueryVariables>;