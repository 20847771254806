import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetNotificationTemplateQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetNotificationTemplateQuery = { __typename?: 'Query', club_worldFamilyClub_memberMessageSetting?: { __typename?: 'Club_WorldFamilyClub_MemberMessageSettingConnection', nodes?: Array<{ __typename?: 'Club_WorldFamilyClub_MemberMessageSetting', createdTime?: any | null, market?: string | null, id: string, messageType?: string | null, notificationId?: number | null, pushMessage?: string | null, smsMessage?: string | null }> | null } | null };


export const GetNotificationTemplateDocument = gql`
    query getNotificationTemplate {
  club_worldFamilyClub_memberMessageSetting(first: 100) {
    nodes {
      createdTime
      market
      id
      messageType
      notificationId
      pushMessage
      smsMessage
    }
  }
}
    `;

/**
 * __useGetNotificationTemplateQuery__
 *
 * To run a query within a React component, call `useGetNotificationTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationTemplateQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNotificationTemplateQuery(baseOptions?: Apollo.QueryHookOptions<GetNotificationTemplateQuery, GetNotificationTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationTemplateQuery, GetNotificationTemplateQueryVariables>(GetNotificationTemplateDocument, options);
      }
export function useGetNotificationTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationTemplateQuery, GetNotificationTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationTemplateQuery, GetNotificationTemplateQueryVariables>(GetNotificationTemplateDocument, options);
        }
export type GetNotificationTemplateQueryHookResult = ReturnType<typeof useGetNotificationTemplateQuery>;
export type GetNotificationTemplateLazyQueryHookResult = ReturnType<typeof useGetNotificationTemplateLazyQuery>;
export type GetNotificationTemplateQueryResult = Apollo.QueryResult<GetNotificationTemplateQuery, GetNotificationTemplateQueryVariables>;