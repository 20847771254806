export const getNotificationTemplate = (list: any, isPassed: boolean, hasComment: boolean, isLastMonth: boolean, isLastApplication: boolean) =>{
    const template = isPassed
        ? isLastApplication
          ? !hasComment
            ? list
                ?.filter((item: any) => item.messageType === "Graduation_one_passed")
                .concat(
                  list?.filter(
                    (item: any) => item.messageType === "Graduation_all_passed"
                  )
                )
            : list
                ?.filter(
                  (item: any) => item.messageType === "Graduation_one_passed_comment"
                )
                .concat(
                  list?.filter(
                    (item: any) => item.messageType === "Graduation_all_passed"
                  )
                )
          : !hasComment
          ? list?.filter(
              (item: any) => item.messageType === "Graduation_one_passed"
            )
          : list?.filter(
              (item: any) => item.messageType === "Graduation_one_passed_comment"
            )
        : isLastMonth
        ? list?.filter(
            (item: any) => item.messageType === "Graduation_failed_last_chance"
          )
        : list?.filter(
            (item: any) => item.messageType === "Graduation_one_failed"
          );
    return template
}