import { NotificationActionButtonType } from "../../types";
import FormItemButtonLinksTo from "./FormItemButtonLinksTo";
import { FormItemButtonText } from "./FormItems";

export default function FormItemButtonFields({
  actionButton,
}: {
  actionButton: NotificationActionButtonType;
}) {
  if (actionButton === NotificationActionButtonType.None) return null;

  return (
    <>
      <FormItemButtonLinksTo actionButton={actionButton} />
      <FormItemButtonText />
    </>
  );
}
