import * as Types from './@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddNotificationMutationVariables = Types.Exact<{
  notification: Types.AfNotificationInput;
  members?: Types.InputMaybe<Array<Types.AfMemberInput> | Types.AfMemberInput>;
  isAllMembers?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type AddNotificationMutation = { __typename?: 'Mutation', afAddNotification: { __typename?: 'Payload', count: number } };


export const AddNotificationDocument = gql`
    mutation AddNotification($notification: AfNotificationInput!, $members: [AfMemberInput!], $isAllMembers: Boolean) {
  afAddNotification(
    notification: $notification
    members: $members
    isAllMembers: $isAllMembers
  ) {
    count
  }
}
    `;
export type AddNotificationMutationFn = Apollo.MutationFunction<AddNotificationMutation, AddNotificationMutationVariables>;

/**
 * __useAddNotificationMutation__
 *
 * To run a mutation, you first call `useAddNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNotificationMutation, { data, loading, error }] = useAddNotificationMutation({
 *   variables: {
 *      notification: // value for 'notification'
 *      members: // value for 'members'
 *      isAllMembers: // value for 'isAllMembers'
 *   },
 * });
 */
export function useAddNotificationMutation(baseOptions?: Apollo.MutationHookOptions<AddNotificationMutation, AddNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddNotificationMutation, AddNotificationMutationVariables>(AddNotificationDocument, options);
      }
export type AddNotificationMutationHookResult = ReturnType<typeof useAddNotificationMutation>;
export type AddNotificationMutationResult = Apollo.MutationResult<AddNotificationMutation>;
export type AddNotificationMutationOptions = Apollo.BaseMutationOptions<AddNotificationMutation, AddNotificationMutationVariables>;