import { Badge } from "antd";

export default function StatusBadge({ isActive }: { isActive: boolean }) {
  return (
    <Badge
      color={isActive ? "#394cf6" : "#D9D9D9"}
      text={isActive ? "In use" : "Not in use"}
    />
  );
}
