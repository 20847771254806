import { useEffect, useState } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

import ContentTitle from "../../../../components/ui/ContentTitle";
import getReportInGroup from "./utils/getReportInGroup";

export default function Dashboard() {
  const isAuthenticated = useIsAuthenticated();
  const { accounts, instance } = useMsal();

  const [embedToken, setEmbedToken] = useState("");
  const [reportInGroup, setReportInGroup] = useState<any>();
  const [error, setError] = useState<any>();

  const getAccessToken = async () => {
    if (isAuthenticated && accounts.length > 0) {
      try {
        const response = await instance.acquireTokenSilent({
          scopes: [
            "openid",
            "profile",
            "https://analysis.windows.net/powerbi/api/.default",
          ],
          account: accounts[0],
        });

        getReportInGroup(
          response.accessToken,
          setEmbedToken,
          setReportInGroup,
          setError
        );
      } catch (error: any) {
        console.error("getAccessToken error", error);
        setError(error);
      }
    } else {
      setError("用户未驗證或没有帳號");
    }
  };

  useEffect(() => {
    getAccessToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { id, embedUrl } = reportInGroup || {};

  console.log(id, embedUrl, embedToken, error);

  return (
    <div className="pb-[0.5px]">
      <ContentTitle title="Dashboard" />

      <div className="bg-white mx-5 my-6 px-6 py-4">
        <iframe
          title="TrialMode_202407"
          width="100%"
          height="660"
          src="https://app.powerbi.com/view?r=eyJrIjoiYjZjMDEzOWYtM2I0Ni00YjdjLWFjYWUtNWY0OGY2N2RlMjE1IiwidCI6ImMzYzhiZjY3LTA2ZGYtNGNmMC1hN2RmLTk2NWY1Y2Y5ZmJjOSIsImMiOjEwfQ%3D%3D "
          allowFullScreen={true}
        />
      </div>
    </div>
  );
}
