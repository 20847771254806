import {
  TrialModeNotificationDataType,
  TrialModeNotificationModifyData,
} from "../types";

export default function generateDataFromRecord(
  record: TrialModeNotificationDataType
): TrialModeNotificationModifyData {
  const {
    actionButton,
    buttonLinksTo,
    buttonText,
    imageFileName,
    imageUrl,
    market,
    notificationTypeId,
    notificationName,
    segmentations,
    templateMessage,
    title,
    scheduleTime,
    jobStatus,
  } = record;

  return {
    actionButton,
    buttonLinksTo,
    buttonLinksToUrl: "",
    buttonText,
    imageFileName,
    market,
    imageUrl,
    serviceType: notificationTypeId,
    notificationName,
    segmentations,
    templateMessage,
    title,
    scheduleTime,
    jobStatus,
    testMemberNo: "",
  };
}
