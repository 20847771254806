import { Button } from "antd";
import type { ColumnsType } from "antd/es/table";

import Tag from "../../../components/ui/Tag";
import getSegmentationDesc from "../utils/getSegmentationDesc";
import { defaultImage } from "../constants";
import { SegmentationType } from "../types";
import getCharacterSrc from "./utils/getCharacterSrc";
import {
  CTADataType,
  CTAContentType,
  ExperienceType,
  ButtonLinkType,
} from "./types";

export function createColumns(
  setEditingData: React.Dispatch<React.SetStateAction<CTADataType | null>>
): ColumnsType<CTADataType> {
  return [
    {
      title: "Segmentation",
      dataIndex: "segmentation",
      render: (value: SegmentationType) => {
        const color =
          value === "Awareness"
            ? "lightBlue"
            : value === "Decision"
            ? "gold"
            : "green";

        return <Tag color={color}>{getSegmentationDesc(value)}</Tag>;
      },
      filters: [
        { text: "Awareness", value: "Awareness" },
        { text: "Interest", value: "Interest" },
        { text: "Decision", value: "Decision" },
      ],
      onFilter: (value, record) =>
        record.segmentation.indexOf(String(value)) === 0,
    },
    {
      title: "Experience Type",
      dataIndex: "experienceName",
      render: (value: ExperienceType) => value,
    },
    {
      title: "Template",
      render: (_, record: CTADataType) => {
        const { template, imageCharacter, photoUrl } = record;

        if (template === "Image")
          return (
            <img
              src={getCharacterSrc(imageCharacter) || defaultImage}
              alt={imageCharacter}
            />
          );

        if (template === "Photo")
          return <img src={photoUrl || defaultImage} alt="photoTemplate" />;

        return <p className="text-grey025">{template}</p>;
      },
    },
    {
      title: "Content",
      dataIndex: "contents",
      render: (value: CTAContentType[]) => {
        const { ctaTitle, ctaContent } = value[0] || {};

        return (
          <>
            <p className="text-base font-semibold leading-6">{ctaTitle}</p>
            <p className="text-sm">{ctaContent}</p>
          </>
        );
      },
    },
    {
      title: "Total no. of content",
      dataIndex: "contents",
      render: (value: CTAContentType[]) => value.length,
    },
    {
      title: "Button",
      dataIndex: "buttonLinksTo",
      render: (value: ButtonLinkType) => value,
      sorter: (a, b) => {
        const charA = a.buttonLinksTo.charAt(0);
        const charB = b.buttonLinksTo.charAt(0);

        if (charA < charB) return -1;
        if (charA > charB) return 1;
        return 0;
      },
    },
    {
      title: "Action",
      render: (_, record: CTADataType) => (
        <Button
          type="link"
          className="p-0"
          onClick={() => setEditingData(record)}
        >
          Edit
        </Button>
      ),
    },
  ];
}
