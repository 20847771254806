import dayjs from "dayjs";
export const getNotificationMessage = (item: any, taskInfo: any, record: any, reasonString: string, comment: string) => {
  const year = record?.market==="HK"? record?.yearCount.split('-')[0]: dayjs().format("YYYY")
  const nextGraduationYear = (parseInt(year, 10)+1).toString()  
  const notificationMessage = item.pushMessage
            ?.replace("[Task Name]", taskInfo)
            .replaceAll("[Graduation Year]", year)
            .replaceAll("[Child Name]", record?.childName)
            .replace("[evaluations]", reasonString)
            .replace(
              "[Next Submission Date]",
              dayjs(record?.applicationDate)
                .add(1, "month")
                .startOf("month")
                .format("YYYY-MM-DD")
            )
            .replace("[Next Graduation Year]", nextGraduationYear)
            .replace("[Evaluator's text]", comment);
    return notificationMessage
}