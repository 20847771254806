import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUploadProductSongsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetUploadProductSongsQuery = { __typename?: 'Query', wf_trialModeLeads_productSong?: { __typename?: 'WF_TrialModeLeads_ProductSongConnection', nodes?: Array<{ __typename?: 'WF_TrialModeLeads_ProductSong', id: string, productCategoryId?: number | null, sortId?: number | null, songTitle?: string | null, audioFileName?: string | null, lyricsFileName?: string | null, audioUrl?: string | null, productCategory?: { __typename?: 'WF_TrialModeLeads_ProductCategory', category?: string | null } | null, productSongAndMarkets?: Array<{ __typename?: 'WF_TrialModeLeads_ProductSongAndMarket', id: string, market?: string | null, segmentation?: string | null } | null> | null }> | null } | null };


export const GetUploadProductSongsDocument = gql`
    query GetUploadProductSongs {
  wf_trialModeLeads_productSong(first: 100, order: {sortId: ASC}) {
    nodes {
      id
      productCategoryId
      sortId
      songTitle
      audioFileName
      audioUrl: audioFileName @wf_blobSignature(name: "TRIAL_MODE_ONLINE_EXPERIENCE_AUDIOS_SIGNATURE")
      lyricsFileName
      productCategory {
        category
      }
      productSongAndMarkets {
        id
        market
        segmentation
      }
    }
  }
}
    `;

/**
 * __useGetUploadProductSongsQuery__
 *
 * To run a query within a React component, call `useGetUploadProductSongsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUploadProductSongsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUploadProductSongsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUploadProductSongsQuery(baseOptions?: Apollo.QueryHookOptions<GetUploadProductSongsQuery, GetUploadProductSongsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUploadProductSongsQuery, GetUploadProductSongsQueryVariables>(GetUploadProductSongsDocument, options);
      }
export function useGetUploadProductSongsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUploadProductSongsQuery, GetUploadProductSongsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUploadProductSongsQuery, GetUploadProductSongsQueryVariables>(GetUploadProductSongsDocument, options);
        }
export type GetUploadProductSongsQueryHookResult = ReturnType<typeof useGetUploadProductSongsQuery>;
export type GetUploadProductSongsLazyQueryHookResult = ReturnType<typeof useGetUploadProductSongsLazyQuery>;
export type GetUploadProductSongsQueryResult = Apollo.QueryResult<GetUploadProductSongsQuery, GetUploadProductSongsQueryVariables>;