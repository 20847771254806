import { useState } from "react";
import Sider from "antd/es/layout/Sider";

import { ServiceTypes } from "../../../types/types";
import { subMenuWidth, serviceItems } from "./constants";
import { ServiceItemsType } from "./types";
import SubMenu from "./SubMenu";
import "./ServiceSubMenu.css";

const ServiceSubMenu = ({ selectService }: { selectService: ServiceTypes }) => {
  const [collapsed, setCollapsed] = useState(false);
  const serviceData: ServiceItemsType | undefined = serviceItems.find(
    (service) => service.service === selectService
  );

  return (
    <Sider
      trigger={
        !collapsed ? (
          <img
            src={
              require("../../../assets/icons/submenu/switcher-left.svg").default
            }
            alt="button"
          />
        ) : (
          <img
            src={
              require("../../../assets/icons/submenu/switcher-right.svg")
                .default
            }
            alt="button"
          />
        )
      }
      collapsible
      collapsed={collapsed}
      collapsedWidth={0}
      onCollapse={(value) => setCollapsed(value)}
      style={{
        height: "calc(100vh - 60px)",
        zIndex: 9,
      }}
      width={subMenuWidth}
    >
      {serviceData ? <SubMenu serviceData={serviceData} /> : null}
    </Sider>
  );
};

export default ServiceSubMenu;
