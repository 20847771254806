import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useReactiveVar } from "@apollo/client";
import { __currentUser__ } from "../../../graphql/policies";
import { Tabs, TabsProps, message } from "antd";
import { ServiceTypes } from "../../../types/types";
import { useGetReleaseVersionQuery } from "../../../graphql/operations/sales188/getReleaseVersion";
import { useGetUserProgramsByServiceQuery } from "../../../graphql/operations/get-user-program-by-service";
import { renderDecodedId } from "../../../utils/renderDecodedId";
import { renderAntDMessageConfig } from "../../../utils/renderAntDMessageConfig";
import { Colors } from "../../../constants/Colors";
import { VersionData } from "./types";
import ContentTitle from "../../../components/ui/ContentTitle";
import Dev from "./dev";
import Release from "./release";
import Spinner from "../../../components/layout/spinner/Spinner";
import NoPermission from "../../../components/others/NoPermission";

interface ReleaseVersionProps {
  setSelectedService: Dispatch<SetStateAction<ServiceTypes>>;
  setSelectSubItem: Dispatch<SetStateAction<string | undefined>>;
}
const Sales188ReleaseVersion = ({
  setSelectedService,
  setSelectSubItem,
}: ReleaseVersionProps) => {
  const [versionData, setVersionData] = useState<VersionData[] | any>([]);
  const { data, refetch } = useGetReleaseVersionQuery();
  const currentUser = useReactiveVar(__currentUser__);
  const {
    data: userPrograms,
    loading: userProgramsLoading,
    error: userProgramsError,
  } = useGetUserProgramsByServiceQuery({
    variables: {
      userId: parseInt(renderDecodedId(currentUser?.id)),
      serviceId: 5,
    },
  });
  useEffect(() => {
    setSelectedService(ServiceTypes.SALES_188);
    setSelectSubItem("Release Version");
    refetch();
  }, [setSelectedService, setSelectSubItem]);

  useEffect(() => {
    if (data) {
      setVersionData(data?.sales188Release);
    }
  }, [data]);
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Dev Build",
      children: <Dev data={versionData} />,
    },
    {
      key: "2",
      label: "Release Build",
      children: <Release data={versionData} />,
    },
  ];

  if (userProgramsError) {
    message.error(
      renderAntDMessageConfig(
        "Something went wrong. Please contact your system administrator."
      )
    );
  }

  if (userProgramsLoading) {
    return <Spinner />;
  }

  if (
    userPrograms &&
    !userPrograms.wf_adminFirst_afUserProgram?.edges?.find(
      (item) => item.node.afProgram?.programName === "Sales Release Version"
    )
  ) {
    return <NoPermission />;
  }

  return (
    <div id="sales188ReleaseVersionWrapper">
      <ContentTitle title="Release Version" />
      <Tabs
        defaultActiveKey="1"
        items={items}
        size="small"
        tabBarStyle={{
          paddingLeft: 24,
          backgroundColor: Colors.white,
          height: 50,
        }}
      />
    </div>
  );
};
export default Sales188ReleaseVersion;
