import dayjs from "dayjs";

import { GetTrialModeNotificationsQuery } from "../../../../graphql/operations/trialMode/pushNotification/get-trial-mode-notifications";
import { MarketType, SegmentationType } from "../../types";
import {
  JobStatusType,
  NotificationActionButtonType,
  TrialModeNotificationDataType,
  TrialModeServiceType,
} from "../types";
import sortSegmentations from "./sortSegmentations";

export default function normalizePushNotificationData(
  data: GetTrialModeNotificationsQuery
): TrialModeNotificationDataType[] {
  const inputData = data.wf_trialModeLeads_notification?.nodes || [];

  const outputData = inputData.map((item) => {
    const {
      id,
      actionButton,
      buttonLinksTo,
      buttonText,
      imageFileName,
      imageUrl,
      jobStatus,
      market,
      notificationToSegmentations,
      notificationTypeId,
      notificationType,
      scheduleTime,
      templateMessage,
      title,
      createdTime,
    } = item;
    const segmentations = sortSegmentations(
      notificationToSegmentations?.map((obj) => {
        return obj?.segmentation as SegmentationType;
      }) || []
    );

    return {
      key: id,
      id,
      market: market as MarketType,
      notificationTypeId: notificationTypeId as TrialModeServiceType,
      notificationName: notificationType?.notificationName as string,
      title: title || "",
      templateMessage: templateMessage || "",
      imageFileName: imageFileName || "",
      imageUrl: imageUrl || "",
      actionButton: actionButton as NotificationActionButtonType,
      buttonLinksTo: buttonLinksTo || "",
      buttonText: buttonText || "",
      scheduleTime: scheduleTime
        ? dayjs(scheduleTime).format("YYYY-MM-DD HH:mm")
        : dayjs(createdTime).format("YYYY-MM-DD HH:mm"),
      jobStatus: jobStatus as JobStatusType,
      segmentations,
    };
  });

  return outputData.filter(({ title }) => !title.startsWith("[TEST]"));
}
