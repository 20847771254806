import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateTrialModeNotificationMutationVariables = Types.Exact<{
  notification: Types.TrialModeNotificationInput;
  segmentations?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  members?: Types.InputMaybe<Array<Types.TrialModeMemberInput> | Types.TrialModeMemberInput>;
}>;


export type CreateTrialModeNotificationMutation = { __typename?: 'Mutation', addTrialModeNotification: { __typename?: 'Payload', count: number } };


export const CreateTrialModeNotificationDocument = gql`
    mutation CreateTrialModeNotification($notification: TrialModeNotificationInput!, $segmentations: [String!], $members: [TrialModeMemberInput!]) {
  addTrialModeNotification(
    notification: $notification
    segmentations: $segmentations
    members: $members
  ) {
    count
  }
}
    `;
export type CreateTrialModeNotificationMutationFn = Apollo.MutationFunction<CreateTrialModeNotificationMutation, CreateTrialModeNotificationMutationVariables>;

/**
 * __useCreateTrialModeNotificationMutation__
 *
 * To run a mutation, you first call `useCreateTrialModeNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTrialModeNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTrialModeNotificationMutation, { data, loading, error }] = useCreateTrialModeNotificationMutation({
 *   variables: {
 *      notification: // value for 'notification'
 *      segmentations: // value for 'segmentations'
 *      members: // value for 'members'
 *   },
 * });
 */
export function useCreateTrialModeNotificationMutation(baseOptions?: Apollo.MutationHookOptions<CreateTrialModeNotificationMutation, CreateTrialModeNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTrialModeNotificationMutation, CreateTrialModeNotificationMutationVariables>(CreateTrialModeNotificationDocument, options);
      }
export type CreateTrialModeNotificationMutationHookResult = ReturnType<typeof useCreateTrialModeNotificationMutation>;
export type CreateTrialModeNotificationMutationResult = Apollo.MutationResult<CreateTrialModeNotificationMutation>;
export type CreateTrialModeNotificationMutationOptions = Apollo.BaseMutationOptions<CreateTrialModeNotificationMutation, CreateTrialModeNotificationMutationVariables>;