import { Button, DatePicker, Form, Modal } from "antd";
import InputCustom from "../../../components/custom/InputCustom";
import "./release.scss";
import dayjs from "dayjs";
import { useState } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { apiCall } from "../../../utils/apiCall";
import { getPlaceholder } from "./utils/getPlaceholder";
import requiredRule from "../../../utils/formRules/requiredRule";
interface ReleaseProps {
  data: any;
}

const Release = ({ data }: ReleaseProps) => {
  const placeholderObject = getPlaceholder(data?.release);
  const [form] = Form.useForm();
  const confirm = () => {
    Modal.confirm({
      title: `Are you sure you want to update the release version ${form.getFieldValue(
        "number"
      )}?`,
      icon: <ExclamationCircleOutlined id="exclamation-icon" />,
      okText: "Update",
      cancelText: "No",
      onOk() {
        const template = [...data.release];
        const selectedDate = form.getFieldValue("date");
        const formattedDate = dayjs(selectedDate).format("YYYY-MM-DD");
        const payloadObject = template.map((info) => {
          return {
            ...info,
            appVersion: form.getFieldValue("number"),
            releaseTime: formattedDate,
          };
        });
        const header = {
          headers: {
            accept: "text/plain",
            "Content-Type": "application/json",
            "User-Code": "P725",
          },
        };
        const payload = {
          appVersions: payloadObject,
        };

        apiCall({
          method: "PATCH",
          payload: payload,
          header: header,
          endPoint:
            process.env.REACT_APP_RELEASE_VERSION_RELEASE_UPDATE_ENDPOINT,
        })
          .then(() => {
            window.location.reload();
          })
          .catch((e) => {
            console.log(e);
          });
      },
    });
  };
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const onFinish = () => {
    confirm();
  };
  return (
    <div id="releaseWrapper">
      <div className="release-content-container">
        <div className="wrapper">
          <Form
            form={form}
            onFinish={onFinish}
            name="release-version"
            onValuesChange={(one, all) => {
              if (!all.date) {
                form.setFieldsValue({
                  date: dayjs(),
                });
              }
              if (
                Object.values(all).some(
                  (value) => value === "" || value === null
                )
              ) {
                setSubmitEnabled(false);
              } else {
                setSubmitEnabled(true);
              }
            }}
            labelCol={{
              span: 6,
            }}
            wrapperCol={{
              span: 12,
            }}
          >
            <Form.Item
              label="Release version number"
              name="number"
              rules={[requiredRule]}
            >
              <InputCustom placeholder={placeholderObject.number} />
            </Form.Item>
            <Form.Item label="Release date" name="date" rules={[requiredRule]}>
              <DatePicker defaultValue={dayjs()} />
            </Form.Item>
            <Form.Item label="App Store link" name="iOS">
              <p className="text-blue">{placeholderObject.iOS}</p>
            </Form.Item>
            <Form.Item label="Google Play link" name="Android">
              <p className="text-blue">{placeholderObject.Android}</p>
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={!submitEnabled}
              className="submit-button"
            >
              Update
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Release;
