import { Input } from "antd";

export default function InputCustom(props: any) {
  return (
    <Input
      {...props}
      onKeyDown={(e) => {
        if (e.key === "Enter") e.preventDefault();
      }}
    />
  );
}
