import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteGraduationApplicationMutationVariables = Types.Exact<{
  applicationId: Types.Scalars['Int']['input'];
}>;


export type DeleteGraduationApplicationMutation = { __typename?: 'Mutation', removeGraduationApplication: { __typename?: 'Payload', count: number } };


export const DeleteGraduationApplicationDocument = gql`
    mutation deleteGraduationApplication($applicationId: Int!) {
  removeGraduationApplication(applicationId: $applicationId) {
    count
  }
}
    `;
export type DeleteGraduationApplicationMutationFn = Apollo.MutationFunction<DeleteGraduationApplicationMutation, DeleteGraduationApplicationMutationVariables>;

/**
 * __useDeleteGraduationApplicationMutation__
 *
 * To run a mutation, you first call `useDeleteGraduationApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGraduationApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGraduationApplicationMutation, { data, loading, error }] = useDeleteGraduationApplicationMutation({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useDeleteGraduationApplicationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGraduationApplicationMutation, DeleteGraduationApplicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGraduationApplicationMutation, DeleteGraduationApplicationMutationVariables>(DeleteGraduationApplicationDocument, options);
      }
export type DeleteGraduationApplicationMutationHookResult = ReturnType<typeof useDeleteGraduationApplicationMutation>;
export type DeleteGraduationApplicationMutationResult = Apollo.MutationResult<DeleteGraduationApplicationMutation>;
export type DeleteGraduationApplicationMutationOptions = Apollo.BaseMutationOptions<DeleteGraduationApplicationMutation, DeleteGraduationApplicationMutationVariables>;