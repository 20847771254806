import * as Types from './@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetFaceCallThemesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetFaceCallThemesQuery = { __typename?: 'Query', club_worldFamilyClub_faceCallSeasonHoliday?: { __typename?: 'Club_WorldFamilyClub_FaceCallSeasonHolidayConnection', edges?: Array<{ __typename?: 'Club_WorldFamilyClub_FaceCallSeasonHolidayEdge', node: { __typename?: 'Club_WorldFamilyClub_FaceCallSeasonHoliday', id: string, name?: string | null, dateFrom?: any | null, dateTo?: any | null, iconFileName?: string | null } }> | null } | null };


export const GetFaceCallThemesDocument = gql`
    query GetFaceCallThemes {
  club_worldFamilyClub_faceCallSeasonHoliday {
    edges {
      node {
        id
        name
        dateFrom
        dateTo
        iconFileName
      }
    }
  }
}
    `;

/**
 * __useGetFaceCallThemesQuery__
 *
 * To run a query within a React component, call `useGetFaceCallThemesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFaceCallThemesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFaceCallThemesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFaceCallThemesQuery(baseOptions?: Apollo.QueryHookOptions<GetFaceCallThemesQuery, GetFaceCallThemesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFaceCallThemesQuery, GetFaceCallThemesQueryVariables>(GetFaceCallThemesDocument, options);
      }
export function useGetFaceCallThemesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFaceCallThemesQuery, GetFaceCallThemesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFaceCallThemesQuery, GetFaceCallThemesQueryVariables>(GetFaceCallThemesDocument, options);
        }
export type GetFaceCallThemesQueryHookResult = ReturnType<typeof useGetFaceCallThemesQuery>;
export type GetFaceCallThemesLazyQueryHookResult = ReturnType<typeof useGetFaceCallThemesLazyQuery>;
export type GetFaceCallThemesQueryResult = Apollo.QueryResult<GetFaceCallThemesQuery, GetFaceCallThemesQueryVariables>;