import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteUploadVideoMutationVariables = Types.Exact<{
  productVideoId: Types.Scalars['Int']['input'];
}>;


export type DeleteUploadVideoMutation = { __typename?: 'Mutation', wf_trialModeLeads: { __typename?: 'WF_TrialModeLeads_Mutation_Payload', count: number } };


export const DeleteUploadVideoDocument = gql`
    mutation DeleteUploadVideo($productVideoId: Int!) {
  wf_trialModeLeads(remove: {productVideo: {productVideoId: $productVideoId}}) {
    count
  }
}
    `;
export type DeleteUploadVideoMutationFn = Apollo.MutationFunction<DeleteUploadVideoMutation, DeleteUploadVideoMutationVariables>;

/**
 * __useDeleteUploadVideoMutation__
 *
 * To run a mutation, you first call `useDeleteUploadVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUploadVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUploadVideoMutation, { data, loading, error }] = useDeleteUploadVideoMutation({
 *   variables: {
 *      productVideoId: // value for 'productVideoId'
 *   },
 * });
 */
export function useDeleteUploadVideoMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUploadVideoMutation, DeleteUploadVideoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUploadVideoMutation, DeleteUploadVideoMutationVariables>(DeleteUploadVideoDocument, options);
      }
export type DeleteUploadVideoMutationHookResult = ReturnType<typeof useDeleteUploadVideoMutation>;
export type DeleteUploadVideoMutationResult = Apollo.MutationResult<DeleteUploadVideoMutation>;
export type DeleteUploadVideoMutationOptions = Apollo.BaseMutationOptions<DeleteUploadVideoMutation, DeleteUploadVideoMutationVariables>;