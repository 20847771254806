import { useEffect, useState } from "react";

import { __currentUser__ } from "../../graphql/policies";
import {
  GetUserProgramsByServiceQuery,
  useGetUserProgramsByServiceQuery,
} from "../../graphql/operations/get-user-program-by-service";
import { useGetSegmentationQuery } from "../../graphql/operations/trialMode/get-segmentation";
import { renderDecodedId } from "../../utils/renderDecodedId";
import Spinner from "../../components/layout/spinner/Spinner";
import NoPermission from "../../components/others/NoPermission";
import { ServiceTypes } from "../../types/types";
import convertToSegMapping from "./utils/convertToSegMapping";
import errorMessage from "./utils/errorMessage";
import { trialSegmentation } from "./constants";
import { userProgramsData } from "./globalState";

interface WithUserProgramsProps {
  setSelectedService: React.Dispatch<React.SetStateAction<ServiceTypes>>;
  setSelectSubItem: React.Dispatch<React.SetStateAction<string | undefined>>;
}

function hasPermission(
  data: GetUserProgramsByServiceQuery,
  serviceName: string
) {
  return data?.wf_adminFirst_afUserProgram?.edges?.find(
    (item) => item.node.afProgram?.programName === serviceName
  );
}

export default function withUserPrograms<P extends WithUserProgramsProps>(
  WrappedComponent: React.ComponentType<P>,
  serviceName: string
) {
  return (props: P) => {
    const { setSelectedService, setSelectSubItem } = props;
    const { id } = __currentUser__() || {};
    const userProgramsStoredData = userProgramsData();

    const [isSegMappingReady, setIsSegMappingReady] = useState<boolean>(false);

    useEffect(() => {
      setSelectedService(ServiceTypes.TRIAL_MODE);
      setSelectSubItem(serviceName);
    }, [setSelectedService, setSelectSubItem]);

    if (userProgramsStoredData)
      return hasPermission(userProgramsStoredData, serviceName) ? (
        <WrappedComponent {...props} />
      ) : (
        <NoPermission />
      );

    const {
      data: queriedData,
      loading,
      error,
    } = useGetUserProgramsByServiceQuery({
      variables: {
        userId: parseInt(renderDecodedId(id)),
        serviceId: 8,
      },
    });

    const {
      data: segData,
      loading: loadingOfGetSeg,
      error: errorOfGetSeg,
    } = useGetSegmentationQuery();

    useEffect(() => {
      if (segData) {
        if (!trialSegmentation())
          trialSegmentation(convertToSegMapping(segData));

        setIsSegMappingReady(true);
      }
    }, [segData]);

    if (error || errorOfGetSeg) errorMessage();
    if (loading || loadingOfGetSeg || !isSegMappingReady) return <Spinner />;

    userProgramsData(queriedData);

    return queriedData && hasPermission(queriedData, serviceName) ? (
      <WrappedComponent {...props} />
    ) : (
      <NoPermission />
    );
  };
}
