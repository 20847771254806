import * as Types from './@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreatePerformerMutationVariables = Types.Exact<{
  name: Types.Scalars['String']['input'];
  market: Types.Scalars['String']['input'];
  avatarFileName: Types.Scalars['String']['input'];
  isZippy: Types.Scalars['Boolean']['input'];
}>;


export type CreatePerformerMutation = { __typename?: 'Mutation', club_worldFamilyClub: { __typename?: 'Club_WorldFamilyClub_Mutation_Payload', count: number } };


export const CreatePerformerDocument = gql`
    mutation CreatePerformer($name: String!, $market: String!, $avatarFileName: String!, $isZippy: Boolean!) {
  club_worldFamilyClub(
    add: {faceCallPerformer: {performerName: $name, market: $market, avatarFileName: $avatarFileName, zippyFlag: $isZippy}}
  ) {
    count
  }
}
    `;
export type CreatePerformerMutationFn = Apollo.MutationFunction<CreatePerformerMutation, CreatePerformerMutationVariables>;

/**
 * __useCreatePerformerMutation__
 *
 * To run a mutation, you first call `useCreatePerformerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePerformerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPerformerMutation, { data, loading, error }] = useCreatePerformerMutation({
 *   variables: {
 *      name: // value for 'name'
 *      market: // value for 'market'
 *      avatarFileName: // value for 'avatarFileName'
 *      isZippy: // value for 'isZippy'
 *   },
 * });
 */
export function useCreatePerformerMutation(baseOptions?: Apollo.MutationHookOptions<CreatePerformerMutation, CreatePerformerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePerformerMutation, CreatePerformerMutationVariables>(CreatePerformerDocument, options);
      }
export type CreatePerformerMutationHookResult = ReturnType<typeof useCreatePerformerMutation>;
export type CreatePerformerMutationResult = Apollo.MutationResult<CreatePerformerMutation>;
export type CreatePerformerMutationOptions = Apollo.BaseMutationOptions<CreatePerformerMutation, CreatePerformerMutationVariables>;