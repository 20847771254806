import { Form, Button } from "antd";

import Carousel from "./Carousel";
import { Content, DeleteButton, Title } from "./FormItems";

export default function FormContents({
  index,
  setIndex,
}: {
  index: number;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
}) {
  return (
    <Form.Item label={null} className="ml-[160px]">
      <Form.List name="contents">
        {(subFields, { add, remove }) => (
          <div>
            <Carousel index={index} setIndex={setIndex}>
              {subFields.map((subField) => (
                <div
                  key={subField.key}
                  className="w-[462px] bg-grey1 p-4 flex-none"
                >
                  <Title subField={subField} />
                  <Content subField={subField} />

                  {subFields.length > 1 ? (
                    <DeleteButton
                      subField={subField}
                      remove={remove}
                      index={index}
                      setIndex={setIndex}
                    />
                  ) : null}
                </div>
              ))}
            </Carousel>

            <Button
              type="primary"
              onClick={() => {
                add();
                setIndex(subFields.length);
              }}
            >
              + Add more content displayed randomly
            </Button>
          </div>
        )}
      </Form.List>
    </Form.Item>
  );
}
