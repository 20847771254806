import getInitialMarket from "../../../../utils/getInitialMarket";
import { SegmentationType } from "../../types";
import { ExperienceType } from "../types";
import { CTADefaultData, CTADefaultContentData } from "../constants";

export default function getDefaultData(
  segmentation: SegmentationType,
  experienceName: ExperienceType
) {
  const { template, buttonLinksTo } =
    CTADefaultData[segmentation][experienceName];

  return {
    market: getInitialMarket(),
    segmentation,
    experienceName,
    template,
    imageCharacter: template === "Image" ? "FroggyBunny" : "",
    photoFileName: "",
    videoUrl: "",
    contents: [{ ...CTADefaultContentData[template] }],
    buttonLinksTo,
    buttonText:
      buttonLinksTo === "Lead page"
        ? "Fill lead form for more"
        : "Get a demo for more",
  };
}
