import dayjs from "dayjs";

import { FutureTime } from "../constants";

export default function isInUse(startDate?: string, endDate?: string) {
  const nowMs = dayjs().valueOf();
  const startMs =
    startDate && startDate !== FutureTime
      ? dayjs(startDate).valueOf()
      : Infinity;
  const endMs =
    endDate && endDate !== FutureTime ? dayjs(endDate).valueOf() : Infinity;

  return nowMs >= startMs && nowMs <= endMs;
}
