import { Dropdown, Space } from "antd";
import type { MenuProps } from "antd";
import { DownOutlined } from "@ant-design/icons";

import { __currentUser__ } from "../../graphql/policies";
import { MarketType } from "../../types/types";

const allMarkets: MarketType[] = ["HK", "KR", "TW"];

const getItems = (markets: MarketType[]) => {
  return markets.map((market) => ({
    key: market,
    label: <p className="px-2 py-1">{market}</p>,
  }));
};

export default function MarketDropdown({
  selectedMarket,
  setSelectedMarket,
  disabled = false,
  alwaysShow = false,
}: {
  selectedMarket: MarketType;
  setSelectedMarket: (value: React.SetStateAction<MarketType>) => void;
  disabled?: boolean;
  alwaysShow?: boolean;
}) {
  const { afUserMarkets } = __currentUser__() || {};
  let items: MenuProps["items"] = alwaysShow
    ? getItems(allMarkets)
    : afUserMarkets
    ? getItems(afUserMarkets.map((item) => item?.market as MarketType))
    : undefined;

  const handleDropdownItemClick = (e: any) => {
    setSelectedMarket(e.key);
  };

  return alwaysShow || (items && items.length > 1) ? (
    <Dropdown
      menu={{ onClick: handleDropdownItemClick, items }}
      className={disabled ? "cursor-not-allowed" : "cursor-pointer"}
      disabled={disabled}
    >
      <Space>
        {`Market: ${selectedMarket}`}
        <DownOutlined />
      </Space>
    </Dropdown>
  ) : null;
}
