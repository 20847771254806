import { ServiceCategoryTypes } from "../../../../types/types";
import { MenuItemsType } from "../types";

export default function getKeyByRouteName(
  items: MenuItemsType[],
  routeValue: string
): string {
  if (!routeValue) return ServiceCategoryTypes.DASHBOARD;

  let key = "";

  items.find(({ category, categoryRoute, subItems }) => {
    if (subItems?.length === 0 && categoryRoute === routeValue) {
      key = category;
      return true;
    }

    return subItems?.find(({ subItemName, routeName }) => {
      if (routeName === routeValue) {
        key = subItemName;
        return true;
      }

      return false;
    });
  });

  return key;
}
