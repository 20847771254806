import { getEmbedUrl } from "../../pages/trialMode/utils/getVideoUrl";

export default function videoUrlRule() {
  return {
    validator(rule: any, value: string) {
      return getEmbedUrl(value)
        ? Promise.resolve()
        : Promise.reject("Only allow videos from Youtube and Vimeo");
    },
  };
}
