/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";
import { Colors } from "../../../constants/Colors";
import { useReactiveVar } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { message, Table, Button } from "antd";
import { ServiceTypes } from "../../../types/types";
import { __currentUser__ } from "../../../graphql/policies";
import { useGetUserProgramsByServiceQuery } from "../../../graphql/operations/get-user-program-by-service";
import { renderDecodedId } from "../../../utils/renderDecodedId";
import { renderAntDMessageConfig } from "../../../utils/renderAntDMessageConfig";
import { SwapRightOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import Spinner from "../../../components/layout/spinner/Spinner";
import NoPermission from "../../../components/others/NoPermission";
import ContentTitle from "../../../components/ui/ContentTitle";
import RegionTag from "../../../components/tags/RegionTag";
import "./ImportantNotice.scss";
import { fetchImportantNoticeData } from "./utils/fetchImportantNoticeData";
import { DataType } from "./utils/dataType";

interface ImportantNoticeProps {
  setSelectedService: Dispatch<SetStateAction<ServiceTypes>>;
  setSelectSubItem: Dispatch<SetStateAction<string | undefined>>;
}

const ImportantNotice = ({
  setSelectedService,
  setSelectSubItem,
}: ImportantNoticeProps) => {
  const [displayData, setDisplayData] = useState<DataType[] | any>([]);
  const marketList = ["hk", "kr", "tw"];
  const currentUser = useReactiveVar(__currentUser__);
  const {
    data: userPrograms,
    loading: userProgramsLoading,
    error: userProgramsError,
  } = useGetUserProgramsByServiceQuery({
    variables: {
      userId: parseInt(renderDecodedId(currentUser?.id)),
      serviceId: 2,
    },
  });
  const navigate = useNavigate();

  useEffect(() => {
    setSelectedService(ServiceTypes.CLUB_APP);
    setSelectSubItem("Important Notice");
    fetchImportantNoticeData()
      .then((data) => {
        setDisplayData(
          data.filter((item: DataType) => marketList.includes(item.region))
        );
      })
      .catch((e: any) => {
        console.log("error", e);
      });
  }, []);

  const columns: ColumnsType<DataType> = [
    {
      title: "Market",
      dataIndex: "region",
      key: "region",
      width: 60,
      render: (v) => {
        return <RegionTag region={v.toUpperCase()} />;
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 250,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 280,
      render: (text) => {
        return <div className="description">{text}</div>;
      },
    },
    {
      title: "Date and Time Range",
      width: 310,
      render: (_, record: DataType) => {
        const start = dayjs(record.startTime).format("YYYY-MM-DD HH:mm");
        const end = dayjs(record.endTime).format("YYYY-MM-DD HH:mm");
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <p>{start}</p>
            <SwapRightOutlined style={{ margin: "0 12px" }} />
            <p>{end}</p>
          </div>
        );
      },
    },
    {
      title: "Status",
      width: 115,
      render: (_, record: DataType) => {
        return dayjs().valueOf() >= dayjs(record.startTime).valueOf() &&
          dayjs().valueOf() <= dayjs(record.endTime).valueOf() ? (
          <div className="in-use-container">
            <div
              className="dot"
              style={{
                backgroundColor: "#394CF6",
              }}
            ></div>
            <p>In use</p>
          </div>
        ) : (
          <div className="in-use-container">
            <div
              className="dot"
              style={{
                backgroundColor: "#D9D9D9",
              }}
            ></div>
            <p>Not in use</p>
          </div>
        );
      },
    },
    {
      title: "Action",
      render: (_, record: DataType) => (
        <>
          <Button
            type="link"
            onClick={() => {
              navigate(`/club-app/important-notice-detail/${record.region}`);
            }}
            style={{ padding: 0, marginRight: 16 }}
          >
            Edit
          </Button>
        </>
      ),
    },
  ];

  if (userProgramsError) {
    message.error(
      renderAntDMessageConfig(
        "Something went wrong. Please contact your system administrator."
      )
    );
  }

  if (userProgramsLoading) {
    return <Spinner />;
  }

  if (
    userPrograms &&
    !userPrograms.wf_adminFirst_afUserProgram?.edges?.find(
      (item) => item.node.afProgram?.programName === "Important Notice"
    )
  ) {
    return <NoPermission />;
  }

  return (
    <div id="importantNoticeWrapper">
      <ContentTitle title="Important Notice" />
      <div className="content-container">
        <div
          className="theme-tab-table-container"
          style={{ backgroundColor: Colors.white }}
        >
          <Table
            rowKey={(record, index) => (index !== undefined ? index : 0)}
            columns={columns}
            dataSource={displayData}
            pagination={false}
          />
        </div>
      </div>
    </div>
  );
};

export default ImportantNotice;
