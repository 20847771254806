export const renderBannerCategoryByType = (type: string | undefined) => {
  switch (type) {
    case "ABC Club":
    case "Family Fun":
    case "Interactive Storytelling":
    case "Junior Club":
    case "Point and Play":
    case "寶貝玩玩":
    case "來趣玩活動":
    case "翻轉小世界":
      return "MP";
    case "Birthday Sing Along Show":
    case "CAP Event":
    case "Dance Along Show":
    case "Play Along Show":
    case "The Froggy and Bunny Show":
    case "跟著玩活動":
    case "Froggy和Bunny點點名":
    case "布偶劇場":
    case "Zippy人偶秀":
    case "Club Show":
      return "WA";
    case "Camp":
    case "夏令營":
      return "Camp";
    case "實體家長/外傭工作坊":
    case "實體家長講座":
    case "線上家長/外傭工作坊":
    case "線上家長講座":
    case "家庭成場學苑":
      return "WK";
    case "English Carnival":
    case "WFC Winter Fair":
    case "美語嘉年華":
      return "EC";
    case "Graduation Ceremony":
    case "畢業典禮":
    case "Graduation Party":
    case "Graduation":
      return "GC";
    default:
      break;
  }
};
