import axios from "axios"

interface payloadType {
    method: string,
    header?: object,
    endPoint: any,
    payload?: object,
}
export const apiCall = async (payloads: payloadType)=> {
    const {method, header, endPoint, payload} = payloads
    if (method === 'GET') {
        if (header) {
            try {
                const { data } = await axios.get(endPoint, header)
                return data
            } catch (error) {
                console.log(error);
            }
        } else {
            try {
                const { data } = await axios.get(endPoint)
                return data
            } catch (error) {
                console.log(error);
            }
        }
    } else if (method === 'POST') {
        const postPayload = payload ? payload : {}
        if (header) {
            try {
                const { data } = await axios.post(endPoint, postPayload ,header)
                return data
            } catch (error) {
                console.log(error);
            }
        } else {
            try {
                const { data } = await axios.post(endPoint, postPayload)
                return data
            } catch (error) {
                console.log(error);
            }
        }
    } else if (method === 'PATCH') {
        const postPayload = payload ? payload : {}
        try {
            const { data } = await axios.patch(endPoint, postPayload, header)
            return data
        } catch (error) {
            console.log(error);
        }
    } else if (method === 'DELETE') {
        try {
            const { data } = await axios.delete(endPoint, header)
            return data
        } catch (error) {
            console.log(error);
        }
    }
}