import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetTrialModeNotificationsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetTrialModeNotificationsQuery = { __typename?: 'Query', wf_trialModeLeads_notification?: { __typename?: 'WF_TrialModeLeads_NotificationConnection', nodes?: Array<{ __typename?: 'WF_TrialModeLeads_Notification', id: string, actionButton?: string | null, buttonLinksTo?: string | null, buttonText?: string | null, imageFileName?: string | null, jobStatus?: string | null, market?: string | null, scheduleTime?: any | null, templateMessage?: string | null, title?: string | null, createdTime?: any | null, notificationTypeId?: string | null, imageUrl?: string | null, notificationToSegmentations?: Array<{ __typename?: 'WF_TrialModeLeads_NotificationToSegmentation', segmentation?: string | null } | null> | null, notificationType?: { __typename?: 'WF_TrialModeLeads_NotificationType', notificationName?: string | null } | null }> | null } | null };


export const GetTrialModeNotificationsDocument = gql`
    query GetTrialModeNotifications {
  wf_trialModeLeads_notification(first: 100, order: {createdTime: DESC}) {
    nodes {
      id
      actionButton
      buttonLinksTo
      buttonText
      imageFileName
      imageUrl: imageFileName @wf_blobSignature(name: "TRIAL_MODE_NOTIFICATION_IMAGES_SIGNATURE")
      jobStatus
      market
      scheduleTime
      templateMessage
      title
      createdTime
      notificationToSegmentations {
        segmentation
      }
      notificationTypeId
      notificationType {
        notificationName
      }
    }
  }
}
    `;

/**
 * __useGetTrialModeNotificationsQuery__
 *
 * To run a query within a React component, call `useGetTrialModeNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrialModeNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrialModeNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTrialModeNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<GetTrialModeNotificationsQuery, GetTrialModeNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTrialModeNotificationsQuery, GetTrialModeNotificationsQueryVariables>(GetTrialModeNotificationsDocument, options);
      }
export function useGetTrialModeNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTrialModeNotificationsQuery, GetTrialModeNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTrialModeNotificationsQuery, GetTrialModeNotificationsQueryVariables>(GetTrialModeNotificationsDocument, options);
        }
export type GetTrialModeNotificationsQueryHookResult = ReturnType<typeof useGetTrialModeNotificationsQuery>;
export type GetTrialModeNotificationsLazyQueryHookResult = ReturnType<typeof useGetTrialModeNotificationsLazyQuery>;
export type GetTrialModeNotificationsQueryResult = Apollo.QueryResult<GetTrialModeNotificationsQuery, GetTrialModeNotificationsQueryVariables>;