import { useEffect, useState } from "react";
import { Table } from "antd";

import { useGetTrialModeNotificationsQuery } from "../../../graphql/operations/trialMode/pushNotification/get-trial-mode-notifications";

import ContentTitle from "../../../components/ui/ContentTitle";
import Spinner from "../../../components/layout/spinner/Spinner";
import getInitialMarket from "../../../utils/getInitialMarket";
import errorMessage from "../utils/errorMessage";
import withUserPrograms from "../withUserPrograms";
import { MarketType } from "../types";
import TableMenu from "./components/TableMenu";
import ModifyForm from "./modifyForm/ModifyForm";
import View from "./modifyForm/View";
import normalizePushNotificationData from "./utils/normalizePushNotificationData";
import { createColumns } from "./columns";
import {
  TrialModeNotificationDataType,
  TrialModeNotificationModifyData,
} from "./types";

function PushNotification() {
  const initialMarket = getInitialMarket();

  const [selectedMarket, setSelectedMarket] =
    useState<MarketType>(initialMarket);
  const [dataSource, setDataSource] = useState<TrialModeNotificationDataType[]>(
    []
  );
  const [marketData, setMarketData] = useState<TrialModeNotificationDataType[]>(
    []
  );
  const [modifyingData, setModifyingData] =
    useState<TrialModeNotificationModifyData | null>(null);
  const [viewData, setViewData] =
    useState<TrialModeNotificationModifyData | null>(null);

  const {
    data,
    loading: loadingOfGet,
    error: errorOfGet,
    refetch,
  } = useGetTrialModeNotificationsQuery({
    fetchPolicy: "no-cache",
    pollInterval: 1000 * 10,
  });

  useEffect(() => {
    if (data) setDataSource(normalizePushNotificationData(data));
  }, [data]);

  useEffect(() => {
    setMarketData(dataSource.filter(({ market }) => market === selectedMarket));
  }, [dataSource, selectedMarket]);

  if (errorOfGet) errorMessage();
  if (loadingOfGet || marketData.length === 0) return <Spinner />;

  const isModifyPage = modifyingData || viewData;
  const wrapperStyle = isModifyPage ? "pb-[72px]" : "pb-[0.5px]";

  return (
    <div className={wrapperStyle}>
      <ContentTitle
        title="Push Notification"
        returnTitle="Notification detail"
        onReturn={
          isModifyPage
            ? () => {
                if (modifyingData) setModifyingData(null);
                if (viewData) setViewData(null);
              }
            : undefined
        }
      />

      <div className="bg-white mx-5 my-6 px-6">
        {modifyingData ? (
          <ModifyForm
            data={modifyingData}
            setModifyingData={setModifyingData}
            refetch={refetch}
          />
        ) : null}

        {viewData ? (
          <View data={viewData} setViewData={setViewData} refetch={refetch} />
        ) : null}

        {!isModifyPage ? (
          <>
            <TableMenu
              selectedMarket={selectedMarket}
              setSelectedMarket={setSelectedMarket}
              setModifyingData={setModifyingData}
            />

            <Table
              className="custom-table-style"
              dataSource={marketData}
              columns={createColumns(setViewData, refetch)}
              pagination={{ hideOnSinglePage: true, pageSize: 10 }}
            />
          </>
        ) : null}
      </div>
    </div>
  );
}

export default withUserPrograms(PushNotification, "Push Notification");
