import { useCallback, useEffect, useState } from "react";
import { Layout, message } from "antd";
import { useMsal } from "@azure/msal-react";
import { useReactiveVar } from "@apollo/client";
import Header from "./components/layout/header/Header";
import { ServiceTypes, ThemeTypes } from "./types/types";
import ServiceMenu from "./components/layout/menu/ServiceMenu";
import ServiceSubMenu from "./components/layout/menu/ServiceSubMenu";
import { Colors } from "./constants/Colors";
import AllRoutes from "./routes/AllRoutes";
import LogIn from "./pages/login/LogIn";
import { loginRequest } from "./auth/authConfig";
import Spinner from "./components/layout/spinner/Spinner";
import { __currentUser__ } from "./graphql/policies";
import { useGetSingleUserLazyQuery } from "./graphql/operations/get-single-user";
import { renderAntDMessageConfig } from "./utils/renderAntDMessageConfig";

const App = () => {
  const [theme, setTheme] = useState(ThemeTypes.LIGHT);
  const [selectedService, setSelectedService] = useState(ServiceTypes.CLUB_APP);
  const [selectSubItem, setSelectSubItem] = useState<undefined | string>();
  const currentUser = useReactiveVar(__currentUser__);
  const [getSingleUser] = useGetSingleUserLazyQuery();
  const [loginStatus, setLoginStatus] = useState<"no-user" | "login" | "block">(
    "block"
  );

  const requestUserInfo = useCallback(
    (email: string) => {
      getSingleUser({ variables: { email } })
        .then((r) => {
          if (r.data?.wf_adminFirst_afUser?.edges?.length === 0) {
            message.error(
              renderAntDMessageConfig(
                "You have no permission to access this site. Please contact your administrator."
              )
            );
            setLoginStatus("no-user");
          } else {
            if (r.data?.wf_adminFirst_afUser?.edges) {
              setLoginStatus("login");
              window.localStorage.setItem(
                "currentUser",
                JSON.stringify(r.data?.wf_adminFirst_afUser?.edges[0].node)
              );
              __currentUser__(r.data?.wf_adminFirst_afUser?.edges[0].node);
            }
          }
        })
        .catch((e) => console.log(e));
    },
    [getSingleUser]
  );

  // MSAL authentication
  const { instance, accounts, inProgress }: any = useMsal();
  const requestProfileData = useCallback(async () => {
    const storedUser = window.localStorage.getItem("currentUser");
    if (storedUser) {
      setLoginStatus("login");
      requestUserInfo(JSON.parse(storedUser).userEmail);
    } else {
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then((response: any) => {
          requestUserInfo(response.account.username);
        })
        .catch((err: any) => {
          setLoginStatus("block");
          message.error(
            renderAntDMessageConfig(
              "User authentication failed. Please re-login again."
            )
          );
          return <LogIn instance={instance} />;
        });
    }
  }, [instance, accounts, requestUserInfo]);

  useEffect(() => {
    if (accounts.length > 0) {
      requestProfileData();
    }
  }, [accounts, requestProfileData]);

  if (loginStatus === "login") {
    return currentUser ? (
      <Layout>
        <Header
          theme={theme}
          isLoggedIn
          currentUser={currentUser}
          setSelectedService={setSelectedService}
          setSelectSubItem={setSelectSubItem}
        />
        <Layout style={{ display: "flex", flexDirection: "row" }}>
          <ServiceMenu
            setTheme={setTheme}
            selectedService={selectedService}
            setSelectedService={setSelectedService}
          />
          <ServiceSubMenu selectService={selectedService} />
          <Layout
            style={{
              backgroundColor: Colors.lightWhite,
              height: "calc(100vh - 60px)",
              overflowY: "auto",
              width: "calc(100% - 360px)",
            }}
          >
            <Layout.Content>
              <AllRoutes
                setSelectedService={setSelectedService}
                setSelectSubItem={setSelectSubItem}
              />
            </Layout.Content>
          </Layout>
        </Layout>
      </Layout>
    ) : (
      <Spinner />
    );
  } else if (inProgress === "login") {
    return <Spinner />;
  } else {
    return <LogIn instance={instance} />;
  }
};

export default App;
