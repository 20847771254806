import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetEvaluationOptionsQueryVariables = Types.Exact<{
  market?: Types.InputMaybe<Types.Scalars['String']['input']>;
  taskId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetEvaluationOptionsQuery = { __typename?: 'Query', club_worldFamilyClub_graduationEvaluation?: { __typename?: 'Club_WorldFamilyClub_GraduationEvaluationConnection', totalCount: number, nodes?: Array<{ __typename?: 'Club_WorldFamilyClub_GraduationEvaluation', market?: string | null, taskId?: number | null, evaluation?: string | null, id: string }> | null } | null };


export const GetEvaluationOptionsDocument = gql`
    query getEvaluationOptions($market: String, $taskId: Int) {
  club_worldFamilyClub_graduationEvaluation(
    first: 100
    filter: {market: {eq: $market}, taskId: {eq: $taskId}}
  ) {
    totalCount
    nodes {
      market
      taskId
      evaluation
      id
    }
  }
}
    `;

/**
 * __useGetEvaluationOptionsQuery__
 *
 * To run a query within a React component, call `useGetEvaluationOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEvaluationOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEvaluationOptionsQuery({
 *   variables: {
 *      market: // value for 'market'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useGetEvaluationOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetEvaluationOptionsQuery, GetEvaluationOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEvaluationOptionsQuery, GetEvaluationOptionsQueryVariables>(GetEvaluationOptionsDocument, options);
      }
export function useGetEvaluationOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEvaluationOptionsQuery, GetEvaluationOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEvaluationOptionsQuery, GetEvaluationOptionsQueryVariables>(GetEvaluationOptionsDocument, options);
        }
export type GetEvaluationOptionsQueryHookResult = ReturnType<typeof useGetEvaluationOptionsQuery>;
export type GetEvaluationOptionsLazyQueryHookResult = ReturnType<typeof useGetEvaluationOptionsLazyQuery>;
export type GetEvaluationOptionsQueryResult = Apollo.QueryResult<GetEvaluationOptionsQuery, GetEvaluationOptionsQueryVariables>;