import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Table, Button, message } from "antd";
import { ServiceTypes } from "../../../types/types";
import ContentTitle from "../../../components/ui/ContentTitle";
import Spinner from "../../../components/layout/spinner/Spinner";
import getInitialMarket from "../../../utils/getInitialMarket";
import NoPermission from "../../../components/others/NoPermission";
import { renderAntDMessageConfig } from "../../../utils/renderAntDMessageConfig";
import { useNavigate, useLocation } from "react-router-dom";
// import errorMessage from "../../trialMode/utils/errorMessage";
import { MarketType } from "../../../types/types";
import "./PushNotification.scss";
import MarketDropdown from "../../../components/ui/MarketDropdown";
import { PlusOutlined } from "@ant-design/icons";
import { useReactiveVar } from "@apollo/client";
import { __currentUser__ } from "../../../graphql/policies";
import { DataType } from "./types";
import { useGetAllNotificationsQuery } from "../../../graphql/operations/sales188/getAllNotification";
import { useDeleteNotificationMutation } from "../../../graphql/operations/sales188/deleteNotification";
import { useGetUserProgramsByServiceQuery } from "../../../graphql/operations/get-user-program-by-service";
import { renderDecodedId } from "../../../utils/renderDecodedId";
import { createColumns } from "./columns";

interface SalesPushNotificationProps {
  setSelectedService: Dispatch<SetStateAction<ServiceTypes>>;
  setSelectSubItem: Dispatch<SetStateAction<string | undefined>>;
}

const SalesPushNotification = ({
  setSelectedService,
  setSelectSubItem,
}: SalesPushNotificationProps) => {
  // const initialMarket = getInitialMarket();
  const [displayData, setDisplayData] = useState<DataType[] | any>([]);
  // const [selectedMarket, setSelectedMarket] = useState<MarketType | any>(
  //   initialMarket
  // );
  const { data, loading, error, refetch } = useGetAllNotificationsQuery();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    refetch();
  }, [location]);
  const [deleteNotification] = useDeleteNotificationMutation();
  useEffect(() => {
    setSelectedService(ServiceTypes.SALES_188);
    setSelectSubItem("Push Notification");
  }, []);
  useEffect(() => {
    if (data) {
      setDisplayData(
        data?.sales188Notification.filter((record) => record.target)
      );
    }
  }, [data]);
  const onView = (id: Number) => {
    navigate(`/sales188/push-notification/${id}`);
  };
  const onDelete = (id: any) => {
    const deletePromise = deleteNotification({
      variables: {
        id: id,
      },
    });
    deletePromise
      .then(() => {
        refetch();
      })
      .catch((e) => {
        console.log("error", e);
      });
  };
  const currentUser = useReactiveVar(__currentUser__);
  const {
    data: userPrograms,
    loading: userProgramsLoading,
    error: userProgramsError,
  } = useGetUserProgramsByServiceQuery({
    variables: {
      userId: parseInt(renderDecodedId(currentUser?.id)),
      serviceId: 5,
    },
  });

  if (userProgramsError) {
    message.error(
      renderAntDMessageConfig(
        "Something went wrong. Please contact your system administrator."
      )
    );
  }

  if (userProgramsLoading || loading) {
    return <Spinner />;
  }

  if (
    userPrograms &&
    !userPrograms.wf_adminFirst_afUserProgram?.edges?.find(
      (item) => item.node.afProgram?.programName === "Sales Notification"
    )
  ) {
    return <NoPermission />;
  }

  return (
    <>
      <div id="sales188PushNotificationWrapper">
        <ContentTitle
          title="Push Notification"
          returnTitle="Notification detail"
        />
        <div className="wrapper">
          <div className="sales-notification-content-container">
            <div className="top-column">
              <div>
                {/* {currentUser?.afUserMarkets &&
                currentUser?.afUserMarkets.length > 1 ? (
                  <MarketDropdown
                    selectedMarket={selectedMarket}
                    setSelectedMarket={setSelectedMarket}
                  />
                ) : (
                  <div />
                )} */}

                <Button
                  type="primary"
                  style={{ marginLeft: 8 }}
                  icon={<PlusOutlined />}
                  onClick={() => {
                    navigate("/sales188/create-notification");
                  }}
                >
                  Create New
                </Button>
              </div>
            </div>
            <div className="mt-4">
              <Table
                className="table"
                dataSource={displayData}
                columns={createColumns(
                  setDisplayData,
                  refetch,
                  onView,
                  onDelete
                )}
                scroll={{
                  x: true,
                  y: 500,
                }}
              ></Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SalesPushNotification;
