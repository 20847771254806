import * as Types from './@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateFaceCallThemeMutationVariables = Types.Exact<{
  themeName: Types.Scalars['String']['input'];
  fileName: Types.Scalars['String']['input'];
  dateFrom: Types.Scalars['DateTime']['input'];
  dateTo: Types.Scalars['DateTime']['input'];
}>;


export type CreateFaceCallThemeMutation = { __typename?: 'Mutation', club_worldFamilyClub: { __typename?: 'Club_WorldFamilyClub_Mutation_Payload', count: number } };


export const CreateFaceCallThemeDocument = gql`
    mutation CreateFaceCallTheme($themeName: String!, $fileName: String!, $dateFrom: DateTime!, $dateTo: DateTime!) {
  club_worldFamilyClub(
    add: {faceCallSeasonHoliday: {name: $themeName, iconFileName: $fileName, dateFrom: $dateFrom, dateTo: $dateTo}}
  ) {
    count
  }
}
    `;
export type CreateFaceCallThemeMutationFn = Apollo.MutationFunction<CreateFaceCallThemeMutation, CreateFaceCallThemeMutationVariables>;

/**
 * __useCreateFaceCallThemeMutation__
 *
 * To run a mutation, you first call `useCreateFaceCallThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFaceCallThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFaceCallThemeMutation, { data, loading, error }] = useCreateFaceCallThemeMutation({
 *   variables: {
 *      themeName: // value for 'themeName'
 *      fileName: // value for 'fileName'
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *   },
 * });
 */
export function useCreateFaceCallThemeMutation(baseOptions?: Apollo.MutationHookOptions<CreateFaceCallThemeMutation, CreateFaceCallThemeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFaceCallThemeMutation, CreateFaceCallThemeMutationVariables>(CreateFaceCallThemeDocument, options);
      }
export type CreateFaceCallThemeMutationHookResult = ReturnType<typeof useCreateFaceCallThemeMutation>;
export type CreateFaceCallThemeMutationResult = Apollo.MutationResult<CreateFaceCallThemeMutation>;
export type CreateFaceCallThemeMutationOptions = Apollo.BaseMutationOptions<CreateFaceCallThemeMutation, CreateFaceCallThemeMutationVariables>;