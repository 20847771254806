import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateVideosSortIdMutationVariables = Types.Exact<{
  VideoData?: Types.InputMaybe<Array<Types.Wf_TrialModeLeads_ProductVideoInput> | Types.Wf_TrialModeLeads_ProductVideoInput>;
}>;


export type UpdateVideosSortIdMutation = { __typename?: 'Mutation', wf_trialModeLeads: { __typename?: 'WF_TrialModeLeads_Mutation_Payload', count: number } };


export const UpdateVideosSortIdDocument = gql`
    mutation UpdateVideosSortId($VideoData: [WF_TrialModeLeads_ProductVideoInput!]) {
  wf_trialModeLeads(updateRange: {productVideos: $VideoData}) {
    count
  }
}
    `;
export type UpdateVideosSortIdMutationFn = Apollo.MutationFunction<UpdateVideosSortIdMutation, UpdateVideosSortIdMutationVariables>;

/**
 * __useUpdateVideosSortIdMutation__
 *
 * To run a mutation, you first call `useUpdateVideosSortIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVideosSortIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVideosSortIdMutation, { data, loading, error }] = useUpdateVideosSortIdMutation({
 *   variables: {
 *      VideoData: // value for 'VideoData'
 *   },
 * });
 */
export function useUpdateVideosSortIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVideosSortIdMutation, UpdateVideosSortIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVideosSortIdMutation, UpdateVideosSortIdMutationVariables>(UpdateVideosSortIdDocument, options);
      }
export type UpdateVideosSortIdMutationHookResult = ReturnType<typeof useUpdateVideosSortIdMutation>;
export type UpdateVideosSortIdMutationResult = Apollo.MutationResult<UpdateVideosSortIdMutation>;
export type UpdateVideosSortIdMutationOptions = Apollo.BaseMutationOptions<UpdateVideosSortIdMutation, UpdateVideosSortIdMutationVariables>;