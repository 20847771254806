import { useEffect, useState } from "react";
import { Button, Drawer, Form, Table, Upload, message } from "antd";
import type { ColumnsType } from "antd/es/table";
import type { UploadProps } from "antd";
import { SwapRightOutlined, UploadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import "./TemplateTabContent.css";
import { Colors } from "../../../../constants/Colors";
import RegionTag from "../../../../components/tags/RegionTag";
import { AfBlobPath } from "../../../../graphql/operations/@types";
import { useUploadFileMutation } from "../../../../graphql/operations/upload-file";
import DrawerFooter from "../../../../components/layout/drawer/DrawerFooter";
import { useGetFaceCallThemesQuery } from "../../../../graphql/operations/get-facecall-themes";
import Spinner from "../../../../components/layout/spinner/Spinner";
import { renderAntDMessageConfig } from "../../../../utils/renderAntDMessageConfig";
import { useGetDefaultServiceBannerListQuery } from "../../../../graphql/operations/clubApp/design/get-default-service-banner-list";
import { useUpdateDefaultServiceBannerMutation } from "../../../../graphql/operations/clubApp/design/update-default-service-banner";

interface DataType {
  __typename: string;
  seq: number;
  service: string;
  image: string;
  imageUrl: string;
  market: string;
  publishDate: string;
  stopDate: string;
  theme: string;
}

const TemplatesTabContent = () => {
  const [showDrawer, setShowDrawer] = useState(false);
  const [selectTemplate, setSelectTemplate] = useState<DataType | undefined>();
  const [uploadedFileName, setUploadedFileName] = useState<string | undefined>(
    ""
  );
  const [displayData, setDisplayData] = useState<DataType[] | any>([]);
  const [form] = Form.useForm();
  const [uploadFile] = useUploadFileMutation();
  const [updateDefaultServiceBanner] = useUpdateDefaultServiceBannerMutation();
  const {
    data: themes,
    loading: themesLoading,
    error: themesError,
  } = useGetFaceCallThemesQuery();

  const { data, loading, error, refetch } =
    useGetDefaultServiceBannerListQuery();

  const props: UploadProps = {
    name: "file",
    action: "https://httpbin.org/post",
    headers: {
      authorization: "authorization-text",
    },
    beforeUpload: (file) => {
      const isImageOrMp4 =
        file.type.startsWith("image") ||
        file.type.split("/")[1] === "mp4" ||
        file.type.split("/")[1] === "MP4";
      if (!isImageOrMp4) {
        message.error(renderAntDMessageConfig("Wrong file format!"));
      }
      return isImageOrMp4 || Upload.LIST_IGNORE;
    },
    onChange(info) {
      setUploadedFileName(info.file.name);
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(
          renderAntDMessageConfig(
            `${info.file.name} file uploaded successfully`
          )
        );
      } else if (info.file.status === "error") {
        message.error(
          renderAntDMessageConfig(`${info.file.name} file upload failed.`)
        );
      }
      uploadFile({
        variables: {
          blobs: [
            {
              file: info.file.originFileObj,
              path: AfBlobPath.AppServiceBanners,
            },
          ],
        },
      }).catch((e) => console.log(e));
    },
    maxCount: 1,
    listType: "picture",
    onRemove: () => setUploadedFileName(""),
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Service",
      dataIndex: "service",
      key: "service",
    },
    {
      title: "Theme",
      dataIndex: "theme",
      key: "theme",
      render: (value: string) =>
        value ? value : <p style={{ color: "rgba(0, 0, 0, 0.45)" }}>N/A</p>,
    },
    {
      title: "Image",
      dataIndex: "imageUrl",
      key: "imageUrl",
      render: (value: string) => (
        <img
          alt="announcement"
          src={value}
          style={{ height: 70, width: "auto" }}
        />
      ),
    },
    {
      title: "Market",
      dataIndex: "market",
      key: "market",
      render: (value: any) => <RegionTag region={value} />,
    },
    {
      title: "Date range",
      dataIndex: "theme",
      key: "theme",
      render: (_, record: DataType) =>
        !record.theme ? (
          <p style={{ color: "rgba(0, 0, 0, 0.45)" }}>No expiry date</p>
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            <p>
              {dayjs(
                themes?.club_worldFamilyClub_faceCallSeasonHoliday?.edges &&
                  themes?.club_worldFamilyClub_faceCallSeasonHoliday?.edges.find(
                    (theme) => theme.node.name === record.theme
                  )?.node.dateFrom
              ).format("MM-DD")}
            </p>
            <SwapRightOutlined style={{ margin: "0 12px" }} />
            <p>
              {dayjs(
                themes?.club_worldFamilyClub_faceCallSeasonHoliday?.edges &&
                  themes?.club_worldFamilyClub_faceCallSeasonHoliday?.edges.find(
                    (theme) => theme.node.name === record.theme
                  )?.node.dateTo
              ).format("MM-DD")}
            </p>
          </div>
        ),
    },
    {
      title: "Action",
      render: (_, record: DataType) => (
        <>
          <Button
            type="link"
            onClick={() => {
              setSelectTemplate(record);
              setShowDrawer(true);
            }}
            style={{ padding: 0, marginRight: 16 }}
          >
            Edit
          </Button>
        </>
      ),
    },
  ];

  const handleSubmit = (values: any) => {
    if (selectTemplate) {
      const updatePromise = updateDefaultServiceBanner({
        variables: {
          seq: selectTemplate.seq,
          image: uploadedFileName ? uploadedFileName : selectTemplate.image,
        },
      });
      updatePromise.then(() => {
        refetch();
        form.resetFields();
        setSelectTemplate(undefined);
        setShowDrawer(false);
      });
    }
  };

  useEffect(() => {
    if (data) {
      setDisplayData(data.defaultServiceBannerList);
    }
  }, [data]);

  useEffect(() => {
    if (selectTemplate) {
      setUploadedFileName(selectTemplate.image);
      form.setFieldsValue({
        image: [
          {
            uid: "0",
            name: selectTemplate.image,
            status: "done",
            url: selectTemplate.imageUrl,
          },
        ],
      });
    }
  }, [selectTemplate, form]);

  if (themesError || error) {
    message.error(
      renderAntDMessageConfig(
        "Something went wrong. Please contact your system administrator."
      )
    );
  }

  if (themesLoading || loading) {
    return <Spinner />;
  }

  return (
    <>
      <div className="theme-tab-content-container">
        <div
          className="theme-tab-table-container"
          style={{ backgroundColor: Colors.white }}
        >
          <Table
            rowKey={(record: DataType) => record.seq}
            columns={columns}
            dataSource={displayData}
            pagination={false}
            className="announcement-table"
          />
        </div>
      </div>
      <Drawer
        title="Edit template"
        open={showDrawer}
        onClose={() => {
          form.resetFields();
          setSelectTemplate(undefined);
          setShowDrawer(false);
        }}
        width={500}
      >
        <Form
          name="theme-form"
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <Form.Item label="Service">
            <p style={{ marginTop: -16, color: "#071491" }}>
              {selectTemplate?.service}
            </p>
          </Form.Item>
          <Form.Item label="Theme">
            <p style={{ marginTop: -16, color: "#071491" }}>
              {selectTemplate?.theme ? selectTemplate.theme : "N/A"}
            </p>
          </Form.Item>
          <Form.Item label="Market">
            <p style={{ marginTop: -16, color: "#071491" }}>
              {selectTemplate?.market}
            </p>
          </Form.Item>
          <Form.Item
            label="Image"
            name="image"
            rules={[{ required: true, message: "This field is required!" }]}
            valuePropName="fileList"
            getValueFromEvent={(e) => {
              if (Array.isArray(e)) {
                return e;
              }
              return e && e.fileList;
            }}
          >
            <Upload {...props} className="service-banner-image-upload">
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
          <p style={{ margin: "-16px 0 24px", color: "rgba(0, 0, 0, 0.45)" }}>
            Formats: JPEG, JPG, PNG, GIF, MP4
          </p>
          <DrawerFooter
            handleCancel={() => {
              form.resetFields();
              setSelectTemplate(undefined);
              setShowDrawer(false);
            }}
          />
        </Form>
      </Drawer>
    </>
  );
};

export default TemplatesTabContent;
