import { Button } from "antd";

import { JobStatusType } from "../types";

export default function ViewAction({
  jobStatus,
}: {
  jobStatus: JobStatusType;
}) {
  return (
    <div
      className="fixed z-[1] left-[268px] bottom-0 flex flex-row-reverse justify-between px-6 py-4 bg-white border-t border-solid border-grey3"
      style={{ width: "calc(100% - 268px)" }}
    >
      <Button size="large" htmlType="submit" disabled={jobStatus === "pending"}>
        Re-push
      </Button>
    </div>
  );
}
