import { Dispatch, SetStateAction } from "react";
import { UploadFile, message } from "antd";

import { AfBlobPath } from "../../../../graphql/operations/@types";
import { renderAntDMessageConfig } from "../../../../utils/renderAntDMessageConfig";
import denormalizePushNotificationData from "../utils/denormalizePushNotificationData";
import { TrialModeNotificationModifyData } from "../types";

export default function handleSubmit({
  values,
  fileList,
  createNotification,
  uploadFile,
  refetch,
  setModifyingData,
  setViewData,
  isRePush,
}: {
  values: TrialModeNotificationModifyData;
  fileList?: UploadFile<any>[];
  createNotification: any;
  uploadFile: any;
  refetch: any;
  setModifyingData?: Dispatch<
    SetStateAction<TrialModeNotificationModifyData | null>
  >;
  setViewData?: Dispatch<
    SetStateAction<TrialModeNotificationModifyData | null>
  >;
  isRePush?: boolean;
}) {
  const { imageFileName, scheduleTime, testMemberNo } = values;
  const isTest = Boolean(testMemberNo);
  const isScheduled = !isTest && !isRePush && scheduleTime;

  if (imageFileName && fileList) {
    const { originFileObj } = fileList[0] || {};

    if (originFileObj)
      uploadFile({
        variables: {
          blobs: [
            {
              file: originFileObj,
              path: AfBlobPath.TrialModeNotificationImages,
            },
          ],
        },
      }).catch((e: any) => console.log(e));
  }

  createNotification({
    variables: denormalizePushNotificationData(values, Boolean(isRePush)),
  })
    .then(() => {
      if (isScheduled) {
        message.success(
          renderAntDMessageConfig("Scheduled Notification has been submitted.")
        );
      } else {
        message.success(
          renderAntDMessageConfig(
            "Notification has been submitted. This can sometimes take a few minutes to arrive."
          )
        );

        if (isTest) return;
      }

      refetch();

      if (setModifyingData) setModifyingData(null);
      if (setViewData) setViewData(null);
    })
    .catch((e: any) => console.log(e));
}
