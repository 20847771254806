import { Button, Form } from "antd";

import InputCustom from "../../../../../components/custom/InputCustom";

export default function FormItemTestMemberNo({
  submitDisabled,
}: {
  submitDisabled: boolean;
}) {
  const validateMobileNumber = (rule: any, value: string) => {
    if (!value) return Promise.resolve();

    const isValid = /^\d{1,11}$/.test(value);

    return isValid
      ? Promise.resolve()
      : Promise.reject("Please enter the mobile number in the correct format.");
  };

  return (
    <>
      <Form.Item
        name="testMemberNo"
        label="Test mobile number"
        rules={[
          {
            validator: validateMobileNumber,
          },
        ]}
      >
        <InputCustom
          placeholder="Enter the mobile number for testing"
          className="w-[484px]"
        />
      </Form.Item>

      <div className="ml-40 flex gap-3">
        <Button type="primary" htmlType="submit" disabled={submitDisabled}>
          Submit to test
        </Button>

        {!submitDisabled ? (
          <div className="flex items-center text-textPrimary">
            You can now submit to test.
          </div>
        ) : null}
      </div>
    </>
  );
}
