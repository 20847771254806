import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateUploadVideoMutationVariables = Types.Exact<{
  productVideoId: Types.Scalars['Int']['input'];
  videoTitle: Types.Scalars['String']['input'];
  productCategoryId: Types.Scalars['Int']['input'];
  introVideoFileName: Types.Scalars['String']['input'];
  videoFileName: Types.Scalars['String']['input'];
  videoThumbnail: Types.Scalars['String']['input'];
}>;


export type UpdateUploadVideoMutation = { __typename?: 'Mutation', wf_trialModeLeads: { __typename?: 'WF_TrialModeLeads_Mutation_Payload', count: number } };


export const UpdateUploadVideoDocument = gql`
    mutation UpdateUploadVideo($productVideoId: Int!, $videoTitle: String!, $productCategoryId: Int!, $introVideoFileName: String!, $videoFileName: String!, $videoThumbnail: String!) {
  wf_trialModeLeads(
    update: {productVideo: {productVideoId: $productVideoId, videoTitle: $videoTitle, productCategoryId: $productCategoryId, introVideoFileName: $introVideoFileName, videoFileName: $videoFileName, videoThumbnail: $videoThumbnail}}
  ) {
    count
  }
}
    `;
export type UpdateUploadVideoMutationFn = Apollo.MutationFunction<UpdateUploadVideoMutation, UpdateUploadVideoMutationVariables>;

/**
 * __useUpdateUploadVideoMutation__
 *
 * To run a mutation, you first call `useUpdateUploadVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUploadVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUploadVideoMutation, { data, loading, error }] = useUpdateUploadVideoMutation({
 *   variables: {
 *      productVideoId: // value for 'productVideoId'
 *      videoTitle: // value for 'videoTitle'
 *      productCategoryId: // value for 'productCategoryId'
 *      introVideoFileName: // value for 'introVideoFileName'
 *      videoFileName: // value for 'videoFileName'
 *      videoThumbnail: // value for 'videoThumbnail'
 *   },
 * });
 */
export function useUpdateUploadVideoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUploadVideoMutation, UpdateUploadVideoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUploadVideoMutation, UpdateUploadVideoMutationVariables>(UpdateUploadVideoDocument, options);
      }
export type UpdateUploadVideoMutationHookResult = ReturnType<typeof useUpdateUploadVideoMutation>;
export type UpdateUploadVideoMutationResult = Apollo.MutationResult<UpdateUploadVideoMutation>;
export type UpdateUploadVideoMutationOptions = Apollo.BaseMutationOptions<UpdateUploadVideoMutation, UpdateUploadVideoMutationVariables>;