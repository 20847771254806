import { useEffect, useState } from "react";
import { Form, UploadFile } from "antd";

import { useUpdateCtaMutation } from "../../../../graphql/operations/trialMode/caringCTA/update-CTA";
import { useCreateCtaContentsMutation } from "../../../../graphql/operations/trialMode/caringCTA/create-CTA-contents";
import { useDeleteCtaContentsMutation } from "../../../../graphql/operations/trialMode/caringCTA/delete-CTA-contents";
import { useUploadFileMutation } from "../../../../graphql/operations/upload-file";

import InputCustom from "../../../../components/custom/InputCustom";
import Spinner from "../../../../components/layout/spinner/Spinner";
import requiredRule from "../../../../utils/formRules/requiredRule";
import EditFormAction from "../../components/EditFormAction";
import getSegmentationDesc from "../../utils/getSegmentationDesc";
import { CTADataType, TemplateType } from "../types";
import getDefaultData from "../utils/getDefaultData";
import FormTemplate from "./formItems/FormTemplate";
import FormContents from "./formItems/FormContents";
import Preview from "./Preview";
import handleSubmit from "./handleSubmit";

export default function EditForm({
  data,
  setEditingData,
  refetch,
}: {
  data: CTADataType;
  setEditingData: React.Dispatch<React.SetStateAction<CTADataType | null>>;
  refetch: any;
}) {
  const { market, segmentation, experienceName, template } = data;
  const [form] = Form.useForm();

  const [selectedTemplate, setSelectedTemplate] =
    useState<TemplateType>(template);
  const [photoUrl, setPhotoUrl] = useState<string>("");
  const [fileList, setFileList] = useState<UploadFile<any>[]>([]);
  const [contentIndex, setContentIndex] = useState(0);
  const [formData, setFormData] = useState<CTADataType>(data);

  const [updateCta, { loading: loadingOfUpdate }] = useUpdateCtaMutation();
  const [createCtaContents, { loading: loadingOfCreateCtaContents }] =
    useCreateCtaContentsMutation();
  const [deleteCtaContents, { loading: loadingOfDeleteCtaContents }] =
    useDeleteCtaContentsMutation();
  const [uploadFile] = useUploadFileMutation();

  const handleReset = () => {
    form.setFieldsValue(getDefaultData(segmentation, experienceName));
    setFormData(form.getFieldsValue());
    setSelectedTemplate(form.getFieldValue("template"));
    setPhotoUrl("");
    setFileList([]);
    setContentIndex(0);
  };

  const onFinishFailed = () => {
    const contents = form.getFieldValue("contents");
    const index = contents.findIndex((content: any) => {
      const { ctaTitle, ctaContent } = content || {};

      return !ctaTitle || !ctaContent;
    });

    if (index > -1) setContentIndex(index);
  };

  useEffect(() => {
    const { template, photoFileName, photoUrl } = data;

    if (template === "Photo" && photoFileName) {
      setPhotoUrl(photoUrl);
      setFileList([
        {
          uid: "0",
          name: photoFileName,
          status: "done",
          url: photoUrl,
          thumbUrl: photoUrl,
        },
      ]);
    }
  }, [data]);

  return (
    <div className="flex justify-center items-center gap-6 flex-none">
      <div className="w-[622px]">
        <Form
          name="edit-caringCTA"
          className="form-align-center"
          form={form}
          initialValues={formData}
          onValuesChange={(changedValues, allValues) => {
            setFormData(allValues);
          }}
          onFinish={(values: CTADataType) => {
            handleSubmit({
              values,
              data,
              fileList,
              updateCta,
              createCtaContents,
              deleteCtaContents,
              uploadFile,
              refetch,
              setEditingData,
            });
          }}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item label="Market">
            <p>{market}</p>
          </Form.Item>

          <Form.Item label="Segmentation" className="w-[300px]">
            <p>{getSegmentationDesc(segmentation)}</p>
          </Form.Item>

          <Form.Item label="Experience Type">
            <p>{experienceName}</p>
          </Form.Item>

          <FormTemplate
            form={form}
            fileList={fileList}
            setFileList={setFileList}
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
            setPhotoUrl={setPhotoUrl}
          />

          {segmentation !== "Decision" ? (
            <>
              <Form.Item name="buttonLinksTo" label="Button links to">
                <p>
                  {segmentation === "Awareness" ? "Lead page" : "Demo page"}
                </p>
              </Form.Item>

              <Form.Item
                name="buttonText"
                label="CTA button text"
                rules={[requiredRule]}
              >
                <InputCustom />
              </Form.Item>
            </>
          ) : null}

          <FormContents index={contentIndex} setIndex={setContentIndex} />

          <EditFormAction
            isModified={JSON.stringify(data) !== JSON.stringify(formData)}
            setEditingData={setEditingData}
            handleReset={handleReset}
            loadingOfUpdate={loadingOfUpdate}
          />
        </Form>
      </div>

      <Preview
        formData={formData}
        index={contentIndex}
        photoUrl={photoUrl}
        segmentation={segmentation}
      />

      {loadingOfUpdate ||
      loadingOfCreateCtaContents ||
      loadingOfDeleteCtaContents ? (
        <Spinner />
      ) : null}
    </div>
  );
}
