import { renderDecodedId } from "../../../../utils/renderDecodedId";
import denormalizeJourneyCardData from "../utils/denormalizeJourneyCardData";
import { JourneyCardType } from "../types";

export default function handleSubmit({
  values,
  data,
  updateJourneyCard,
  refetch,
  setEditingData,
}: {
  values: JourneyCardType;
  data: JourneyCardType;
  updateJourneyCard: any;
  refetch: any;
  setEditingData: React.Dispatch<React.SetStateAction<JourneyCardType | null>>;
}) {
  const { id, journeyExperienceTypeId } = data;
  const journeyCardId = parseInt(renderDecodedId(id));

  updateJourneyCard({
    variables: {
      journeyCardId,
      journeyExperienceTypeId,
      ...denormalizeJourneyCardData(values),
    },
  })
    .then(() => {
      refetch();
      setEditingData(null);
    })
    .catch((e: any) => console.log(e));
}
