import * as Types from './@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteGoGoLiveMutationVariables = Types.Exact<{
  GGLIds?: Types.InputMaybe<Array<Types.Club_WorldFamilyClub_GoGoLivePromotionInput> | Types.Club_WorldFamilyClub_GoGoLivePromotionInput>;
}>;


export type DeleteGoGoLiveMutation = { __typename?: 'Mutation', club_worldFamilyClub: { __typename?: 'Club_WorldFamilyClub_Mutation_Payload', count: number } };


export const DeleteGoGoLiveDocument = gql`
    mutation DeleteGoGoLive($GGLIds: [Club_WorldFamilyClub_GoGoLivePromotionInput!]) {
  club_worldFamilyClub(removeRange: {goGoLivePromotions: $GGLIds}) {
    count
  }
}
    `;
export type DeleteGoGoLiveMutationFn = Apollo.MutationFunction<DeleteGoGoLiveMutation, DeleteGoGoLiveMutationVariables>;

/**
 * __useDeleteGoGoLiveMutation__
 *
 * To run a mutation, you first call `useDeleteGoGoLiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGoGoLiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGoGoLiveMutation, { data, loading, error }] = useDeleteGoGoLiveMutation({
 *   variables: {
 *      GGLIds: // value for 'GGLIds'
 *   },
 * });
 */
export function useDeleteGoGoLiveMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGoGoLiveMutation, DeleteGoGoLiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGoGoLiveMutation, DeleteGoGoLiveMutationVariables>(DeleteGoGoLiveDocument, options);
      }
export type DeleteGoGoLiveMutationHookResult = ReturnType<typeof useDeleteGoGoLiveMutation>;
export type DeleteGoGoLiveMutationResult = Apollo.MutationResult<DeleteGoGoLiveMutation>;
export type DeleteGoGoLiveMutationOptions = Apollo.BaseMutationOptions<DeleteGoGoLiveMutation, DeleteGoGoLiveMutationVariables>;