import {
  NotificationActionButtonType,
  TrialModeNotificationModifyData,
} from "../types";

export default function denormalizePushNotificationData(
  data: TrialModeNotificationModifyData,
  isRePush: boolean
) {
  const {
    actionButton,
    buttonLinksTo,
    buttonLinksToUrl,
    buttonText,
    imageFileName,
    market,
    scheduleTime,
    segmentations,
    serviceType,
    templateMessage,
    testMemberNo,
    title,
  } = data;
  const url = buttonLinksToUrl ? `https://${buttonLinksToUrl}` : "";

  const notification = {
    notificationId: null,
    notificationTypeId: serviceType,
    actionButton:
      actionButton === NotificationActionButtonType.None ? "" : actionButton,
    buttonLinksTo: buttonLinksTo || url,
    buttonText: buttonText || "",
    imageFileName,
    market,
    title: testMemberNo ? `[TEST]${title}` : title,
    templateMessage,
    ...(!testMemberNo && scheduleTime && !isRePush ? { scheduleTime } : {}),
  };

  return {
    notification,
    segmentations: testMemberNo ? [] : segmentations,
    members: testMemberNo ? [{ phoneNumber: testMemberNo }] : null,
  };
}
