import { Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import "./MainContentHeader.css";
import { Colors } from "../../../constants/Colors";
import { useNavigate } from "react-router-dom";

interface MainContentHeaderProps {
  title: string;
}

const MainContentHeader = ({ title }: MainContentHeaderProps) => {
  const navigate = useNavigate();

  return (
    <div
      className="main-content-header-container"
      style={{ backgroundColor: Colors.white }}
    >
      <div className="main-content-header-title-container">
        <Button
          type="text"
          icon={<ArrowLeftOutlined />}
          onClick={() => navigate(-1)}
          style={{ marginLeft: -8 }}
        />
        <p>{title}</p>
      </div>
    </div>
  );
};

export default MainContentHeader;
