export const buttonLinkMapping: {
  [key: string]: string;
} = {
  "on-line-video": "ABC Channel",
  fc: "Face Call",
  "parent-support": "Parents Support",
};

export const actionButtonMapping: {
  [key: string]: string;
} = {
  "": "None",
  AppScreen: "Club app screen",
  AppEmbedURL: "Embed URL",
  OpenBrowser: "Open browser",
};
