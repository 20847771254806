import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateVideoAndMarketsMutationVariables = Types.Exact<{
  productVideoAndMarkets?: Types.InputMaybe<Array<Types.Wf_TrialModeLeads_ProductVideoAndMarketInput> | Types.Wf_TrialModeLeads_ProductVideoAndMarketInput>;
}>;


export type CreateVideoAndMarketsMutation = { __typename?: 'Mutation', wf_trialModeLeads: { __typename?: 'WF_TrialModeLeads_Mutation_Payload', count: number } };


export const CreateVideoAndMarketsDocument = gql`
    mutation CreateVideoAndMarkets($productVideoAndMarkets: [WF_TrialModeLeads_ProductVideoAndMarketInput!]) {
  wf_trialModeLeads(addRange: {productVideoAndMarkets: $productVideoAndMarkets}) {
    count
  }
}
    `;
export type CreateVideoAndMarketsMutationFn = Apollo.MutationFunction<CreateVideoAndMarketsMutation, CreateVideoAndMarketsMutationVariables>;

/**
 * __useCreateVideoAndMarketsMutation__
 *
 * To run a mutation, you first call `useCreateVideoAndMarketsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVideoAndMarketsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVideoAndMarketsMutation, { data, loading, error }] = useCreateVideoAndMarketsMutation({
 *   variables: {
 *      productVideoAndMarkets: // value for 'productVideoAndMarkets'
 *   },
 * });
 */
export function useCreateVideoAndMarketsMutation(baseOptions?: Apollo.MutationHookOptions<CreateVideoAndMarketsMutation, CreateVideoAndMarketsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateVideoAndMarketsMutation, CreateVideoAndMarketsMutationVariables>(CreateVideoAndMarketsDocument, options);
      }
export type CreateVideoAndMarketsMutationHookResult = ReturnType<typeof useCreateVideoAndMarketsMutation>;
export type CreateVideoAndMarketsMutationResult = Apollo.MutationResult<CreateVideoAndMarketsMutation>;
export type CreateVideoAndMarketsMutationOptions = Apollo.BaseMutationOptions<CreateVideoAndMarketsMutation, CreateVideoAndMarketsMutationVariables>;