import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateUploadVideoMutationVariables = Types.Exact<{
  videoTitle: Types.Scalars['String']['input'];
  productCategoryId: Types.Scalars['Int']['input'];
  introVideoFileName: Types.Scalars['String']['input'];
  videoFileName: Types.Scalars['String']['input'];
  videoThumbnail: Types.Scalars['String']['input'];
}>;


export type CreateUploadVideoMutation = { __typename?: 'Mutation', wf_trialModeLeads: { __typename?: 'WF_TrialModeLeads_Mutation_Payload', count: number } };


export const CreateUploadVideoDocument = gql`
    mutation CreateUploadVideo($videoTitle: String!, $productCategoryId: Int!, $introVideoFileName: String!, $videoFileName: String!, $videoThumbnail: String!) {
  wf_trialModeLeads(
    add: {productVideo: {videoTitle: $videoTitle, productCategoryId: $productCategoryId, introVideoFileName: $introVideoFileName, videoFileName: $videoFileName, videoThumbnail: $videoThumbnail}}
  ) {
    count
  }
}
    `;
export type CreateUploadVideoMutationFn = Apollo.MutationFunction<CreateUploadVideoMutation, CreateUploadVideoMutationVariables>;

/**
 * __useCreateUploadVideoMutation__
 *
 * To run a mutation, you first call `useCreateUploadVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUploadVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUploadVideoMutation, { data, loading, error }] = useCreateUploadVideoMutation({
 *   variables: {
 *      videoTitle: // value for 'videoTitle'
 *      productCategoryId: // value for 'productCategoryId'
 *      introVideoFileName: // value for 'introVideoFileName'
 *      videoFileName: // value for 'videoFileName'
 *      videoThumbnail: // value for 'videoThumbnail'
 *   },
 * });
 */
export function useCreateUploadVideoMutation(baseOptions?: Apollo.MutationHookOptions<CreateUploadVideoMutation, CreateUploadVideoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUploadVideoMutation, CreateUploadVideoMutationVariables>(CreateUploadVideoDocument, options);
      }
export type CreateUploadVideoMutationHookResult = ReturnType<typeof useCreateUploadVideoMutation>;
export type CreateUploadVideoMutationResult = Apollo.MutationResult<CreateUploadVideoMutation>;
export type CreateUploadVideoMutationOptions = Apollo.BaseMutationOptions<CreateUploadVideoMutation, CreateUploadVideoMutationVariables>;