import avatarZippy from "../../../../assets/images/trialMode/avatarZippy.png";
import avatarElvis from "../../../../assets/images/trialMode/avatarElvis.png";
import avatarBunny from "../../../../assets/images/trialMode/avatarBunny.png";
import avatarCoco from "../../../../assets/images/trialMode/avatarCoco.png";

import { CharacterType } from "../types";

const characterAvatars: Record<CharacterType, string> = {
  Zippy: avatarZippy,
  Elvis: avatarElvis,
  Bunny: avatarBunny,
  Coco: avatarCoco,
  None: "",
};

export default function getCharacterSrc(name: CharacterType | ""): string {
  return name ? characterAvatars[name] : "";
}
