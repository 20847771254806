import { makeVar } from "@apollo/client";

export const defaultImage = "https://i.imgur.com/eRgPhL1.png";

export const sortColumn = {
  key: "sort",
  title: "Order",
  width: 72,
};

export const trialSegmentation = makeVar<{ [key: string]: string } | undefined>(
  undefined
);
