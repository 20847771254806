import * as Types from '../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetReleaseVersionQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetReleaseVersionQuery = { __typename?: 'Query', sales188Release: Array<{ __typename?: 'Sales188Release', seq: any, version?: string | null, osVersion?: string | null, appVersion?: string | null, appstore?: string | null, releaseTime: any }> };


export const GetReleaseVersionDocument = gql`
    query getReleaseVersion {
  sales188Release {
    seq
    version
    osVersion
    appVersion
    appstore
    releaseTime
  }
}
    `;

/**
 * __useGetReleaseVersionQuery__
 *
 * To run a query within a React component, call `useGetReleaseVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReleaseVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReleaseVersionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetReleaseVersionQuery(baseOptions?: Apollo.QueryHookOptions<GetReleaseVersionQuery, GetReleaseVersionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReleaseVersionQuery, GetReleaseVersionQueryVariables>(GetReleaseVersionDocument, options);
      }
export function useGetReleaseVersionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReleaseVersionQuery, GetReleaseVersionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReleaseVersionQuery, GetReleaseVersionQueryVariables>(GetReleaseVersionDocument, options);
        }
export type GetReleaseVersionQueryHookResult = ReturnType<typeof useGetReleaseVersionQuery>;
export type GetReleaseVersionLazyQueryHookResult = ReturnType<typeof useGetReleaseVersionLazyQuery>;
export type GetReleaseVersionQueryResult = Apollo.QueryResult<GetReleaseVersionQuery, GetReleaseVersionQueryVariables>;