import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteTrialModeNotificationMutationVariables = Types.Exact<{
  notification: Types.TrialModeNotificationInput;
}>;


export type DeleteTrialModeNotificationMutation = { __typename?: 'Mutation', removeTrialModeNotification: { __typename?: 'Payload', count: number, error?: Types.Error | null } };


export const DeleteTrialModeNotificationDocument = gql`
    mutation DeleteTrialModeNotification($notification: TrialModeNotificationInput!) {
  removeTrialModeNotification(modeNotification: $notification) {
    count
    error
  }
}
    `;
export type DeleteTrialModeNotificationMutationFn = Apollo.MutationFunction<DeleteTrialModeNotificationMutation, DeleteTrialModeNotificationMutationVariables>;

/**
 * __useDeleteTrialModeNotificationMutation__
 *
 * To run a mutation, you first call `useDeleteTrialModeNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTrialModeNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTrialModeNotificationMutation, { data, loading, error }] = useDeleteTrialModeNotificationMutation({
 *   variables: {
 *      notification: // value for 'notification'
 *   },
 * });
 */
export function useDeleteTrialModeNotificationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTrialModeNotificationMutation, DeleteTrialModeNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTrialModeNotificationMutation, DeleteTrialModeNotificationMutationVariables>(DeleteTrialModeNotificationDocument, options);
      }
export type DeleteTrialModeNotificationMutationHookResult = ReturnType<typeof useDeleteTrialModeNotificationMutation>;
export type DeleteTrialModeNotificationMutationResult = Apollo.MutationResult<DeleteTrialModeNotificationMutation>;
export type DeleteTrialModeNotificationMutationOptions = Apollo.BaseMutationOptions<DeleteTrialModeNotificationMutation, DeleteTrialModeNotificationMutationVariables>;