import { UploadFile } from "antd";

import { AfBlobPath } from "../../../../graphql/operations/@types";
import { renderDecodedId } from "../../../../utils/renderDecodedId";
import denormalizeCTAData from "../utils/denormalizeCTAData";
import { CTADataType } from "../types";

export default function handleSubmit({
  values,
  data,
  fileList,
  updateCta,
  createCtaContents,
  deleteCtaContents,
  uploadFile,
  refetch,
  setEditingData,
}: {
  values: CTADataType;
  data: CTADataType;
  fileList: UploadFile<any>[];
  updateCta: any;
  createCtaContents: any;
  deleteCtaContents: any;
  uploadFile: any;
  refetch: any;
  setEditingData: React.Dispatch<React.SetStateAction<CTADataType | null>>;
}) {
  const { id, ctaExperienceTypeId } = data;
  const ctaId = parseInt(renderDecodedId(id));
  const promises = [];

  if (values.template === "Photo") {
    const { originFileObj } = fileList[0] || {};

    if (originFileObj)
      uploadFile({
        variables: {
          blobs: [
            {
              file: originFileObj,
              path: AfBlobPath.TrialModeCallToActionEncouragePhotos,
            },
          ],
        },
      }).catch((e: any) => console.log(e));
  }

  if (JSON.stringify(data.contents) !== JSON.stringify(values.contents)) {
    const createVar = {
      CTAContents: values.contents.map(({ ctaTitle, ctaContent }) => ({
        ctaId,
        ctaTitle,
        ctaContent,
      })),
    };
    const deleteVar = {
      CTAContents: data.contents.map((item) => ({
        ctaContentId: parseInt(renderDecodedId(item.id)),
      })),
    };

    if (data.contents.length === 0) {
      promises.push(createCtaContents({ variables: createVar }));
    } else {
      promises.push(
        deleteCtaContents({ variables: deleteVar }).then(() => {
          return createCtaContents({ variables: createVar });
        })
      );
    }
  }

  promises.push(
    updateCta({
      variables: {
        ctaId,
        ctaExperienceTypeId,
        ...denormalizeCTAData(values),
      },
    })
  );

  Promise.all(promises)
    .then(() => {
      refetch();
      setEditingData(null);
    })
    .catch((e: any) => console.log(e));
}
