import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateUploadSongMutationVariables = Types.Exact<{
  productSongId: Types.Scalars['Int']['input'];
  songTitle: Types.Scalars['String']['input'];
  productCategoryId: Types.Scalars['Int']['input'];
  audioFileName: Types.Scalars['String']['input'];
  lyricsFileName: Types.Scalars['String']['input'];
}>;


export type UpdateUploadSongMutation = { __typename?: 'Mutation', wf_trialModeLeads: { __typename?: 'WF_TrialModeLeads_Mutation_Payload', count: number } };


export const UpdateUploadSongDocument = gql`
    mutation UpdateUploadSong($productSongId: Int!, $songTitle: String!, $productCategoryId: Int!, $audioFileName: String!, $lyricsFileName: String!) {
  wf_trialModeLeads(
    update: {productSong: {productSongId: $productSongId, songTitle: $songTitle, productCategoryId: $productCategoryId, audioFileName: $audioFileName, lyricsFileName: $lyricsFileName}}
  ) {
    count
  }
}
    `;
export type UpdateUploadSongMutationFn = Apollo.MutationFunction<UpdateUploadSongMutation, UpdateUploadSongMutationVariables>;

/**
 * __useUpdateUploadSongMutation__
 *
 * To run a mutation, you first call `useUpdateUploadSongMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUploadSongMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUploadSongMutation, { data, loading, error }] = useUpdateUploadSongMutation({
 *   variables: {
 *      productSongId: // value for 'productSongId'
 *      songTitle: // value for 'songTitle'
 *      productCategoryId: // value for 'productCategoryId'
 *      audioFileName: // value for 'audioFileName'
 *      lyricsFileName: // value for 'lyricsFileName'
 *   },
 * });
 */
export function useUpdateUploadSongMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUploadSongMutation, UpdateUploadSongMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUploadSongMutation, UpdateUploadSongMutationVariables>(UpdateUploadSongDocument, options);
      }
export type UpdateUploadSongMutationHookResult = ReturnType<typeof useUpdateUploadSongMutation>;
export type UpdateUploadSongMutationResult = Apollo.MutationResult<UpdateUploadSongMutation>;
export type UpdateUploadSongMutationOptions = Apollo.BaseMutationOptions<UpdateUploadSongMutation, UpdateUploadSongMutationVariables>;