import { Dispatch, SetStateAction } from "react";
import axios from "axios";

import getEmbedToken from "./getEmbedToken";

export default async function getReportInGroup(
  accessToken: string,
  setEmbedToken: Dispatch<SetStateAction<string>>,
  setReportInGroup: Dispatch<SetStateAction<any>>,
  setError: Dispatch<SetStateAction<any>>
) {
  const groupId = process.env.REACT_APP_TRIAL_MODE_DASHBOARD_GROUP_ID;
  const reportId = process.env.REACT_APP_TRIAL_MODE_DASHBOARD_REPORT_ID;

  try {
    const response = await axios.get(
      `https://api.powerbi.com/v1.0/myorg/groups/${groupId}/reports/${reportId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`, // You need to define accessToken
          "Content-type": "application/json",
        },
      }
    );

    setReportInGroup(response.data);
    getEmbedToken(accessToken, response.data, setEmbedToken, setError);
  } catch (error: any) {
    console.error("getReportInGroup error", error);
    setError(error);
  }
}
