import * as Types from './@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateUserMutationVariables = Types.Exact<{
  name: Types.Scalars['String']['input'];
  email: Types.Scalars['String']['input'];
  isAdmin: Types.Scalars['Boolean']['input'];
  avatarUrl: Types.Scalars['String']['input'];
  userId: Types.Scalars['Int']['input'];
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', wf_adminFirst: { __typename?: 'WF_AdminFirst_Mutation_Payload', count: number } };


export const UpdateUserDocument = gql`
    mutation UpdateUser($name: String!, $email: String!, $isAdmin: Boolean!, $avatarUrl: String!, $userId: Int!) {
  wf_adminFirst(
    update: {afUser: {userName: $name, userEmail: $email, isAdministrator: $isAdmin, avatarUrl: $avatarUrl, afUserId: $userId}}
  ) {
    count
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      isAdmin: // value for 'isAdmin'
 *      avatarUrl: // value for 'avatarUrl'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;