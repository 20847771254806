/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import { Dispatch, SetStateAction, useEffect } from "react";
import { useReactiveVar } from "@apollo/client";
import { ServiceTypes } from "../../../types/types";
import { Colors } from "../../../constants/Colors";
import { useGetUserProgramsByServiceQuery } from "../../../graphql/operations/get-user-program-by-service";
import { __currentUser__ } from "../../../graphql/policies";
import { renderDecodedId } from "../../../utils/renderDecodedId";
import NoPermission from "../../../components/others/NoPermission";
import Spinner from "../../../components/layout/spinner/Spinner";
import AnnouncementTabContent from "./components/AnnouncementTabContent";

interface HomeProps {
  setSelectedService: Dispatch<SetStateAction<ServiceTypes>>;
  setSelectSubItem: Dispatch<SetStateAction<string | undefined>>;
}

const AnnouncementBar = ({
  setSelectedService,
  setSelectSubItem,
}: HomeProps) => {
  const currentUser = useReactiveVar(__currentUser__);
  const {
    data: userPrograms,
    loading: userProgramsLoading,
    error: userProgramsError,
  } = useGetUserProgramsByServiceQuery({
    variables: {
      userId: parseInt(renderDecodedId(currentUser?.id)),
      serviceId: 2,
    },
  });

  useEffect(() => {
    setSelectedService(ServiceTypes.CLUB_APP);
    setSelectSubItem("Announcement Bar");
  }, []);

  if (userProgramsError) {
    message.error(
      renderAntDMessageConfig(
        "Something went wrong. Please contact your system administrator."
      )
    );
  }

  if (userProgramsLoading) {
    return <Spinner />;
  }

  if (
    userPrograms &&
    !userPrograms.wf_adminFirst_afUserProgram?.edges?.find(
      (item) => item.node.afProgram?.programName === "Announcement Bar"
    )
  ) {
    return <NoPermission />;
  }
  return (
    <div>
      <div
        className="header"
        style={{ backgroundColor: Colors.white, marginBottom: 16 }}
      >
        <p className="header-title" style={{ color: Colors.darkGrey }}>
          Announcement Bar
        </p>
      </div>
      <AnnouncementTabContent />
    </div>
  );
};

export default AnnouncementBar;
