import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetRegularEventsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetRegularEventsQuery = { __typename?: 'Query', wf_trialModeLeads_regularEvent?: { __typename?: 'WF_TrialModeLeads_RegularEventConnection', nodes?: Array<{ __typename?: 'WF_TrialModeLeads_RegularEvent', id: string, sortId?: number | null, market?: string | null, eventTitle?: string | null, eventDescription?: string | null, imageFileName?: string | null, navigationUrl?: string | null, startDate?: any | null, endDate?: any | null, imageUrl?: string | null }> | null } | null };


export const GetRegularEventsDocument = gql`
    query GetRegularEvents {
  wf_trialModeLeads_regularEvent(first: 100) {
    nodes {
      id
      sortId
      market
      eventTitle
      eventDescription
      imageFileName
      imageUrl: imageFileName @wf_blobSignature(name: "TRIAL_MODE_LOCAL_EVENT_REGULAR_EVENT_BANNERS_SIGNATURE")
      navigationUrl
      startDate
      endDate
    }
  }
}
    `;

/**
 * __useGetRegularEventsQuery__
 *
 * To run a query within a React component, call `useGetRegularEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegularEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegularEventsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRegularEventsQuery(baseOptions?: Apollo.QueryHookOptions<GetRegularEventsQuery, GetRegularEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRegularEventsQuery, GetRegularEventsQueryVariables>(GetRegularEventsDocument, options);
      }
export function useGetRegularEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRegularEventsQuery, GetRegularEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRegularEventsQuery, GetRegularEventsQueryVariables>(GetRegularEventsDocument, options);
        }
export type GetRegularEventsQueryHookResult = ReturnType<typeof useGetRegularEventsQuery>;
export type GetRegularEventsLazyQueryHookResult = ReturnType<typeof useGetRegularEventsLazyQuery>;
export type GetRegularEventsQueryResult = Apollo.QueryResult<GetRegularEventsQuery, GetRegularEventsQueryVariables>;