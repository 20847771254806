import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useReactiveVar } from "@apollo/client";
import { renderDecodedId } from "../../../utils/renderDecodedId";
import { ServiceTypes } from "../../../types/types";
import { apiCall } from "../../../utils/apiCall";
import ContentTitle from "../../../components/ui/ContentTitle";
import { __currentUser__ } from "../../../graphql/policies";
import { useGetUserProgramsByServiceQuery } from "../../../graphql/operations/get-user-program-by-service";
import { Tabs, TabsProps, message } from "antd";
import Spinner from "../../../components/layout/spinner/Spinner";
import { renderAntDMessageConfig } from "../../../utils/renderAntDMessageConfig";
import { Colors } from "../../../constants/Colors";
import Dev from "./dev";
import Release from "./release";
import NoPermission from "../../../components/others/NoPermission";

interface ReleaseVersionProps {
  setSelectedService: Dispatch<SetStateAction<ServiceTypes>>;
  setSelectSubItem: Dispatch<SetStateAction<string | undefined>>;
}

const ReleaseVersion = ({
  setSelectedService,
  setSelectSubItem,
}: ReleaseVersionProps) => {
  const currentUser = useReactiveVar(__currentUser__);
  const {
    data: userPrograms,
    loading: userProgramsLoading,
    error: userProgramsError,
  } = useGetUserProgramsByServiceQuery({
    variables: {
      userId: parseInt(renderDecodedId(currentUser?.id)),
      serviceId: 2,
    },
  });
  const [versionData, setVersionData] = useState();
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Dev Build",
      children: <Dev data={versionData} />,
    },
    {
      key: "2",
      label: "Release Build",
      children: <Release data={versionData} />,
    },
  ];
  const fetchVersionData = async () => {
    const header = {
      headers: {
        accept: "text/plain",
        "Content-Type": "application/json",
        "User-Code": "P725",
      },
    };
    try {
      const data = await apiCall({
        method: "GET",
        header: header,
        endPoint: process.env.REACT_APP_RELEASE_VERSION_ENDPOINT,
      });
      setVersionData(data);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  useEffect(() => {
    setSelectedService(ServiceTypes.CLUB_APP);
    setSelectSubItem("Release Version");
    fetchVersionData();
  }, [setSelectedService, setSelectSubItem]);
  if (userProgramsError) {
    message.error(
      renderAntDMessageConfig(
        "Something went wrong. Please contact your system administrator."
      )
    );
  }

  if (userProgramsLoading) {
    return <Spinner />;
  }

  if (
    userPrograms &&
    !userPrograms.wf_adminFirst_afUserProgram?.edges?.find(
      (item) => item.node.afProgram?.programName === "Release Version"
    )
  ) {
    return <NoPermission />;
  }

  return (
    <div id="clubAppReleaseVersionWrapper">
      <ContentTitle title="Release Version" />
      <Tabs
        defaultActiveKey="1"
        items={items}
        size="small"
        tabBarStyle={{
          paddingLeft: 24,
          backgroundColor: Colors.white,
          height: 50,
        }}
      />
    </div>
  );
};

export default ReleaseVersion;
