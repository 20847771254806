import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateSongAndMarketsMutationVariables = Types.Exact<{
  productSongAndMarkets?: Types.InputMaybe<Array<Types.Wf_TrialModeLeads_ProductSongAndMarketInput> | Types.Wf_TrialModeLeads_ProductSongAndMarketInput>;
}>;


export type CreateSongAndMarketsMutation = { __typename?: 'Mutation', wf_trialModeLeads: { __typename?: 'WF_TrialModeLeads_Mutation_Payload', count: number } };


export const CreateSongAndMarketsDocument = gql`
    mutation CreateSongAndMarkets($productSongAndMarkets: [WF_TrialModeLeads_ProductSongAndMarketInput!]) {
  wf_trialModeLeads(addRange: {productSongAndMarkets: $productSongAndMarkets}) {
    count
  }
}
    `;
export type CreateSongAndMarketsMutationFn = Apollo.MutationFunction<CreateSongAndMarketsMutation, CreateSongAndMarketsMutationVariables>;

/**
 * __useCreateSongAndMarketsMutation__
 *
 * To run a mutation, you first call `useCreateSongAndMarketsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSongAndMarketsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSongAndMarketsMutation, { data, loading, error }] = useCreateSongAndMarketsMutation({
 *   variables: {
 *      productSongAndMarkets: // value for 'productSongAndMarkets'
 *   },
 * });
 */
export function useCreateSongAndMarketsMutation(baseOptions?: Apollo.MutationHookOptions<CreateSongAndMarketsMutation, CreateSongAndMarketsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSongAndMarketsMutation, CreateSongAndMarketsMutationVariables>(CreateSongAndMarketsDocument, options);
      }
export type CreateSongAndMarketsMutationHookResult = ReturnType<typeof useCreateSongAndMarketsMutation>;
export type CreateSongAndMarketsMutationResult = Apollo.MutationResult<CreateSongAndMarketsMutation>;
export type CreateSongAndMarketsMutationOptions = Apollo.BaseMutationOptions<CreateSongAndMarketsMutation, CreateSongAndMarketsMutationVariables>;