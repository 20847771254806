import { useEffect, useState } from "react";
import { Table } from "antd";

import { useGetCtAsQuery } from "../../../graphql/operations/trialMode/caringCTA/get-CTAs";
import ContentTitle from "../../../components/ui/ContentTitle";
import Spinner from "../../../components/layout/spinner/Spinner";
import MarketDropdown from "../../../components/ui/MarketDropdown";
import getInitialMarket from "../../../utils/getInitialMarket";
import errorMessage from "../utils/errorMessage";
import withUserPrograms from "../withUserPrograms";
import { MarketType } from "../types";
import EditForm from "./editForm/EditForm";
import normalizeCTAData from "./utils/normalizeCTAData";
import { createColumns } from "./columns";
import { CTADataType } from "./types";

function CaringCTA() {
  const initialMarket = getInitialMarket();

  const [selectedMarket, setSelectedMarket] =
    useState<MarketType>(initialMarket);
  const [dataSource, setDataSource] = useState<CTADataType[]>([]);
  const [marketData, setMarketData] = useState<CTADataType[]>([]);
  const [editingData, setEditingData] = useState<CTADataType | null>(null);

  const {
    data,
    loading: loadingOfGet,
    error: errorOfGet,
    refetch,
  } = useGetCtAsQuery({
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (data) setDataSource(normalizeCTAData(data));
  }, [data]);

  useEffect(() => {
    setMarketData(dataSource.filter(({ market }) => market === selectedMarket));
  }, [dataSource, selectedMarket]);

  if (errorOfGet) errorMessage();
  if (loadingOfGet) return <Spinner />;

  return (
    <div>
      <ContentTitle
        title="Caring CTA"
        returnTitle="Caring CTA detail"
        onReturn={editingData ? () => setEditingData(null) : undefined}
      />

      <div className="mx-6 pt-5 pb-24">
        <div className="min-w-[1084px] px-6 bg-white">
          {editingData ? (
            <div className="py-6">
              <EditForm
                data={editingData}
                setEditingData={setEditingData}
                refetch={refetch}
              />
            </div>
          ) : (
            <>
              <div className="h-16 flex flex-row-reverse px-2 py-4">
                <MarketDropdown
                  selectedMarket={selectedMarket}
                  setSelectedMarket={setSelectedMarket}
                />
              </div>

              <Table
                className="custom-table-style"
                dataSource={marketData}
                columns={createColumns(setEditingData)}
                pagination={false}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default withUserPrograms(CaringCTA, "Caring CTA");
