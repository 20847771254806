import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateScheduledServiceBannerMutationVariables = Types.Exact<{
  seqs: Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input'];
  service: Types.Scalars['String']['input'];
  image: Types.Scalars['String']['input'];
  markets: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
  publishDate?: Types.InputMaybe<Types.Scalars['String']['input']>;
  stopDate?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type UpdateScheduledServiceBannerMutation = { __typename?: 'Mutation', updateScheduledServiceBanner: { __typename?: 'Payload', count: number } };


export const UpdateScheduledServiceBannerDocument = gql`
    mutation UpdateScheduledServiceBanner($seqs: [Int!]!, $service: String!, $image: String!, $markets: [String!]!, $publishDate: String, $stopDate: String) {
  updateScheduledServiceBanner(
    parameter: {seqs: $seqs, service: $service, image: $image, markets: $markets, publishDate: $publishDate, stopDate: $stopDate}
  ) {
    count
  }
}
    `;
export type UpdateScheduledServiceBannerMutationFn = Apollo.MutationFunction<UpdateScheduledServiceBannerMutation, UpdateScheduledServiceBannerMutationVariables>;

/**
 * __useUpdateScheduledServiceBannerMutation__
 *
 * To run a mutation, you first call `useUpdateScheduledServiceBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScheduledServiceBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScheduledServiceBannerMutation, { data, loading, error }] = useUpdateScheduledServiceBannerMutation({
 *   variables: {
 *      seqs: // value for 'seqs'
 *      service: // value for 'service'
 *      image: // value for 'image'
 *      markets: // value for 'markets'
 *      publishDate: // value for 'publishDate'
 *      stopDate: // value for 'stopDate'
 *   },
 * });
 */
export function useUpdateScheduledServiceBannerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateScheduledServiceBannerMutation, UpdateScheduledServiceBannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateScheduledServiceBannerMutation, UpdateScheduledServiceBannerMutationVariables>(UpdateScheduledServiceBannerDocument, options);
      }
export type UpdateScheduledServiceBannerMutationHookResult = ReturnType<typeof useUpdateScheduledServiceBannerMutation>;
export type UpdateScheduledServiceBannerMutationResult = Apollo.MutationResult<UpdateScheduledServiceBannerMutation>;
export type UpdateScheduledServiceBannerMutationOptions = Apollo.BaseMutationOptions<UpdateScheduledServiceBannerMutation, UpdateScheduledServiceBannerMutationVariables>;