import { Dispatch, SetStateAction } from "react";
import { FormInstance } from "antd";
import { UploadChangeParam, UploadFile } from "antd/es/upload";

const getBase64 = (
  file: File,
  callback: (result: string | ArrayBuffer | null) => void
) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(file);
};

export default function handleUploadOnChange(
  fieldName: string,
  form: FormInstance<any>,
  setFileList: Dispatch<SetStateAction<UploadFile<any>[]>>,
  setPhotoUrl?: Dispatch<SetStateAction<string>>
) {
  return (info: UploadChangeParam<UploadFile<any>>) => {
    if (!info.file.originFileObj) return;

    if (info.file.status === "removed") {
      if (setPhotoUrl) setPhotoUrl("");
      setFileList([]);
      return;
    }

    setFileList([info.file]);

    getBase64(info.file.originFileObj, (url) => {
      if (typeof url === "string" && setPhotoUrl) setPhotoUrl(url);

      form.setFieldsValue({
        [fieldName]: info.file.name,
      });
    });
  };
}
