import { Button, DatePicker, Form } from "antd";
import InputCustom from "../../../components/custom/InputCustom";
import "./dev.scss";
import dayjs from "dayjs";
import { useState } from "react";
import { getPlaceholder } from "./utils/getPlaceholder";
import { addHttpsProtocol } from "./utils/urlProcessing";
import requiredRule from "../../../utils/formRules/requiredRule";
import { useUpdateSales188ReleaseMutation } from "../../../graphql/operations/sales188/updateReleaseVersion";

interface DevProps {
  data: any;
}
const Dev = ({ data }: DevProps) => {
  const [updateSales188Release] = useUpdateSales188ReleaseMutation();
  const placeholderObject = getPlaceholder(
    data?.filter((v: any) => v.version === "test")
  );
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const onFinish = (values: any) => {
    const template = data?.filter((v: any) => v.version === "test");
    const selectedDate = values.date;
    const formattedDate = dayjs(selectedDate).format("YYYY-MM-DD");
    const payloadObject = template.map((info: any) => {
      return {
        ...info,
        appVersion: values.number,
        releaseTime: formattedDate,
        appstore:
          info.osVersion === "Android"
            ? addHttpsProtocol(values.Android)
            : addHttpsProtocol(values.iOS),
      };
    });
    const Promise0 = updateSales188Release({
      variables: {
        ...payloadObject[0],
      },
    });
    const Promise1 = updateSales188Release({
      variables: {
        ...payloadObject[1],
      },
    });
    Promise.all([Promise0, Promise1])
      .then(() => {
        window.location.reload();
      })
      .catch((e) => console.log(e));
  };
  const [form] = Form.useForm();
  return (
    <div id="sales188DevWrapper">
      <div className="dev-content-container">
        <div className="wrapper">
          <Form
            form={form}
            onFinish={onFinish}
            name="dev-version"
            onValuesChange={(one, all) => {
              if (!all.date) {
                form.setFieldsValue({
                  date: dayjs(),
                });
              }
              if (
                Object.values(all).some(
                  (value) => value === "" || value === null
                )
              ) {
                setSubmitEnabled(false);
              } else {
                setSubmitEnabled(true);
              }
            }}
            labelCol={{
              span: 6,
            }}
            wrapperCol={{
              span: 12,
            }}
          >
            <Form.Item
              label="Dev version number"
              name="number"
              rules={[requiredRule]}
            >
              <InputCustom placeholder={placeholderObject.number} />
            </Form.Item>
            <Form.Item label="Build date" name="date" rules={[requiredRule]}>
              <DatePicker defaultValue={dayjs()} />
            </Form.Item>
            <Form.Item label="iOS link" name="iOS" rules={[requiredRule]}>
              <InputCustom
                placeholder={placeholderObject.iOS}
                addonBefore="https://"
              />
            </Form.Item>
            <Form.Item
              label="Android link"
              name="Android"
              rules={[requiredRule]}
            >
              <InputCustom
                placeholder={placeholderObject.Android}
                addonBefore="https://"
              />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={!submitEnabled}
              className="submit-button"
            >
              Update
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};
export default Dev;
