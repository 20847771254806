import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateDefaultServiceBannerMutationVariables = Types.Exact<{
  seq: Types.Scalars['Int']['input'];
  image: Types.Scalars['String']['input'];
}>;


export type UpdateDefaultServiceBannerMutation = { __typename?: 'Mutation', updateDefaultServiceBanner: { __typename?: 'Payload', count: number, error?: Types.Error | null } };


export const UpdateDefaultServiceBannerDocument = gql`
    mutation UpdateDefaultServiceBanner($seq: Int!, $image: String!) {
  updateDefaultServiceBanner(parameter: {seq: $seq, image: $image}) {
    count
    error
  }
}
    `;
export type UpdateDefaultServiceBannerMutationFn = Apollo.MutationFunction<UpdateDefaultServiceBannerMutation, UpdateDefaultServiceBannerMutationVariables>;

/**
 * __useUpdateDefaultServiceBannerMutation__
 *
 * To run a mutation, you first call `useUpdateDefaultServiceBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDefaultServiceBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDefaultServiceBannerMutation, { data, loading, error }] = useUpdateDefaultServiceBannerMutation({
 *   variables: {
 *      seq: // value for 'seq'
 *      image: // value for 'image'
 *   },
 * });
 */
export function useUpdateDefaultServiceBannerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDefaultServiceBannerMutation, UpdateDefaultServiceBannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDefaultServiceBannerMutation, UpdateDefaultServiceBannerMutationVariables>(UpdateDefaultServiceBannerDocument, options);
      }
export type UpdateDefaultServiceBannerMutationHookResult = ReturnType<typeof useUpdateDefaultServiceBannerMutation>;
export type UpdateDefaultServiceBannerMutationResult = Apollo.MutationResult<UpdateDefaultServiceBannerMutation>;
export type UpdateDefaultServiceBannerMutationOptions = Apollo.BaseMutationOptions<UpdateDefaultServiceBannerMutation, UpdateDefaultServiceBannerMutationVariables>;