/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import { useEffect, useRef, useState } from "react";
import {
  Button,
  DatePicker,
  Drawer,
  Form,
  Image,
  Input,
  Modal,
  Radio,
  Select,
  Space,
  Table,
  // Upload,
  message,
} from "antd";
import type { ColumnType, ColumnsType } from "antd/es/table";
import type {
  InputRef,
  // UploadProps
} from "antd";
import {
  PlusOutlined,
  SearchOutlined,
  // UploadOutlined,
  LinkOutlined,
  PlayCircleFilled,
} from "@ant-design/icons";
import type { FilterConfirmProps } from "antd/es/table/interface";
import Highlighter from "react-highlight-words";
import dayjs from "dayjs";
import Player from "@vimeo/player";
import "./VideoTabContent.css";
import { Colors } from "../../../../constants/Colors";
import { data } from "../../../../data/data";
import {
  FaceCallVideoThemeTypes,
  NormalTagTypes,
} from "../../../../types/types";
import NormalTag from "../../../../components/tags/NormalTag";
import DrawerFooter from "../../../../components/layout/drawer/DrawerFooter";
import InputCustom from "../../../../components/custom/InputCustom";
import { useGetFaceCallVideosQuery } from "../../../../graphql/operations/get-facecall-videos";
import Spinner from "../../../../components/layout/spinner/Spinner";
import { renderDecodedId } from "../../../../utils/renderDecodedId";
import { useGetTeSongsQuery } from "../../../../graphql/operations/get-te-songs";
import { useGetBlobSasQuery } from "../../../../graphql/operations/get-blob-info";
import { useGetAllPerformersQuery } from "../../../../graphql/operations/get-all-performers";
import { useCreateFaceCallVideoMutation } from "../../../../graphql/operations/create-facecall-video";
import { useUpdateFaceCallVideoMutation } from "../../../../graphql/operations/update-facecall-video";
import { useReactiveVar } from "@apollo/client";
import { __currentUser__ } from "../../../../graphql/policies";
import { data as MapData } from "../../../../data/data";

const { faceCallVideosQueryTypes, faceCallVideoThemes } = data;

interface DataType {
  id: string;
  title: string;
  performer: string[];
  theme: FaceCallVideoThemeTypes;
  publishDate: string;
  stopDate: string;
  vimeoUrl: string;
  vimeoId: string;
  songAndPerformers: {
    faceCallSongAndPerformerId: string | undefined;
    faceCallPerformerId: string | undefined;
  }[];
}

type DataIndex = keyof DataType;

// const props: UploadProps = {
//   name: "file",
//   action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
//   headers: {
//     authorization: "authorization-text",
//   },
//   beforeUpload: (file) => {
//     const isVideo = file.type.startsWith("video");
//     if (!isVideo) {
//       message.error("Only upload video file!");
//     }
//     return isVideo || Upload.LIST_IGNORE;
//   },
//   onChange(info) {
//     if (info.file.status !== "uploading") {
//       console.log(info.file, info.fileList);
//     }
//     if (info.file.status === "done") {
//       message.success(`${info.file.name} file uploaded successfully`);
//     } else if (info.file.status === "error") {
//       message.error(`${info.file.name} file upload failed.`);
//     }
//   },
//   maxCount: 1,
// };

const renderThemeName = (themeId: number) => {
  switch (themeId) {
    case 0:
      return "Normal";
    case 1:
      return NormalTagTypes.CHRISTMAS;
    case 2:
      return NormalTagTypes.HALLOWEEN;
    case 3:
      return NormalTagTypes.NEW_YEAR;
    default:
      break;
  }
};

/**
 * Date validation for filter video display
 * @param holiday
 * @returns
 */
const isDateIncluded = (
  holiday: "Christmas" | "Halloween" | "NewYear"
): boolean => {
  const [startMonth, startDay, endMonth, endDay] =
    MapData.themeValidDate[holiday];
  let currentDate = dayjs();

  // Create the start and end dates
  let startDate = dayjs(`${startMonth}-${startDay}`, "MM-DD");
  let endDate = dayjs(`${endMonth}-${endDay}`, "MM-DD");

  // Use isSame function to check if current date is within the date range
  return (
    currentDate.isSame(startDate, "day") ||
    currentDate.isSame(endDate, "day") ||
    (currentDate.isAfter(startDate) && currentDate.isBefore(endDate))
  );
};

/**
 * Filter video to display by theme and DataValidation.
 * @param video
 * @returns
 */
function publishVideoFilter(video: DataType): unknown {
  // const isPublishDateExpired = dayjs(video.publishDate).valueOf() < dayjs().valueOf()
  // return (
  //   (video.theme === "Christmas" &&
  //     (isDateIncluded("Christmas") || !video.publishDate)) ||
  //   (video.theme === "Halloween" &&
  //     (isDateIncluded("Halloween") || !video.publishDate)) ||
  //   (video.theme === "New Year" &&
  //     (isDateIncluded("NewYear") || !video.publishDate)) ||
  //   (video.theme === "Normal" && video.publishDate)
  // );
  switch (video.theme) {
    case "Halloween":
      if (isDateIncluded("Halloween")) {
        return true;
      }
      return false;
    case "Christmas":
      if (isDateIncluded("Christmas")) {
        return true;
      }
      return false;
    case "New Year":
      if (isDateIncluded("NewYear")) {
        return true;
      }
      return false;
    case "Normal":
      if (
        video.publishDate &&
        dayjs().add(8, "hours").isAfter(dayjs(video.publishDate)) &&
        dayjs().isBefore(dayjs(video.stopDate))
      ) {
        return true;
      }
      return false;
    default:
      break;
  }
}

const VideoTabCOntent = ({ filterKey }) => {
  const [displayVideos, setDisplayVideos] = useState<DataType[]>([]);
  const [displayType, setDisplayType] = useState<number>(2); // 1 for All, 2 for Published, 3 for Unpublished
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [showCreateDrawer, setShowCreateDrawer] = useState(false);
  const [showEditDrawer, setShowEditDrawer] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState<DataType | undefined>();
  const [showPreview, setShowPreview] = useState(false);
  const [selectVideo, setSelectVideo] = useState<DataType | null>(null);
  const searchInput = useRef<InputRef>(null);
  const [createVideoForm] = Form.useForm();
  const [editVideoForm] = Form.useForm();
  const vimeoRef = useRef<any>();
  const { data, loading, error, refetch } = useGetFaceCallVideosQuery();
  const currentUser = useReactiveVar(__currentUser__);

  const {
    data: teSongs,
    loading: teSongsLoading,
    error: teSongsError,
  } = useGetTeSongsQuery();
  const {
    data: performers,
    loading: performersLoading,
    error: performersError,
  } = useGetAllPerformersQuery();
  const {
    data: blobInfo,
    loading: blobLoading,
    error: blobError,
  } = useGetBlobSasQuery();
  const [createVideo, { loading: createLoading }] =
    useCreateFaceCallVideoMutation();
  const [updateVideo, { loading: updateLoading }] =
    useUpdateFaceCallVideoMutation();

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  let performerConfirm: any;
  let setPerformerFilterKey: any;

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => {
      if (dataIndex === "performer") {
        performerConfirm = confirm;
        setPerformerFilterKey = setSelectedKeys;
      }
      return (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => {
                handleSearch(selectedKeys as string[], confirm, dataIndex);
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilters && handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Clear
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                close();
              }}
            >
              close
            </Button>
          </Space>
        </div>
      );
    },
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const filterOption: any = (
    input: string,
    option: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onCancel = () => {
    const player = new Player(vimeoRef.current);
    player.pause();
    setShowPreview(false);
    setSelectVideo(null);
  };

  const handlePreviewClick = (record: DataType) => {
    setShowPreview(true);
    setSelectVideo(record);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      render: (value: string) => renderDecodedId(value),
    },
    {
      title: "Song title",
      dataIndex: "title",
      key: "title",
      ...getColumnSearchProps("title"),
      render: (value: string, record: DataType) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <PlayCircleFilled
            style={{ marginRight: 8, fontSize: 20, cursor: "pointer" }}
            onClick={() => handlePreviewClick(record)}
          />
          <p>{value}</p>
        </div>
      ),
      sorter: (a, b) => a.title?.localeCompare(b.title),
    },
    {
      title: "Performer",
      dataIndex: "performer",
      key: "performer",
      ...getColumnSearchProps("performer"),
      render: (value) => <Space>{value}</Space>,
      sorter: (a, b) => a.performer[0]?.localeCompare(b.performer[0]),
    },
    {
      title: "Theme",
      dataIndex: "theme",
      key: "theme",
      render: (value: number, record: DataType) => {
        // if (record.isNewOfThisMonth) {
        //   return <NormalTag type={NormalTagTypes.NEW} />;
        // } else
        if (
          value === NormalTagTypes.CHRISTMAS ||
          value === NormalTagTypes.HALLOWEEN ||
          value === NormalTagTypes.NEW_YEAR
        ) {
          return <NormalTag type={value} />;
        }
      },
      filters: [
        {
          text: "Christmas",
          value: "Christmas",
        },
        {
          text: "Halloween",
          value: "Halloween",
        },
        {
          text: "New Year",
          value: "New Year",
        },
        // {
        //   text: "New",
        //   value: "New",
        // },
        {
          text: "Normal",
          value: "Normal",
        },
      ],
      onFilter: (value: any, record) => record.theme.indexOf(value) === 0,
    },
    {
      title: "Publish date",
      dataIndex: "publishDate",
      key: "publishDate",
      sorter: (a, b) =>
        dayjs(a.publishDate).valueOf() - dayjs(b.publishDate).valueOf(),
      render: (value: string) =>
        value ? dayjs(value).format("YYYY-MM-DD") : "",
      defaultSortOrder: "descend",
      sortOrder: displayType === 3 ? "ascend" : "descend",
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      width: 100,
      render: (_, record: DataType) => (
        <Button
          type="link"
          style={{ padding: 0 }}
          onClick={() => handleEditClick(record)}
        >
          Edit
        </Button>
      ),
    },
  ];

  const handleEditClick = (selectedVideo: DataType) => {
    setSelectedVideo(selectedVideo);
    setShowEditDrawer(true);
  };

  const handleCreateConfirm = (values: any) => {
    const selectedPerformers = values.performer.map((item) => ({
      faceCallPerformerId: parseInt(
        renderDecodedId(
          performers?.afFaceCallPerformer.find((p) => p.performerName === item)
            ?.id
        )
      ),
    }));
    const formData = {
      songId: parseInt(renderDecodedId(values.title)),
      performerId: selectedPerformers,
      vimeoId: values.vimeoId,
      themeId: values.theme ? values.theme : 0,
    };
    console.log(formData);
    createVideo({ variables: formData })
      .then(() => {
        refetch()
          .then(() => setShowCreateDrawer(false))
          .catch((e) => console.log(e));
      })
      .catch((e) => console.log(e));
  };

  const handleUpdateConfirm = (values: any) => {
    const songId = teSongs?.afTeSong.find(
      (song) => song.songName === selectedVideo?.title
    )?.id;
    const themeId = faceCallVideoThemes.find(
      (theme) => theme.name === selectedVideo?.theme
    )?.id;
    const selectedPerformers = selectedVideo?.songAndPerformers.map((item) => ({
      faceCallSongAndPerformerId: parseInt(
        renderDecodedId(item.faceCallSongAndPerformerId)
      ),
      faceCallPerformerId: parseInt(renderDecodedId(item.faceCallPerformerId)),
    }));
    const formData = {
      id: parseInt(renderDecodedId(selectedVideo?.id)),
      songId: parseInt(renderDecodedId(songId)),
      performerId: selectedPerformers,
      vimeoId: selectedVideo?.videoId,
      themeId: themeId ? themeId : 0,
      startDate: values.publishDate
        ? dayjs(values.publishDate).startOf("month").add(1, "day").toISOString()
        : null,
      endDate: values.stopDate ? dayjs(values.stopDate).toISOString() : null,
    };
    console.log(formData);
    updateVideo({ variables: formData })
      .then(() => {
        refetch()
          .then(() => setShowEditDrawer(false))
          .catch((e) => console.log(e));
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    if (filterKey) {
      setPerformerFilterKey([filterKey]);
      handleSearch([filterKey], performerConfirm, "performer");
    }
  }, [filterKey]);

  useEffect(() => {
    if (data) {
      const display = data.afFaceCallSong.map((video) => ({
        id: video.id,
        title: video.teSong.songName,
        performer: video.faceCallSongAndPerformers.map(
          (performer) => performer.faceCallPerformer.performerName
        ),
        theme: renderThemeName(video.faceCallSeasonHolidayId),
        publishDate: video.startDate,
        stopDate: video.endDate,
        vimeoUrl: `https://player.vimeo.com/video/${video.vimeoVideoId}`,
        videoId: video.vimeoVideoId,
        songAndPerformers: video.faceCallSongAndPerformers.map((performer) => ({
          faceCallSongAndPerformerId: performer?.id,
          faceCallPerformerId: performer?.faceCallPerformer?.id,
        })),
      }));
      if (displayType === 1) {
        // ALL
        setDisplayVideos(display);
      } else if (displayType === 2) {
        // In use
        const filteredData = display.filter(
          (video) => publishVideoFilter(video) && video.theme === "Normal"
        );
        setDisplayVideos(filteredData);
      } else if (displayType === 3) {
        // Upcoming
        const filteredData = display.filter(
          (video) =>
            video.publishDate &&
            dayjs(video.publishDate).isAfter(
              dayjs().startOf("month").add(1, "month")
            ) &&
            video.theme === "Normal"
        );
        setDisplayVideos(filteredData);
      } else if (displayType === 4) {
        // Theme
        const filteredData = display.filter(
          (video) => video.theme !== "Normal"
        );
        setDisplayVideos(filteredData);
      } else if (displayType === 5) {
        // Not in use
        const filteredData = display.filter(
          (video) =>
            (video.theme === "Normal" && !video.publishDate) ||
            (video.theme === "Normal" &&
              dayjs().isBefore(dayjs(video.publishDate)) &&
              dayjs().isAfter(dayjs(video.stopDate)))
        );
        setDisplayVideos(filteredData);
      }
    }
  }, [displayType, data]);
  useEffect(() => {
    if (selectedVideo) {
      editVideoForm.setFieldsValue({
        publishDate: selectedVideo.publishDate
          ? dayjs(selectedVideo.publishDate)
          : undefined,
        stopDate: selectedVideo.stopDate
          ? dayjs(selectedVideo.stopDate)
          : undefined,
      });
      if (selectedVideo.theme === FaceCallVideoThemeTypes.CHRISTMAS) {
        editVideoForm.setFieldsValue({
          publishDate: dayjs("2023-12-01"),
          stopDate: dayjs("2023-12-30"),
        });
      }
      if (selectedVideo.theme === FaceCallVideoThemeTypes.HALLOWEEN) {
        editVideoForm.setFieldsValue({
          publishDate: dayjs("2023-10-01"),
          stopDate: dayjs("2023-10-31"),
        });
      }
      if (selectedVideo.theme === FaceCallVideoThemeTypes.NEW_YEAR) {
        editVideoForm.setFieldsValue({
          publishDate: dayjs("2023-12-31"),
          stopDate: dayjs("2024-01-10"),
        });
      }
    }
  }, [selectedVideo, editVideoForm]);

  if (error || teSongsError || performersError || blobError) {
    message.error(
      renderAntDMessageConfig(
        "Something went wrong. Please contact your system administrator."
      )
    );
  }

  if (loading || teSongsLoading || performersLoading || blobLoading) {
    return <Spinner />;
  }

  return (
    <>
      <div className="tab-content-container">
        <div
          className="tab-content-inner-container"
          style={{ backgroundColor: Colors.white }}
        >
          <div className="face-call-video-header-container">
            <div style={{ display: "flex", alignItems: "center" }}>
              <Radio.Group
                optionType="button"
                options={faceCallVideosQueryTypes.map((type) => ({
                  label: type.typeName,
                  value: type.id,
                }))}
                defaultValue={2}
                onChange={(e) => setDisplayType(e.target.value)}
              />
              <p
                style={{ marginLeft: 16, color: Colors.blue, fontWeight: 900 }}
              >
                {searchText && `Search text: ${searchText}`}
              </p>
            </div>
            {currentUser?.isAdministrator ? (
              <div>
                <Button
                  type="primary"
                  style={{ marginLeft: 8 }}
                  icon={<PlusOutlined />}
                  onClick={() => setShowCreateDrawer(true)}
                >
                  Create New
                </Button>
              </div>
            ) : null}
          </div>
          <Table
            rowKey={(record: DataType) => record.id}
            columns={
              displayType === 4
                ? columns
                    .filter((col) =>
                      currentUser?.isAdministrator
                        ? true
                        : col.title !== "Action"
                    )
                    .filter((item) => item.title !== "Publish date")
                : columns
                    .filter((col) =>
                      currentUser?.isAdministrator
                        ? true
                        : col.title !== "Action"
                    )
                    .filter((item) => item.title !== "Theme")
            }
            dataSource={displayVideos}
            pagination={{ hideOnSinglePage: true, pageSize: 10 }}
          />
        </div>
      </div>
      <Drawer
        open={showCreateDrawer}
        onClose={() => {
          createVideoForm.resetFields();
          setShowCreateDrawer(false);
        }}
        title="Create new video"
        width={500}
      >
        <Form
          name="create-video-form"
          form={createVideoForm}
          onFinish={handleCreateConfirm}
          layout="vertical"
        >
          <Form.Item
            label="Song title"
            name="title"
            rules={[{ required: true, message: "This field is required!" }]}
          >
            <Select
              showSearch
              placeholder="- Select -"
              optionFilterProp="children"
              filterOption={filterOption}
              options={teSongs?.afTeSong.map((song) => ({
                value: song.id,
                label: song.songName,
              }))}
              style={{ marginTop: 8 }}
            />
          </Form.Item>
          <Form.Item
            label="Performer"
            name="performer"
            rules={[{ required: true, message: "This field is required!" }]}
          >
            <Select
              showSearch
              placeholder="- Select -"
              optionFilterProp="children"
              filterOption={filterOption}
              style={{ marginTop: 8 }}
              optionLabelProp="label"
              mode="multiple"
              allowClear
            >
              {performers?.afFaceCallPerformer.map((performer) => (
                <Select.Option
                  key={performer.id}
                  value={performer.performerName}
                  label={performer.performerName}
                >
                  <div
                    style={{
                      display: "flex",
                      height: 94,
                      alignItems: "center",
                    }}
                  >
                    <Image
                      src={`${blobInfo?.afBlobContainerSignature[1].uri}/${performer.avatarFileName}${blobInfo?.afBlobContainerSignature[1].token}`}
                      style={{ width: 84, height: "auto", marginRight: 8 }}
                    />
                    <p>{performer.performerName}</p>
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Theme" name="theme">
            <Select
              placeholder="- Select -"
              options={faceCallVideoThemes.map((theme) => ({
                label: theme.name,
                value: theme.id,
              }))}
              style={{ marginTop: 8 }}
            />
          </Form.Item>
          <Form.Item
            label="Vimeo ID"
            name="vimeoId"
            rules={[{ required: true, message: "This field is required!" }]}
          >
            <InputCustom />
          </Form.Item>
          {/* <p style={{ margin: "-18px 0 24px", color: Colors.textSecondary }}>
            Unpublished the song if the theme has expired
          </p> */}
          {/* <Form.Item
            label="Video file"
            name="videoFile"
            rules={[{ required: true, message: "This field is required!" }]}
            valuePropName="fileList"
          >
            <Upload {...props}>
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item> */}
          <DrawerFooter
            handleCancel={() => {
              createVideoForm.resetFields();
              setShowCreateDrawer(false);
            }}
            isLoading={createLoading}
          />
        </Form>
      </Drawer>
      <Drawer
        open={showEditDrawer}
        onClose={() => {
          editVideoForm.resetFields();
          setSelectedVideo(undefined);
          setShowEditDrawer(false);
        }}
        title="Edit video"
        width={500}
      >
        <Form
          name="edit-video-form"
          form={editVideoForm}
          onFinish={handleUpdateConfirm}
          layout="vertical"
        >
          <Form.Item label="Song title" name="title">
            <p style={{ color: Colors.notEditableText }}>
              {selectedVideo?.title}
            </p>
          </Form.Item>
          <Form.Item label="Performer" name="performer">
            <p style={{ color: Colors.notEditableText }}>
              {selectedVideo?.performer.map((item, i) => {
                if (i === selectedVideo?.performer.length - 1) {
                  return item;
                }
                return `${item}, `;
              })}
            </p>
          </Form.Item>
          <Form.Item label="Theme" name="theme">
            <p style={{ color: Colors.notEditableText }}>
              {selectedVideo?.theme}
            </p>
          </Form.Item>
          <p style={{ margin: "-18px 0 24px", color: Colors.textSecondary }}>
            Unpublished the song if the theme has expired
          </p>
          <Form.Item
            label="Video link"
            name="videoFile"
            valuePropName="fileList"
          >
            <Button type="link" icon={<LinkOutlined />}>
              {selectedVideo?.vimeoUrl}
            </Button>
          </Form.Item>
          <Form.Item label="Publish Date" name="publishDate">
            {selectedVideo?.theme === FaceCallVideoThemeTypes.CHRISTMAS ||
            selectedVideo?.theme === FaceCallVideoThemeTypes.HALLOWEEN ||
            selectedVideo?.theme === FaceCallVideoThemeTypes.NEW_YEAR ? (
              <p style={{ color: Colors.notEditableText }}>
                {selectedVideo?.publishDate}
              </p>
            ) : (
              <DatePicker
                style={{ marginTop: 8, width: "100%" }}
                placeholder="Select month"
                picker="month"
                disabledDate={(current) =>
                  current && current < dayjs().endOf("day")
                }
                format="YYYY-MM-01"
                className="month-picker"
              />
            )}
          </Form.Item>
          {(selectedVideo?.theme === FaceCallVideoThemeTypes.NONE ||
            selectedVideo?.theme === FaceCallVideoThemeTypes.NEW) && (
            <p
              style={{
                margin: "-18px 0 24px",
                color: Colors.textSecondary,
                lineHeight: 1.2,
              }}
            >
              Only 3 videos can be published per month, except theme songs
            </p>
          )}
          <Form.Item label="Stop date" name="stopDate">
            {selectedVideo?.theme === FaceCallVideoThemeTypes.CHRISTMAS ||
            selectedVideo?.theme === FaceCallVideoThemeTypes.HALLOWEEN ||
            selectedVideo?.theme === FaceCallVideoThemeTypes.NEW_YEAR ? (
              <p style={{ color: Colors.notEditableText }}>
                {selectedVideo?.stopDate}
              </p>
            ) : (
              <DatePicker
                style={{ marginTop: 8, width: "100%" }}
                placeholder="Select date"
                disabledDate={(current) =>
                  current &&
                  current < dayjs(selectedVideo?.publishDate).endOf("day")
                }
                defaultPickerValue={dayjs(selectedVideo?.publishDate)}
              />
            )}
          </Form.Item>
          <DrawerFooter
            disabled={
              selectedVideo?.theme === FaceCallVideoThemeTypes.CHRISTMAS ||
              selectedVideo?.theme === FaceCallVideoThemeTypes.HALLOWEEN ||
              selectedVideo?.theme === FaceCallVideoThemeTypes.NEW_YEAR
            }
            handleCancel={() => {
              editVideoForm.resetFields();
              setSelectedVideo(undefined);
              setShowEditDrawer(false);
            }}
            isLoading={updateLoading}
          />
        </Form>
      </Drawer>
      <Modal
        open={showPreview}
        onCancel={onCancel}
        title={selectVideo?.title}
        footer={null}
        width={410}
        forceRender
      >
        {selectVideo && (
          <iframe
            ref={vimeoRef}
            src={selectVideo.vimeoUrl}
            width="360"
            height="640"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen={false}
            title={selectVideo.title}
          ></iframe>
        )}
      </Modal>
    </>
  );
};

export default VideoTabCOntent;
