// @ts-nocheck
import { Dispatch, SetStateAction, useEffect, useState, useRef } from "react";
import { useReactiveVar } from "@apollo/client";
import { Colors } from "../../../../constants/Colors";
import { Button, Form, Radio, Select } from "antd";
import "./campaignId.scss";
import {
  __currentUser__,
  __jwtToken__,
  __projectKey__,
} from "../../../../graphql/policies";
import { ServiceTypes } from "../../../../types/types";
import { useGetUserProgramsByServiceQuery } from "../../../../graphql/operations/get-user-program-by-service";
import { renderDecodedId } from "../../../../utils/renderDecodedId";
import Spinner from "../../../../components/layout/spinner/Spinner";
import NoPermission from "../../../../components/others/NoPermission";
import requiredRule from "../../../../utils/formRules/requiredRule";
import { useGetTrialCampaignChannelQuery } from "../../../../graphql/operations/trialMode/dataAnalysis/get-trial-campaign-channel";
import { useGetTrialCampaignChannelUrlQuery } from "../../../../graphql/operations/trialMode/dataAnalysis/get-trial-campaign-channel-url";
import QRCode from "qrcode";

interface CampaignIdProps {
  setSelectedService: Dispatch<SetStateAction<ServiceTypes>>;
  setSelectSubItem: Dispatch<SetStateAction<string | undefined>>;
}
interface CampaignListItemType {
  __typename?: string;
  channel?: string;
}

const CampaignId = ({
  setSelectedService,
  setSelectSubItem,
}: CampaignIdProps) => {
  const currentUser = useReactiveVar(__currentUser__);
  const jwtToken = useReactiveVar(__jwtToken__);
  const projectKey = useReactiveVar(__projectKey__);
  const [channelList, setChannelList] = useState<CampaignListItemType[] | any>(
    []
  );
  const [market, setMarket] = useState("");
  const [singleMarket, setSingleMarket] = useState(false);
  const [channel, setChannel] = useState("Instagram");
  const [device, setDevice] = useState("");
  const [url, setUrl] = useState("");
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [form] = Form.useForm();
  const { data } = useGetTrialCampaignChannelQuery();
  const { data: urlData } = useGetTrialCampaignChannelUrlQuery({
    variables: {
      market: market,
      channel: channel,
      device: device,
    },
  });
  const {
    data: userPrograms,
    loading: userProgramsLoading,
    error: userProgramsError,
  } = useGetUserProgramsByServiceQuery({
    variables: {
      userId: parseInt(renderDecodedId(currentUser?.id)),
      serviceId: 8,
    },
  });
  const downloadLinkRef = useRef(null);
  const handleDownloadClick = () => {
    if (downloadLinkRef.current) {
      downloadLinkRef.current.href = qrCodeUrl;
      downloadLinkRef.current.download = "qrcode.png";
      downloadLinkRef.current.click();
    }
  };
  const handleGenerate = () => {
    setUrl(urlData?.trialCampaignChannelUrl[0].url);
  };
  useEffect(() => {
    setSelectedService(ServiceTypes.TRIAL_MODE);
    setSelectSubItem("Campaign ID");
  }, []);
  useEffect(() => {
    if (url) {
      const generateQrCode = async () => {
        try {
          const qrCodeDataUrl = await QRCode.toDataURL(url);
          setQrCodeUrl(qrCodeDataUrl);
        } catch (err) {
          console.error("Failed to generate QR code:", err);
        }
      };
      generateQrCode();
    }
  }, [url]);
  useEffect(() => {
    if (data) {
      setChannelList(
        data?.trialCampaignChannel.map((item) => {
          return {
            label: item.channel,
            value: item.channel,
          };
        })
      );
    }
  }, [data]);
  useEffect(() => {
    if (currentUser?.afUserMarkets?.length === 1) {
      setSingleMarket(true);
      setMarket(currentUser?.afUserMarkets[0]?.market);
    }
  }, [currentUser]);

  if (userProgramsError) {
    message.error(
      renderAntDMessageConfig(
        "Something went wrong. Please contact your system administrator."
      )
    );
  }

  if (userProgramsLoading) {
    return <Spinner />;
  }

  if (
    userPrograms &&
    !userPrograms.wf_adminFirst_afUserProgram?.edges?.find(
      (item) => item.node.afProgram?.programName === "Campaign ID"
    )
  ) {
    return <NoPermission />;
  }

  return (
    <div id="campaignIdWrapper">
      <div className="header" style={{ backgroundColor: Colors.white }}>
        <p className="header-title" style={{ color: Colors.darkGrey }}>
          Campaign ID
        </p>
      </div>
      <div className="content-container">
        <Form form={form} onFinish={handleGenerate} name="campaignId">
          <Form.Item label="Market" name="market" required={[requiredRule]}>
            <Radio.Group
              options={[
                { label: "HK", value: "HK" },
                { label: "KR", value: "KR" },
                { label: "TW", value: "TW" },
              ]}
              onChange={(e) => {
                setMarket(e.target.value);
              }}
              disabled={singleMarket}
              defaultValue={market}
              optionType="button"
            />
          </Form.Item>
          <Form.Item
            label="Campaign channels"
            name="channel"
            required={[requiredRule]}
          >
            <Select
              options={channelList}
              defaultValue={channel}
              onChange={(e) => {
                setChannel(e);
              }}
            />
          </Form.Item>
          <Form.Item label="Device" name="device" required={[requiredRule]}>
            <Radio.Group
              options={[
                { label: "iOS", value: "iOS" },
                { label: "Android", value: "Android" },
                { label: "Both", value: "both" },
              ]}
              onChange={(e) => {
                setDevice(e.target.value);
              }}
              optionType="button"
            />
          </Form.Item>
          <Form.Item>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              className="generate-button"
              disabled={!market || !device || !channel}
            >
              Generate QR code
            </Button>
          </Form.Item>
          {url && (
            <Form.Item label="Campaign ID">
              <div className="id-box">
                <p className="text-blue">{url}</p>
                <div
                  onClick={() => {
                    navigator.clipboard.writeText(url);
                  }}
                >
                  <img
                    alt="copy icon"
                    src={require("../../../../assets/icons/copy.png")}
                  />
                </div>
              </div>
            </Form.Item>
          )}
          {url && (
            <Form.Item label="QR code">
              <div className="qrcode-box">
                <img src={qrCodeUrl} alt="QR Code" className="qrcode" />
                <Button
                  size="large"
                  className="download-button"
                  onClick={handleDownloadClick}
                >
                  Download QR code
                </Button>
                <a ref={downloadLinkRef} className="d-none">
                  Download
                </a>
              </div>
            </Form.Item>
          )}
        </Form>
      </div>
    </div>
  );
};

export default CampaignId;
