/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button, Spin, Table, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useReactiveVar } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import "./Notification.css";
import "./components/NotificationList.css";
import { NormalTagTypes, ServiceTypes } from "../../../../types/types";
import { Colors } from "../../../../constants/Colors";
import { __currentUser__ } from "../../../../graphql/policies";
import { useGetNotificationsByMarketQuery } from "../../../../graphql/operations/get-notifications-by-market";
import { useDeleteNotificationMutation } from "../../../../graphql/operations/delete-notification";
import { renderDecodedId } from "../../../../utils/renderDecodedId";
import { renderMarketValue } from "../../../../utils/renderMarketValue";
import { useGetNotificationsQuery } from "../../../../graphql/operations/get-notifications";
import Spinner from "../../../../components/layout/spinner/Spinner";
import { renderNotificationTypeName } from "../../../../utils/renderNotificationTypeName";
import { useGetUserProgramsByServiceQuery } from "../../../../graphql/operations/get-user-program-by-service";
import NoPermission from "../../../../components/others/NoPermission";
import NormalTag from "../../../../components/tags/NormalTag";
import ContentTitle from "../../../../components/ui/ContentTitle";
import MarketDropdown from "../../../../components/ui/MarketDropdown";
import getInitialMarket from "../../../../utils/getInitialMarket";

interface NotificationProps {
  setSelectedService: Dispatch<SetStateAction<ServiceTypes>>;
  setSelectSubItem: Dispatch<SetStateAction<string | undefined>>;
}

interface DataType {
  __typename?: "WF_AdminFirst_AfNotification";
  id: string;
  templateMessage?: string | null;
  title?: string | null;
  actionButton?: string | null;
  buttonLinksTo?: string | null;
  buttonText?: string | null;
  createdTime?: any | null;
  creator?: string | null;
  imageFileName?: string | null;
  isRegular?: boolean | null;
  market?: string | null;
  afNotificationServiceTypeId?: string | null;
  scheduleTime?: any | null;
  jobStatus?: string | null;
}

const Notification = ({
  setSelectedService,
  setSelectSubItem,
}: NotificationProps) => {
  const currentUser = useReactiveVar(__currentUser__);
  const [selectedMarket, setSelectedMarket] = useState<MarketType>(
    getInitialMarket()
  );
  const [displayData, setDisplayData] = useState([]);
  const [marketData, setMarketData] = useState([]);
  const [selectedId, setSelectedId] = useState<string | undefined>();
  const { data, loading, error, refetch } = useGetNotificationsByMarketQuery({
    variables: {
      market: currentUser?.afUserMarkets[0]?.market,
    },
    fetchPolicy: "no-cache",
    pollInterval: 1000 * 10,
  });
  const {
    data: allNotifications,
    loading: allLoading,
    error: allError,
    refetch: allRefetch,
  } = useGetNotificationsQuery({
    fetchPolicy: "no-cache",
    pollInterval: 1000 * 10,
  });
  const [deleteNotification, { loading: deleteLoading }] =
    useDeleteNotificationMutation();
  const {
    data: userPrograms,
    loading: userProgramsLoading,
    error: userProgramsError,
  } = useGetUserProgramsByServiceQuery({
    variables: {
      userId: parseInt(renderDecodedId(currentUser?.id)),
      serviceId: 2,
    },
  });
  const navigate = useNavigate();

  const handleEditClick = (record: DataType) => {
    navigate(
      `/club-app/push-notification/${renderDecodedId(record.id)}/detail`
    );
  };

  const handleDeleteClick = (record: DataType) => {
    setSelectedId(record.id);
    deleteNotification({
      variables: {
        notification: {
          market: renderMarketValue(record.market),
          notificationId: parseInt(renderDecodedId(record.id)),
        },
      },
    })
      .then(() => {
        if (currentUser?.afUserMarkets?.length > 1) {
          allRefetch();
        } else {
          refetch();
        }
      })
      .catch((e) => console.log(e));
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Service type",
      dataIndex: "afNotificationServiceTypeId",
      key: "afNotificationServiceTypeId",
      width: 160,
      render: (value: string) => renderNotificationTypeName(value),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      ellipsis: true,
    },
    {
      title: "Push Date and time",
      dataIndex: "scheduleTime",
      key: "scheduleTime",
      render: (value: string, record: DataType) =>
        value
          ? dayjs(value).format("YYYY-MM-DD HH:mm")
          : dayjs(record.createdTime).format("YYYY-MM-DD HH:mm"),
      sorter: (a, b) => {
        if (a.scheduleTime && b.scheduleTime) {
          return (
            dayjs(a.scheduleTime).valueOf() - dayjs(b.scheduleTime).valueOf()
          );
        } else if (!a.scheduleTime && !b.scheduleTime) {
          return (
            dayjs(a.createdTime).valueOf() - dayjs(b.createdTime).valueOf()
          );
        } else if (!a.scheduleTime && b.scheduleTime) {
          return (
            dayjs(a.createdTime).valueOf() - dayjs(b.scheduleTime).valueOf()
          );
        } else if (a.scheduleTime && !b.scheduleTime) {
          return (
            dayjs(a.scheduleTime).valueOf() - dayjs(b.createdTime).valueOf()
          );
        }
      },
      width: 250,
      defaultSortOrder: "descend",
    },
    {
      title: "Status",
      dataIndex: "jobStatus",
      key: "jobStatus",
      render: (value: string, record: DataType) => {
        if (value === "pending") {
          return <Spin size="small" />;
        }
        if (dayjs(record.scheduleTime).valueOf() > dayjs().valueOf()) {
          return <NormalTag type={NormalTagTypes.PENDING} />;
        }
        return <NormalTag type={NormalTagTypes.SUCCESS} />;
      },
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      width: 160,
      render: (_, record: DataType) => (
        <>
          <Button
            type="link"
            style={{ padding: 0 }}
            onClick={() => handleEditClick(record)}
            disabled={record.jobStatus === "pending"}
          >
            View
          </Button>
          {record.scheduleTime &&
            dayjs(record.scheduleTime).valueOf() > dayjs().valueOf() && (
              <Button
                type="link"
                style={{ padding: 0, marginLeft: 16 }}
                onClick={() => handleDeleteClick(record)}
                loading={deleteLoading && record.id === selectedId}
                disabled={record.jobStatus === "pending"}
              >
                Delete
              </Button>
            )}
        </>
      ),
    },
  ];

  useEffect(() => {
    setSelectedService(ServiceTypes.CLUB_APP);
    setSelectSubItem("Push Notification");
  }, []);

  useEffect(() => {
    if (currentUser && data && allNotifications) {
      console.log(currentUser.afUserMarkets?.length);
      if (currentUser.afUserMarkets?.length > 1) {
        setDisplayData(
          allNotifications.wf_adminFirst_afNotification?.edges
            ?.map((item) => item.node)
            .filter((el) => !el.title?.startsWith("[TEST]"))
        );
      } else {
        setDisplayData(
          data.wf_adminFirst_afNotification?.edges
            ?.map((item) => item.node)
            .filter((el) => !el.title?.startsWith("[TEST]"))
        );
      }
    }
  }, [currentUser, data, allNotifications]);

  useEffect(() => {
    setMarketData(
      displayData.filter(({ market }) => market === selectedMarket)
    );
  }, [displayData, selectedMarket]);

  useEffect(() => {
    if (userPrograms) {
      console.log(userPrograms.wf_adminFirst_afUserProgram?.edges);
    }
  }, [userPrograms]);

  if (error || allError || userProgramsError) {
    message.error(
      renderAntDMessageConfig(
        "Something went wrong. Please contact your system administrator."
      )
    );
  }

  if (loading || allLoading || userProgramsLoading) {
    return <Spinner />;
  }

  if (
    userPrograms &&
    !userPrograms.wf_adminFirst_afUserProgram?.edges?.find(
      (item) => item.node.afProgram?.programName === "Notification"
    )
  ) {
    return <NoPermission />;
  }

  return (
    <div>
      <ContentTitle
        title="Push Notification"
        guideUrl="https://wfeinnovation.atlassian.net/wiki/x/R4ClVg"
      />

      <div style={{ padding: 20 }}>
        <div className="search-container">
          <MarketDropdown
            selectedMarket={selectedMarket}
            setSelectedMarket={setSelectedMarket}
          />

          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => navigate("/club-app/push-notification/create")}
          >
            Create New
          </Button>
        </div>
        <div style={{ padding: 20, backgroundColor: Colors.white }}>
          <Table
            rowKey={(record: DataType) => record.id}
            columns={columns}
            dataSource={marketData}
            pagination={{ hideOnSinglePage: true }}
          />
        </div>
      </div>
    </div>
  );
};

export default Notification;
