import { ButtonLinkType, TemplateType } from "./types";

type CTADefaultDataType = {
  [segmentation: string]: {
    [experience: string]: {
      template: TemplateType;
      buttonLinksTo: ButtonLinkType;
    };
  };
};

export const CTADefaultData: CTADefaultDataType = {
  Awareness: {
    "Try to re-listen unlocked songs": {
      template: "Image",
      buttonLinksTo: "Lead page",
    },
    "Try to listen locked songs": {
      template: "Image",
      buttonLinksTo: "Lead page",
    },
    "Try to rewatch unlocked videos": {
      template: "Image",
      buttonLinksTo: "Demo page",
    },
    "After preview locked videos 3 times": {
      template: "Image",
      buttonLinksTo: "Demo page",
    },
    "After playing Magic Pen once": {
      template: "Feature",
      buttonLinksTo: "Demo page",
    },
    "After playing Talk Along Card once": {
      template: "Video",
      buttonLinksTo: "Demo page",
    },
    "After playing Q&A Card once": {
      template: "Feature",
      buttonLinksTo: "Demo page",
    },
    "Remain still for 10 seconds": {
      template: "Image",
      buttonLinksTo: "Lead page",
    },
  },
  Interest: {
    "Try to listen locked songs": {
      template: "Photo",
      buttonLinksTo: "Demo page",
    },
    "After preview locked videos 3 times": {
      template: "Photo",
      buttonLinksTo: "Demo page",
    },
    "After playing Magic Pen 3 times": {
      template: "Feature",
      buttonLinksTo: "Demo page",
    },
    "After playing Talk Along Card 3 times": {
      template: "Video",
      buttonLinksTo: "Demo page",
    },
    "After playing Q&A Card 3 times": {
      template: "Feature",
      buttonLinksTo: "Demo page",
    },
    "Remain still for 10 seconds": {
      template: "Photo",
      buttonLinksTo: "Demo page",
    },
  },
  Decision: {
    "Booked demo after 20 days": {
      template: "Video",
      buttonLinksTo: "Demo page",
    },
    "Booked demo after 25 days": {
      template: "Video",
      buttonLinksTo: "Demo page",
    },
    "Booked demo after 29 days": {
      template: "Video",
      buttonLinksTo: "Demo page",
    },
  },
};

export const CTADefaultContentData = {
  Image: {
    ctaTitle: "Interested?",
    ctaContent:
      "Do you know babies have a secret superpower—the ability to learn any language in the world? Register to enjoy the online experience",
  },
  Photo: {
    ctaTitle: "Interested?",
    ctaContent:
      "Do you know there are 1,000,000 World Family kids in the world?",
  },
  Video: {
    ctaTitle: "Interested?",
    ctaContent: "Fill out the form to get invited and play more!",
  },
  Feature: {
    ctaTitle: "Did you notice?",
    ctaContent:
      "Regardless of whether the answers are correct or not, the cards will provide responses and hints, encouraging children to keep trying.",
  },
};
