// @ts-nocheck
/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useReactiveVar } from "@apollo/client";
import { Button, Form, DatePicker } from "antd";
import fileDownload from "js-file-download";
import "./leadsDetailDownload.scss";
import {
  __currentUser__,
  __jwtToken__,
  __projectKey__,
} from "../../../../graphql/policies";
import { useGetUserProgramsByServiceQuery } from "../../../../graphql/operations/get-user-program-by-service";
import { renderDecodedId } from "../../../../utils/renderDecodedId";
import Spinner from "../../../../components/layout/spinner/Spinner";
import NoPermission from "../../../../components/others/NoPermission";
import { Colors } from "../../../../constants/Colors";
import { ServiceTypes } from "../../../../types/types";
import { apiCall } from "../../../../utils/apiCall";
import requiredRule from "../../../../utils/formRules/requiredRule";
import MarketDropdown from "../../../../components/ui/MarketDropdown";

interface LeadsDetailDownloadProps {
  setSelectedService: Dispatch<SetStateAction<ServiceTypes>>;
  setSelectSubItem: Dispatch<SetStateAction<string | undefined>>;
}

const LeadsDetailDownload = ({
  setSelectedService,
  setSelectSubItem,
}: LeadsDetailDownloadProps) => {
  const currentUser = useReactiveVar(__currentUser__);
  const jwtToken = useReactiveVar(__jwtToken__);
  const projectKey = useReactiveVar(__projectKey__);
  const [selectedMarket, setSelectedMarket] = useState<MarketType | any>();
  const [dateRange, setDateRange] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [downloadEnabled, setDownloadEnabled] = useState(false);
  const {
    data: userPrograms,
    loading: userProgramsLoading,
    error: userProgramsError,
  } = useGetUserProgramsByServiceQuery({
    variables: {
      userId: parseInt(renderDecodedId(currentUser?.id)),
      serviceId: 8,
    },
  });
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;
  const handleDownload = async () => {
    setIsLoading(true);
    try {
      const downloadPromise = async () => {
        const endPoint = process.env.REACT_APP_LEADS_DETAIL?.replace(
          "{market}",
          selectedMarket
        )
          .replace("{startDate}", dateRange[0])
          .replace("{endDate}", dateRange[1]);
        const header = {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "X-Project-Id": "AF",
            "X-Authorization-Provider": "Anonymous",
            "X-Project-Key": projectKey,
          },
          responseType: "blob",
        };
        console.log(endPoint, header);
        try {
          const data = await apiCall({
            method: "GET",
            header: header,
            endPoint: endPoint,
          });
          fileDownload(data, `leads-detail.xlsx`);
          return data;
        } catch (error) {
          console.log(error);
          throw error; // Re-throw error to be caught by Promise.all
        }
      };
      downloadPromise()
        .then(() => {
          setIsLoading(false);
        })
        .catch((error) => {
          console.log("error: ", error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      const startDate = dates[0].format("YYYY-MM-DD");
      const endDate = dates[1].format("YYYY-MM-DD");
      setDateRange([startDate, endDate]);
    }
  };
  useEffect(() => {
    if (dateRange.length > 0) {
      setDownloadEnabled(true);
    }
  }, [dateRange]);

  useEffect(() => {
    if (currentUser?.afUserMarkets && currentUser?.afUserMarkets[0]?.market) {
      if (currentUser?.afUserMarkets.length === 1) {
        setSelectedMarket(currentUser?.afUserMarkets[0]?.market);
      } else {
        setSelectedMarket("TW");
      }
    }
  }, [currentUser]);

  useEffect(() => {
    setSelectedService(ServiceTypes.TRIAL_MODE);
    setSelectSubItem("Leads Detail Download");
  }, []);

  if (userProgramsError) {
    message.error(
      renderAntDMessageConfig(
        "Something went wrong. Please contact your system administrator."
      )
    );
  }

  if (userProgramsLoading) {
    return <Spinner />;
  }

  if (
    userPrograms &&
    !userPrograms.wf_adminFirst_afUserProgram?.edges?.find(
      (item) => item.node.afProgram?.programName === "Leads Detail Download"
    )
  ) {
    return <NoPermission />;
  }

  return (
    <div id="leadsDetailDownloadWrapper">
      <div className="header" style={{ backgroundColor: Colors.white }}>
        <p className="header-title" style={{ color: Colors.darkGrey }}>
          Leads Detail Download
        </p>
      </div>
      <div className="content-container">
        <div
          className="market-container"
          style={
            currentUser?.afUserMarkets?.length === 1 ? { display: "none" } : {}
          }
        >
          <div />
          <MarketDropdown
            selectedMarket={selectedMarket}
            setSelectedMarket={setSelectedMarket}
          />
        </div>

        <Form form={form} onFinish={handleDownload}>
          <Form.Item
            label="Data date range"
            name="dateRange"
            required={[requiredRule]}
          >
            <RangePicker
              className="date-range-picker"
              onChange={handleDateChange}
            />
          </Form.Item>
          <Form.Item>
            <div className="row">
              <div className="spacer" />
              <div className="button-container">
                <Button
                  htmlType="submit"
                  type="primary"
                  style={{ marginRight: 8 }}
                  loading={isLoading}
                  disabled={!downloadEnabled}
                >
                  Download report
                </Button>
                <p className="text-container">
                  After clicking the download button, the Excel file will
                  download automatically.
                </p>
              </div>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default LeadsDetailDownload;
