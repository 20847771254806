import * as Types from '../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateSales188ReleaseMutationVariables = Types.Exact<{
  seq: Types.Scalars['Short']['input'];
  version?: Types.InputMaybe<Types.Scalars['String']['input']>;
  osVersion?: Types.InputMaybe<Types.Scalars['String']['input']>;
  appVersion?: Types.InputMaybe<Types.Scalars['String']['input']>;
  appstore?: Types.InputMaybe<Types.Scalars['String']['input']>;
  releaseTime?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
}>;


export type UpdateSales188ReleaseMutation = { __typename?: 'Mutation', updateSales188Release: { __typename?: 'Payload', count: number } };


export const UpdateSales188ReleaseDocument = gql`
    mutation UpdateSales188Release($seq: Short!, $version: String, $osVersion: String, $appVersion: String, $appstore: String, $releaseTime: DateTime) {
  updateSales188Release(
    seq: $seq
    version: $version
    osVersion: $osVersion
    appVersion: $appVersion
    appstore: $appstore
    releaseTime: $releaseTime
  ) {
    count
  }
}
    `;
export type UpdateSales188ReleaseMutationFn = Apollo.MutationFunction<UpdateSales188ReleaseMutation, UpdateSales188ReleaseMutationVariables>;

/**
 * __useUpdateSales188ReleaseMutation__
 *
 * To run a mutation, you first call `useUpdateSales188ReleaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSales188ReleaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSales188ReleaseMutation, { data, loading, error }] = useUpdateSales188ReleaseMutation({
 *   variables: {
 *      seq: // value for 'seq'
 *      version: // value for 'version'
 *      osVersion: // value for 'osVersion'
 *      appVersion: // value for 'appVersion'
 *      appstore: // value for 'appstore'
 *      releaseTime: // value for 'releaseTime'
 *   },
 * });
 */
export function useUpdateSales188ReleaseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSales188ReleaseMutation, UpdateSales188ReleaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSales188ReleaseMutation, UpdateSales188ReleaseMutationVariables>(UpdateSales188ReleaseDocument, options);
      }
export type UpdateSales188ReleaseMutationHookResult = ReturnType<typeof useUpdateSales188ReleaseMutation>;
export type UpdateSales188ReleaseMutationResult = Apollo.MutationResult<UpdateSales188ReleaseMutation>;
export type UpdateSales188ReleaseMutationOptions = Apollo.BaseMutationOptions<UpdateSales188ReleaseMutation, UpdateSales188ReleaseMutationVariables>;