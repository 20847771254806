import { Children } from "react";
import { Button } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

export default function Carousel({
  children,
  index,
  setIndex,
}: {
  children: React.ReactNode;
  index: number;
  setIndex: any;
}) {
  const length = Children.count(children);

  const onPrev = () => setIndex((index - 1 + length) % length);
  const onNext = () => setIndex((index + 1) % length);

  return (
    <div className="w-[462px] mb-4">
      <div className="flex items-center justify-center gap-4">
        <div className="relative w-[462px] h-full overflow-hidden">
          <div
            className="relative h-full flex rounded-lg ease-linear duration-300"
            style={{ transform: `translate(-${100 * index}%)` }}
          >
            {children}
          </div>
        </div>
      </div>

      {length > 1 ? (
        <div className="h-6 mt-4 flex justify-between items-center">
          <p>{`Showing ${index + 1} of ${length}`}</p>

          <div className="flex gap-2">
            <Button
              shape="circle"
              size="small"
              icon={<LeftOutlined />}
              onClick={onPrev}
              disabled={index === 0}
            />
            <Button
              shape="circle"
              size="small"
              icon={<RightOutlined />}
              onClick={onNext}
              disabled={index === length - 1}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}
