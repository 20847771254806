import ContentTitle from "../../../components/ui/ContentTitle";
import TabsCustom from "../../../components/custom/TabsCustom";
import withUserPrograms from "../withUserPrograms";
import FeaturedEvents from "./featuredEvents/FeaturedEvents";
import RegularEvents from "./regularEvents/RegularEvents";

function LocalEvent() {
  return (
    <div>
      <ContentTitle title="Local Event" />

      <TabsCustom
        items={[
          {
            key: "1",
            label: "Featured Events",
            children: <FeaturedEvents />,
          },
          {
            key: "2",
            label: "Regular Events",
            children: <RegularEvents />,
          },
        ]}
      />
    </div>
  );
}

export default withUserPrograms(LocalEvent, "Local Event");
