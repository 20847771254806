import React, { createContext } from "react";
import { Button, FormInstance, Modal } from "antd";
import { SwapRightOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

import Spinner from "../../../../components/layout/spinner/Spinner";
import { useDeleteFeaturedEventMutation } from "../../../../graphql/operations/trialMode/localEvent/delete-featured-event";
import { useDeleteRegularEventMutation } from "../../../../graphql/operations/trialMode/localEvent/delete-regular-event";
import { renderDecodedId } from "../../../../utils/renderDecodedId";
import getInitialMarket from "../../../../utils/getInitialMarket";
import { DrawerActionType } from "../../types";
import { EventType, RecordDataType } from "../types";
import { FutureTime } from "../constants";
import isInUse from "../utils/isInUse";

const ReachableContext = createContext<string | null>(null);

function Image({ src }: { src?: string }) {
  return (
    <img
      alt="banner"
      src={src ? src : "https://i.imgur.com/eRgPhL1.png"}
      className="w-28"
    />
  );
}

function NoExpiryDate() {
  return <p className="text-textSecondary">No expiry date</p>;
}

function DateRange({
  startDate,
  endDate,
}: {
  startDate?: string;
  endDate?: string;
}) {
  if (!startDate || startDate === FutureTime) return <NoExpiryDate />;

  return (
    <div className="flex items-center">
      <p>{dayjs(startDate).format("YYYY-MM-DD")}</p>

      <SwapRightOutlined className="mx-3" />

      {endDate && endDate !== FutureTime ? (
        <p>{dayjs(endDate).format("YYYY-MM-DD")}</p>
      ) : (
        <NoExpiryDate />
      )}
    </div>
  );
}

function Actions({
  eventType,
  record,
  form,
  setOpenDrawer,
  setDrawerAction,
  refetch,
}: {
  eventType: EventType;
  record: RecordDataType;
  form: FormInstance<any>;
  setOpenDrawer: (value: React.SetStateAction<boolean>) => void;
  setDrawerAction: (value: React.SetStateAction<DrawerActionType>) => void;
  refetch: any;
}) {
  const [modal, contextHolder] = Modal.useModal();
  const [deleteFeaturedEvent, { loading: loadingOfFeaturedEventDelete }] =
    useDeleteFeaturedEventMutation();
  const [deleteRegularEvent, { loading: loadingOfRegularEventDelete }] =
    useDeleteRegularEventMutation();

  const handleEdit = () => {
    const { market, imageFileName, imageUrl, publishDate, stopDate } = record;
    const imageFileList = imageFileName
      ? [
          {
            uid: "0",
            name: imageFileName,
            status: "done",
            url: imageUrl,
            thumbUrl: imageUrl,
          },
        ]
      : null;

    form.setFieldsValue({
      ...record,
      market: market || getInitialMarket(),
      image: imageFileList,
      publishDate:
        publishDate && publishDate !== FutureTime ? dayjs(publishDate) : null,
      stopDate: stopDate && stopDate !== FutureTime ? dayjs(stopDate) : null,
    });

    setOpenDrawer(true);
    setDrawerAction("Edit");
  };

  const deleteEvent = () => {
    const id = parseInt(renderDecodedId(record.id));

    if (eventType === "FeaturedEvent")
      deleteFeaturedEvent({ variables: { featuredEventId: id } })
        .then(() => refetch())
        .catch((e) => console.log(e));

    if (eventType === "RegularEvent")
      deleteRegularEvent({ variables: { regularEventId: id } })
        .then(() => refetch())
        .catch((e) => console.log(e));
  };

  const handleDelete = async () => {
    const { publishDate, stopDate } = record;

    if (isInUse(publishDate, stopDate)) {
      const confirmed = await modal.confirm({
        title: "Are you sure?",
        content: (
          <ReachableContext.Consumer>
            {() => (
              <p className="text-sm leading-6">
                {`The ${
                  eventType === "FeaturedEvent" ? "featured" : "regular"
                } event is still in use, are you sure you want to delete it?`}
              </p>
            )}
          </ReachableContext.Consumer>
        ),
        okText: "Yes, delete it",
        cancelText: "No",
      });

      if (confirmed) deleteEvent();
    } else {
      deleteEvent();
    }
  };

  if (loadingOfFeaturedEventDelete || loadingOfRegularEventDelete)
    return <Spinner />;

  return (
    <ReachableContext.Provider value="Light">
      <div className="flex gap-4">
        <Button type="link" className="p-0" onClick={handleEdit}>
          Edit
        </Button>

        <Button type="link" className="p-0" onClick={handleDelete}>
          Delete
        </Button>
      </div>

      {contextHolder}
    </ReachableContext.Provider>
  );
}

export { Image, DateRange, Actions };
