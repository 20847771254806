import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUserAllGraduationApplyTwQueryVariables = Types.Exact<{
  memberNo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  childNo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  currentYearStart?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  currentYearEnd?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
}>;


export type GetUserAllGraduationApplyTwQuery = { __typename?: 'Query', club_worldFamilyClub_graduationApplication?: { __typename?: 'Club_WorldFamilyClub_GraduationApplicationConnection', totalCount: number, nodes?: Array<{ __typename?: 'Club_WorldFamilyClub_GraduationApplication', id: string, age?: string | null, applicationDate?: any | null, audioFile?: string | null, childEnglishName?: string | null, childName?: string | null, childNo?: string | null, isExcellent?: boolean | null, market?: string | null, memberNo?: string | null, taskId?: number | null, videoUrl?: string | null, isPassed?: boolean | null, approvalDate?: any | null, overallComment?: string | null, graduationSubApplications?: Array<{ __typename?: 'Club_WorldFamilyClub_GraduationSubApplication', id: string, applicationId?: number | null, subTaskId?: number | null, subTask?: { __typename?: 'Club_WorldFamilyClub_GraduationApplicationSubTask', graduationSubTaskMarketDescriptions?: Array<{ __typename?: 'Club_WorldFamilyClub_GraduationSubTaskMarketDescription', market?: string | null, subTaskId?: number | null, subTaskName?: string | null } | null> | null } | null } | null> | null }> | null } | null };


export const GetUserAllGraduationApplyTwDocument = gql`
    query getUserAllGraduationApplyTw($memberNo: String, $childNo: String, $currentYearStart: DateTime, $currentYearEnd: DateTime) {
  club_worldFamilyClub_graduationApplication(
    filter: {memberNo: {eq: $memberNo}, childNo: {eq: $childNo}, applicationDate: {gt: $currentYearStart, lt: $currentYearEnd}}
    first: 100
  ) {
    totalCount
    nodes {
      id
      age
      applicationDate
      audioFile
      childEnglishName
      childName
      childNo
      isExcellent
      market
      memberNo
      taskId
      videoUrl
      graduationSubApplications {
        id
        applicationId
        subTaskId
        subTask {
          graduationSubTaskMarketDescriptions {
            market
            subTaskId
            subTaskName
          }
        }
      }
      isPassed
      approvalDate
      overallComment
    }
  }
}
    `;

/**
 * __useGetUserAllGraduationApplyTwQuery__
 *
 * To run a query within a React component, call `useGetUserAllGraduationApplyTwQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAllGraduationApplyTwQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAllGraduationApplyTwQuery({
 *   variables: {
 *      memberNo: // value for 'memberNo'
 *      childNo: // value for 'childNo'
 *      currentYearStart: // value for 'currentYearStart'
 *      currentYearEnd: // value for 'currentYearEnd'
 *   },
 * });
 */
export function useGetUserAllGraduationApplyTwQuery(baseOptions?: Apollo.QueryHookOptions<GetUserAllGraduationApplyTwQuery, GetUserAllGraduationApplyTwQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserAllGraduationApplyTwQuery, GetUserAllGraduationApplyTwQueryVariables>(GetUserAllGraduationApplyTwDocument, options);
      }
export function useGetUserAllGraduationApplyTwLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserAllGraduationApplyTwQuery, GetUserAllGraduationApplyTwQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserAllGraduationApplyTwQuery, GetUserAllGraduationApplyTwQueryVariables>(GetUserAllGraduationApplyTwDocument, options);
        }
export type GetUserAllGraduationApplyTwQueryHookResult = ReturnType<typeof useGetUserAllGraduationApplyTwQuery>;
export type GetUserAllGraduationApplyTwLazyQueryHookResult = ReturnType<typeof useGetUserAllGraduationApplyTwLazyQuery>;
export type GetUserAllGraduationApplyTwQueryResult = Apollo.QueryResult<GetUserAllGraduationApplyTwQuery, GetUserAllGraduationApplyTwQueryVariables>;