import { MarketType } from "../../types";
import { ShelfType } from "../types";
import { segmentationOrder } from "../constants";

export default function getSegmentationOrder(
  selectedMarket: MarketType,
  shelfData: ShelfType[]
) {
  return shelfData.reduce((result, { market, segmentation }) => {
    if (market === selectedMarket) {
      const order = segmentationOrder[segmentation];

      if (order != null && result > order) return order;
    }

    return result;
  }, Infinity);
}
