import Tag from "../../../components/ui/Tag";
import dayjs from "dayjs";
import { DataType } from "./types";
import TableActions from "./TableActions";
import { SortOrder } from "antd/es/table/interface";

export function createColumns(
  setViewData: (value: React.SetStateAction<DataType | null>) => void,
  refetch: any,
  onView: (id: Number) => void,
  onDelete: (id: Number) => void
) {
  return [
    {
      title: "Target",
      dataIndex: "target",
      key: "target",
      width: 110,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 560,
    },
    {
      title: "Push date and time",
      dataIndex: "scheduleTime",
      key: "pushTime",
      width: 180,
      defaultSortOrder: "descend" as SortOrder,
      render: (scheduleTime: any, record: DataType) => {
        const displayTime = scheduleTime || record.createdTime;
        return <span>{dayjs(displayTime).format("YYYY-MM-DD HH:mm")}</span>;
      },
      sorter: (a: DataType, b: DataType) =>
        dayjs(a.scheduleTime || a.createdTime).valueOf() -
        dayjs(b.scheduleTime || b.createdTime).valueOf(),
    },
    {
      title: "Status",
      key: "status",
      width: 104,
      render: (_: any, record: DataType) => {
        const pushTime = record.scheduleTime || record.createdTime;

        const pending = dayjs(pushTime).isAfter(dayjs());

        const color = pending ? "blue" : "grey";
        const value = pending ? "pending" : "success";

        return <Tag color={color}>{value}</Tag>;
      },
    },
    {
      title: "Actions",
      key: "actions",
      width: 120,
      render: (_: any, record: DataType) => {
        const pushTime = record.scheduleTime || record.createdTime;

        const pending = dayjs(pushTime).isAfter(dayjs());
        return (
          <TableActions
            showDeleteButton={pending}
            onView={onView}
            onDelete={onDelete}
            refetch={refetch}
            record={record}
          />
        );
      },
    },
  ];
}
