import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllGraduationProcessTwQueryVariables = Types.Exact<{
  currentYear?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  market?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetAllGraduationProcessTwQuery = { __typename?: 'Query', afChildGraduationApplication: Array<{ __typename?: 'Club_WorldFamilyClub_ChildGraduationApplication', id: string, applicationCount?: number | null, applicationYear?: number | null, approvedCount?: number | null, childNo?: string | null, graduationDate?: any | null, isExcellent?: boolean | null, lastApprovalDate?: any | null, market?: string | null, memberNo?: string | null, rejectedCount?: number | null }> };


export const GetAllGraduationProcessTwDocument = gql`
    query getAllGraduationProcessTw($currentYear: Int, $market: String) {
  afChildGraduationApplication(
    filter: {applicationYear: {eq: $currentYear}, market: {eq: $market}}
  ) {
    id
    applicationCount
    applicationYear
    approvedCount
    childNo
    graduationDate
    isExcellent
    lastApprovalDate
    market
    memberNo
    rejectedCount
  }
}
    `;

/**
 * __useGetAllGraduationProcessTwQuery__
 *
 * To run a query within a React component, call `useGetAllGraduationProcessTwQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllGraduationProcessTwQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllGraduationProcessTwQuery({
 *   variables: {
 *      currentYear: // value for 'currentYear'
 *      market: // value for 'market'
 *   },
 * });
 */
export function useGetAllGraduationProcessTwQuery(baseOptions?: Apollo.QueryHookOptions<GetAllGraduationProcessTwQuery, GetAllGraduationProcessTwQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllGraduationProcessTwQuery, GetAllGraduationProcessTwQueryVariables>(GetAllGraduationProcessTwDocument, options);
      }
export function useGetAllGraduationProcessTwLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllGraduationProcessTwQuery, GetAllGraduationProcessTwQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllGraduationProcessTwQuery, GetAllGraduationProcessTwQueryVariables>(GetAllGraduationProcessTwDocument, options);
        }
export type GetAllGraduationProcessTwQueryHookResult = ReturnType<typeof useGetAllGraduationProcessTwQuery>;
export type GetAllGraduationProcessTwLazyQueryHookResult = ReturnType<typeof useGetAllGraduationProcessTwLazyQuery>;
export type GetAllGraduationProcessTwQueryResult = Apollo.QueryResult<GetAllGraduationProcessTwQuery, GetAllGraduationProcessTwQueryVariables>;