import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { InfoCircleFilled } from "@ant-design/icons";
import "./MainContentFooter.css";
import { Colors } from "../../../constants/Colors";

interface MainContentFooterProps {
  isLoading: boolean;
  disabled?: boolean;
  testId?: string;
  backTo: string;
  isOneButton?: boolean;
  handleClick?: (values: any) => void;
}

const MainContentFooter = ({
  isLoading,
  disabled,
  testId,
  backTo,
  isOneButton,
  handleClick,
}: MainContentFooterProps) => {
  const navigate = useNavigate();

  return (
    <div
      className="main-content-footer-container"
      style={{ backgroundColor: Colors.white }}
    >
      {testId && (
        <div className="warning">
          <InfoCircleFilled style={{ color: "#394CF6", marginRight: 10 }} />
          <p>
            To send it officially, please clear the "Test member No." field
            first
          </p>
        </div>
      )}
      {isOneButton ? (
        <Button size="large" onClick={handleClick} loading={isLoading}>
          Re-push
        </Button>
      ) : (
        <>
          <Button size="large" onClick={() => navigate(backTo)}>
            Cancel
          </Button>
          <Button
            style={{ marginLeft: 8 }}
            type="primary"
            htmlType="submit"
            loading={isLoading}
            disabled={disabled}
            size="large"
          >
            Submit
          </Button>
        </>
      )}
    </div>
  );
};

export default MainContentFooter;
