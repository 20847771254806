import * as Types from './@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateUserMutationVariables = Types.Exact<{
  name: Types.Scalars['String']['input'];
  email: Types.Scalars['String']['input'];
  isAdmin: Types.Scalars['Boolean']['input'];
  market?: Types.InputMaybe<Array<Types.Wf_AdminFirst_AfUserMarketInput> | Types.Wf_AdminFirst_AfUserMarketInput>;
  serviceId?: Types.InputMaybe<Array<Types.Wf_AdminFirst_AfUserServiceInput> | Types.Wf_AdminFirst_AfUserServiceInput>;
  avatarUrl: Types.Scalars['String']['input'];
}>;


export type CreateUserMutation = { __typename?: 'Mutation', wf_adminFirst: { __typename?: 'WF_AdminFirst_Mutation_Payload', count: number } };


export const CreateUserDocument = gql`
    mutation CreateUser($name: String!, $email: String!, $isAdmin: Boolean!, $market: [WF_AdminFirst_AfUserMarketInput!], $serviceId: [WF_AdminFirst_AfUserServiceInput!], $avatarUrl: String!) {
  wf_adminFirst(
    add: {afUser: {userName: $name, userEmail: $email, isAdministrator: $isAdmin, avatarUrl: $avatarUrl, afUserMarkets: $market, afUserServices: $serviceId}}
  ) {
    count
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      isAdmin: // value for 'isAdmin'
 *      market: // value for 'market'
 *      serviceId: // value for 'serviceId'
 *      avatarUrl: // value for 'avatarUrl'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;