import { useCallback, useEffect, useState } from "react";
import {
  Button,
  Divider,
  Drawer,
  Empty,
  Form,
  Radio,
  Table,
  Upload,
  message,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { SwapRightOutlined, UploadOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import axios from "axios";
import "./EventBannerUpload.css";
import { Colors } from "../../../../constants/Colors";
import DrawerFooter from "../../../../components/layout/drawer/DrawerFooter";
import TemplateListItem from "./TemplateListItem";
import { renderAntDMessageConfig } from "../../../../utils/renderAntDMessageConfig";
import Spinner from "../../../../components/layout/spinner/Spinner";
import { renderBannerCategoryByType } from "../../../../utils/renderBannerCategoryByType";
import uploadFileToClubServiceBlob from "../../../../utils/uploadFileToClubServiceBlob";

interface EventBannerUploadProps {
  apiUri: string | undefined;
}

export interface DataType {
  id: string;
  eventType: string;
  eventTopic: string;
  eventDetail: string;
  imageFileName: string | null;
  image: string | null;
  eventStartDate: string;
  eventEndDate: string;
  openDate: string;
  mainNo: string;
  region: string;
}

export interface TemplateBannerType {
  id: string;
  bannerType: string;
  eventType: string;
  image: string;
  fileName: string;
  templateName: string;
}

const EventBannerUpload = ({ apiUri }: EventBannerUploadProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [allBanners, setAllBanners] = useState<DataType[]>([]);
  const [selectBanner, setSelectBanner] = useState<DataType | undefined>();
  const [showDrawer, setShowDrawer] = useState(false);
  const [selectUploadType, setSelectUploadType] = useState("1");
  const [selectTemplate, setSelectTemplate] = useState<TemplateBannerType>();
  const [uploadedFileName, setUploadedFileName] = useState<any>("");
  const [selectMarket, setSelectMarket] = useState("hk");
  const [displayTemplate, setDisplayTemplate] = useState<TemplateBannerType[]>(
    []
  );
  const [form] = Form.useForm();

  const getBase64 = (img: any, callback: any) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const getMarketBanners = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `${apiUri}/app/region-act-list/${selectMarket}`,
        {
          headers: { "user-code": "P606" },
        }
      );
      const transformedData = data.map((item: any, i: any) => ({
        id: i.toString(),
        eventType: item.actTypeName,
        eventTopic: item.actName,
        eventDetail: item.actContent,
        image: item.imageUrl,
        imageFileFame: item.imageUrl?.split("?")[0].split("-img/")[1],
        eventStartDate: item.actDateB,
        eventEndDate: item.actDateE,
        openDate: item.webDateB,
        region: selectMarket,
        mainNo: item.mainNo,
      }));
      setAllBanners(transformedData);
      setIsLoading(false);
    } catch (error) {
      message.error(
        renderAntDMessageConfig(
          "Something went wrong. Please contact your system administrator."
        )
      );
      setIsLoading(false);
    }
  }, [apiUri, selectMarket]);

  const getTemplatesByType = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `${apiUri}/app/banner-list/${renderBannerCategoryByType(
          selectBanner?.eventType
        )}`,
        {
          headers: { "user-code": "P606" },
        }
      );
      const transformedData = data.map((item: any) => ({
        id: item.seq,
        bannerType: item.typeEnglishName,
        eventType: item.textContent,
        image: item.imageUrl,
        fileName: item.imageUrl.split("?")[0].split("-img/")[1],
        templateName: item.textContent,
      }));
      setDisplayTemplate(transformedData);
      setIsLoading(false);
    } catch (error) {
      message.error(
        renderAntDMessageConfig(
          "Something went wrong. Please contact your system administrator."
        )
      );
      setIsLoading(false);
    }
  }, [apiUri, selectBanner]);

  const handleReset = async (mainNo: string) => {
    setIsLoading(true);
    try {
      await axios.delete(
        `${apiUri}/app/region-banner/${selectMarket}/${mainNo}`,
        {
          headers: { "user-code": "P606" },
        }
      );
      getMarketBanners();
      setIsLoading(false);
    } catch (error) {
      message.error(
        renderAntDMessageConfig(
          "Something went wrong. Please contact your system administrator."
        )
      );
      setIsLoading(false);
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Event type",
      dataIndex: "eventType",
      key: "eventType",
      sorter: (a, b) => a.eventType.localeCompare(b.eventType),
    },
    {
      title: "Event topic",
      dataIndex: "eventTopic",
      key: "eventTopic",
      sorter: (a, b) => a.eventTopic.localeCompare(b.eventTopic),
      width: 200,
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (value: string) => (
        <img
          alt="banner"
          src={value ? value : "https://i.imgur.com/eRgPhL1.png"}
          style={{ width: 112 }}
        />
      ),
      sorter: true,
    },
    {
      title: "Event date",
      render: (value: any, record: DataType) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <p>{dayjs(record.eventStartDate).format("YYYY-MM-DD")}</p>
          <SwapRightOutlined style={{ margin: "0 12px" }} />
          <p>{dayjs(record.eventEndDate).format("YYYY-MM-DD")}</p>
        </div>
      ),
    },
    {
      title: "Open date",
      dataIndex: "openDate",
      key: "openDate",
      render: (value: string) => dayjs(value).format("YYYY-MM-DD"),
    },
    {
      title: "Action",
      render: (_, record: DataType) => (
        <>
          <Button
            type="link"
            onClick={() => {
              setSelectBanner(record);
              setShowDrawer(true);
            }}
            style={{ padding: 0, marginRight: 16 }}
          >
            Edit
          </Button>
          <Button
            type="link"
            onClick={() => handleReset(record.mainNo)}
            style={{ padding: 0, marginRight: 16 }}
          >
            Reset
          </Button>
        </>
      ),
    },
  ];

  const props: UploadProps = {
    name: "file",
    action: "https://httpbin.org/post",
    headers: {
      authorization: "authorization-text",
    },
    beforeUpload: (file) => {
      const isImage = file.type.startsWith("image");
      if (!isImage) {
        message.error(renderAntDMessageConfig("Only upload image file!"));
      }
      return isImage || Upload.LIST_IGNORE;
    },
    onChange(info) {
      setUploadedFileName(info.file.name);
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        getBase64(info.file.originFileObj, async () => {
          const newFileName = await uploadFileToClubServiceBlob(
            info.file.originFileObj,
            "regionEventBanner",
            "P606"
          );
          message.success(
            renderAntDMessageConfig(
              `${info.file.name} file uploaded successfully`
            )
          );
          setUploadedFileName(newFileName);
        });
      } else if (info.file.status === "error") {
        message.error(
          renderAntDMessageConfig(`${info.file.name} file upload failed.`)
        );
      }
    },
    maxCount: 1,
    listType: "picture",
    onRemove: () => setUploadedFileName(""),
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const updatedBannerInfo = {
        region: selectMarket,
        mainNo: selectBanner?.mainNo,
        imageName: selectUploadType === "1" ? selectTemplate : uploadedFileName,
      };
      if (!selectBanner?.image) {
        await axios.post(`${apiUri}/app/region-banner`, updatedBannerInfo, {
          headers: {
            "User-Code": "P606",
          },
        });
      } else {
        await axios.patch(`${apiUri}/app/region-banner`, updatedBannerInfo, {
          headers: {
            "User-Code": "P606",
          },
        });
      }
      getMarketBanners();
      form.resetFields();
      setSelectBanner(undefined);
      setSelectTemplate(undefined);
      setSelectUploadType("1");
      setUploadedFileName("");
      setShowDrawer(false);
      setIsLoading(false);
    } catch (error) {
      message.error(
        renderAntDMessageConfig(
          "Something went wrong. Please contact your system administrator."
        )
      );
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getMarketBanners();
  }, [getMarketBanners]);

  useEffect(() => {
    if (selectBanner) {
      console.log(selectBanner);
      getTemplatesByType();
    }
  }, [selectBanner, getTemplatesByType, form]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <div className="theme-tab-content-container">
        <div
          className="theme-tab-table-container"
          style={{ backgroundColor: Colors.white }}
        >
          <Radio.Group
            options={[
              { label: "HK", value: "hk" },
              { label: "KR", value: "kr" },
              { label: "TW", value: "tw" },
            ]}
            optionType="button"
            onChange={(e) => setSelectMarket(e.target.value)}
            defaultValue="MP"
            value={selectMarket}
            style={{ marginBottom: 24 }}
          />
          <Table
            rowKey={(record: DataType) => record.id}
            columns={columns}
            dataSource={allBanners}
            pagination={false}
            className="announcement-table"
            scroll={{ x: true, y: window.innerHeight * 0.7 - 48 - 55 }}
          />
        </div>
      </div>
      <Drawer
        title="Edit event banner"
        open={showDrawer}
        onClose={() => {
          form.resetFields();
          setSelectUploadType("1");
          setSelectBanner(undefined);
          setShowDrawer(false);
        }}
        width={500}
      >
        <Form
          name="upload-banner-form"
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <Form.Item label="Event type">
            <p style={{ marginTop: -16, color: "#071491" }}>
              {selectBanner?.eventType}
            </p>
          </Form.Item>
          <Form.Item label="Event topic">
            <p style={{ marginTop: -16, color: "#071491" }}>
              {selectBanner?.eventTopic}
            </p>
          </Form.Item>
          <Form.Item label="Event detail" className="event-detail">
            <p style={{ color: "#071491" }}>{selectBanner?.eventDetail}</p>
          </Form.Item>
          <Divider />
          <Form.Item label="Image upload">
            <Radio.Group
              optionType="button"
              options={[
                { label: "Use template", value: "1" },
                { label: "Manually upload", value: "2" },
                // { label: "Default", value: "3" },
              ]}
              value={selectUploadType}
              onChange={(e) => setSelectUploadType(e.target.value)}
            />
          </Form.Item>
          {selectUploadType === "1" && (
            <Form.Item
              label="Event type templates"
              name="imageFileName"
              rules={[
                {
                  required: displayTemplate.length > 0 ? true : false,
                  message: "This field is required!",
                },
              ]}
              className="template-list-template"
            >
              {displayTemplate?.length > 0 ? (
                <Radio.Group
                  options={displayTemplate.map((banner: any) => ({
                    label: (
                      <TemplateListItem
                        banner={banner}
                        isSelected={banner.fileName === selectTemplate}
                      />
                    ),
                    value: banner.fileName,
                  }))}
                  onChange={(e) => setSelectTemplate(e.target.value)}
                />
              ) : (
                <Empty style={{ margin: "24px 0" }} className="no-data" />
              )}
            </Form.Item>
          )}
          {selectUploadType === "2" && (
            <Form.Item
              label="Image"
              name="imageFileName"
              rules={[
                {
                  required: true,
                  message: "This field is required!",
                },
              ]}
            >
              <Upload {...props} className="banner-upload">
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </Form.Item>
          )}
          {/* {selectUploadType === "3" && (
            <Form.Item
              label="Event type default image"
              className="template-list-default"
            >
              <Radio defaultChecked value="">
                <TemplateListItem
                  defaultBanner={mockUploadBanners[1]}
                  isSelected
                />
              </Radio>
            </Form.Item>
          )} */}
          <DrawerFooter
            handleCancel={() => {
              form.resetFields();
              setSelectUploadType("1");
              setSelectBanner(undefined);
              setShowDrawer(false);
            }}
            disabled={displayTemplate.length === 0 && selectUploadType === "1"}
          />
        </Form>
      </Drawer>
    </>
  );
};

export default EventBannerUpload;
