import { useEffect, useState } from "react";
import { Button, DatePicker, Drawer, Form, Table, message, Upload } from "antd";
import {
  PlusOutlined,
  SwapRightOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import type { UploadProps } from "antd";
import dayjs from "dayjs";
import "./ThemeTabContent.css";
import { Colors } from "../../../../constants/Colors";
import DrawerFooter from "../../../../components/layout/drawer/DrawerFooter";
import InputCustom from "../../../../components/custom/InputCustom";
import { renderDecodedId } from "../../../../utils/renderDecodedId";
import { useGetFaceCallThemesQuery } from "../../../../graphql/operations/get-facecall-themes";
import Spinner from "../../../../components/layout/spinner/Spinner";
import { useGetBlobSasQuery } from "../../../../graphql/operations/get-blob-info";
import { useUploadFileMutation } from "../../../../graphql/operations/upload-file";
import { useCreateFaceCallThemeMutation } from "../../../../graphql/operations/create-facecall-theme";
import { AfBlobPath } from "../../../../graphql/operations/@types";
import { useUpdateFaceCallThemeMutation } from "../../../../graphql/operations/update-facecall-theme";
import { useReactiveVar } from "@apollo/client";
import { __currentUser__ } from "../../../../graphql/policies";
import { renderAntDMessageConfig } from "../../../../utils/renderAntDMessageConfig";

const { RangePicker } = DatePicker;

interface DataType {
  __typename?: "Club_WorldFamilyClub_FaceCallSeasonHoliday";
  id: string;
  name?: string | null;
  dateFrom?: any | null;
  dateTo?: any | null;
  iconFileName?: string | null;
}

const ThemeTabContent = () => {
  const [showDrawer, setShowDrawer] = useState(false);
  const [selectTheme, setSelectTheme] = useState<DataType | undefined>();
  const [uploadedFileName, setUploadedFileName] = useState<any>("");
  const currentUser = useReactiveVar(__currentUser__);
  const [form] = Form.useForm();
  const { data, loading, error, refetch } = useGetFaceCallThemesQuery();
  const {
    data: blobInfo,
    loading: blobLoading,
    error: blobError,
  } = useGetBlobSasQuery();
  const [uploadFile] = useUploadFileMutation();
  const [createTheme, { loading: createLoading }] =
    useCreateFaceCallThemeMutation();
  const [updateTheme, { loading: updateLoading }] =
    useUpdateFaceCallThemeMutation();

  const props: UploadProps = {
    name: "file",
    action: "https://httpbin.org/post",
    headers: {
      authorization: "authorization-text",
    },
    beforeUpload: (file) => {
      const isImage = file.type.startsWith("image");
      if (!isImage) {
        message.error(renderAntDMessageConfig("Only upload image file!"));
      }
      return isImage || Upload.LIST_IGNORE;
    },
    onChange(info) {
      setUploadedFileName(info.file.name);
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(
          renderAntDMessageConfig(
            `${info.file.name} file uploaded successfully`
          )
        );
      } else if (info.file.status === "error") {
        message.error(
          renderAntDMessageConfig(`${info.file.name} file upload failed.`)
        );
      }
      uploadFile({
        variables: {
          blobs: [
            {
              file: info.file.originFileObj,
              path: AfBlobPath.FaceCallImage,
            },
          ],
        },
      }).catch((e) => console.log(e));
    },
    maxCount: 1,
    listType: "picture",
    onRemove: () => setUploadedFileName(""),
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      render: (value: string) => renderDecodedId(value),
    },
    {
      title: "Theme",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Tag",
      dataIndex: "iconFileName",
      key: "iconFileName",
      render: (value: string) => (
        <img
          alt="theme icon"
          src={`${
            blobInfo?.afBlobContainerSignature[0].uri &&
            blobInfo?.afBlobContainerSignature[0].uri.replace(
              "notification-image",
              "face-call-image"
            )
          }/${value}${blobInfo?.afBlobContainerSignature[0].token}`}
          style={{ width: 22, height: 22 }}
        />
      ),
    },
    {
      title: "Date range",
      render: (value: any, record: DataType) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <p>{dayjs(record.dateFrom).format("MM-DD")}</p>
          <SwapRightOutlined style={{ margin: "0 12px" }} />
          <p>{dayjs(record.dateTo).format("MM-DD")}</p>
        </div>
      ),
    },
    {
      title: "Action",
      render: (_, record: DataType) => (
        <Button
          type="link"
          onClick={() => {
            setSelectTheme(record);
            setShowDrawer(true);
          }}
          style={{ padding: 0 }}
        >
          Edit
        </Button>
      ),
    },
  ];

  const handleSubmit = (values: any) => {
    const from = `1970-${dayjs(dayjs(values.dateRange[0])).format("MM-DD")}`;
    const to = `1970-${dayjs(dayjs(values.dateRange[1])).format("MM-DD")}`;
    const formData = {
      themeName: values.name,
      fileName: uploadedFileName,
      dateFrom: dayjs(from).toISOString(),
      dateTo: dayjs(to).toISOString(),
    };
    console.log(formData);
    if (selectTheme) {
      updateTheme({
        variables: {
          ...formData,
          id: parseInt(renderDecodedId(selectTheme.id)),
        },
      })
        .then(() => {
          refetch();
          setShowDrawer(false);
        })
        .catch((e) => console.log(e));
    } else {
      createTheme({ variables: formData })
        .then(() => {
          refetch();
          setShowDrawer(false);
        })
        .catch((e) => console.log(e));
    }
  };

  useEffect(() => {
    if (selectTheme) {
      setUploadedFileName(selectTheme.iconFileName);
      form.setFieldsValue({
        name: selectTheme.name,
        dateRange:
          selectTheme.name === "New year"
            ? [
                dayjs(selectTheme.dateFrom.replace("1970", dayjs().year())),
                dayjs(
                  selectTheme.dateTo.replace(
                    "1970",
                    dayjs().month() === 1
                      ? dayjs().year()
                      : dayjs().add(1, "year").year()
                  )
                ),
              ]
            : [
                dayjs(selectTheme.dateFrom.replace("1970", dayjs().year())),
                dayjs(selectTheme.dateTo.replace("1970", dayjs().year())),
              ],
        tagImage: [
          {
            uid: "0",
            name: selectTheme.iconFileName,
            status: "done",
            url: `${blobInfo?.afBlobContainerSignature[0].uri}}/${selectTheme.iconFileName}${blobInfo?.afBlobContainerSignature[0].token}`,
          },
        ],
      });
    }
  }, [selectTheme, form, blobInfo]);

  if (error || blobError) {
    message.error(
      renderAntDMessageConfig(
        "Something went wrong. Please contact your system administrator."
      )
    );
  }

  if (loading || blobLoading) {
    return <Spinner />;
  }

  return (
    <>
      <div className="theme-tab-content-container">
        <div
          className="theme-tab-table-container"
          style={{ backgroundColor: Colors.white }}
        >
          {currentUser?.isAdministrator ? (
            <div className="theme-tab-header-container">
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setShowDrawer(true)}
              >
                Create New
              </Button>
            </div>
          ) : null}
          <Table
            rowKey={(record: DataType) => record.id}
            columns={columns.filter((col) =>
              currentUser?.isAdministrator ? true : col.title !== "Action"
            )}
            dataSource={data?.club_worldFamilyClub_faceCallSeasonHoliday?.edges
              ?.map((item) => item.node)
              .filter((_, i) => i !== 0)}
            pagination={{ hideOnSinglePage: true }}
          />
        </div>
      </div>
      <Drawer
        title={selectTheme ? "Edit theme" : "Create new theme"}
        open={showDrawer}
        onClose={() => {
          form.resetFields();
          setSelectTheme(undefined);
          setShowDrawer(false);
        }}
        width={500}
      >
        <Form
          name="theme-form"
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <Form.Item
            label="Theme name"
            name="name"
            rules={[
              {
                required: selectTheme ? false : true,
                message: "This field is required!",
              },
            ]}
          >
            {selectTheme ? (
              <p style={{ color: Colors.notEditableText }}>
                {selectTheme.name}
              </p>
            ) : (
              <InputCustom style={{ width: "100%" }} />
            )}
          </Form.Item>
          <Form.Item
            label="Tag image"
            name="tagImage"
            rules={[{ required: true, message: "This field is required!" }]}
            valuePropName="fileList"
            getValueFromEvent={(e) => {
              if (Array.isArray(e)) {
                return e;
              }
              return e && e.fileList;
            }}
          >
            <Upload {...props} className="theme-upload">
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            label="Date range"
            name="dateRange"
            rules={[{ required: true, message: "This field is required!" }]}
          >
            <RangePicker style={{ width: "100%" }} format="MM-DD" />
          </Form.Item>
          <DrawerFooter
            handleCancel={() => {
              form.resetFields();
              setSelectTheme(undefined);
              setShowDrawer(false);
            }}
            isLoading={createLoading || updateLoading}
          />
        </Form>
      </Drawer>
    </>
  );
};

export default ThemeTabContent;
