import { GetFeaturedEventsQuery } from "../../../../graphql/operations/trialMode/localEvent/get-featured-events";
import { GetRegularEventsQuery } from "../../../../graphql/operations/trialMode/localEvent/get-regular-events";
import { MarketType } from "../../types";
import { FeaturedEventsDataType, RegularEventsDataType } from "../types";

export function normalizeFeaturedEventsData(
  data: GetFeaturedEventsQuery
): FeaturedEventsDataType[] {
  const inputData = data.wf_trialModeLeads_featuredEvent?.nodes || [];

  inputData.sort((a, b) => (a.sortId || 0) - (b.sortId || 0));

  return inputData.map((item, i) => {
    const {
      id,
      sortId,
      market,
      imageFileName,
      imageUrl,
      navigationUrl,
      startDate,
      endDate,
    } = item;

    return {
      id,
      sortId: sortId ?? 0,
      key: String(i),
      market: (market as MarketType) ?? "",
      imageFileName: imageFileName ?? "",
      imageUrl: imageUrl ?? "",
      navigationUrl: navigationUrl ? navigationUrl.replace("https://", "") : "",
      publishDate: startDate,
      stopDate: endDate,
    };
  });
}

export function normalizeRegularEventsData(
  data: GetRegularEventsQuery
): RegularEventsDataType[] {
  const inputData = data.wf_trialModeLeads_regularEvent?.nodes || [];

  inputData.sort((a, b) => (a.sortId || 0) - (b.sortId || 0));

  return inputData.map((item, i) => {
    const {
      id,
      sortId,
      market,
      eventTitle,
      eventDescription,
      imageFileName,
      imageUrl,
      navigationUrl,
      startDate,
      endDate,
    } = item;

    return {
      id,
      sortId: sortId ?? 0,
      key: String(i),
      market: (market as MarketType) ?? "",
      title: eventTitle ?? "",
      description: eventDescription ?? "",
      imageFileName: imageFileName ?? "",
      imageUrl: imageUrl ?? "",
      navigationUrl: navigationUrl ? navigationUrl.replace("https://", "") : "",
      publishDate: startDate,
      stopDate: endDate,
    };
  });
}
