import { Button } from "antd";
import "./DrawerFooter.css";

interface DataType {
  id: string;
  name: string;
  imageUrl: string;
  countOfSong: number;
  region: string;
  fileName: string;
  publishedSongs: number;
}
interface DrawerFooterProps {
  handleSubmit?: () => void;
  handleCancel: () => void;
  handleDelete?: any;
  disabled?: boolean;
  isLoading?: boolean;
  isDeleteLoading?: boolean;
  performer?: DataType | undefined;
}

const DrawerFooter = ({
  handleSubmit,
  handleCancel,
  handleDelete,
  disabled,
  isLoading,
  performer,
  isDeleteLoading,
}: DrawerFooterProps) => {
  return (
    <div
      className="drawer-footer-container"
      style={!performer ? { justifyContent: "flex-end" } : undefined}
    >
      {performer && (
        <Button
          danger
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
          onClick={() => {
            handleDelete(performer);
          }}
          loading={isDeleteLoading}
        >
          <img
            alt="delete icon"
            src={require("../../../assets/icons/delete-icon.png")}
            style={{ width: 16, height: 16, marginRight: 10 }}
          />
          Delete
        </Button>
      )}

      <div>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button
          type="primary"
          style={{ marginLeft: 8, textAlign: "center" }}
          htmlType="submit"
          disabled={disabled}
          loading={isLoading}
          onClick={() => {
            if (handleSubmit) {
              handleSubmit();
            }
          }}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default DrawerFooter;
