import { GetCtAsQuery } from "../../../../graphql/operations/trialMode/caringCTA/get-CTAs";
import { getStandardUrl } from "../../utils/getVideoUrl";
import { MarketType, SegmentationType } from "../../types";
import {
  ButtonLinkType,
  CTADataType,
  CharacterName,
  ExperienceType,
  TemplateType,
} from "../types";

export default function normalizeCTAData(data: GetCtAsQuery): CTADataType[] {
  const inputData = data.wf_trialModeLeads_callToAction?.nodes || [];

  return inputData.map((item) => {
    const {
      id,
      ctaExperienceTypeId,
      market,
      segmentation,
      ctaExperienceType,
      template,
      imageCharacter,
      photoFileName,
      photoUrl,
      videoUrl,
      callToActionContents,
      buttonLinksTo,
      buttonText,
    } = item;

    return {
      key: id,
      id,
      ctaExperienceTypeId: ctaExperienceTypeId as number,
      market: market as MarketType,
      segmentation: segmentation as SegmentationType,
      experienceName: ctaExperienceType?.experienceName as ExperienceType,
      template: template as TemplateType,
      imageCharacter: (imageCharacter as CharacterName) || "",
      photoFileName: photoFileName || "",
      photoUrl: photoUrl || "",
      videoUrl: getStandardUrl(videoUrl || "").replace("https://", ""),
      contents:
        callToActionContents?.map((item) => {
          const { id, ctaTitle, ctaContent } = item || {};

          return {
            id: id || "",
            ctaTitle: ctaTitle || "",
            ctaContent: ctaContent || "",
          };
        }) || [],
      buttonLinksTo: (buttonLinksTo as ButtonLinkType) || "",
      buttonText: buttonText || "",
    };
  });
}
