import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateRegularEventMutationVariables = Types.Exact<{
  regularEventId: Types.Scalars['Int']['input'];
  market: Types.Scalars['String']['input'];
  eventTitle: Types.Scalars['String']['input'];
  eventDescription?: Types.InputMaybe<Types.Scalars['String']['input']>;
  imageFileName: Types.Scalars['String']['input'];
  navigationUrl: Types.Scalars['String']['input'];
  startDate?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  endDate?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
}>;


export type UpdateRegularEventMutation = { __typename?: 'Mutation', wf_trialModeLeads: { __typename?: 'WF_TrialModeLeads_Mutation_Payload', count: number } };


export const UpdateRegularEventDocument = gql`
    mutation UpdateRegularEvent($regularEventId: Int!, $market: String!, $eventTitle: String!, $eventDescription: String, $imageFileName: String!, $navigationUrl: String!, $startDate: DateTime, $endDate: DateTime) {
  wf_trialModeLeads(
    update: {regularEvent: {regularEventId: $regularEventId, market: $market, eventTitle: $eventTitle, eventDescription: $eventDescription, imageFileName: $imageFileName, navigationUrl: $navigationUrl, startDate: $startDate, endDate: $endDate}}
  ) {
    count
  }
}
    `;
export type UpdateRegularEventMutationFn = Apollo.MutationFunction<UpdateRegularEventMutation, UpdateRegularEventMutationVariables>;

/**
 * __useUpdateRegularEventMutation__
 *
 * To run a mutation, you first call `useUpdateRegularEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRegularEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRegularEventMutation, { data, loading, error }] = useUpdateRegularEventMutation({
 *   variables: {
 *      regularEventId: // value for 'regularEventId'
 *      market: // value for 'market'
 *      eventTitle: // value for 'eventTitle'
 *      eventDescription: // value for 'eventDescription'
 *      imageFileName: // value for 'imageFileName'
 *      navigationUrl: // value for 'navigationUrl'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useUpdateRegularEventMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRegularEventMutation, UpdateRegularEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRegularEventMutation, UpdateRegularEventMutationVariables>(UpdateRegularEventDocument, options);
      }
export type UpdateRegularEventMutationHookResult = ReturnType<typeof useUpdateRegularEventMutation>;
export type UpdateRegularEventMutationResult = Apollo.MutationResult<UpdateRegularEventMutation>;
export type UpdateRegularEventMutationOptions = Apollo.BaseMutationOptions<UpdateRegularEventMutation, UpdateRegularEventMutationVariables>;