import { Form, Radio } from "antd";

import requiredRule from "../../../../../utils/formRules/requiredRule";
import { NotificationActionButtonType } from "../../types";

interface OptionType {
  value: NotificationActionButtonType;
  label: string;
}

const options: OptionType[] = [
  { label: "None", value: NotificationActionButtonType.None },
  {
    label: "Club app screen",
    value: NotificationActionButtonType.ClubAppScreen,
  },
  { label: "Embed URL", value: NotificationActionButtonType.EmbedURL },
  { label: "Open browser", value: NotificationActionButtonType.OpenBrowser },
];

export default function FormItemActionButton() {
  return (
    <Form.Item name="actionButton" label="Action Button" rules={[requiredRule]}>
      <Radio.Group optionType="button">
        {options.map(({ label, value }) => (
          <Radio.Button key={label} value={value}>
            {label}
          </Radio.Button>
        ))}
      </Radio.Group>
    </Form.Item>
  );
}
