import { Form, Select } from "antd";

import requiredRule from "../../../../../utils/formRules/requiredRule";
import getSegmentationDesc from "../../../utils/getSegmentationDesc";
import { SegmentationType } from "../../../types";

interface OptionType {
  value: SegmentationType;
  label: string;
}

export default function FormItemSegmentation() {
  const options: OptionType[] = [
    { value: "Awareness", label: getSegmentationDesc("Awareness") },
    { value: "Interest", label: getSegmentationDesc("Interest") },
    { value: "Decision", label: getSegmentationDesc("Decision") },
  ];

  return (
    <Form.Item name="segmentations" label="Segmentation" rules={[requiredRule]}>
      <Select
        mode="tags"
        options={options}
        className="w-[484px]"
        placeholder="- Please Select -"
      />
    </Form.Item>
  );
}
