import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddScheduledServiceBannerMutationVariables = Types.Exact<{
  service: Types.Scalars['String']['input'];
  image: Types.Scalars['String']['input'];
  markets: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
  publishDate?: Types.InputMaybe<Types.Scalars['String']['input']>;
  stopDate?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type AddScheduledServiceBannerMutation = { __typename?: 'Mutation', addScheduledServiceBanner: { __typename?: 'Payload', count: number, error?: Types.Error | null } };


export const AddScheduledServiceBannerDocument = gql`
    mutation AddScheduledServiceBanner($service: String!, $image: String!, $markets: [String!]!, $publishDate: String, $stopDate: String) {
  addScheduledServiceBanner(
    parameter: {service: $service, image: $image, markets: $markets, publishDate: $publishDate, stopDate: $stopDate}
  ) {
    count
    error
  }
}
    `;
export type AddScheduledServiceBannerMutationFn = Apollo.MutationFunction<AddScheduledServiceBannerMutation, AddScheduledServiceBannerMutationVariables>;

/**
 * __useAddScheduledServiceBannerMutation__
 *
 * To run a mutation, you first call `useAddScheduledServiceBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddScheduledServiceBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addScheduledServiceBannerMutation, { data, loading, error }] = useAddScheduledServiceBannerMutation({
 *   variables: {
 *      service: // value for 'service'
 *      image: // value for 'image'
 *      markets: // value for 'markets'
 *      publishDate: // value for 'publishDate'
 *      stopDate: // value for 'stopDate'
 *   },
 * });
 */
export function useAddScheduledServiceBannerMutation(baseOptions?: Apollo.MutationHookOptions<AddScheduledServiceBannerMutation, AddScheduledServiceBannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddScheduledServiceBannerMutation, AddScheduledServiceBannerMutationVariables>(AddScheduledServiceBannerDocument, options);
      }
export type AddScheduledServiceBannerMutationHookResult = ReturnType<typeof useAddScheduledServiceBannerMutation>;
export type AddScheduledServiceBannerMutationResult = Apollo.MutationResult<AddScheduledServiceBannerMutation>;
export type AddScheduledServiceBannerMutationOptions = Apollo.BaseMutationOptions<AddScheduledServiceBannerMutation, AddScheduledServiceBannerMutationVariables>;