import { ExclamationCircleFilled } from "@ant-design/icons";

import cn from "../../utils/cn";

export default function Alert({
  message,
  className,
}: {
  message: string;
  className?: string;
}) {
  return (
    <div
      className={cn(
        "w-fit rounded-sm border border-solid border-primary3 px-4 py-[9px] bg-primary1 flex gap-2.5",
        className
      )}
    >
      <ExclamationCircleFilled className="text-primary6" />
      <p className="text-textPrimary font-normal leading-5">{message}</p>
    </div>
  );
}
