import { MenuItem } from "../types";

export default function getSubMenuKeys(items: MenuItem[]) {
  return items
    .filter((item) => {
      return item && "children" in item
        ? Array.isArray(item.children) && item.children.length > 0
        : false;
    })
    .map((item) => String(item?.key));
}
