import { GetProductSongsQuery } from "../../../../graphql/operations/trialMode/trialContent/get-product-songs";
import { GetProductVideosQuery } from "../../../../graphql/operations/trialMode/trialContent/get-product-videos";
import {
  ShelfType,
  TrialContentSongType,
  TrialContentVideoType,
} from "../types";

export function normalizeSongData(
  data: GetProductSongsQuery
): TrialContentSongType[] {
  const inputData = data.wf_trialModeLeads_productSong?.nodes || [];

  return inputData.map((item, index) => {
    const {
      id,
      productCategoryId,
      sortId,
      songTitle,
      productCategory,
      productSongAndMarkets,
      audioUrl,
    } = item;

    return {
      key: String(index + 1),
      id,
      productCategoryId: productCategoryId as number,
      sortId: sortId as number,
      songTitle: songTitle || "",
      category: productCategory?.category || "",
      productSongAndMarkets: productSongAndMarkets as ShelfType[],
      audioUrl: audioUrl || "",
    };
  });
}

export function normalizeVideoData(
  data: GetProductVideosQuery
): TrialContentVideoType[] {
  const inputData = data.wf_trialModeLeads_productVideo?.nodes || [];

  return inputData.map((item, index) => {
    const {
      id,
      productCategoryId,
      sortId,
      videoTitle,
      productCategory,
      productVideoAndMarkets,
      videoUrl,
    } = item;

    return {
      key: String(index + 1),
      id,
      productCategoryId: productCategoryId as number,
      sortId: sortId as number,
      videoTitle: videoTitle || "",
      category: productCategory?.category || "",
      productVideoAndMarkets: productVideoAndMarkets as ShelfType[],
      videoUrl: videoUrl || "",
    };
  });
}
