import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetDefaultServiceBannerListQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetDefaultServiceBannerListQuery = { __typename?: 'Query', defaultServiceBannerList: Array<{ __typename?: 'DefaultServiceBannerInfo', seq: number, service: string, theme: string, image: string, market: string, publishDate: string, stopDate: string, imageUrl: string }> };


export const GetDefaultServiceBannerListDocument = gql`
    query getDefaultServiceBannerList {
  defaultServiceBannerList {
    seq
    service
    theme
    image
    imageUrl: image @wf_blobSignature(name: "APP_SERVICE_BANNERS_SIGNATURE")
    market
    publishDate
    stopDate
  }
}
    `;

/**
 * __useGetDefaultServiceBannerListQuery__
 *
 * To run a query within a React component, call `useGetDefaultServiceBannerListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefaultServiceBannerListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefaultServiceBannerListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDefaultServiceBannerListQuery(baseOptions?: Apollo.QueryHookOptions<GetDefaultServiceBannerListQuery, GetDefaultServiceBannerListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDefaultServiceBannerListQuery, GetDefaultServiceBannerListQueryVariables>(GetDefaultServiceBannerListDocument, options);
      }
export function useGetDefaultServiceBannerListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDefaultServiceBannerListQuery, GetDefaultServiceBannerListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDefaultServiceBannerListQuery, GetDefaultServiceBannerListQueryVariables>(GetDefaultServiceBannerListDocument, options);
        }
export type GetDefaultServiceBannerListQueryHookResult = ReturnType<typeof useGetDefaultServiceBannerListQuery>;
export type GetDefaultServiceBannerListLazyQueryHookResult = ReturnType<typeof useGetDefaultServiceBannerListLazyQuery>;
export type GetDefaultServiceBannerListQueryResult = Apollo.QueryResult<GetDefaultServiceBannerListQuery, GetDefaultServiceBannerListQueryVariables>;