import { Form, FormInstance, Upload, UploadFile, UploadProps } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import requiredRule from "../../../../../utils/formRules/requiredRule";
import imageValidation from "../../../../../utils/formValidation/imageValidation";
import handleUploadOnChange from "../../../utils/handleUploadOnChange";
import normFile from "../../../utils/normFile";
import { TemplateType } from "../../types";

const props: UploadProps = {
  name: "file",
  action: "https://httpbin.org/post",
  beforeUpload: imageValidation(150, "KB"),
  maxCount: 1,
  listType: "picture-card",
};

export default function FormItemPhoto({
  form,
  fileList,
  setFileList,
  setPhotoUrl,
  selectedTemplate,
}: {
  form: FormInstance<any>;
  fileList: any[];
  setFileList: React.Dispatch<React.SetStateAction<UploadFile<any>[]>>;
  setPhotoUrl: any;
  selectedTemplate: TemplateType;
}) {
  return (
    <Form.Item
      name="photoFileName"
      label="Photo"
      className={selectedTemplate === "Photo" ? "" : "hidden"}
      getValueFromEvent={normFile}
      rules={selectedTemplate === "Photo" ? [requiredRule] : undefined}
    >
      <div className="flex gap-4">
        <Upload
          {...props}
          fileList={fileList}
          onChange={handleUploadOnChange(
            "photoFileName",
            form,
            setFileList,
            setPhotoUrl
          )}
        >
          <div className="relative">
            <PlusOutlined />
            <div className="mt-2">Upload</div>

            <ul className="text-textSecondary leading-5 list-disc absolute left-[110px] -top-6 text-left w-40">
              <li>Max file size: 150KB</li>
              <li>Recommended size: 358*220px</li>
            </ul>
          </div>
        </Upload>
      </div>
    </Form.Item>
  );
}
