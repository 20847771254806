import { useEffect, useRef, useState } from "react";
import { Table } from "antd";

import { useGetProductSongsQuery } from "../../../graphql/operations/trialMode/trialContent/get-product-songs";
import { useGetProductVideosQuery } from "../../../graphql/operations/trialMode/trialContent/get-product-videos";
import { useCreateSongAndMarketsMutation } from "../../../graphql/operations/trialMode/trialContent/create-song-and-markets";
import { useDeleteSongAndMarketsMutation } from "../../../graphql/operations/trialMode/trialContent/delete-song-and-markets";
import { useCreateVideoAndMarketsMutation } from "../../../graphql/operations/trialMode/trialContent/create-video-and-markets";
import { useDeleteVideoAndMarketsMutation } from "../../../graphql/operations/trialMode/trialContent/delete-video-and-markets";

import ContentTitle from "../../../components/ui/ContentTitle";
import Spinner from "../../../components/layout/spinner/Spinner";
import getInitialMarket from "../../../utils/getInitialMarket";
import errorMessage from "../utils/errorMessage";
import withUserPrograms from "../withUserPrograms";
import { MarketType } from "../types";
import { normalizeSongData, normalizeVideoData } from "./utils/normalizeData";
import TableMenu from "./TableMenu";
import { createSongColumns, createVideoColumns } from "./columns";
import {
  ContentType,
  TrialContentSongType,
  TrialContentVideoType,
} from "./types";
import AudioModel from "../components/AudioModel";
import VideoModel from "../components/VideoModel";

function TrialContent() {
  const audioRef = useRef<HTMLAudioElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const initialMarket = getInitialMarket();

  const [selectedContentType, setSelectedContentType] =
    useState<ContentType>("song");
  const [selectedMarket, setSelectedMarket] =
    useState<MarketType>(initialMarket);
  const [songDataSource, setSongDataSource] = useState<TrialContentSongType[]>(
    []
  );
  const [videoDataSource, setVideoDataSource] = useState<
    TrialContentVideoType[]
  >([]);
  const [editingSong, setEditingSong] = useState<TrialContentSongType | null>(
    null
  );
  const [editingVideo, setEditingVideo] =
    useState<TrialContentVideoType | null>(null);
  const [editingSegmentation, setEditingSegmentation] =
    useState<number>(Infinity);
  const [modalSongData, setModalSongData] = useState<TrialContentSongType>();
  const [modalVideoData, setModalVideoData] = useState<TrialContentVideoType>();

  const isEditing = Boolean(editingSong || editingVideo);
  const paginationSetting = {
    hideOnSinglePage: true,
    pageSize: 10,
    disabled: isEditing,
  };

  const {
    data: songData,
    loading: loadingOfGetSongData,
    error: errorOfGetSongData,
    refetch: refetchSongData,
  } = useGetProductSongsQuery({
    fetchPolicy: "no-cache",
  });
  const {
    data: videoData,
    loading: loadingOfGetVideoData,
    error: errorOfGetVideoData,
    refetch: refetchVideoData,
  } = useGetProductVideosQuery({
    fetchPolicy: "no-cache",
  });
  const [createSongAndMarkets, { loading: loadingOfCreateSongAndMarkets }] =
    useCreateSongAndMarketsMutation();
  const [deleteSongAndMarkets, { loading: loadingOfDeleteSongAndMarkets }] =
    useDeleteSongAndMarketsMutation();
  const [createVideoAndMarkets, { loading: loadingOfCreateVideoAndMarkets }] =
    useCreateVideoAndMarketsMutation();
  const [deleteVideoAndMarkets, { loading: loadingOfDeleteVideoAndMarkets }] =
    useDeleteVideoAndMarketsMutation();

  useEffect(() => {
    if (songData) setSongDataSource(normalizeSongData(songData));
    if (videoData) setVideoDataSource(normalizeVideoData(videoData));
  }, [songData, videoData]);

  useEffect(() => {
    audioRef.current?.load();
  }, [modalSongData]);

  useEffect(() => {
    videoRef.current?.load();
  }, [modalVideoData]);

  if (errorOfGetSongData || errorOfGetVideoData) errorMessage();
  if (loadingOfGetSongData || loadingOfGetVideoData) return <Spinner />;

  return (
    <div>
      <ContentTitle title="Trial Content" />

      <div className="mx-6 pt-5 pb-24">
        <div className="px-6 bg-white">
          <TableMenu
            isEditing={isEditing}
            setSelectedContentType={setSelectedContentType}
            selectedMarket={selectedMarket}
            setSelectedMarket={setSelectedMarket}
          />

          {selectedContentType === "song" ? (
            <Table
              className="custom-table-style"
              dataSource={songDataSource}
              columns={createSongColumns(
                selectedMarket,
                editingSong,
                setEditingSong,
                editingSegmentation,
                setEditingSegmentation,
                createSongAndMarkets,
                deleteSongAndMarkets,
                refetchSongData,
                setModalSongData
              )}
              pagination={paginationSetting}
            />
          ) : (
            <Table
              className="custom-table-style"
              dataSource={videoDataSource}
              columns={createVideoColumns(
                selectedMarket,
                editingVideo,
                setEditingVideo,
                editingSegmentation,
                setEditingSegmentation,
                createVideoAndMarkets,
                deleteVideoAndMarkets,
                refetchVideoData,
                setModalVideoData
              )}
              pagination={paginationSetting}
            />
          )}
        </div>
      </div>

      <AudioModel
        modalData={modalSongData}
        setModalData={setModalSongData}
        audioRef={audioRef}
      />

      <VideoModel
        modalData={modalVideoData}
        setModalData={setModalVideoData}
        videoRef={videoRef}
      />

      {loadingOfCreateSongAndMarkets ||
      loadingOfDeleteSongAndMarkets ||
      loadingOfCreateVideoAndMarkets ||
      loadingOfDeleteVideoAndMarkets ? (
        <Spinner />
      ) : null}
    </div>
  );
}

export default withUserPrograms(TrialContent, "Trial Content");
