import { FormInstance } from "antd";
import type { ColumnsType } from "antd/es/table";

import { sortColumn } from "../../constants";
import { DrawerActionType } from "../../types";
import { Image, DateRange, Actions } from "../components/FormCells";
import { RegularEventsDataType } from "../types";

export function createColumns(
  form: FormInstance<any>,
  setOpenDrawer: (value: React.SetStateAction<boolean>) => void,
  setDrawerAction: (value: React.SetStateAction<DrawerActionType>) => void,
  refetch: any,
  hasKey?: boolean
): ColumnsType<RegularEventsDataType> {
  const keyColumn = hasKey ? [sortColumn] : [];

  return [
    ...keyColumn,
    {
      title: "Title",
      dataIndex: "title",
    },
    {
      title: "Image",
      dataIndex: "imageUrl",
      render: (value: string) => <Image src={value} />,
    },
    {
      title: "Date range",
      dataIndex: "dateRange",
      render: (_, record: RegularEventsDataType) => (
        <DateRange startDate={record.publishDate} endDate={record.stopDate} />
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, record: RegularEventsDataType) => (
        <Actions
          eventType="RegularEvent"
          record={record}
          form={form}
          setOpenDrawer={setOpenDrawer}
          setDrawerAction={setDrawerAction}
          refetch={refetch}
        />
      ),
    },
  ];
}
