import { Radio } from "antd";
import type { FormInstance } from "antd";

import MarketDropdown from "../../../../components/ui/MarketDropdown";
import ButtonCreate from "../../components/ButtonCreate";
import { MarketType, DrawerActionType } from "../../types";

export default function TableMenu({
  form,
  setDisplayType,
  selectedMarket,
  setSelectedMarket,
  setOpenDrawer,
  setDrawerAction,
}: {
  form: FormInstance<any>;
  setDisplayType: (value: React.SetStateAction<string>) => void;
  selectedMarket: MarketType;
  setSelectedMarket: (value: React.SetStateAction<MarketType>) => void;
  setOpenDrawer: (value: React.SetStateAction<boolean>) => void;
  setDrawerAction: (value: React.SetStateAction<DrawerActionType>) => void;
}) {
  return (
    <div className="flex justify-between px-2 py-4">
      <Radio.Group
        optionType="button"
        options={[
          {
            value: "In use",
            label: "In use",
          },
          {
            value: "Not in use",
            label: "Not in use",
          },
        ]}
        defaultValue="In use"
        onChange={(e) => setDisplayType(e.target.value)}
      />

      <div className="flex gap-4">
        <MarketDropdown
          selectedMarket={selectedMarket}
          setSelectedMarket={setSelectedMarket}
        />

        <ButtonCreate
          form={form}
          setDrawerAction={setDrawerAction}
          setOpenDrawer={setOpenDrawer}
        />
      </div>
    </div>
  );
}
