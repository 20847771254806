import { Button } from "antd";

import { TrialContentType } from "./types";

export default function TableActions({
  record,
  editingData,
  editingSegmentation,
  segmentationOrder,
  onEdit,
  onCancel,
  onSave,
}: {
  record: TrialContentType;
  editingData: TrialContentType | null;
  editingSegmentation: number;
  segmentationOrder: number;
  onEdit: () => void;
  onCancel: () => void;
  onSave: () => void;
}) {
  const isEditing = editingData && editingData.id === record.id;

  return (
    <div className="flex gap-4">
      {isEditing ? (
        <>
          <Button
            type="link"
            className="w-9 p-0"
            onClick={onSave}
            disabled={isEditing && editingSegmentation === segmentationOrder}
          >
            Save
          </Button>

          <Button type="link" className="w-12 p-0" onClick={onCancel}>
            Cancel
          </Button>
        </>
      ) : (
        <>
          <Button
            type="link"
            className="w-9 p-0"
            onClick={onEdit}
            disabled={editingData !== null && !isEditing}
          >
            Edit
          </Button>

          <div className="w-12" />
        </>
      )}
    </div>
  );
}
