import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetTrialCampaignChannelQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetTrialCampaignChannelQuery = { __typename?: 'Query', trialCampaignChannel: Array<{ __typename?: 'TrialCampaignChannel', channel?: string | null }> };


export const GetTrialCampaignChannelDocument = gql`
    query GetTrialCampaignChannel {
  trialCampaignChannel {
    channel
  }
}
    `;

/**
 * __useGetTrialCampaignChannelQuery__
 *
 * To run a query within a React component, call `useGetTrialCampaignChannelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrialCampaignChannelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrialCampaignChannelQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTrialCampaignChannelQuery(baseOptions?: Apollo.QueryHookOptions<GetTrialCampaignChannelQuery, GetTrialCampaignChannelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTrialCampaignChannelQuery, GetTrialCampaignChannelQueryVariables>(GetTrialCampaignChannelDocument, options);
      }
export function useGetTrialCampaignChannelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTrialCampaignChannelQuery, GetTrialCampaignChannelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTrialCampaignChannelQuery, GetTrialCampaignChannelQueryVariables>(GetTrialCampaignChannelDocument, options);
        }
export type GetTrialCampaignChannelQueryHookResult = ReturnType<typeof useGetTrialCampaignChannelQuery>;
export type GetTrialCampaignChannelLazyQueryHookResult = ReturnType<typeof useGetTrialCampaignChannelLazyQuery>;
export type GetTrialCampaignChannelQueryResult = Apollo.QueryResult<GetTrialCampaignChannelQuery, GetTrialCampaignChannelQueryVariables>;