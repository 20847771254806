import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateCtaContentsMutationVariables = Types.Exact<{
  CTAContents?: Types.InputMaybe<Array<Types.Wf_TrialModeLeads_CallToActionContentInput> | Types.Wf_TrialModeLeads_CallToActionContentInput>;
}>;


export type CreateCtaContentsMutation = { __typename?: 'Mutation', wf_trialModeLeads: { __typename?: 'WF_TrialModeLeads_Mutation_Payload', count: number } };


export const CreateCtaContentsDocument = gql`
    mutation CreateCTAContents($CTAContents: [WF_TrialModeLeads_CallToActionContentInput!]) {
  wf_trialModeLeads(addRange: {callToActionContents: $CTAContents}) {
    count
  }
}
    `;
export type CreateCtaContentsMutationFn = Apollo.MutationFunction<CreateCtaContentsMutation, CreateCtaContentsMutationVariables>;

/**
 * __useCreateCtaContentsMutation__
 *
 * To run a mutation, you first call `useCreateCtaContentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCtaContentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCtaContentsMutation, { data, loading, error }] = useCreateCtaContentsMutation({
 *   variables: {
 *      CTAContents: // value for 'CTAContents'
 *   },
 * });
 */
export function useCreateCtaContentsMutation(baseOptions?: Apollo.MutationHookOptions<CreateCtaContentsMutation, CreateCtaContentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCtaContentsMutation, CreateCtaContentsMutationVariables>(CreateCtaContentsDocument, options);
      }
export type CreateCtaContentsMutationHookResult = ReturnType<typeof useCreateCtaContentsMutation>;
export type CreateCtaContentsMutationResult = Apollo.MutationResult<CreateCtaContentsMutation>;
export type CreateCtaContentsMutationOptions = Apollo.BaseMutationOptions<CreateCtaContentsMutation, CreateCtaContentsMutationVariables>;