import type { GetProp, MenuProps } from "antd";

import { MenuItemsType } from "../types";

type MenuItem = GetProp<MenuProps, "items">[number];

function getItem(
  label: React.ReactNode,
  key?: React.Key | null,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

export default function getMenuItems(items: MenuItemsType[]) {
  return items.map(({ category, icon, subItems }) => {
    const children =
      subItems && subItems.length > 0
        ? subItems.map(({ subItemName }: { subItemName: string }) => {
            return getItem(subItemName, subItemName);
          })
        : undefined;

    return getItem(
      category,
      category,
      <img src={icon} alt={category} className="w-5 h-5" />,
      children
    );
  });
}
