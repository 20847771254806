import * as Types from './@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateGoGoLiveMutationVariables = Types.Exact<{
  GGLData?: Types.InputMaybe<Array<Types.Club_WorldFamilyClub_GoGoLivePromotionInput> | Types.Club_WorldFamilyClub_GoGoLivePromotionInput>;
}>;


export type CreateGoGoLiveMutation = { __typename?: 'Mutation', club_worldFamilyClub: { __typename?: 'Club_WorldFamilyClub_Mutation_Payload', count: number } };


export const CreateGoGoLiveDocument = gql`
    mutation CreateGoGoLive($GGLData: [Club_WorldFamilyClub_GoGoLivePromotionInput!]) {
  club_worldFamilyClub(addRange: {goGoLivePromotions: $GGLData}) {
    count
  }
}
    `;
export type CreateGoGoLiveMutationFn = Apollo.MutationFunction<CreateGoGoLiveMutation, CreateGoGoLiveMutationVariables>;

/**
 * __useCreateGoGoLiveMutation__
 *
 * To run a mutation, you first call `useCreateGoGoLiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGoGoLiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGoGoLiveMutation, { data, loading, error }] = useCreateGoGoLiveMutation({
 *   variables: {
 *      GGLData: // value for 'GGLData'
 *   },
 * });
 */
export function useCreateGoGoLiveMutation(baseOptions?: Apollo.MutationHookOptions<CreateGoGoLiveMutation, CreateGoGoLiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGoGoLiveMutation, CreateGoGoLiveMutationVariables>(CreateGoGoLiveDocument, options);
      }
export type CreateGoGoLiveMutationHookResult = ReturnType<typeof useCreateGoGoLiveMutation>;
export type CreateGoGoLiveMutationResult = Apollo.MutationResult<CreateGoGoLiveMutation>;
export type CreateGoGoLiveMutationOptions = Apollo.BaseMutationOptions<CreateGoGoLiveMutation, CreateGoGoLiveMutationVariables>;