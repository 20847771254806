import { renderDecodedId } from "../../../utils/renderDecodedId";
import { MarketType } from "../types";
import { TrialContentSongType, TrialContentVideoType } from "./types";

const segmentations = ["Awareness", "Interest", "Decision"];

export function handleSongSave(
  record: TrialContentSongType,
  segmentationOrder: number,
  selectedMarket: MarketType,
  setEditingSong: React.Dispatch<
    React.SetStateAction<TrialContentSongType | null>
  >,
  editingSegmentation: number,
  setEditingSegmentation: React.Dispatch<React.SetStateAction<number>>,
  createSongAndMarkets: any,
  deleteSongAndMarkets: any,
  refetchSongData: any
) {
  let mutationFunction;
  let variables;

  if (segmentationOrder > editingSegmentation) {
    const slicedSegmentations = segmentations.slice(
      editingSegmentation,
      segmentationOrder
    );
    variables = {
      productSongAndMarkets: slicedSegmentations.map((segmentation) => ({
        productSongId: parseInt(renderDecodedId(record.id)),
        market: selectedMarket,
        segmentation,
      })),
    };
    mutationFunction = createSongAndMarkets;
  }

  if (segmentationOrder < editingSegmentation) {
    const slicedSegmentations = segmentations.slice(
      segmentationOrder,
      editingSegmentation
    );
    variables = {
      productSongAndMarkets: record.productSongAndMarkets.reduce<
        { productSongAndMarketId: number }[]
      >((result, { id, market, segmentation }) => {
        if (
          market === selectedMarket &&
          slicedSegmentations.includes(segmentation)
        )
          result.push({
            productSongAndMarketId: parseInt(renderDecodedId(id)),
          });

        return result;
      }, []),
    };
    mutationFunction = deleteSongAndMarkets;
  }

  mutationFunction({ variables })
    .then(() => {
      refetchSongData();
      setEditingSong(null);
      setEditingSegmentation(Infinity);
    })
    .catch((e: any) => console.log(e));
}

export function handleVideoSave(
  record: TrialContentVideoType,
  segmentationOrder: number,
  selectedMarket: MarketType,
  setEditingVideo: React.Dispatch<
    React.SetStateAction<TrialContentVideoType | null>
  >,
  editingSegmentation: number,
  setEditingSegmentation: React.Dispatch<React.SetStateAction<number>>,
  createVideoAndMarkets: any,
  deleteVideoAndMarkets: any,
  refetchVideoData: any
) {
  let mutationFunction;
  let variables;

  if (segmentationOrder > editingSegmentation) {
    const slicedSegmentations = segmentations.slice(
      editingSegmentation,
      segmentationOrder
    );
    variables = {
      productVideoAndMarkets: slicedSegmentations.map((segmentation) => ({
        productVideoId: parseInt(renderDecodedId(record.id)),
        market: selectedMarket,
        segmentation,
      })),
    };
    mutationFunction = createVideoAndMarkets;
  }

  if (segmentationOrder < editingSegmentation) {
    const slicedSegmentations = segmentations.slice(
      segmentationOrder,
      editingSegmentation
    );
    variables = {
      productVideoAndMarkets: record.productVideoAndMarkets.reduce<
        { productVideoAndMarketId: number }[]
      >((result, { id, market, segmentation }) => {
        if (
          market === selectedMarket &&
          slicedSegmentations.includes(segmentation)
        )
          result.push({
            productVideoAndMarketId: parseInt(renderDecodedId(id)),
          });

        return result;
      }, []),
    };
    mutationFunction = deleteVideoAndMarkets;
  }

  mutationFunction({ variables })
    .then(() => {
      refetchVideoData();
      setEditingVideo(null);
      setEditingSegmentation(Infinity);
    })
    .catch((e: any) => console.log(e));
}
