import { CaretRightOutlined, RightOutlined } from "@ant-design/icons";

import cn from "../../../utils/cn";
import getCharacterSrc from "./utils/getCharacterSrc";
import { JourneyCardType } from "./types";

export function TemplatePreview({
  data,
  className,
}: {
  data: JourneyCardType;
  className?: string;
}) {
  const {
    greeting,
    experiencePromotionWording,
    experienceNavigationWording,
    experienceCharacter,
    isDisplayVideo,
    videoWording,
  } = data;

  return (
    <div
      className={cn(
        "w-[390px] h-[128px] py-2 bg-gradient-to-b from-[#f5e2cd] to-[#faeee2] text-black",
        className
      )}
    >
      <div className="flex justify-between items-center h-8 px-4">
        <h2 className="font-semibold text-[17px] leading-6">
          {greeting && greeting.replace("{User's Name}", "Ms. Lee")}
        </h2>

        {isDisplayVideo ? (
          <div className="flex justify-center items-center gap-1 border border-solid border-orange1 bg-white px-3 py-2 rounded-full cursor-pointer">
            <p className="text-xs text-orange5 font-medium">{videoWording}</p>
            <div className="w-4 h-4 rounded-full bg-orange5 flex justify-center items-center">
              <CaretRightOutlined className="text-white text-[10px]" />
            </div>
          </div>
        ) : (
          <div />
        )}
      </div>

      <div className="min-h-16 flex justify-between items-center gap-2 mt-4 px-4 py-[10px] bg-gradient-to-r from-white from-80% to-transparent rounded-r-full">
        <div className="w-3/5 flex items-center gap-[6px]">
          {experienceCharacter && experienceCharacter !== "None" ? (
            <img
              className="custom-avatar w-11 h-11"
              src={getCharacterSrc(experienceCharacter)}
              alt={experienceCharacter}
            />
          ) : null}

          <div>
            {experiencePromotionWording.split("\n").map((text, index) => (
              <p key={index} className="leading-5">
                {text}
              </p>
            ))}
          </div>
        </div>

        <div className="w-2/5 flex flex-row justify-end items-center gap-1 cursor-pointer">
          <div>
            {experienceNavigationWording.split("\n").map((text, index) => (
              <p key={index} className="leading-5">
                {text}
              </p>
            ))}
          </div>

          <RightOutlined />
        </div>
      </div>
    </div>
  );
}
