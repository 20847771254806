import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import getInitialMarket from "../../../../utils/getInitialMarket";
import {
  NotificationActionButtonType,
  TrialModeNotificationModifyData,
} from "../types";

export default function ButtonCreate({
  setModifyingData,
}: {
  setModifyingData: (
    value: React.SetStateAction<TrialModeNotificationModifyData | null>
  ) => void;
}) {
  return (
    <Button
      type="primary"
      style={{ marginLeft: 8 }}
      icon={<PlusOutlined />}
      onClick={() => {
        setModifyingData({
          actionButton: NotificationActionButtonType.None,
          buttonLinksTo: "",
          buttonLinksToUrl: "",
          buttonText: "",
          imageFileName: "",
          imageUrl: "",
          market: getInitialMarket(),
          serviceType: null,
          notificationName: "",
          segmentations: [],
          templateMessage: "",
          title: "",
          scheduleTime: "",
          jobStatus: "pending",
          testMemberNo: "",
        });
      }}
    >
      Create New
    </Button>
  );
}
