import * as Types from '../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSegmentationQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetSegmentationQuery = { __typename?: 'Query', wf_trialModeLeads_trialSegmentation?: { __typename?: 'WF_TrialModeLeads_TrialSegmentationConnection', nodes?: Array<{ __typename?: 'WF_TrialModeLeads_TrialSegmentation', segmentation?: string | null, segmentationDescription?: string | null }> | null } | null };


export const GetSegmentationDocument = gql`
    query GetSegmentation {
  wf_trialModeLeads_trialSegmentation {
    nodes {
      segmentation
      segmentationDescription
    }
  }
}
    `;

/**
 * __useGetSegmentationQuery__
 *
 * To run a query within a React component, call `useGetSegmentationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSegmentationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSegmentationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSegmentationQuery(baseOptions?: Apollo.QueryHookOptions<GetSegmentationQuery, GetSegmentationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSegmentationQuery, GetSegmentationQueryVariables>(GetSegmentationDocument, options);
      }
export function useGetSegmentationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSegmentationQuery, GetSegmentationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSegmentationQuery, GetSegmentationQueryVariables>(GetSegmentationDocument, options);
        }
export type GetSegmentationQueryHookResult = ReturnType<typeof useGetSegmentationQuery>;
export type GetSegmentationLazyQueryHookResult = ReturnType<typeof useGetSegmentationLazyQuery>;
export type GetSegmentationQueryResult = Apollo.QueryResult<GetSegmentationQuery, GetSegmentationQueryVariables>;