import * as Types from './@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteUserProgramMutationVariables = Types.Exact<{
  deleteData: Array<Types.Wf_AdminFirst_AfUserProgramInput> | Types.Wf_AdminFirst_AfUserProgramInput;
}>;


export type DeleteUserProgramMutation = { __typename?: 'Mutation', wf_adminFirst: { __typename?: 'WF_AdminFirst_Mutation_Payload', count: number } };


export const DeleteUserProgramDocument = gql`
    mutation deleteUserProgram($deleteData: [WF_AdminFirst_AfUserProgramInput!]!) {
  wf_adminFirst(removeRange: {afUserPrograms: $deleteData}) {
    count
  }
}
    `;
export type DeleteUserProgramMutationFn = Apollo.MutationFunction<DeleteUserProgramMutation, DeleteUserProgramMutationVariables>;

/**
 * __useDeleteUserProgramMutation__
 *
 * To run a mutation, you first call `useDeleteUserProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserProgramMutation, { data, loading, error }] = useDeleteUserProgramMutation({
 *   variables: {
 *      deleteData: // value for 'deleteData'
 *   },
 * });
 */
export function useDeleteUserProgramMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserProgramMutation, DeleteUserProgramMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserProgramMutation, DeleteUserProgramMutationVariables>(DeleteUserProgramDocument, options);
      }
export type DeleteUserProgramMutationHookResult = ReturnType<typeof useDeleteUserProgramMutation>;
export type DeleteUserProgramMutationResult = Apollo.MutationResult<DeleteUserProgramMutation>;
export type DeleteUserProgramMutationOptions = Apollo.BaseMutationOptions<DeleteUserProgramMutation, DeleteUserProgramMutationVariables>;