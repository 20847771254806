import { apiCall } from "../../../../utils/apiCall";

export const fetchImportantNoticeData = async () => {
  const header = {
    headers: {
      accept: "text/plain",
      "Content-Type": "application/json",
      "User-Code": "P725",
    },
  };
  try {
    const data = await apiCall({
      method: "GET",
      header: header,
      endPoint: process.env.REACT_APP_IMPORTANT_NOTICE + "-list",
    });
    console.log("data", data);
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
