import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateFeaturedEventsSortIdMutationVariables = Types.Exact<{
  FeaturedEventData?: Types.InputMaybe<Array<Types.Wf_TrialModeLeads_FeaturedEventInput> | Types.Wf_TrialModeLeads_FeaturedEventInput>;
}>;


export type UpdateFeaturedEventsSortIdMutation = { __typename?: 'Mutation', wf_trialModeLeads: { __typename?: 'WF_TrialModeLeads_Mutation_Payload', count: number } };


export const UpdateFeaturedEventsSortIdDocument = gql`
    mutation UpdateFeaturedEventsSortId($FeaturedEventData: [WF_TrialModeLeads_FeaturedEventInput!]) {
  wf_trialModeLeads(updateRange: {featuredEvents: $FeaturedEventData}) {
    count
  }
}
    `;
export type UpdateFeaturedEventsSortIdMutationFn = Apollo.MutationFunction<UpdateFeaturedEventsSortIdMutation, UpdateFeaturedEventsSortIdMutationVariables>;

/**
 * __useUpdateFeaturedEventsSortIdMutation__
 *
 * To run a mutation, you first call `useUpdateFeaturedEventsSortIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFeaturedEventsSortIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFeaturedEventsSortIdMutation, { data, loading, error }] = useUpdateFeaturedEventsSortIdMutation({
 *   variables: {
 *      FeaturedEventData: // value for 'FeaturedEventData'
 *   },
 * });
 */
export function useUpdateFeaturedEventsSortIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFeaturedEventsSortIdMutation, UpdateFeaturedEventsSortIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFeaturedEventsSortIdMutation, UpdateFeaturedEventsSortIdMutationVariables>(UpdateFeaturedEventsSortIdDocument, options);
      }
export type UpdateFeaturedEventsSortIdMutationHookResult = ReturnType<typeof useUpdateFeaturedEventsSortIdMutation>;
export type UpdateFeaturedEventsSortIdMutationResult = Apollo.MutationResult<UpdateFeaturedEventsSortIdMutation>;
export type UpdateFeaturedEventsSortIdMutationOptions = Apollo.BaseMutationOptions<UpdateFeaturedEventsSortIdMutation, UpdateFeaturedEventsSortIdMutationVariables>;