import axios from "axios";
// ./src/azure-storage-blob.ts

// <snippet_package>
// THIS IS SAMPLE CODE ONLY - NOT MEANT FOR PRODUCTION USE
import { BlobServiceClient, ContainerClient } from "@azure/storage-blob";
import { renderApiUri } from "../pages/clubApp/design/EventBanner";

// THIS IS SAMPLE CODE ONLY - DON'T STORE TOKEN IN PRODUCTION CODE
let sasToken: string | undefined;
let containerName: string;
const getToken = async (type: string, userCode: string) => {
  try {
    const apiUrl = renderApiUri();
    const endPoint =
      type === "appEventBanner"
        ? "app-event-banner-image"
        : "app-event-region-banner-image";
    const { data } = await axios.get(
      `${apiUrl}/azure-blob/${endPoint}/upload-token`,
      {
        headers: { "User-Code": userCode },
      }
    );
    sasToken = data.token;
    containerName = data.containerName;
  } catch (error) {
    console.log(error);
  }
};

const storageAccountName = "clubservice"; // Fill string with your Storage resource name
// </snippet_package>

// <snippet_isStorageConfigured>
// Feature flag - disable storage feature to app if not configured
export const isStorageConfigured = () => {
  return !storageAccountName || !sasToken ? false : true;
};
// </snippet_isStorageConfigured>

// <snippet_getBlobsInContainer>
// return list of blobs in container to display
// const getBlobsInContainer = async (containerClient: ContainerClient) => {
//   const returnedBlobUrls: string[] = [];

//   // get list of blobs in container
//   // eslint-disable-next-line
//   for await (const blob of containerClient.listBlobsFlat()) {
//     // if image is public, just construct URL
//     returnedBlobUrls.push(
//       `https://${storageAccountName}.blob.core.windows.net/${containerName}/${blob.name}`
//     );
//   }

//   return returnedBlobUrls;
// };
// </snippet_getBlobsInContainer>

// <snippet_createBlobInContainer>
const createBlobInContainer = async (
  containerClient: ContainerClient,
  file: File,
  type: string
) => {
  const newFileName = `${file.name.split(".")[0]}_${Date.now()}.${
    file.name.split(".")[1]
  }`.toLowerCase();
  // create blobClient for container
  const blobClientEventBanner = containerClient.getBlockBlobClient(
    `data/app-event-banner-img/${newFileName}`
  );
  const blobClientRegionEventBanner = containerClient.getBlockBlobClient(
    `data/app-event-region-banner-img/${newFileName}`
  );

  // set mimetype as determined from browser with file upload control
  const options = { blobHTTPHeaders: { blobContentType: file.type } };

  // upload file
  if (type === "regionEventBanner") {
    await blobClientRegionEventBanner.uploadBrowserData(file, options);
  } else {
    await blobClientEventBanner.uploadBrowserData(file, options);
    await blobClientRegionEventBanner.uploadBrowserData(file, options);
  }

  return newFileName;
};
// </snippet_createBlobInContainer>

// <snippet_uploadFileToBlob>
const uploadFileToClubServiceBlob = async (
  file: File | null | undefined,
  type: string,
  userCode: string
) => {
  await getToken(type, userCode);

  if (!file) return [];

  // get BlobService = notice `?` is pulled out of sasToken - if created in Azure portal
  const blobService = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
  );

  // get Container - full public read access
  const containerClient: ContainerClient =
    blobService.getContainerClient(containerName);
  // await containerClient.createIfNotExists({
  //   access: 'container',
  // });

  // upload file
  const newFileName = await createBlobInContainer(containerClient, file, type);

  // get list of blobs in container
  return newFileName;
};
// </snippet_uploadFileToBlob>

export default uploadFileToClubServiceBlob;
