import { useEffect, useRef, useState } from "react";
import { Form } from "antd";

import DrawerCustom from "../../../../components/custom/DrawerCustom";
import Spinner from "../../../../components/layout/spinner/Spinner";

import { useGetUploadProductSongsQuery } from "../../../../graphql/operations/trialMode/trialContentUpload/get-upload-product-songs";
import { useUpdateSongsSortIdMutation } from "../../../../graphql/operations/trialMode/trialContentUpload/update-songs-sort-id";
import { useCreateUploadSongMutation } from "../../../../graphql/operations/trialMode/trialContentUpload/create-upload-song";
import { useUploadFileMutation } from "../../../../graphql/operations/upload-file";
import { useUpdateUploadSongMutation } from "../../../../graphql/operations/trialMode/trialContentUpload/update-upload-song";
import { useDeleteUploadSongMutation } from "../../../../graphql/operations/trialMode/trialContentUpload/delete-upload-song";

import { FormItemTitle } from "../../components/FormItems";
import AudioModel from "../../components/AudioModel";
import errorMessage from "../../utils/errorMessage";
import { DrawerActionType } from "../../types";
import TableMenu from "../components/TableMenu";
import DraggableTable from "../components/DraggableTable";
import {
  FormItemCategory,
  FormItemUploadLyric,
  FormItemUploadMp3,
} from "../components/FormItems";
import { normalizeUploadSongData } from "../utils/normalizeUploadData";
import {
  TrialContentUploadSongFormDataType,
  TrialContentUploadSongType,
} from "../types";
import { createSongColumns } from "./columns";
import handleSubmit from "./handleSubmit";

export default function ContentSong() {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [form] = Form.useForm();

  const [modalData, setModalData] = useState<TrialContentUploadSongType>();
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [drawerAction, setDrawerAction] = useState<DrawerActionType>("Create");
  const [songData, setSongData] = useState<TrialContentUploadSongType[]>([]);

  const [uploadFile] = useUploadFileMutation();
  const {
    data,
    loading: loadingOfGet,
    error: errorOfGet,
    refetch,
  } = useGetUploadProductSongsQuery({
    fetchPolicy: "no-cache",
  });
  const [createSong, { loading: loadingOfCreate }] =
    useCreateUploadSongMutation();
  const [updateSong, { loading: loadingOfUpdate }] =
    useUpdateUploadSongMutation();
  const [updateSortId, { loading: loadingOfUpdateSortId }] =
    useUpdateSongsSortIdMutation();
  const [updateSortIdInDrawer, { loading: loadingOfUpdateSortIdInDrawer }] =
    useUpdateSongsSortIdMutation();
  const [deleteSong, { loading: loadingOfDelete }] =
    useDeleteUploadSongMutation();

  useEffect(() => {
    if (data) setSongData(normalizeUploadSongData(data));
  }, [data]);

  useEffect(() => {
    audioRef.current?.load();
  }, [modalData]);

  if (errorOfGet) errorMessage();
  if (loadingOfGet || loadingOfUpdateSortId || loadingOfDelete)
    return <Spinner />;

  return (
    <div className="mx-6 py-5">
      <div className="px-6 bg-white">
        <TableMenu
          count={songData.length}
          setOpenDrawer={setOpenDrawer}
          setDrawerAction={setDrawerAction}
        />

        <DraggableTable
          contentType="song"
          data={songData}
          setData={setSongData}
          columns={createSongColumns(
            songData,
            form,
            setModalData,
            setOpenDrawer,
            setDrawerAction,
            deleteSong,
            refetch
          )}
          updateSortId={updateSortId}
          refetch={refetch}
        />

        <DrawerCustom
          title={`${drawerAction === "Create" ? "Create new" : "Edit"} song`}
          name="create-new-song"
          form={form}
          onFinish={(values: TrialContentUploadSongFormDataType) => {
            handleSubmit({
              values,
              form,
              songData,
              drawerAction,
              uploadFile,
              createSong,
              updateSong,
              updateSortId: updateSortIdInDrawer,
              refetch,
              setOpenDrawer,
            });
          }}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          isLoading={
            loadingOfCreate || loadingOfUpdate || loadingOfUpdateSortIdInDrawer
          }
        >
          <FormItemTitle name="songTitle" />
          <FormItemCategory />
          <FormItemUploadMp3 />
          <FormItemUploadLyric />
        </DrawerCustom>

        <AudioModel
          modalData={modalData}
          setModalData={setModalData}
          audioRef={audioRef}
        />
      </div>
    </div>
  );
}
