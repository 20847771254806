import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteRegularEventMutationVariables = Types.Exact<{
  regularEventId: Types.Scalars['Int']['input'];
}>;


export type DeleteRegularEventMutation = { __typename?: 'Mutation', wf_trialModeLeads: { __typename?: 'WF_TrialModeLeads_Mutation_Payload', count: number } };


export const DeleteRegularEventDocument = gql`
    mutation DeleteRegularEvent($regularEventId: Int!) {
  wf_trialModeLeads(remove: {regularEvent: {regularEventId: $regularEventId}}) {
    count
  }
}
    `;
export type DeleteRegularEventMutationFn = Apollo.MutationFunction<DeleteRegularEventMutation, DeleteRegularEventMutationVariables>;

/**
 * __useDeleteRegularEventMutation__
 *
 * To run a mutation, you first call `useDeleteRegularEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRegularEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRegularEventMutation, { data, loading, error }] = useDeleteRegularEventMutation({
 *   variables: {
 *      regularEventId: // value for 'regularEventId'
 *   },
 * });
 */
export function useDeleteRegularEventMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRegularEventMutation, DeleteRegularEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRegularEventMutation, DeleteRegularEventMutationVariables>(DeleteRegularEventDocument, options);
      }
export type DeleteRegularEventMutationHookResult = ReturnType<typeof useDeleteRegularEventMutation>;
export type DeleteRegularEventMutationResult = Apollo.MutationResult<DeleteRegularEventMutation>;
export type DeleteRegularEventMutationOptions = Apollo.BaseMutationOptions<DeleteRegularEventMutation, DeleteRegularEventMutationVariables>;