import * as Types from './@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllUsersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllUsersQuery = { __typename?: 'Query', wf_adminFirst_afUser?: { __typename?: 'WF_AdminFirst_AfUserConnection', edges?: Array<{ __typename?: 'WF_AdminFirst_AfUserEdge', node: { __typename?: 'WF_AdminFirst_AfUser', id: string, userName?: string | null, userEmail?: string | null, isAdministrator?: boolean | null, avatarUrl?: string | null, afUserMarkets?: Array<{ __typename?: 'WF_AdminFirst_AfUserMarket', market?: string | null } | null> | null, afUserServices?: Array<{ __typename?: 'WF_AdminFirst_AfUserService', afServiceId?: number | null } | null> | null, afUserPrograms?: Array<{ __typename?: 'WF_AdminFirst_AfUserProgram', afProgramId?: number | null, afProgram?: { __typename?: 'WF_AdminFirst_AfProgram', programName?: string | null } | null } | null> | null } }> | null } | null };


export const GetAllUsersDocument = gql`
    query GetAllUsers {
  wf_adminFirst_afUser(first: 100) {
    edges {
      node {
        id
        userName
        userEmail
        isAdministrator
        avatarUrl
        afUserMarkets {
          market
        }
        afUserServices {
          afServiceId
        }
        afUserPrograms {
          afProgramId
          afProgram {
            programName
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetAllUsersQuery__
 *
 * To run a query within a React component, call `useGetAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(GetAllUsersDocument, options);
      }
export function useGetAllUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(GetAllUsersDocument, options);
        }
export type GetAllUsersQueryHookResult = ReturnType<typeof useGetAllUsersQuery>;
export type GetAllUsersLazyQueryHookResult = ReturnType<typeof useGetAllUsersLazyQuery>;
export type GetAllUsersQueryResult = Apollo.QueryResult<GetAllUsersQuery, GetAllUsersQueryVariables>;