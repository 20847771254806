/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";

import clubAppSelectedIcon from "../../../assets/icons/menu/club-app-selected.png";
import clubAppIcon from "../../../assets/icons/menu/club-app.png";
import clubServiceSelectedIcon from "../../../assets/icons/menu/club-service-selected.png";
import clubServiceIcon from "../../../assets/icons/menu/club-service.png";
import sales188SelectedIcon from "../../../assets/icons/menu/sales188-selected.png";
import sales188Icon from "../../../assets/icons/menu/sales188.png";
import mktSelectedIcon from "../../../assets/icons/menu/mkt-selected.png";
import mktIcon from "../../../assets/icons/menu/mkt.png";
import techpressoSelectedIcon from "../../../assets/icons/menu/techpresso-selected.png";
import techpressoIcon from "../../../assets/icons/menu/techpresso.png";
import checkInSelectedIcon from "../../../assets/icons/menu/check-in-selected.png";
import checkInIcon from "../../../assets/icons/menu/check-in.png";
import trialModeSelectedIcon from "../../../assets/icons/menu/trial-mode-selected.png";
import trialModeIcon from "../../../assets/icons/menu/trial-mode.png";

import { Colors } from "../../../constants/Colors";
import {
  ServiceCategoryRouteTypes,
  ServiceRouteTypes,
  ServiceTypes,
  ThemeTypes,
} from "../../../types/types";
import { data } from "../../../data/data";
import { __currentUser__ } from "../../../graphql/policies";
import Spinner from "../spinner/Spinner";
import "./ServiceMenu.css";

interface ServiceMenuProps {
  setTheme: Dispatch<SetStateAction<ThemeTypes>>;
  selectedService: ServiceTypes;
  setSelectedService: any;
}

interface DisplayDataTypes {
  id: number;
  service: ServiceTypes;
  serviceRoute: ServiceRouteTypes;
}

const { services } = data;

const ServiceMenu = ({
  setTheme,
  selectedService,
  setSelectedService,
}: ServiceMenuProps) => {
  const currentUser = useReactiveVar(__currentUser__);
  const userServicesIds =
    currentUser?.afUserServices &&
    currentUser?.afUserServices.map((service) => service?.afServiceId);
  const [displayData, setDisplayData] = useState<DisplayDataTypes[]>([]);
  const navigate = useNavigate();

  const renderBgColor = () => {
    switch (selectedService) {
      case ServiceTypes.CLUB_APP:
        return Colors.lightOrange;
      case ServiceTypes.CLUB_SERVICE:
        return Colors.clubServiceBg;
      case ServiceTypes.SALES_188:
        return Colors.sales188Bg;
      case ServiceTypes.MKT:
        return Colors.mktBg;
      case ServiceTypes.TECHPRESSO:
        return Colors.darkTheme;
      case ServiceTypes.CHECK_IN:
        return Colors.white;
      case ServiceTypes.TRIAL_MODE:
        return Colors.trialModeBg;
      default:
        break;
    }
  };

  const renderIconPath = (service: any) => {
    switch (service) {
      case ServiceTypes.CLUB_APP:
        return service === selectedService ? clubAppSelectedIcon : clubAppIcon;
      case ServiceTypes.CLUB_SERVICE:
        return service === selectedService
          ? clubServiceSelectedIcon
          : clubServiceIcon;
      case ServiceTypes.SALES_188:
        return service === selectedService
          ? sales188SelectedIcon
          : sales188Icon;
      case ServiceTypes.MKT:
        return service === selectedService ? mktSelectedIcon : mktIcon;
      case ServiceTypes.TECHPRESSO:
        return service === selectedService
          ? techpressoSelectedIcon
          : techpressoIcon;
      case ServiceTypes.CHECK_IN:
        return service === selectedService ? checkInSelectedIcon : checkInIcon;
      case ServiceTypes.TRIAL_MODE:
        return service === selectedService
          ? trialModeSelectedIcon
          : trialModeIcon;
      default:
        break;
    }
  };

  const renderSelectedBgColor = (service: any) => {
    switch (service) {
      case ServiceTypes.CLUB_APP:
      case ServiceTypes.CLUB_SERVICE:
      case ServiceTypes.CHECK_IN:
        return Colors.menuSelectedOrange;
      case ServiceTypes.SALES_188:
      case ServiceTypes.MKT:
        return "rgba(255, 255, 255, 0.5)";
      case ServiceTypes.TECHPRESSO:
        return "rgba(0, 0, 0, 0.85)";
      case ServiceTypes.TRIAL_MODE:
        return Colors.trialModeSelectedBg;
      default:
        break;
    }
  };

  useEffect(() => {
    const filteredData = services.filter((service) =>
      userServicesIds?.includes(service.id)
    );
    setDisplayData(filteredData);
  }, []);

  useEffect(() => {
    if (selectedService === ServiceTypes.TECHPRESSO) {
      setTheme(ThemeTypes.DARK);
      return;
    }
    if (selectedService === ServiceTypes.TRIAL_MODE) {
      setTheme(ThemeTypes.TRIAL_MODE);
      return;
    }
    setTheme(ThemeTypes.LIGHT);
  }, [selectedService, setTheme]);

  return displayData.length > 0 ? (
    <div
      className="service-menu-container"
      style={{ backgroundColor: renderBgColor() }}
    >
      {displayData.map((service) => (
        <div
          key={service.id}
          onClick={() => {
            // TODO: Save theme to global variable
            if (selectedService !== service.service) {
              setSelectedService(service.service);
              navigate(
                `/${service.serviceRoute}/${ServiceCategoryRouteTypes.DASHBOARD}`
              );
            }
          }}
        >
          <div
            className="service-container"
            style={{
              backgroundColor:
                selectedService !== service.service
                  ? Colors.transparent
                  : renderSelectedBgColor(selectedService),
            }}
          >
            <img
              src={renderIconPath(service.service)}
              className="service-icon"
              alt=""
            />
          </div>
        </div>
      ))}
    </div>
  ) : (
    <Spinner />
  );
};

export default ServiceMenu;
