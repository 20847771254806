import { makeVar } from "@apollo/client";
import { TypePolicies } from "@apollo/client/cache/inmemory/policies";
import { CurrentUserType } from "../types/types";

export const __currentUser__ = makeVar<CurrentUserType | undefined>(undefined);
export const __jwtToken__ = makeVar(
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IjlHbW55RlBraGMzaE91UjIybXZTdmduTG83WSJ9.eyJhdWQiOiIyNjZjY2Y5Yy1mM2QxLTRjZjMtYTc1Ni0wNjU3MzE0NjJkMmQiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vYzNjOGJmNjctMDZkZi00Y2YwLWE3ZGYtOTY1ZjVjZjlmYmM5L3YyLjAiLCJpYXQiOjE2OTkyNDE4NjIsIm5iZiI6MTY5OTI0MTg2MiwiZXhwIjoxNjk5MjQ1NzYyLCJlbWFpbCI6IldhdGVyWWVoQHdvcmxkZmFtaWx5ZW5nbGlzaC5jb20iLCJncm91cHMiOlsiMDUzYWZhMGUtN2ExYS00MjQ0LWExY2EtZjY5Y2M2ZjczMWMwIiwiYjA1MjNkMTQtZmVjYi00YzQ3LTlhNzItYzFlNmM2MDg0ZWZhIiwiOWJlYmYyODAtNzU0ZS00NjZiLThhNWEtYzc4NGU0MzNmMGQwIiwiZDFlMDQ0OTQtMjdkZS00MDdkLWFjZjEtZjcxMDVjMTFhMjJjIiwiODVmNTAzY2YtODlmNC00NTU5LTk3ZDgtMDY3MTRlZDgwNTM3IiwiODczMDNhZGMtODczOS00MDE4LWI0YTEtNTE0NDFkZTliYjNjIiwiMmRhNzg4MjEtNmE4Yi00NzdiLTllNjUtNTk3ODQyNGU3ZjVhIiwiOWI3NjcyNDUtYjQzNS00NGM1LWE0ODEtNDg2MTUwNzhhNjFiIiwiNWNhM2Q0ZmQtMjlkOS00NWJiLWE5ZmEtM2U4MTRhZDM0ZWRiIiwiMTY4NDVlZmUtNGJhYi00ZDUwLTk4ZmMtM2Q0ODVjNjQwNDNjIl0sIm5hbWUiOiJXYXRlclllaCIsIm9pZCI6ImUwZmNkMDg2LTExYmEtNDI0Ni05YTRkLTNmN2Y0MzEzYzMwYyIsInByZWZlcnJlZF91c2VybmFtZSI6IldhdGVyWWVoQHdvcmxkZmFtaWx5ZW5nbGlzaC5jb20iLCJyaCI6IjAuQVNzQVo3X0l3OThHOEV5bjM1WmZYUG43eVp6UGJDYlI4X05NcDFZR1Z6RkdMUzByQU1ZLiIsInN1YiI6Inc1Y25Da19hNmJFczZPdmktaVBlZUE4RHNJU2xhUlNLSElxV2ZaS3NJb0EiLCJ0aWQiOiJjM2M4YmY2Ny0wNmRmLTRjZjAtYTdkZi05NjVmNWNmOWZiYzkiLCJ1dGkiOiI5a0ZyWWsyT1hVcWlsdjA2eVdCREFBIiwidmVyIjoiMi4wIiwid2lkcyI6WyJjZjFjMzhlNS0zNjIxLTQwMDQtYTdjYi04Nzk2MjRkY2VkN2MiLCI5Yjg5NWQ5Mi0yY2QzLTQ0YzctOWQwMi1hNmFjMmQ1ZWE1YzMiLCJiNzlmYmY0ZC0zZWY5LTQ2ODktODE0My03NmIxOTRlODU1MDkiXX0.gbaRE9p0jyp3Sxdmkn-i-Gh1gz4ps_h79SK8XOfu0MyO9RgjDiFu2UklJPWvy_qrsveIxNB9whM7PDjTEM0SZ-BbJlw5r-CwJ_2RKit1U5ZmMg7PF2q1o4bH6-gKMnWEodl2euh57xxYOU8dnMrLyejAziF08gAcSgjXcJGhr3qhp4Hn6bCXnZr06-5uhHYMjuhb3aPNk9v26XJUK2EZAYSVBEK0CAUA25E2X-_2C1F0H6O9wKB_kfoPb1ujEkVkk8gnLqXPAVO0yORkYc-iyyIA3E7dv9dk6V0Ep84EaBLeVycQoJUq3K4dU7AFZm0O8r1dAOW5oiA-pNhHsjX_jw"
);
export const __projectKey__ = makeVar(
  "r^iVV@5NNxd9n$RW*YLH5^n^vhAL2Cpm9i@@fAKR5f6dsE8xCM6a*rEkrG%e8CD5FFm9z@wD7RqgJMZP$EUc#PdpPb3SjVKiQ2Chpr9!b$5a8RbKj#BR&n*jk&uD!GAt"
);
export const __isPermissionPage__ = makeVar<boolean>(false);
export const policies: TypePolicies = {
  Query: {
    fields: {},
  },
};
