import * as Types from './@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetFaceCallVideosQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetFaceCallVideosQuery = { __typename?: 'Query', afFaceCallSong: Array<{ __typename?: 'Club_WorldFamilyClub_FaceCallSong', id: string, faceCallSeasonHolidayId?: number | null, startDate?: any | null, endDate?: any | null, vimeoVideoId?: string | null, teSong?: { __typename?: 'Club_WorldFamilyClub_TeSong', songName?: string | null } | null, faceCallSongAndPerformers?: Array<{ __typename?: 'Club_WorldFamilyClub_FaceCallSongAndPerformer', id: string, faceCallPerformer?: { __typename?: 'Club_WorldFamilyClub_FaceCallPerformer', id: string, performerName?: string | null } | null } | null> | null }> };


export const GetFaceCallVideosDocument = gql`
    query GetFaceCallVideos {
  afFaceCallSong {
    id
    teSong {
      songName
    }
    faceCallSongAndPerformers {
      id
      faceCallPerformer {
        id
        performerName
      }
    }
    faceCallSeasonHolidayId
    startDate
    endDate
    vimeoVideoId
  }
}
    `;

/**
 * __useGetFaceCallVideosQuery__
 *
 * To run a query within a React component, call `useGetFaceCallVideosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFaceCallVideosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFaceCallVideosQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFaceCallVideosQuery(baseOptions?: Apollo.QueryHookOptions<GetFaceCallVideosQuery, GetFaceCallVideosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFaceCallVideosQuery, GetFaceCallVideosQueryVariables>(GetFaceCallVideosDocument, options);
      }
export function useGetFaceCallVideosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFaceCallVideosQuery, GetFaceCallVideosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFaceCallVideosQuery, GetFaceCallVideosQueryVariables>(GetFaceCallVideosDocument, options);
        }
export type GetFaceCallVideosQueryHookResult = ReturnType<typeof useGetFaceCallVideosQuery>;
export type GetFaceCallVideosLazyQueryHookResult = ReturnType<typeof useGetFaceCallVideosLazyQuery>;
export type GetFaceCallVideosQueryResult = Apollo.QueryResult<GetFaceCallVideosQuery, GetFaceCallVideosQueryVariables>;