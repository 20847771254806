import { FormInstance } from "antd";

import { TrialContentUploadSongType } from "../types";

export default function setValuesOnEdit(
  record: TrialContentUploadSongType,
  form: FormInstance<any>
) {
  const { id, songTitle, productCategoryId, audioFileName, lyricsFileName } =
    record;
  const audioFileList = audioFileName
    ? [
        {
          uid: "0",
          name: audioFileName,
          status: "done",
        },
      ]
    : null;
  const alyricsFileList = lyricsFileName
    ? [
        {
          uid: "0",
          name: lyricsFileName,
          status: "done",
        },
      ]
    : null;

  form.setFieldsValue({
    id,
    songTitle,
    productCategoryId,
    audioFile: audioFileList,
    lyricsFile: alyricsFileList,
  });
}
