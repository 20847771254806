import { useNavigate } from "react-router-dom";
import "./Header.css";
import {
  CurrentUserType,
  ServiceTypes,
  ThemeTypes,
} from "../../../types/types";
import { Colors } from "../../../constants/Colors";
import Account from "./Account";
import { useReactiveVar } from "@apollo/client";
import { __isPermissionPage__ } from "../../../graphql/policies";

interface HeaderProps {
  theme: ThemeTypes;
  isLoggedIn: boolean;
  currentUser?: CurrentUserType;
  setSelectedService?: any;
  setSelectSubItem?: any;
}

const Header = ({
  theme,
  isLoggedIn,
  currentUser,
  setSelectedService,
  setSelectSubItem,
}: HeaderProps) => {
  const navigate = useNavigate();
  const isPermissionPage = useReactiveVar(__isPermissionPage__);

  const renderLogoPath = () => {
    switch (theme) {
      case ThemeTypes.LIGHT:
      case ThemeTypes.TRIAL_MODE:
        return require("../../../assets/images/logo-light.png");
      case ThemeTypes.DARK:
        return require("../../../assets/images/logo-dark.png");
      default:
        break;
    }
  };

  return (
    <div
      className="header-container"
      style={{
        backgroundColor:
          theme === ThemeTypes.DARK
            ? Colors.darkTheme
            : theme === ThemeTypes.TRIAL_MODE
            ? Colors.trialModeTheme
            : Colors.white,
        borderBottom: `1px solid ${
          theme === ThemeTypes.DARK ? "#1E2640" : Colors.lightWhite
        }`,
        zIndex: isPermissionPage ? 9999 : 999,
      }}
    >
      <img
        src={renderLogoPath()}
        className="logo"
        alt=""
        onClick={() => {
          setSelectedService(ServiceTypes.CLUB_APP);
          setSelectSubItem(undefined);
          navigate("/");
          window.location.reload();
        }}
      />
      {isLoggedIn && (
        <Account
          theme={theme}
          userName={currentUser?.userName}
          photoUrl={currentUser?.avatarUrl}
          isAdministrator={currentUser?.isAdministrator}
        />
      )}
    </div>
  );
};

export default Header;
