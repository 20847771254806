import { getEmbedUrl } from "../../utils/getVideoUrl";
import { CTADataType } from "../types";

export default function denormalizeCTAData(data: CTADataType) {
  const {
    template,
    buttonLinksTo = "",
    buttonText = "",
    imageCharacter,
    photoFileName,
    videoUrl,
  } = data;

  return {
    template,
    imageCharacter: template === "Image" ? imageCharacter : "",
    photoFileName: template === "Photo" ? photoFileName : "",
    videoUrl: template === "Video" ? getEmbedUrl(videoUrl) : "",
    buttonLinksTo,
    buttonText,
  };
}
