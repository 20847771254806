import { Button } from "antd";
import type { ColumnsType } from "antd/es/table";

import Tag from "../../../components/ui/Tag";
import getSegmentationDesc from "../utils/getSegmentationDesc";
import { SegmentationType } from "../types";
import { JourneyCardType, ExperienceType } from "./types";
import { TemplatePreview } from "./TemplatePreview";

export function createColumns(
  setEditingData: React.Dispatch<React.SetStateAction<JourneyCardType | null>>
): ColumnsType<JourneyCardType> {
  return [
    {
      title: "Segmentation",
      dataIndex: "segmentation",
      render: (value: SegmentationType) => {
        const color =
          value === "Awareness"
            ? "lightBlue"
            : value === "Decision"
            ? "gold"
            : "green";

        return <Tag color={color}>{getSegmentationDesc(value)}</Tag>;
      },
      filters: [
        { text: "Awareness", value: "Awareness" },
        { text: "Interest", value: "Interest" },
        { text: "Decision", value: "Decision" },
      ],
      onFilter: (value, record) =>
        record.segmentation.indexOf(String(value)) === 0,
    },
    {
      title: "Experience Type",
      dataIndex: "experienceName",
      render: (value: ExperienceType) => value,
      filters: [
        {
          text: "Entering the app for the first time",
          value: "Entering the app for the first time",
        },
        { text: "Visiting the app before", value: "Visiting the app before" },
        {
          text: "More than 7 days not using the app",
          value: "More than 7 days not using the app",
        },
      ],
      onFilter: (value, record) =>
        record.experienceName.indexOf(String(value)) === 0,
    },
    {
      title: "Template",
      render: (_, record: JourneyCardType) => (
        <div className="w-[195px] h-16">
          <TemplatePreview data={record} className="scale-50 origin-top-left" />
        </div>
      ),
    },
    {
      title: "Action",
      render: (_, record: JourneyCardType) => (
        <Button
          type="link"
          className="p-0"
          onClick={() => setEditingData(record)}
        >
          Edit
        </Button>
      ),
    },
  ];
}
