import { useState } from "react";
import "./Account.css";
import { NormalTagTypes, ThemeTypes } from "../../../types/types";
import { Colors } from "../../../constants/Colors";
import NormalTag from "../../tags/NormalTag";
import { useNavigate } from "react-router-dom";
import { __currentUser__ } from "../../../graphql/policies";

interface AccountProps {
  theme: ThemeTypes;
  photoUrl: any;
  userName: string | undefined | null;
  isAdministrator: boolean | undefined | null;
}

const Account = ({
  theme,
  photoUrl,
  userName,
  isAdministrator,
}: AccountProps) => {
  const [isExpand, setIsExpand] = useState(false);
  const navigate = useNavigate();

  const renderArrowDownPath = (theme: ThemeTypes) => {
    switch (theme) {
      case ThemeTypes.DARK:
        return require("../../../assets/icons/arrow-down-dark.png");
      case ThemeTypes.LIGHT:
      case ThemeTypes.TRIAL_MODE:
        return require("../../../assets/icons/arrow-down.png");
      default:
        break;
    }
  };

  const renderArrowUpPath = (theme: ThemeTypes) => {
    switch (theme) {
      case ThemeTypes.DARK:
        return require("../../../assets/icons/arrow-up-dark.png");
      case ThemeTypes.LIGHT:
      case ThemeTypes.TRIAL_MODE:
        return require("../../../assets/icons/arrow-up.png");
      default:
        break;
    }
  };

  const handleLogOut = () => {
    window.sessionStorage.clear();
    window.localStorage.removeItem("currentUser");
    __currentUser__(undefined);
    window.location.reload();
  };

  return (
    <div className="account-container" onClick={() => setIsExpand(!isExpand)}>
      <div
        className="avatar-container"
        style={{
          border: `2px solid ${
            theme === ThemeTypes.DARK ? Colors.darkBorder : Colors.lightIndigo
          }`,
        }}
      >
        <img src={photoUrl} className="avatar" alt="" />
      </div>
      <p
        style={{
          color: theme === ThemeTypes.DARK ? Colors.white : Colors.textPrimary,
        }}
      >
        {userName}
      </p>
      {isExpand ? (
        <img src={renderArrowUpPath(theme)} className="arrow" alt="" />
      ) : (
        <img src={renderArrowDownPath(theme)} className="arrow" alt="" />
      )}
      {isExpand && (
        <div className="sub-options-container">
          <div className="sub-option-header-container">
            <p>{userName}</p>
            {isAdministrator && <NormalTag type={NormalTagTypes.ADMIN} />}
          </div>
          <div className="sub-option-content-container">
            {isAdministrator && (
              <div className="sub-option-container">
                <p onClick={() => navigate("/permission/user-management")}>
                  User Management
                </p>
                <p onClick={() => navigate("/permission/program-setting")}>
                  Program permission settings
                </p>
              </div>
            )}
            <div className="sub-option-logout-container">
              <p onClick={handleLogOut}>Log out</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Account;
