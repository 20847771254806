import { GetJourneyCardsQuery } from "../../../../graphql/operations/trialMode/journeyCard/get-journey-cards";
import { getStandardUrl } from "../../utils/getVideoUrl";
import { MarketType, SegmentationType } from "../../types";
import { CharacterType, ExperienceType, JourneyCardType } from "../types";

const segmentationOrder = {
  Awareness: 1,
  Interest: 2,
  Decision: 3,
};

export default function normalizeJourneyCardData(
  data: GetJourneyCardsQuery
): JourneyCardType[] {
  const inputData = data.wf_trialModeLeads_journeyCard?.nodes || [];

  const outputData = inputData.map((item) => {
    const {
      id,
      journeyExperienceTypeId,
      market,
      segmentation,
      journeyExperienceType,
      greeting,
      experiencePromotionWording,
      experienceNavigationWording,
      experienceCharacter,
      isDisplayVideo,
      videoUrl,
      videoWording,
    } = item;

    return {
      key: id,
      id,
      journeyExperienceTypeId: journeyExperienceTypeId as number,
      market: market as MarketType,
      segmentation: segmentation as SegmentationType,
      experienceName: journeyExperienceType?.experienceName as ExperienceType,
      greeting: greeting || "",
      experiencePromotionWording: experiencePromotionWording || "",
      experienceNavigationWording: experienceNavigationWording || "",
      experienceCharacter: experienceCharacter as CharacterType,
      isDisplayVideo: isDisplayVideo || false,
      videoUrl: videoUrl
        ? getStandardUrl(videoUrl || "").replace("https://", "")
        : "",
      videoWording: videoWording || "",
    };
  });

  return outputData.sort(
    (a, b) =>
      segmentationOrder[a.segmentation] - segmentationOrder[b.segmentation]
  );
}
