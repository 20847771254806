import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateGraduationApplyMutationVariables = Types.Exact<{
  applicationId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  applicationDate?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  approvalDate?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  isPassed?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  overallComment?: Types.InputMaybe<Types.Scalars['String']['input']>;
  taskId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  updatedTime?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  updater?: Types.InputMaybe<Types.Scalars['String']['input']>;
  isExcellent?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type UpdateGraduationApplyMutation = { __typename?: 'Mutation', club_worldFamilyClub: { __typename?: 'Club_WorldFamilyClub_Mutation_Payload', count: number } };


export const UpdateGraduationApplyDocument = gql`
    mutation updateGraduationApply($applicationId: Int, $applicationDate: DateTime, $approvalDate: DateTime, $isPassed: Boolean, $overallComment: String, $taskId: Int, $updatedTime: DateTime, $updater: String, $isExcellent: Boolean) {
  club_worldFamilyClub(
    update: {graduationApplication: {applicationId: $applicationId, applicationDate: $applicationDate, approvalDate: $approvalDate, isPassed: $isPassed, overallComment: $overallComment, taskId: $taskId, updatedTime: $updatedTime, updater: $updater, isExcellent: $isExcellent}}
  ) {
    count
  }
}
    `;
export type UpdateGraduationApplyMutationFn = Apollo.MutationFunction<UpdateGraduationApplyMutation, UpdateGraduationApplyMutationVariables>;

/**
 * __useUpdateGraduationApplyMutation__
 *
 * To run a mutation, you first call `useUpdateGraduationApplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGraduationApplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGraduationApplyMutation, { data, loading, error }] = useUpdateGraduationApplyMutation({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *      applicationDate: // value for 'applicationDate'
 *      approvalDate: // value for 'approvalDate'
 *      isPassed: // value for 'isPassed'
 *      overallComment: // value for 'overallComment'
 *      taskId: // value for 'taskId'
 *      updatedTime: // value for 'updatedTime'
 *      updater: // value for 'updater'
 *      isExcellent: // value for 'isExcellent'
 *   },
 * });
 */
export function useUpdateGraduationApplyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGraduationApplyMutation, UpdateGraduationApplyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGraduationApplyMutation, UpdateGraduationApplyMutationVariables>(UpdateGraduationApplyDocument, options);
      }
export type UpdateGraduationApplyMutationHookResult = ReturnType<typeof useUpdateGraduationApplyMutation>;
export type UpdateGraduationApplyMutationResult = Apollo.MutationResult<UpdateGraduationApplyMutation>;
export type UpdateGraduationApplyMutationOptions = Apollo.BaseMutationOptions<UpdateGraduationApplyMutation, UpdateGraduationApplyMutationVariables>;