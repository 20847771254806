import { Dispatch, SetStateAction, useState } from "react";
import { Form, UploadFile } from "antd";

import { useCreateTrialModeNotificationMutation } from "../../../../graphql/operations/trialMode/pushNotification/create-trial-mode-notification";
import { useUploadFileMutation } from "../../../../graphql/operations/upload-file";

import Spinner from "../../../../components/layout/spinner/Spinner";
import EditFormAction from "../../components/EditFormAction";
import { FormItemMarket } from "../../components/FormItems";
import FormDivider from "../components/FormDivider";
import {
  NotificationActionButtonType,
  TrialModeNotificationModifyData,
} from "../types";
import {
  FormItemMessage,
  FormItemPushDateAndTime,
  FormItemTitle,
} from "./formItems/FormItems";
import FormItemSegmentations from "./formItems/FormItemSegmentations";
import FormItemServiceType from "./formItems/FormItemServiceType";
import FormItemImage from "./formItems/FormItemImage";
import FormItemActionButton from "./formItems/FormItemActionButton";
import FormItemButtonFields from "./formItems/FormItemButtonFields";
import FormItemTestMemberNo from "./formItems/FormItemTestMemberNo";
import handleSubmit from "./handleSubmit";

export default function ModifyForm({
  data,
  setModifyingData,
  refetch,
}: {
  data: TrialModeNotificationModifyData;
  setModifyingData: Dispatch<
    SetStateAction<TrialModeNotificationModifyData | null>
  >;
  refetch: any;
}) {
  const [form] = Form.useForm();

  const [fileList, setFileList] = useState<UploadFile<any>[]>([]);
  const [actionButton, setActionButton] =
    useState<NotificationActionButtonType>(data.actionButton);
  const [testMemberNo, setTestMemberNo] = useState<string>("");

  const [createNotification, { loading: loadingOfCreate }] =
    useCreateTrialModeNotificationMutation();
  const [uploadFile] = useUploadFileMutation();

  return (
    <div className="flex justify-center py-6">
      <Form
        name="create-and-edit-pushNotification"
        className="form-align-center"
        form={form}
        initialValues={data}
        onValuesChange={(changedValues) => {
          if (changedValues.actionButton)
            setActionButton(changedValues.actionButton);
          if (typeof changedValues.testMemberNo === "string")
            setTestMemberNo(changedValues.testMemberNo);
        }}
        onFinish={(values) => {
          handleSubmit({
            values,
            fileList,
            createNotification,
            uploadFile,
            refetch,
            setModifyingData,
          });
        }}
      >
        <FormItemMarket />
        <FormItemSegmentations />
        <FormItemServiceType />
        <FormItemTitle />
        <FormItemMessage />
        <FormItemImage form={form} setFileList={setFileList} />
        <FormItemActionButton />
        <FormItemButtonFields actionButton={actionButton} />
        <FormItemPushDateAndTime />

        <FormDivider />

        <FormItemTestMemberNo submitDisabled={!Boolean(testMemberNo)} />

        <EditFormAction
          isModified={true}
          setEditingData={setModifyingData}
          loadingOfUpdate={loadingOfCreate}
          submitDisabled={Boolean(testMemberNo)}
          alertMessage={`To send it officially, please clear the "Test member No." field first`}
        />
      </Form>

      {loadingOfCreate ? <Spinner /> : null}
    </div>
  );
}
