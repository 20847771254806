import { FormInstance } from "antd";

import { TrialContentUploadVideoType } from "../types";

export default function setValuesOnEdit(
  record: TrialContentUploadVideoType,
  form: FormInstance<any>
) {
  const {
    id,
    videoTitle,
    productCategoryId,
    introVideoFileName,
    videoFileName,
    videoThumbnail,
    videoThumbnailUrl,
  } = record;
  const introVideoFileList = introVideoFileName
    ? [
        {
          uid: "0",
          name: introVideoFileName,
          status: "done",
        },
      ]
    : null;
  const videoFileList = videoFileName
    ? [
        {
          uid: "0",
          name: videoFileName,
          status: "done",
        },
      ]
    : null;
  const videoThumbnailFileList = videoThumbnail
    ? [
        {
          uid: "0",
          name: videoThumbnail,
          status: "done",
          url: videoThumbnailUrl,
          thumbUrl: videoThumbnailUrl,
        },
      ]
    : null;

  form.setFieldsValue({
    id,
    videoTitle,
    productCategoryId,
    introVideoFile: introVideoFileList,
    videoFile: videoFileList,
    videoThumbnailFile: videoThumbnailFileList,
  });
}
