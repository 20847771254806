import { Modal } from "antd";

export default function AudioModel<
  T extends {
    songTitle: string;
    audioUrl: string;
  }
>({
  modalData,
  setModalData,
  audioRef,
}: {
  modalData: T | undefined;
  setModalData: React.Dispatch<React.SetStateAction<T | undefined>>;
  audioRef: React.RefObject<HTMLAudioElement>;
}) {
  return (
    <Modal
      title={modalData?.songTitle}
      open={Boolean(modalData)}
      onCancel={() => setModalData(undefined)}
      footer={null}
    >
      <audio ref={audioRef} controls className="w-full">
        <source src={modalData?.audioUrl} type="audio/mpeg" />
        Your browser does not support the audio tag.
      </audio>
    </Modal>
  );
}
