import * as Types from './@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateUserProgramMutationVariables = Types.Exact<{
  createData: Array<Types.Wf_AdminFirst_AfUserProgramInput> | Types.Wf_AdminFirst_AfUserProgramInput;
}>;


export type CreateUserProgramMutation = { __typename?: 'Mutation', wf_adminFirst: { __typename?: 'WF_AdminFirst_Mutation_Payload', count: number } };


export const CreateUserProgramDocument = gql`
    mutation createUserProgram($createData: [WF_AdminFirst_AfUserProgramInput!]!) {
  wf_adminFirst(addRange: {afUserPrograms: $createData}) {
    count
  }
}
    `;
export type CreateUserProgramMutationFn = Apollo.MutationFunction<CreateUserProgramMutation, CreateUserProgramMutationVariables>;

/**
 * __useCreateUserProgramMutation__
 *
 * To run a mutation, you first call `useCreateUserProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserProgramMutation, { data, loading, error }] = useCreateUserProgramMutation({
 *   variables: {
 *      createData: // value for 'createData'
 *   },
 * });
 */
export function useCreateUserProgramMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserProgramMutation, CreateUserProgramMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserProgramMutation, CreateUserProgramMutationVariables>(CreateUserProgramDocument, options);
      }
export type CreateUserProgramMutationHookResult = ReturnType<typeof useCreateUserProgramMutation>;
export type CreateUserProgramMutationResult = Apollo.MutationResult<CreateUserProgramMutation>;
export type CreateUserProgramMutationOptions = Apollo.BaseMutationOptions<CreateUserProgramMutation, CreateUserProgramMutationVariables>;