import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetGraduateChildQueryVariables = Types.Exact<{
  graduationYear: Types.Scalars['Int']['input'];
}>;


export type GetGraduateChildQuery = { __typename?: 'Query', afGraduationChild: Array<{ __typename?: 'AfGraduationChild', seq: number, market?: string | null, year: number, memberNo?: string | null, childNo?: string | null, childName?: string | null, childEnglishName?: string | null, childHeight: number, childWeight: number, childPhotoFilename?: string | null, isGraduate: boolean, isCambridge: boolean, childWillingEnglishSpeech: boolean, parentWillingChineseSpeech: boolean, isSubmittedFromApp: boolean, updatedTime: any, isExcellent?: boolean | null, imageUrl?: string | null }> };


export const GetGraduateChildDocument = gql`
    query getGraduateChild($graduationYear: Int!) {
  afGraduationChild(graduationYear: $graduationYear, isSubmittedFromApp: true) {
    seq
    market
    year
    memberNo
    childNo
    childName
    childEnglishName
    childHeight
    childWeight
    childPhotoFilename
    imageUrl: childPhotoFilename @wf_blobSignature(name: "GRADUATION_CHILD_PHOTOS_SIGNATURE")
    isGraduate
    isCambridge
    childWillingEnglishSpeech
    parentWillingChineseSpeech
    isSubmittedFromApp
    updatedTime
    isExcellent
  }
}
    `;

/**
 * __useGetGraduateChildQuery__
 *
 * To run a query within a React component, call `useGetGraduateChildQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGraduateChildQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGraduateChildQuery({
 *   variables: {
 *      graduationYear: // value for 'graduationYear'
 *   },
 * });
 */
export function useGetGraduateChildQuery(baseOptions: Apollo.QueryHookOptions<GetGraduateChildQuery, GetGraduateChildQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGraduateChildQuery, GetGraduateChildQueryVariables>(GetGraduateChildDocument, options);
      }
export function useGetGraduateChildLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGraduateChildQuery, GetGraduateChildQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGraduateChildQuery, GetGraduateChildQueryVariables>(GetGraduateChildDocument, options);
        }
export type GetGraduateChildQueryHookResult = ReturnType<typeof useGetGraduateChildQuery>;
export type GetGraduateChildLazyQueryHookResult = ReturnType<typeof useGetGraduateChildLazyQuery>;
export type GetGraduateChildQueryResult = Apollo.QueryResult<GetGraduateChildQuery, GetGraduateChildQueryVariables>;