import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteFeaturedEventMutationVariables = Types.Exact<{
  featuredEventId: Types.Scalars['Int']['input'];
}>;


export type DeleteFeaturedEventMutation = { __typename?: 'Mutation', wf_trialModeLeads: { __typename?: 'WF_TrialModeLeads_Mutation_Payload', count: number } };


export const DeleteFeaturedEventDocument = gql`
    mutation DeleteFeaturedEvent($featuredEventId: Int!) {
  wf_trialModeLeads(remove: {featuredEvent: {featuredEventId: $featuredEventId}}) {
    count
  }
}
    `;
export type DeleteFeaturedEventMutationFn = Apollo.MutationFunction<DeleteFeaturedEventMutation, DeleteFeaturedEventMutationVariables>;

/**
 * __useDeleteFeaturedEventMutation__
 *
 * To run a mutation, you first call `useDeleteFeaturedEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFeaturedEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFeaturedEventMutation, { data, loading, error }] = useDeleteFeaturedEventMutation({
 *   variables: {
 *      featuredEventId: // value for 'featuredEventId'
 *   },
 * });
 */
export function useDeleteFeaturedEventMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFeaturedEventMutation, DeleteFeaturedEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFeaturedEventMutation, DeleteFeaturedEventMutationVariables>(DeleteFeaturedEventDocument, options);
      }
export type DeleteFeaturedEventMutationHookResult = ReturnType<typeof useDeleteFeaturedEventMutation>;
export type DeleteFeaturedEventMutationResult = Apollo.MutationResult<DeleteFeaturedEventMutation>;
export type DeleteFeaturedEventMutationOptions = Apollo.BaseMutationOptions<DeleteFeaturedEventMutation, DeleteFeaturedEventMutationVariables>;