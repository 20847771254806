import Tag from "../../../components/ui/Tag";
import getSegmentationDesc from "../utils/getSegmentationDesc";
import { SegmentationType } from "../types";
import TableActions from "./components/TableActions";
import generateDataFromRecord from "./utils/generateDataFromRecord";
import {
  TrialModeNotificationDataType,
  TrialModeNotificationModifyData,
} from "./types";

export function createColumns(
  setViewData: (
    value: React.SetStateAction<TrialModeNotificationModifyData | null>
  ) => void,
  refetch: any
) {
  return [
    {
      title: "Segmentation",
      dataIndex: "segmentations",
      width: 260,
      render: (values: SegmentationType[]) => {
        return (
          <div className="flex gap-2 flex-wrap">
            {values.map((value, i) => {
              const color =
                value === "Awareness"
                  ? "lightBlue"
                  : value === "Decision"
                  ? "gold"
                  : "green";

              return (
                <Tag key={i} color={color}>
                  {getSegmentationDesc(value)}
                </Tag>
              );
            })}
          </div>
        );
      },
    },
    {
      title: "Service Type",
      dataIndex: "notificationName",
      width: 120,
    },
    {
      title: "Title",
      dataIndex: "title",
    },
    {
      title: "Push Date and time",
      dataIndex: "scheduleTime",
      width: 185,
      sorter: (
        a: TrialModeNotificationDataType,
        b: TrialModeNotificationDataType
      ) =>
        new Date(a.scheduleTime).getTime() - new Date(b.scheduleTime).getTime(),
    },
    {
      title: "Status",
      dataIndex: "jobStatus",
      width: 120,
      render: (_: any, record: TrialModeNotificationDataType) => {
        const { jobStatus } = record;

        if (!jobStatus) return null;

        const color = jobStatus === "pending" ? "blue" : "grey";
        const value = jobStatus === "pending" ? "pending" : "success";

        return <Tag color={color}>{value}</Tag>;
      },
    },
    {
      title: "Actions",
      width: 120,
      render: (_: any, record: TrialModeNotificationDataType) => {
        return (
          <TableActions
            record={record}
            onView={() => setViewData(generateDataFromRecord(record))}
            refetch={refetch}
          />
        );
      },
    },
  ];
}
