import {
  DataType as DefaultBannerType,
  TemplateBannerType,
} from "./EventBannerUpload";

interface TemplateListItemProps {
  banner?: TemplateBannerType;
  isSelected: boolean;
  defaultBanner?: DefaultBannerType;
}

const TemplateListItem = ({
  banner,
  isSelected,
  defaultBanner,
}: TemplateListItemProps) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        height: 80,
        width: 450,
        backgroundColor: isSelected ? "#EBEDFE" : "#FFFFFF",
        marginLeft: -32,
        paddingLeft: 32,
      }}
    >
      <img
        alt="banner"
        src={banner ? banner.image : "https://i.imgur.com/eRgPhL1.png"}
        style={{ width: 112, margin: "0 24px 0 12px" }}
      />
      <div>{banner ? banner.templateName : defaultBanner?.eventType}</div>
    </div>
  );
};

export default TemplateListItem;
