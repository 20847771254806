import interest from "../../../../assets/images/trialMode/interested.png";
import didYouNotice from "../../../../assets/images/trialMode/didYouNotice.png";
import froggyToy from "../../../../assets/images/trialMode/Froggy-Toy.png";
import cn from "../../../../utils/cn";
import { getEmbedUrl } from "../../utils/getVideoUrl";
import { SegmentationType } from "../../types";
import getCharacterSrc from "../utils/getCharacterSrc";
import { TemplateType } from "../types";

function PreviewTitle({
  ctaTitle,
  template,
}: {
  ctaTitle: string;
  template: TemplateType;
}) {
  if (template === "Video") return null;
  if (ctaTitle === "Interested?")
    return <img src={interest} alt="Interested?" className="mb-2" />;
  if (ctaTitle === "Did you notice?")
    return <img src={didYouNotice} alt="Did you notice?" className="mb-2" />;

  return (
    <h1 className="mb-2 text-2xl font-semibold text-black text-center">
      {ctaTitle}
    </h1>
  );
}

function PreviewContentText({ ctaContent }: { ctaContent: any }) {
  if (typeof ctaContent !== "string") return null;

  return (
    <div className="flex flex-col gap-2">
      {ctaContent.split("\n").map((text, index) => (
        <p key={index} className="font-semibold leading-5 text-center">
          {text}
        </p>
      ))}
    </div>
  );
}

export default function Preview({
  formData,
  index,
  photoUrl,
  segmentation,
}: {
  formData: any;
  index: number;
  photoUrl: string;
  segmentation: SegmentationType;
}) {
  const {
    template,
    imageCharacter,
    videoUrl,
    contents = [],
    buttonText,
  } = formData;
  const { ctaTitle, ctaContent } = contents[index] || {};

  const buttonBgColor =
    template === "Video" || template === "Feature" ? "bg-gold4" : "bg-black";
  const textColor = template === "Video" ? "text-white" : "text-black";
  const bgStyle =
    template === "Video"
      ? "bg-grey5"
      : template === "Feature"
      ? "bg-pink1"
      : "bg-[url('/src/assets/images/trialMode/CTA_BG.png')]";
  const height = template === "Feature" ? "h-fit pt-10 pb-6" : "h-[720px]";
  const contentMargin = template === "Feature" ? "mt-4 mb-6" : "mt-4 mb-10";

  return (
    <div
      className={cn(
        "flex items-center justify-center w-[390px] h-[720px]",
        bgStyle,
        height
      )}
    >
      <div className="flex flex-col items-center">
        <PreviewTitle ctaTitle={ctaTitle} template={template} />

        {template === "Image" ? (
          <img src={getCharacterSrc(imageCharacter)} alt={imageCharacter} />
        ) : null}
        {template === "Photo" && photoUrl ? (
          <div className="mt-2 mx-4 rounded-md overflow-hidden">
            <img src={photoUrl} alt={photoUrl} className="max-h-[380px]" />
          </div>
        ) : null}
        {template === "Video" && videoUrl ? (
          <div className="mt-2 mx-4 rounded-md overflow-hidden">
            <iframe
              title="videoTemplate"
              width="358"
              height="264"
              src={getEmbedUrl(`https://${videoUrl}`)}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
          </div>
        ) : null}

        {template === "Photo" ? (
          <div
            className={cn(
              "relative w-[390px] flex flex-row-reverse",
              photoUrl ? "-top-8 mb-8" : "top-0 mb-16"
            )}
          >
            <div className="relative w-[310px] mr-2 p-6 bg-white rounded-3xl shadow-md">
              <PreviewContentText ctaContent={ctaContent} />

              <img
                src={froggyToy}
                alt="froggy toy"
                className="absolute -left-12 -bottom-12"
              />
            </div>
          </div>
        ) : (
          <div className={cn("w-[310px]", textColor, contentMargin)}>
            {template === "Video" ? (
              <h1 className="mb-4 text-xl font-semibold text-white text-center">
                {ctaTitle}
              </h1>
            ) : null}

            <PreviewContentText ctaContent={ctaContent} />
          </div>
        )}

        <div
          className={cn(
            "w-[260px] h-14 rounded-full flex items-center justify-center cursor-pointer",
            buttonBgColor
          )}
        >
          <p className="text-white font-semibold text-base">
            {segmentation === "Decision" ? "Got it" : buttonText}
          </p>
        </div>

        {segmentation !== "Decision" ? (
          <p
            className={cn(
              "my-5 font-semibold leading-5 cursor-pointer",
              textColor
            )}
          >
            {template === "Feature" ? "Got it" : "Maybe later"}
          </p>
        ) : null}
      </div>
    </div>
  );
}
