import { Button } from "antd";

import { useDeleteTrialModeNotificationMutation } from "../../../../graphql/operations/trialMode/pushNotification/delete-trial-mode-notification";
import Spinner from "../../../../components/layout/spinner/Spinner";
import { renderDecodedId } from "../../../../utils/renderDecodedId";
import { TrialModeNotificationDataType } from "../types";

export default function TableActions({
  record,
  onView,
  refetch,
}: {
  record: TrialModeNotificationDataType;
  onView: () => void;
  refetch: any;
}) {
  const { jobStatus } = record;
  const showDeleteButton = jobStatus === "pending";

  const [deleteNotification, { loading: loadingOfDelete }] =
    useDeleteTrialModeNotificationMutation();

  const handleDelete = () => {
    const id = parseInt(renderDecodedId(record.id));

    deleteNotification({
      variables: {
        notification: {
          notificationId: id,
        },
      },
    })
      .then((data) => {
        setTimeout(() => refetch(), 1000);
      })
      .catch((e) => console.log(e));
  };

  return (
    <div className="flex gap-4">
      <Button type="link" className="w-9 p-0" onClick={onView}>
        View
      </Button>

      {showDeleteButton ? (
        <Button type="link" className="w-12 p-0" onClick={handleDelete}>
          Delete
        </Button>
      ) : null}

      {loadingOfDelete ? <Spinner /> : null}
    </div>
  );
}
