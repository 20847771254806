import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteUploadSongMutationVariables = Types.Exact<{
  productSongId: Types.Scalars['Int']['input'];
}>;


export type DeleteUploadSongMutation = { __typename?: 'Mutation', wf_trialModeLeads: { __typename?: 'WF_TrialModeLeads_Mutation_Payload', count: number } };


export const DeleteUploadSongDocument = gql`
    mutation DeleteUploadSong($productSongId: Int!) {
  wf_trialModeLeads(remove: {productSong: {productSongId: $productSongId}}) {
    count
  }
}
    `;
export type DeleteUploadSongMutationFn = Apollo.MutationFunction<DeleteUploadSongMutation, DeleteUploadSongMutationVariables>;

/**
 * __useDeleteUploadSongMutation__
 *
 * To run a mutation, you first call `useDeleteUploadSongMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUploadSongMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUploadSongMutation, { data, loading, error }] = useDeleteUploadSongMutation({
 *   variables: {
 *      productSongId: // value for 'productSongId'
 *   },
 * });
 */
export function useDeleteUploadSongMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUploadSongMutation, DeleteUploadSongMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUploadSongMutation, DeleteUploadSongMutationVariables>(DeleteUploadSongDocument, options);
      }
export type DeleteUploadSongMutationHookResult = ReturnType<typeof useDeleteUploadSongMutation>;
export type DeleteUploadSongMutationResult = Apollo.MutationResult<DeleteUploadSongMutation>;
export type DeleteUploadSongMutationOptions = Apollo.BaseMutationOptions<DeleteUploadSongMutation, DeleteUploadSongMutationVariables>;