/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction, useEffect } from "react";
import { useReactiveVar } from "@apollo/client";
import { Tabs, TabsProps, message } from "antd";
import { ServiceTypes } from "../../../types/types";
import { __currentUser__ } from "../../../graphql/policies";
import { useGetUserProgramsByServiceQuery } from "../../../graphql/operations/get-user-program-by-service";
import { renderDecodedId } from "../../../utils/renderDecodedId";
import Spinner from "../../../components/layout/spinner/Spinner";
import NoPermission from "../../../components/others/NoPermission";
import { Colors } from "../../../constants/Colors";
import EventBannerUpload from "./components/EventBannerUpload";
import EventBannerTemplate from "./components/EventBannerTemplate";
// import EventBannerDefault from "./components/EventBannerDefault";
import { renderAntDMessageConfig } from "../../../utils/renderAntDMessageConfig";

const host = window.location.host;

export const renderApiUri = () => {
  if (
    host.startsWith("localhost") ||
    host === "adminfirst-web-dev.worldfamilyenglish.com"
  ) {
    return "https://testapiadmin.worldfamilyclub.com.tw";
  } else if (
    host === "adminfirst-web.worldfamilyenglish.com" ||
    host === "admin-first-fe-preview.azurewebsites.net"
  ) {
    return "https://apiadmin.worldfamilyclub.com.tw";
  }
};

interface EventBannerProps {
  setSelectedService: Dispatch<SetStateAction<ServiceTypes>>;
  setSelectSubItem: Dispatch<SetStateAction<string | undefined>>;
}

const items: TabsProps["items"] = [
  {
    key: "1",
    label: "Events",
    children: <EventBannerUpload apiUri={renderApiUri()} />,
  },
  {
    key: "2",
    label: "Templates",
    children: <EventBannerTemplate apiUri={renderApiUri()} />,
  },
  // {
  //   key: "3",
  //   label: "Default",
  //   children: <EventBannerDefault />,
  // },
];

const EventBanner = ({
  setSelectedService,
  setSelectSubItem,
}: EventBannerProps) => {
  const currentUser = useReactiveVar(__currentUser__);
  const {
    data: userPrograms,
    loading: userProgramsLoading,
    error: userProgramsError,
  } = useGetUserProgramsByServiceQuery({
    variables: {
      userId: parseInt(renderDecodedId(currentUser?.id)),
      serviceId: 2,
    },
  });

  const onChange = (key: string) => {
    console.log(key);
  };

  useEffect(() => {
    setSelectedService(ServiceTypes.CLUB_APP);
    setSelectSubItem("Event Banner");
  }, []);

  if (userProgramsError) {
    message.error(
      renderAntDMessageConfig(
        "Something went wrong. Please contact your system administrator."
      )
    );
  }

  if (userProgramsLoading) {
    return <Spinner />;
  }

  if (
    userPrograms &&
    !userPrograms.wf_adminFirst_afUserProgram?.edges?.find(
      (item) => item.node.afProgram?.programName === "Event Banner"
    )
  ) {
    return <NoPermission />;
  }

  return (
    <div>
      <div className="header" style={{ backgroundColor: Colors.white }}>
        <p className="header-title" style={{ color: Colors.darkGrey }}>
          Event Banner
        </p>
      </div>
      <Tabs
        defaultActiveKey="1"
        items={items}
        onChange={onChange}
        size="small"
        tabBarStyle={{
          paddingLeft: 24,
          backgroundColor: Colors.white,
          height: 50,
        }}
      />
    </div>
  );
};

export default EventBanner;
