/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useReactiveVar } from "@apollo/client";
import { Button, Form, Modal, Radio, Table, message } from "antd";
import {
  PlusOutlined,
  PlayCircleFilled,
  CheckCircleFilled,
  QuestionCircleFilled,
} from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import Player from "@vimeo/player";
import "./GoGoLive.css";
import { ServiceTypes } from "../../../types/types";
import { Colors } from "../../../constants/Colors";
import { useGetUserProgramsByServiceQuery } from "../../../graphql/operations/get-user-program-by-service";
import { __currentUser__ } from "../../../graphql/policies";
import { renderDecodedId } from "../../../utils/renderDecodedId";
import NoPermission from "../../../components/others/NoPermission";
import Spinner from "../../../components/layout/spinner/Spinner";
import { data } from "../../../data/data";
import GoGoLiveCreateDrawer from "./components/GoGoLiveCreateDrawer";
import GoGoLiveEditDrawer from "./components/GoGoLiveEditDrawer";
import OpenHouseCreateDrawer from "./components/OpenHouseCreateDrawer";
import OpenHouseEditDrawer from "./components/OpenHouseEditDrawer";
import { useGetGglDataQuery } from "../../../graphql/operations/get-ggl-data";
import { useGetOpenHouseDataQuery } from "../../../graphql/operations/get-open-house-data";
import { useCreateGoGoLiveMutation } from "../../../graphql/operations/create-ggl";
import { useDeleteGoGoLiveMutation } from "../../../graphql/operations/delete-ggl";
import { useUpdateGoGoLiveMutation } from "../../../graphql/operations/update-ggl";
import { renderAntDMessageConfig } from "../../../utils/renderAntDMessageConfig";

interface GoGoLiveProps {
  setSelectedService: Dispatch<SetStateAction<ServiceTypes>>;
  setSelectSubItem: Dispatch<SetStateAction<string | undefined>>;
}

interface DataType {
  id: string;
  type: string | undefined | null;
  yearMonth: string | undefined | null;
  capLevel: string | undefined | null;
  vimeoId: string | undefined | null;
  fileNameHK: string | null | undefined;
  fileNameKR: string | null | undefined;
  fileNameTW: string | null | undefined;
  fileNamePoster: string | null | undefined;
  seq: number[];
  createTime: string | null | undefined;
}

const { capFilters } = data;

const GoGoLive = ({ setSelectedService, setSelectSubItem }: GoGoLiveProps) => {
  const currentUser = useReactiveVar(__currentUser__);
  const [selectType, setSelectType] = useState("ggl");
  const [displayGglData, setDisplayGglData] = useState<DataType[]>([]);
  const [displayOhData, setDisplayOhData] = useState<DataType[]>([]);
  const [showPreview, setShowPreview] = useState(false);
  const [selectPreview, setSelectPreview] = useState<DataType | any>();
  const [showGGLEditDrawer, setShowGGLEditDrawer] = useState(false);
  const [showGGLCreateDrawer, setShowGGLCreateDrawer] = useState(false);
  const [showOhEditDrawer, setShowOhEditDrawer] = useState(false);
  const [showOhCreateDrawer, setShowOhCreateDrawer] = useState(false);
  const [selectVideo, setSelectVideo] = useState<DataType | undefined | null>();
  const [hkPdfFileName, setHkPdfFileName] = useState<
    string | undefined | null
  >();
  const [krPdfFileName, setKrPdfFileName] = useState<
    string | undefined | null
  >();
  const [twPdfFileName, setTwPdfFileName] = useState<
    string | undefined | null
  >();
  const [posterFileName, setPosterFileName] = useState<
    string | undefined | null
  >();
  const vimeoRef = useRef<any>();
  const [gglCreateForm] = Form.useForm();
  const [ohCreateForm] = Form.useForm();
  const [gglEditForm] = Form.useForm();
  const [ohEditForm] = Form.useForm();
  const {
    data: userPrograms,
    loading: userProgramsLoading,
    error: userProgramsError,
  } = useGetUserProgramsByServiceQuery({
    variables: {
      userId: parseInt(renderDecodedId(currentUser?.id)),
      serviceId: 2,
    },
  });
  const {
    data: gglData,
    loading: gglDataLoading,
    error: gglDataError,
    refetch: gglRefetch,
  } = useGetGglDataQuery({
    variables: { startDate: dayjs().startOf("month") },
  });
  const {
    data: ohData,
    loading: ohDataLoading,
    error: ohDataError,
    refetch: ohRefetch,
  } = useGetOpenHouseDataQuery({
    variables: { startDate: dayjs().startOf("year").subtract(1, "year") },
  });
  const [createGGL, { loading: createLoading }] = useCreateGoGoLiveMutation();
  const [updateGGL, { loading: updateLoading }] = useUpdateGoGoLiveMutation();
  const [deleteGGL, { loading: deleteLoading }] = useDeleteGoGoLiveMutation();

  const onCancel = () => {
    const player = new Player(vimeoRef.current);
    player.pause();
    setShowPreview(false);
    setSelectPreview(null);
  };

  const handlePreviewClick = (record: DataType) => {
    setShowPreview(true);
    setSelectPreview(record);
  };

  const handleEditClick = (record: DataType) => {
    setSelectVideo(record);
    if (selectType === "ggl") {
      setShowGGLEditDrawer(true);
    } else {
      setShowOhEditDrawer(true);
    }
  };

  const handleDelete = (record: DataType) => {
    setSelectVideo(record);
    deleteGGL({ variables: { GGLIds: record.seq?.map((seq) => ({ seq })) } })
      .then(() => {
        setSelectVideo(undefined);
        message.success(
          renderAntDMessageConfig("Delete Go, Go Live! content successful.")
        );
        if (selectType === "ggl") {
          gglRefetch().catch((e) => console.log(e));
        } else {
          ohRefetch().catch((e) => console.log(e));
        }
      })
      .catch((e) => console.log(e));
  };

  const gglColumns: ColumnsType<DataType> = [
    {
      title: "Year month",
      dataIndex: "yearMonth",
      key: "yearMonth",
      render: (value: string) => dayjs(value).format("YYYY-MMM"),
      sorter: (a: DataType, b: DataType) =>
        dayjs(a.yearMonth).valueOf() - dayjs(b.yearMonth).valueOf(),
    },
    {
      title: "CAP level",
      dataIndex: "capLevel",
      key: "capLevel",
      filters: capFilters,
      onFilter: (value: any, record: any) =>
        record.capLevel.indexOf(value) === 0,
      sorter: (a: any, b: any) => a.capLevel?.localeCompare(b.capLevel),
    },
    {
      title: "Video",
      dataIndex: "vimeoId",
      key: "vimeoId",
      render: (value: string, record: DataType) =>
        value ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <PlayCircleFilled
              style={{ marginRight: 8, fontSize: 20, cursor: "pointer" }}
              onClick={() => handlePreviewClick(record)}
            />
            <p>{value}</p>
          </div>
        ) : (
          <p style={{ color: "rgba(0,0,0,0.25)" }}>N/A</p>
        ),
    },
    {
      title: "Parent's guide",
      render: (_, record: DataType) => (
        <div className="ggl-preview-container">
          <div className="ggl-preview-inner-container">
            HK
            {record.fileNameHK ? (
              <CheckCircleFilled className="guide-icon" />
            ) : (
              <QuestionCircleFilled className="guide-icon-not-exist" />
            )}
          </div>
          <div className="ggl-preview-inner-container">
            KR
            {record.fileNameKR ? (
              <CheckCircleFilled className="guide-icon" />
            ) : (
              <QuestionCircleFilled className="guide-icon-not-exist" />
            )}
          </div>
          <div className="ggl-preview-inner-container">
            TW
            {record.fileNameTW ? (
              <CheckCircleFilled className="guide-icon" />
            ) : (
              <QuestionCircleFilled className="guide-icon-not-exist" />
            )}
          </div>
        </div>
      ),
    },
    {
      title: "Poster",
      dataIndex: "fileNamePoster",
      key: "fileNamePoster",
      render: (value: string | null | undefined) => (
        <div className="ggl-preview-container">
          <div className="ggl-preview-inner-container">
            {value ? "Done" : "Undone"}
            {value ? (
              <CheckCircleFilled className="guide-icon" />
            ) : (
              <QuestionCircleFilled className="guide-icon-not-exist" />
            )}
          </div>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      width: 100,
      render: (_, record: DataType) => (
        <div style={{ display: "flex" }}>
          <Button
            type="link"
            style={{ padding: 0, marginRight: 16 }}
            onClick={() => handleEditClick(record)}
          >
            Edit
          </Button>
          <Button
            type="link"
            style={{ padding: 0 }}
            onClick={() => handleDelete(record)}
            loading={deleteLoading && record.id === selectVideo?.id}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  const ohColumns: ColumnsType<DataType> = [
    {
      title: "Year month",
      dataIndex: "yearMonth",
      key: "yearMonth",
      render: (value: string) => dayjs(value).format("YYYY-MMM"),
      sorter: (a: DataType, b: DataType) =>
        dayjs(a.yearMonth).valueOf() - dayjs(b.yearMonth).valueOf(),
    },
    {
      title: "Video",
      dataIndex: "vimeoId",
      key: "vimeoId",
      render: (value: string, record: DataType) =>
        value ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <PlayCircleFilled
              style={{ marginRight: 8, fontSize: 20, cursor: "pointer" }}
              onClick={() => handlePreviewClick(record)}
            />
            <p>{value}</p>
          </div>
        ) : (
          <p style={{ color: "rgba(0,0,0,0.25)" }}>N/A</p>
        ),
    },
    {
      title: "Parent's guide",
      render: (_, record: DataType) => (
        <div className="ggl-preview-container">
          <div className="ggl-preview-inner-container">
            {record.fileNameHK ? "Done" : "Undone"}
            {record.fileNameHK ? (
              <CheckCircleFilled className="guide-icon" />
            ) : (
              <QuestionCircleFilled className="guide-icon-not-exist" />
            )}
          </div>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      width: 100,
      render: (_, record: DataType) => (
        <div style={{ display: "flex" }}>
          <Button
            type="link"
            style={{ padding: 0, marginRight: 16 }}
            onClick={() => handleEditClick(record)}
          >
            Edit
          </Button>
          <Button
            type="link"
            style={{ padding: 0 }}
            onClick={() => handleDelete(record)}
            loading={deleteLoading && record.id === selectVideo?.id}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  const handleGGLConfirm = (values: any) => {
    let GGLData = [];
    for (let index = 0; index < 3; index++) {
      GGLData.push({
        parentGuideUrl:
          index === 0
            ? twPdfFileName
            : index === 1
            ? hkPdfFileName
            : krPdfFileName,
        reviewPosterFileName: posterFileName,
        region: index === 0 ? "tw" : index === 1 ? "hk" : "kr",
        capLevel: selectVideo ? selectVideo.capLevel : values.capLevel,
        serviceType: "GGLA",
        videoUrl: values.videoFile
          ? `https://player.vimeo.com/video/${values.videoFile}`
          : undefined,
        yearMonth: selectVideo
          ? dayjs(selectVideo.yearMonth).format("YYYYMM")
          : dayjs(values.yearMonth).format("YYYYMM"),
        topicYearMonth: selectVideo
          ? `${dayjs(selectVideo.yearMonth)
              .startOf("month")
              .format("YYYY-MM-DD")}T00:00:00.000Z`
          : `${dayjs(values.yearMonth)
              .startOf("month")
              .format("YYYY-MM-DD")}T00:00:00.000Z`,
        priceInformation:
          index === 0
            ? "小孩週間每位 $300，週末每位 $380"
            : index === 1
            ? "小孩每位$120 ($120 per child)"
            : "어린이 1명: 11,000원",
        createTime: selectVideo ? selectVideo.createTime : undefined,
        seq: selectVideo ? selectVideo.seq[index] : undefined,
      });
    }
    console.log(GGLData);
    if (!selectVideo) {
      console.log("create ggl");
      createGGL({ variables: { GGLData } })
        .then((r) => {
          if (r.data && r.data.club_worldFamilyClub.count > 0) {
            gglRefetch()
              .then(() => {
                message.success(
                  renderAntDMessageConfig(
                    "Create Go, Go Live! content successful."
                  )
                );
                setShowGGLCreateDrawer(false);
              })
              .catch((e) => console.log(e));
          }
        })
        .catch((e) => console.log(e));
    } else {
      console.log("update ggl");
      updateGGL({ variables: { GGLData } })
        .then((r) => {
          if (r.data && r.data.club_worldFamilyClub.count > 0) {
            gglRefetch()
              .then(() => {
                message.success(
                  renderAntDMessageConfig(
                    "Update Go, Go Live! content successful."
                  )
                );
                setShowGGLEditDrawer(false);
              })
              .catch((e) => console.log(e));
          }
        })
        .catch((e) => console.log(e));
    }
  };

  const handleOHConfirm = (values: any) => {
    let ohData = [];
    for (let index = 0; index < 3; index++) {
      ohData.push({
        parentGuideUrl: hkPdfFileName,
        reviewPosterFileName: undefined,
        region: index === 0 ? "tw" : index === 1 ? "hk" : "kr",
        capLevel: "NONE",
        serviceType: "VEFB",
        videoUrl: values.videoFile
          ? `https://player.vimeo.com/video/${values.videoFile}`
          : undefined,
        yearMonth: selectVideo
          ? dayjs(selectVideo.yearMonth).format("YYYYMM")
          : dayjs(values.yearMonth).format("YYYYMM"),
        topicYearMonth: selectVideo
          ? `${dayjs(selectVideo.yearMonth)
              .startOf("month")
              .format("YYYY-MM-DD")}T00:00:00.000Z`
          : `${dayjs(values.yearMonth)
              .startOf("month")
              .format("YYYY-MM-DD")}T00:00:00.000Z`,
        priceInformation: index === 2 ? "무료" : "免費",
        createTime: selectVideo ? selectVideo.createTime : undefined,
        seq: selectVideo ? selectVideo.seq[index] : undefined,
      });
    }
    console.log(ohData);
    if (!selectVideo) {
      console.log("create open house");
      createGGL({ variables: { GGLData: ohData } })
        .then((r) => {
          if (r.data && r.data.club_worldFamilyClub.count > 0) {
            ohRefetch()
              .then(() => {
                message.success(
                  renderAntDMessageConfig(
                    "Create Open house content successful."
                  )
                );
                setShowOhCreateDrawer(false);
              })
              .catch((e) => console.log(e));
          }
        })
        .catch((e) => console.log(e));
    } else {
      console.log("update open house");
      updateGGL({ variables: { GGLData: ohData } })
        .then((r) => {
          if (r.data && r.data.club_worldFamilyClub.count > 0) {
            ohRefetch()
              .then(() => {
                message.success(
                  renderAntDMessageConfig(
                    "Update Open house content successful."
                  )
                );
                setShowOhEditDrawer(false);
              })
              .catch((e) => console.log(e));
          }
        })
        .catch((e) => console.log(e));
    }
  };

  useEffect(() => {
    setSelectedService(ServiceTypes.CLUB_APP);
    setSelectSubItem("Go, Go Live!");
  }, []);

  useEffect(() => {
    if (gglData && gglData.club_worldFamilyClub_goGoLivePromotion?.nodes) {
      const originalData =
        gglData.club_worldFamilyClub_goGoLivePromotion?.nodes;
      let newData = [];
      let transformData: DataType[] = [];
      for (let i = 0; i < originalData.length; i += 3) {
        const chunk = originalData.slice(i, i + 3);
        newData.push(chunk);
      }
      newData.forEach((arr, i) => {
        transformData.push({
          id: i.toString(),
          type: arr[0].serviceType,
          yearMonth: arr[0].topicYearMonth,
          capLevel: arr[0].capLevel,
          vimeoId: arr[0].videoUrl?.split("video/")[1],
          fileNameHK: arr[1].parentGuideUrl?.split("?")[0].split("ves-pdf/")[1],
          fileNameKR: arr[2].parentGuideUrl?.split("?")[0].split("ves-pdf/")[1],
          fileNameTW: arr[0].parentGuideUrl?.split("?")[0].split("ves-pdf/")[1],
          fileNamePoster: arr[0].reviewPosterFileName
            ?.split("?")[0]
            .split("gogolive-img/")[1],
          seq: [
            parseInt(renderDecodedId(arr[0].id)),
            parseInt(renderDecodedId(arr[1].id)),
            parseInt(renderDecodedId(arr[2].id)),
          ],
          createTime: arr[0].createTime,
        });
      });
      setDisplayGglData(transformData);
    }
  }, [gglData]);

  useEffect(() => {
    if (ohData && ohData.club_worldFamilyClub_goGoLivePromotion?.nodes) {
      const originalData = ohData.club_worldFamilyClub_goGoLivePromotion?.nodes;
      let newData = [];
      let transformData: DataType[] = [];
      for (let i = 0; i < originalData.length; i += 3) {
        const chunk = originalData.slice(i, i + 3);
        newData.push(chunk);
      }
      newData.forEach((arr, i) => {
        transformData.push({
          id: i.toString(),
          type: arr[0].serviceType,
          yearMonth: arr[0].topicYearMonth,
          capLevel: "None",
          vimeoId: arr[0].videoUrl?.split("video/")[1],
          fileNameHK: arr[0].parentGuideUrl?.split("?")[0].split("ves-pdf/")[1],
          fileNameKR: null,
          fileNameTW: null,
          fileNamePoster: null,
          seq: [
            parseInt(renderDecodedId(arr[0].id)),
            parseInt(renderDecodedId(arr[1].id)),
            parseInt(renderDecodedId(arr[2].id)),
          ],
          createTime: arr[0].createTime,
        });
      });
      setDisplayOhData(transformData);
    }
  }, [ohData]);

  if (userProgramsError || ohDataError || gglDataError) {
    message.error(
      renderAntDMessageConfig(
        "Something went wrong. Please contact your system administrator."
      )
    );
  }

  if (userProgramsLoading || gglDataLoading || ohDataLoading) {
    return <Spinner />;
  }

  if (
    userPrograms &&
    !userPrograms.wf_adminFirst_afUserProgram?.edges?.find(
      (item) => item.node.afProgram?.programName === "Go, Go Live!"
    )
  ) {
    return <NoPermission />;
  }

  return (
    <>
      <div>
        <div className="header" style={{ backgroundColor: Colors.white }}>
          <p className="header-title" style={{ color: Colors.darkGrey }}>
            Go, Go Live!
          </p>
        </div>
        <div className="ggl-content-container">
          <div className="ggl-content-inner-container">
            <div className="ggl-content-header">
              <Radio.Group
                optionType="button"
                options={[
                  { label: "Go, Go Live!", value: "ggl" },
                  { label: "Open House", value: "oh" },
                ]}
                defaultValue={selectType}
                onChange={(e) => {
                  setSelectVideo(undefined);
                  setSelectType(e.target.value);
                }}
              />
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setSelectVideo(undefined);
                  selectType === "ggl"
                    ? setShowGGLCreateDrawer(true)
                    : setShowOhCreateDrawer(true);
                }}
              >
                Create New
              </Button>
            </div>
            <div className="ggl-table-container">
              <Table
                rowKey="id"
                columns={selectType === "ggl" ? gglColumns : ohColumns}
                dataSource={
                  selectType === "ggl" ? displayGglData : displayOhData
                }
                pagination={{ hideOnSinglePage: true }}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={showPreview}
        onCancel={onCancel}
        title={selectPreview?.vimeoId}
        footer={null}
        width={690}
        forceRender
      >
        {selectPreview && (
          <iframe
            ref={vimeoRef}
            src={`https://player.vimeo.com/video/${selectPreview?.vimeoId}`}
            width="640"
            height="360"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen={false}
            title={selectPreview?.vimeoId}
          ></iframe>
        )}
      </Modal>
      <GoGoLiveCreateDrawer
        show={showGGLCreateDrawer}
        form={gglCreateForm}
        setShow={setShowGGLCreateDrawer}
        handleConfirm={handleGGLConfirm}
        setHkPdfFileName={setHkPdfFileName}
        setKrPdfFileName={setKrPdfFileName}
        setTwPdfFileName={setTwPdfFileName}
        setPosterFileName={setPosterFileName}
        btnLoading={createLoading}
      />
      <GoGoLiveEditDrawer
        show={showGGLEditDrawer}
        form={gglEditForm}
        setShow={setShowGGLEditDrawer}
        setSelectVideo={setSelectVideo}
        selectVideo={selectVideo}
        handleConfirm={handleGGLConfirm}
        setHkPdfFileName={setHkPdfFileName}
        setKrPdfFileName={setKrPdfFileName}
        setTwPdfFileName={setTwPdfFileName}
        setPosterFileName={setPosterFileName}
        btnLoading={updateLoading}
      />
      <OpenHouseCreateDrawer
        show={showOhCreateDrawer}
        form={ohCreateForm}
        setShow={setShowOhCreateDrawer}
        handleConfirm={handleOHConfirm}
        setHkPdfFileName={setHkPdfFileName}
        btnLoading={createLoading}
      />
      <OpenHouseEditDrawer
        show={showOhEditDrawer}
        form={ohEditForm}
        setShow={setShowOhEditDrawer}
        setSelectVideo={setSelectVideo}
        selectVideo={selectVideo}
        handleConfirm={handleOHConfirm}
        setHkPdfFileName={setHkPdfFileName}
        btnLoading={updateLoading}
      />
    </>
  );
};

export default GoGoLive;
