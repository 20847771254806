import * as Types from './@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetGglDataQueryVariables = Types.Exact<{
  startDate: Types.Scalars['DateTime']['input'];
}>;


export type GetGglDataQuery = { __typename?: 'Query', club_worldFamilyClub_goGoLivePromotion?: { __typename?: 'Club_WorldFamilyClub_GoGoLivePromotionConnection', nodes?: Array<{ __typename?: 'Club_WorldFamilyClub_GoGoLivePromotion', id: string, capLevel?: string | null, createTime?: any | null, parentGuideUrl?: string | null, priceInformation?: string | null, region?: string | null, serviceType?: string | null, videoUrl?: string | null, yearMonth?: string | null, topicYearMonth?: any | null, reviewPosterFileName?: string | null }> | null } | null };


export const GetGglDataDocument = gql`
    query GetGGLData($startDate: DateTime!) {
  club_worldFamilyClub_goGoLivePromotion(
    filter: {serviceType: {eq: "GGLA"}, topicYearMonth: {gte: $startDate}}
    order: {yearMonth: DESC, capLevel: ASC}
    first: 99
  ) {
    nodes {
      id
      capLevel
      createTime
      parentGuideUrl @wf_blobSignature(name: "VES_PDF_SIGNATURE")
      priceInformation
      region
      serviceType
      videoUrl
      yearMonth
      topicYearMonth
      reviewPosterFileName @wf_blobSignature(name: "GOGOLIVE_IMAGE_SIGNATURE")
      createTime
    }
  }
}
    `;

/**
 * __useGetGglDataQuery__
 *
 * To run a query within a React component, call `useGetGglDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGglDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGglDataQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useGetGglDataQuery(baseOptions: Apollo.QueryHookOptions<GetGglDataQuery, GetGglDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGglDataQuery, GetGglDataQueryVariables>(GetGglDataDocument, options);
      }
export function useGetGglDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGglDataQuery, GetGglDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGglDataQuery, GetGglDataQueryVariables>(GetGglDataDocument, options);
        }
export type GetGglDataQueryHookResult = ReturnType<typeof useGetGglDataQuery>;
export type GetGglDataLazyQueryHookResult = ReturnType<typeof useGetGglDataLazyQuery>;
export type GetGglDataQueryResult = Apollo.QueryResult<GetGglDataQuery, GetGglDataQueryVariables>;