import { Upload, message } from "antd";

export const mp3Validation = (file: any) => {
  const isMp3 = file.type === "audio/mp3" || file.type === "audio/mpeg";

  if (!isMp3) message.error(`${file.name} is not an MP3 file`);

  return isMp3 || Upload.LIST_IGNORE;
};

export const mp4Validation = (file: any) => {
  const isMp4 = file.type === "video/mp4";

  if (!isMp4) message.error(`${file.name} is not an MP4 file`);

  return isMp4 || Upload.LIST_IGNORE;
};

export const lrcValidation = (file: any) => {
  const { name } = file;
  const fileExtension = name.substring(name.lastIndexOf(".") + 1).toLowerCase();
  const isLrc = fileExtension === "lrc";

  if (!isLrc) message.error(`${name} is not an LRC file`);

  return isLrc || Upload.LIST_IGNORE;
};

export const imageValidation = (file: any) => {
  const isImage =
    file.type === "image/jpeg" ||
    file.type === "image/jpg" ||
    file.type === "image/png";

  if (!isImage) message.error(`${file.name} is not a JPEG/JPG/PNG file`);

  return isImage || Upload.LIST_IGNORE;
};
