import { FormInstance } from "antd";

import { AfBlobPath } from "../../../../graphql/operations/@types";
import { renderDecodedId } from "../../../../utils/renderDecodedId";
import { DrawerActionType } from "../../types";
import { denormalizeUploadVideoData } from "../utils/denormalizeUploadData";
import {
  TrialContentUploadVideoFormDataType,
  TrialContentUploadVideoType,
} from "../types";
import sortIdUpdator from "../utils/sortIdUpdator";

export default function handleSubmit({
  values,
  form,
  videoData,
  drawerAction,
  uploadFile,
  createVideo,
  updateVideo,
  updateSortId,
  refetch,
  setOpenDrawer,
}: {
  values: TrialContentUploadVideoFormDataType;
  form: FormInstance<any>;
  videoData: TrialContentUploadVideoType[];
  drawerAction: DrawerActionType;
  uploadFile: any;
  createVideo: any;
  updateVideo: any;
  updateSortId: any;
  refetch: any;
  setOpenDrawer: (value: React.SetStateAction<boolean>) => void;
}) {
  const id = form.getFieldValue("id");
  const introVideoFile = values.introVideoFile[0] || {};
  const videoFile = values.videoFile[0] || {};
  const videoThumbnail = values.videoThumbnailFile[0] || {};
  const blobs = [];

  if (introVideoFile.originFileObj)
    blobs.push({
      file: introVideoFile.originFileObj,
      path: AfBlobPath.TrialModeOnlineExperienceIntroVideos,
    });
  if (videoFile.originFileObj)
    blobs.push({
      file: videoFile.originFileObj,
      path: AfBlobPath.TrialModeOnlineExperienceVideos,
    });
  if (videoThumbnail.originFileObj)
    blobs.push({
      file: videoThumbnail.originFileObj,
      path: AfBlobPath.TrialModeOnlineExperienceVideosThumbnail,
    });

  if (blobs.length > 0)
    uploadFile({
      variables: {
        blobs,
      },
    }).catch((e: any) => console.log(e));

  if (drawerAction === "Create")
    Promise.all([
      sortIdUpdator({
        contentType: "video",
        data: videoData,
        updateSortId,
        increment: 1,
      }),
      createVideo({ variables: denormalizeUploadVideoData(values) }),
    ])
      .then(() => {
        refetch()
          .then(() => {
            form.resetFields();
            setOpenDrawer(false);
          })
          .catch((e: any) => console.log(e));
      })
      .catch((e: any) => console.log(e));

  if (drawerAction === "Edit")
    updateVideo({
      variables: {
        productVideoId: parseInt(renderDecodedId(id)),
        ...denormalizeUploadVideoData(values),
      },
    })
      .then(() => {
        refetch();
        form.resetFields();
        setOpenDrawer(false);
      })
      .catch((e: any) => console.log(e));
}
