import { Drawer, Form, Input, Radio, Upload, message, Button } from "antd";
import type { UploadProps } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { renderAntDMessageConfig } from "../../../utils/renderAntDMessageConfig";
import { AfBlobPath } from "../../../graphql/operations/@types";
import { useEffect, useState } from "react";
import { useUploadFileMutation } from "../../../graphql/operations/upload-file";
import { useUpdateGraduateChildMutation } from "../../../graphql/operations/clubApp/graduation/update-graduate-child";
import DrawerFooter from "../../../components/layout/drawer/DrawerFooter";
import "./KidsInfoDrawer.scss";
import requiredRule from "../../../utils/formRules/requiredRule";
interface KidsInfoDrawerProps {
  showDrawer: boolean;
  setShowDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedRecord: React.Dispatch<React.SetStateAction<any>>;
  record: any;
  refetch: any;
}
const KidsInfoDrawer = ({
  showDrawer,
  setShowDrawer,
  setSelectedRecord,
  record,
  refetch,
}: KidsInfoDrawerProps) => {
  const [form] = Form.useForm();
  const [uploadFile] = useUploadFileMutation();
  const [updateGraduateChild] = useUpdateGraduateChildMutation();
  const [uploadedFileName, setUploadedFileName] = useState<string>("");
  const options = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];
  const handleDrawerSubmit = () => {
    const { englishName, height, weight, childSpeech, parentSpeech } =
      form.getFieldsValue();
    const updatePromise = updateGraduateChild({
      variables: {
        seq: record.seq,
        childHeight: parseInt(height),
        childWeight: parseInt(weight),
        childWillingEnglishSpeech: childSpeech,
        parentWillingChineseSpeech: parentSpeech,
        childEnglishName: englishName,
      },
    });
    updatePromise.then(() => {
      setShowDrawer(false);
      setSelectedRecord(null);
      refetch();
    });
  };
  useEffect(() => {
    if (record) {
      let processedFileName = record.imageUrl;
      if (record.imageUrl) {
        processedFileName = record.imageUrl
          .split("child-photos/")[1]
          .split("?")[0];
        setUploadedFileName(processedFileName);
      }
      form.setFieldsValue({
        englishName: record.childEnglishName,
        height: record.childHeight,
        weight: record.childWeight,
        image: [
          {
            uid: "0",
            name: processedFileName,
            status: "done",
            url: record.imageUrl,
          },
        ],
        childSpeech: record.childWillingEnglishSpeech,
        parentSpeech: record.parentWillingChineseSpeech,
      });
    }
  }, [record, form]);
  const props: UploadProps = {
    name: "file",
    action: "https://httpbin.org/post",
    headers: {
      authorization: "authorization-text",
    },
    beforeUpload: (file) => {
      const isImage = file.type.startsWith("image");
      if (!isImage) {
        message.error(renderAntDMessageConfig("Wrong file format!"));
        return Upload.LIST_IGNORE;
      }
      const modifiedFile = new File([file], uploadedFileName, {
        type: file.type,
        lastModified: file.lastModified,
      });
      return modifiedFile;
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(
          renderAntDMessageConfig(
            `${info.file.name} file uploaded successfully`
          )
        );
      } else if (info.file.status === "error") {
        message.error(
          renderAntDMessageConfig(`${info.file.name} file upload failed.`)
        );
      }
      uploadFile({
        variables: {
          blobs: [
            {
              file: info.file.originFileObj,
              path: AfBlobPath.GraduationChildPhotos,
            },
          ],
        },
      }).catch((e) => console.log(e));
    },
    maxCount: 1,
    listType: "picture",
  };
  return (
    <Drawer
      title="Edit the kid's info"
      open={showDrawer}
      width={500}
      onClose={() => {
        setShowDrawer(false);
        setSelectedRecord(null);
      }}
      footer={
        <DrawerFooter
          handleSubmit={handleDrawerSubmit}
          handleCancel={() => {
            setShowDrawer(false);
          }}
        />
      }
    >
      <div id="kidsInfoDrawerWrapper">
        <div>
          <p className="title">Attendance type</p>
          <p className="content">
            {record?.isGraduate
              ? record.isCambridge
                ? "Graduate/Cambridge"
                : "Graduate"
              : "Cambridge"}
          </p>
        </div>
        <div className="drawer-content">
          <p className="title">Member ID </p>
          <p className="content">{record?.memberNo}</p>
        </div>
        <div className="drawer-content">
          <p className="title">Chinese name</p>
          <p className="content">{record?.childName}</p>
        </div>
        <Form form={form} onFinish={() => {}} layout="vertical">
          {record?.isGraduate && (
            <div className="drawer-content">
              <Form.Item
                label="English Name"
                name="englishName"
                labelCol={{ style: { marginBottom: "8px" } }}
              >
                <Input defaultValue={record?.childEnglishName} />
              </Form.Item>
            </div>
          )}
          {record?.isGraduate && (
            <div className="drawer-content">
              <Form.Item
                style={{ margin: 0 }}
                label="Image"
                name="image"
                rules={[{ required: true, message: "This field is required!" }]}
                valuePropName="fileList"
                getValueFromEvent={(e) => {
                  if (Array.isArray(e)) {
                    return e;
                  }
                  return e && e.fileList ? e.fileList : [];
                }}
              >
                <Upload {...props} className="kids-info-image-upload">
                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
              </Form.Item>
            </div>
          )}
          {record?.isGraduate && (
            <Form.Item className="form-reminder">
              <p>Formats: JPEG, JPG, PNG</p>
            </Form.Item>
          )}
          {record?.isGraduate && (
            <Form.Item className="form-reminder">
              <p>
                Note: Upon uploading, the old photo would be deleted
                permanently. Please download it in advance if needed.
              </p>
            </Form.Item>
          )}
          <div className="drawer-content">
            <Form.Item
              label="Height"
              name="height"
              labelCol={{ style: { marginBottom: "8px" } }}
              rules={[requiredRule]}
            >
              <Input defaultValue={record?.childHeight} addonAfter="cm" />
            </Form.Item>
          </div>
          <div className="drawer-content">
            <Form.Item
              label="Weight"
              name="weight"
              labelCol={{ style: { marginBottom: "8px" } }}
              rules={[requiredRule]}
            >
              <Input addonAfter="kg" />
            </Form.Item>
          </div>
          <div className="drawer-content">
            <Form.Item
              label="The child is willing to give a speech in English"
              name="childSpeech"
              labelCol={{ style: { marginBottom: "8px" } }}
              rules={[requiredRule]}
            >
              <Radio.Group options={options} optionType="button" />
            </Form.Item>
          </div>
          <div className="drawer-content">
            <Form.Item
              label="The parent is willing to give a speech in Chinese"
              name="parentSpeech"
              labelCol={{ style: { marginBottom: "8px" } }}
              rules={[requiredRule]}
            >
              <Radio.Group options={options} optionType="button" />
            </Form.Item>
          </div>
          {record?.isGraduate && (
            <div className="drawer-content">
              <p className="title">
                This kid's graduation tasks were rated as excellent
              </p>
              <p className="content">{record?.isExcellent ? "Yes" : "No"}</p>
            </div>
          )}
        </Form>
      </div>
    </Drawer>
  );
};
export default KidsInfoDrawer;
