import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllGraduationProcessQueryVariables = Types.Exact<{
  market?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetAllGraduationProcessQuery = { __typename?: 'Query', afChildGraduationApplication: Array<{ __typename?: 'Club_WorldFamilyClub_ChildGraduationApplication', id: string, applicationCount?: number | null, applicationYear?: number | null, approvedCount?: number | null, childNo?: string | null, graduationDate?: any | null, isExcellent?: boolean | null, lastApprovalDate?: any | null, market?: string | null, memberNo?: string | null, rejectedCount?: number | null }> };


export const GetAllGraduationProcessDocument = gql`
    query getAllGraduationProcess($market: String) {
  afChildGraduationApplication(filter: {market: {eq: $market}}) {
    id
    applicationCount
    applicationYear
    approvedCount
    childNo
    graduationDate
    isExcellent
    lastApprovalDate
    market
    memberNo
    rejectedCount
  }
}
    `;

/**
 * __useGetAllGraduationProcessQuery__
 *
 * To run a query within a React component, call `useGetAllGraduationProcessQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllGraduationProcessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllGraduationProcessQuery({
 *   variables: {
 *      market: // value for 'market'
 *   },
 * });
 */
export function useGetAllGraduationProcessQuery(baseOptions?: Apollo.QueryHookOptions<GetAllGraduationProcessQuery, GetAllGraduationProcessQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllGraduationProcessQuery, GetAllGraduationProcessQueryVariables>(GetAllGraduationProcessDocument, options);
      }
export function useGetAllGraduationProcessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllGraduationProcessQuery, GetAllGraduationProcessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllGraduationProcessQuery, GetAllGraduationProcessQueryVariables>(GetAllGraduationProcessDocument, options);
        }
export type GetAllGraduationProcessQueryHookResult = ReturnType<typeof useGetAllGraduationProcessQuery>;
export type GetAllGraduationProcessLazyQueryHookResult = ReturnType<typeof useGetAllGraduationProcessLazyQuery>;
export type GetAllGraduationProcessQueryResult = Apollo.QueryResult<GetAllGraduationProcessQuery, GetAllGraduationProcessQueryVariables>;