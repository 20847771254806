import { useEffect, useState } from "react";
import { Form, Input, DatePicker, Button, message } from "antd";
import { renderAntDMessageConfig } from "../../../utils/renderAntDMessageConfig";
import dayjs from "dayjs";
import { apiCall } from "../../../utils/apiCall";
import { __currentUser__ } from "../../../graphql/policies";
import { useReactiveVar } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { fetchImportantNoticeData } from "./utils/fetchImportantNoticeData";
import { DataType } from "./utils/dataType";
import requiredRule from "../../../utils/formRules/requiredRule";
import "./ImportantNoticeDetail.scss";
import ContentTitle from "../../../components/ui/ContentTitle";
import TextArea from "antd/es/input/TextArea";
const ImportantNoticeDetail = () => {
  const [displayData, setDisplayData] = useState<DataType | any>();
  const [tempData, setTempData] = useState({ title: "", content: "" });
  const navigate = useNavigate();
  const { market } = useParams();
  const { RangePicker } = DatePicker;
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const currentUser = useReactiveVar(__currentUser__);
  const [form] = Form.useForm();
  const handleSubmit = async () => {
    const header = {
      headers: {
        accept: "text/plain",
        "Content-Type": "application/json",
        "User-Code": "P725",
      },
    };
    const payload = {
      region: displayData.region,
      title: form.getFieldValue("title"),
      description: form.getFieldValue("content"),
      startTime: form.getFieldValue("time")[0].format("YYYY-MM-DDTHH:mm:ss"),
      endTime: form.getFieldValue("time")[1].format("YYYY-MM-DDTHH:mm:ss"),
      cdt: displayData.cdt,
      udt: dayjs().format("YYYY-MM-DDTHH:mm:ss"),
      creator: displayData.creator,
      revisor: currentUser?.userName,
    };
    try {
      const data = await apiCall({
        method: "PATCH",
        header: header,
        endPoint: process.env.REACT_APP_IMPORTANT_NOTICE,
        payload: payload,
      });
      message.success(
        renderAntDMessageConfig("Important notice updated successfully")
      );
      fetchImportantNoticeData()
        .then((data) => {
          setDisplayData(data.find((item: DataType) => item.region === market));
        })
        .catch((e: any) => {
          console.log("error", e);
        });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  useEffect(() => {
    fetchImportantNoticeData()
      .then((data) => {
        setDisplayData(data.find((item: DataType) => item.region === market));
      })
      .catch((e: any) => {
        console.log("error", e);
      });
  }, []);
  useEffect(() => {
    form.setFieldsValue({
      title: displayData?.title,
      content: displayData?.description,
      time: [dayjs(displayData?.startTime), dayjs(displayData?.endTime)],
    });
    setTempData({
      title: displayData?.title,
      content: displayData?.description,
    });
  }, [displayData]);
  useEffect(() => {
    const { title, content } = form.getFieldsValue();
    setTempData({
      title: title,
      content: content,
    });
  }, [form]);
  return (
    <div id="importantNoticeDetailWrapper">
      <ContentTitle
        title="Important Notice Detail"
        returnTitle="Important Notice Detail"
        onReturn={() => {
          navigate(-1);
        }}
      />
      <div className="content-container">
        <div className="left">
          <Form
            name="important-notice-detail"
            form={form}
            onFinish={handleSubmit}
            {...layout}
            className="form"
          >
            <Form.Item label="Market" name="market">
              <p className="content">{displayData?.region.toUpperCase()}</p>
            </Form.Item>
            <Form.Item rules={[requiredRule]} label="Title" name="title">
              <Input
                onChange={(e) => {
                  setTempData({ ...tempData, title: e.target.value });
                }}
              />
            </Form.Item>
            <Form.Item rules={[requiredRule]} label="Content" name="content">
              <TextArea
                showCount
                maxLength={250}
                style={{ height: 280 }}
                onChange={(e) => {
                  setTempData({ ...tempData, content: e.target.value });
                }}
              />
            </Form.Item>
            <Form.Item
              rules={[requiredRule]}
              label="Date and time range"
              name="time"
            >
              <RangePicker showTime />
            </Form.Item>
          </Form>
        </div>
        <div className="right">
          <div className="emulator">
            <div className="textarea">
              <p className="emulator-title">{tempData.title}</p>
              <p className="emulator-content">
                {(tempData.content || "").split("\n").map((line, index) => (
                  <span key={index}>
                    {line}
                    <br />
                  </span>
                ))}
              </p>
              <img
                className="icon"
                alt="elvis-intro icon"
                src={require("../../../assets/icons/elvis-intro.png")}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <Button
          size="large"
          style={{
            paddingLeft: 20,
            paddingRight: 20,
          }}
          onClick={() => {
            navigate(-1);
          }}
        >
          Cancel
        </Button>
        <Button
          size="large"
          type="primary"
          style={{
            marginLeft: 8,
            textAlign: "center",
            paddingLeft: 20,
            paddingRight: 20,
          }}
          htmlType="submit"
          onClick={() => {
            form
              .validateFields()
              .then(() => {
                form.submit();
              })
              .catch((errorInfo) => {
                console.log("Validation failed:", errorInfo);
              });
          }}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default ImportantNoticeDetail;
