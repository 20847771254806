import { useEffect, useState } from "react";
import { Drawer, Checkbox } from "antd";
import DrawerFooter from "../../../components/layout/drawer/DrawerFooter";
import "./GraduationDrawer.scss";
import dayjs from "dayjs";
import { RightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { renderDecodedId } from "../../../utils/renderDecodedId";
import { DataType, TaskDataType } from "./types";
interface GraduationDrawerProps {
  showDrawer: boolean;
  setShowDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  selectedGraduationData: DataType;
  taskProgress: number[];
  taskProgressArray: number[];
  isExcellent: boolean;
  isExcellentEnabled: boolean;
  setIsExcellent: React.Dispatch<React.SetStateAction<boolean>> | any;
  filteredAllApplications: any;
  taskDataList: any;
  shouldPass?: boolean;
  isViewerOnly?: boolean;
  handleDrawerSubmit?: any;
}

const GraduationDrawer: React.FC<GraduationDrawerProps> = ({
  showDrawer,
  setShowDrawer,
  selectedGraduationData,
  taskProgress,
  taskProgressArray,
  isExcellent,
  isExcellentEnabled,
  setIsExcellent,
  filteredAllApplications,
  taskDataList,
  shouldPass,
  isViewerOnly,
  handleDrawerSubmit,
}) => {
  const navigate = useNavigate();
  const { childName, childEnglishName, age } = selectedGraduationData || {};
  const ageSubstring = age ? age.substring(0, age.indexOf("Y")) : "";
  const isEvaluated = selectedGraduationData?.approvalDate !== null;
  const [deductedData, setDeductedData] = useState<any[]>([]);
  useEffect(() => {
    const temporal = [...filteredAllApplications];
    const container: any[] = [];
    temporal.forEach((v, i) => {
      const lastTaskId = container[container.length - 1]
        ? container[container.length - 1][0].taskId
        : undefined;
      if (v?.taskId !== lastTaskId) {
        container.push([v]);
      } else {
        container[container.length - 1].push(v);
      }
    });
    setDeductedData(container);
  }, [filteredAllApplications]);
  return (
    <Drawer
      title="View all submissions"
      open={showDrawer}
      width={500}
      onClose={() => {
        setShowDrawer(false);
      }}
      footer={
        isExcellentEnabled &&
        isEvaluated &&
        !isViewerOnly &&
        taskProgress[0] === 8 && (
          <DrawerFooter
            handleSubmit={handleDrawerSubmit}
            handleCancel={() => {
              setShowDrawer(false);
            }}
          />
        )
      }
    >
      <div id="graduationDrawerWrapper">
        <div>
          <p className="title">Market</p>
          <p className="content">{selectedGraduationData?.market}</p>
        </div>
        <div className="drawer-content">
          <p className="title">Member ID </p>
          <p className="content">{selectedGraduationData?.memberNo}</p>
        </div>
        <div className="drawer-content">
          <p className="title">Kid(age)</p>
          <p className="content">
            {childName} {childEnglishName} ({ageSubstring})
          </p>
        </div>
        <div className="drawer-content">
          <p className="title">Progress</p>
          <div className="progress-bar-container">
            <div className="progress-bar content">
              {taskProgressArray.map((item) => (
                <div
                  className="unit"
                  style={
                    item === 0
                      ? { backgroundColor: "#071491" }
                      : item === 1
                      ? { backgroundColor: "#C7CDFC" }
                      : { backgroundColor: "#F5F5F5" }
                  }
                ></div>
              ))}
            </div>
            <p className="text-vertical-center content">{taskProgress[0]}/8</p>
          </div>
          {isEvaluated && taskProgress[0] === 8 && (
            <div className="text-vertical-center mt-5">
              <Checkbox
                disabled={!isExcellentEnabled || isViewerOnly}
                checked={isExcellent}
                onChange={() => {
                  setIsExcellent(!isExcellent);
                }}
              />
              <p className="text-vertical-center ms-2">Mark as Excellent</p>
            </div>
          )}
          <div>
            {deductedData.map((record: any) => (
              <div className="process-item">
                <div className="row-container">
                  <div className="left-part">
                    <img
                      className="number-icon"
                      alt="number-icon"
                      src={require(`../../../assets/icons/clubApp/graduation/count-${record[0].taskId}.png`)}
                    />
                    <div className="item-info">
                      <div>
                        {
                          taskDataList.find(
                            (task: TaskDataType) =>
                              task.taskId === record[0]?.taskId &&
                              task.market === record[0]?.market
                          )?.taskName
                        }
                      </div>
                    </div>
                  </div>
                  <div className="right-part" />
                </div>
                {record.map((single: any) => (
                  <div className="row-container mt-3">
                    <div className="left-part">
                      <img
                        className="number-icon display-none"
                        alt="number-icon"
                        src={require(`../../../assets/icons/clubApp/graduation/count-${single.taskId}.png`)}
                      />
                      <div className="item-info-date ml-2">
                        {dayjs(single.applicationDate).format("YYYY-MM-DD")}
                      </div>
                    </div>
                    <div className="right-part">
                      {single.isPassed === null ? (
                        <div className="under-review">Under review</div>
                      ) : single.isPassed === true ? (
                        <div className="pass">Pass</div>
                      ) : selectedGraduationData?.market === "TW" ? (
                        <div className="retry">
                          Try again after{" "}
                          {dayjs(single.applicationDate).month() > 7
                            ? dayjs(single.applicationDate)
                                .add(1, "year")
                                .set("month", 0)
                                .set("date", 1)
                                .format("YYYY-MM-DD")
                            : dayjs(single.applicationDate)
                                .add(1, "month")
                                .startOf("month")
                                .format("YYYY-MM-DD")}
                        </div>
                      ) : (
                        <div className="retry">Try again</div>
                      )}
                      <RightOutlined
                        onClick={() => {
                          const id = renderDecodedId(single.id);
                          navigate(`/club-app/review-graduation-cap/${id}`);
                          setShowDrawer(false);
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Drawer>
  );
};
export default GraduationDrawer;
