import { Dispatch, SetStateAction } from "react";
import { Form, FormInstance, Upload, UploadFile, UploadProps } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import handleUploadOnChange from "../../../utils/handleUploadOnChange";
import imageValidation from "../../../../../utils/formValidation/imageValidation";
import normFile from "../../../utils/normFile";

const props: UploadProps = {
  name: "file",
  action: "https://httpbin.org/post",
  beforeUpload: imageValidation(100, "KB"),
  maxCount: 1,
  listType: "picture-card",
};

export default function FormItemImage({
  form,
  setFileList,
}: {
  form: FormInstance<any>;
  setFileList: Dispatch<SetStateAction<UploadFile<any>[]>>;
}) {
  return (
    <Form.Item
      name="imageFileName"
      label={
        <span>
          Image
          <span className="text-textSecondary">(optional)</span>
        </span>
      }
      getValueFromEvent={normFile}
    >
      <div className="flex gap-4 max-w-[484px]">
        <Upload
          {...props}
          onChange={handleUploadOnChange("imageFileName", form, setFileList)}
        >
          <div className="relative">
            <PlusOutlined />
            <div className="mt-2">Upload</div>

            <ul className="text-textSecondary leading-5 list-disc absolute left-[100px] -top-6 text-left">
              <li>Max file size: 100KB</li>
              <li>Recommended width: 600px</li>
              <li>
                <span>{`Get the monthly Face Call banner from:\n`}</span>

                <a
                  href="https://wfeinnovation.atlassian.net/wiki/x/RwAKWQ"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-primary6"
                >
                  https://wfeinnovation.atlassian.net/wiki/x/RwAKWQ
                </a>
              </li>
            </ul>
          </div>
        </Upload>
      </div>
    </Form.Item>
  );
}
