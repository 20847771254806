import { Routes, Route, Navigate } from "react-router-dom";
import Notification from "../pages/clubApp/text/notification/Notification";
import ClubAppDashboard from "../pages/clubApp/dashboard/ClubAppDashboard";
import ClubServiceDashboard from "../pages/clubService/dashboard/ClubServiceDashboard";
import Sales188Dashboard from "../pages/sales188/dashboard/Sales188Dashboard";
import MktDashboard from "../pages/mkt/dashboard/MktDashboard";
import TechpressoDashboard from "../pages/techpresso/dashboard/TechpressoDashboard";
import { Dispatch, SetStateAction } from "react";
import { ServiceTypes } from "../types/types";
import ManualNotificationDetail from "../pages/clubApp/text/notification/ManualNotificationDetail";
import FaceCall from "../pages/clubApp/content/FaceCall";
import UserManagement from "../pages/permission/UserManagement";
import ProgramSetting from "../pages/permission/ProgramSetting";
import CheckInDashboard from "../pages/checkIn/dashboard/CheckInDashboard";
import VendorReports from "../pages/mkt/content/VendorReports";
import EventBanner from "../pages/clubApp/design/EventBanner";
import GoGoLive from "../pages/clubApp/design/GoGoLive";
import TrialModeDashboard from "../pages/trialMode/dataAnalysis/dashboard/Dashboard";
import LocalEvent from "../pages/trialMode/localEvent/LocalEvent";
import CaringCTA from "../pages/trialMode/caringCTA/CaringCTA";
import JourneyCard from "../pages/trialMode/journeyCard/JourneyCard";
import TrialContent from "../pages/trialMode/trialContent/TrialContent";
import TrialContentUpload from "../pages/trialMode/trialContentUpload/TrialContentUpload";
import ImportantNotice from "../pages/clubApp/content/ImportantNotice";
import ImportantNoticeDetail from "../pages/clubApp/content/ImportantNoticeDetail";
import WaitingForReview from "../pages/clubApp/graduation/WaitingForReview";
import GraduationReviewed from "../pages/clubApp/graduation/GraduationReviewed";
import PushNotification from "../pages/trialMode/pushNotification/PushNotification";
import ReviewGraduationCap from "../pages/clubApp/graduation/ReviewGraduationCap";
import ServiceBannerAndIcon from "../pages/clubApp/design/ServiceBannerAndIcon";
import AnnouncementBar from "../pages/clubApp/design/AnnouncementBar";
import AdExclusionList from "../pages/mkt/content/AdExclusionList";
import ReleaseVersion from "../pages/clubApp/version/ReleaseVersion";
import SalesPushNotification from "../pages/sales188/pushNotification/PushNotification";
import ViewNotificationDetail from "../pages/sales188/pushNotification/ViewNotificationDetail";
import CreateNotification from "../pages/sales188/pushNotification/CreateNotification";
import LeadsDetailDownload from "../pages/trialMode/dataAnalysis/leadsDetailDownload";
import CampaignId from "../pages/trialMode/dataAnalysis/campaignId";
import Sales188ReleaseVersion from "../pages/sales188/version/ReleaseVersion";
import KidsInfo from "../pages/clubApp/graduation/KidsInfo";

interface AllRoutesProps {
  setSelectedService: Dispatch<SetStateAction<ServiceTypes>>;
  setSelectSubItem: Dispatch<SetStateAction<string | undefined>>;
}

const AllRoutes = ({
  setSelectedService,
  setSelectSubItem,
}: AllRoutesProps) => {
  return (
    <Routes>
      <Route path="/" element={<ClubAppDashboard />} />
      <Route path="/club-app/dashboard" element={<ClubAppDashboard />} />
      <Route
        path="/club-app/service-banner-and-icon"
        element={
          <ServiceBannerAndIcon
            setSelectedService={setSelectedService}
            setSelectSubItem={setSelectSubItem}
          />
        }
      />
      <Route
        path="/club-app/announcement-bar"
        element={
          <AnnouncementBar
            setSelectedService={setSelectedService}
            setSelectSubItem={setSelectSubItem}
          />
        }
      />
      <Route
        path="/club-app/event-banner"
        element={
          <EventBanner
            setSelectedService={setSelectedService}
            setSelectSubItem={setSelectSubItem}
          />
        }
      />
      <Route
        path="/club-app/go-go-live"
        element={
          <GoGoLive
            setSelectedService={setSelectedService}
            setSelectSubItem={setSelectSubItem}
          />
        }
      />
      <Route
        path="/club-app/push-notification"
        element={
          <Notification
            setSelectedService={setSelectedService}
            setSelectSubItem={setSelectSubItem}
          />
        }
      />
      <Route
        path="/club-app/push-notification/create"
        element={
          <ManualNotificationDetail
            setSelectedService={setSelectedService}
            setSelectSubItem={setSelectSubItem}
          />
        }
      />
      <Route
        path="/club-app/push-notification/:id/detail"
        element={
          <ManualNotificationDetail
            setSelectedService={setSelectedService}
            setSelectSubItem={setSelectSubItem}
          />
        }
      />
      <Route
        path="/club-app/face-call"
        element={
          <FaceCall
            setSelectedService={setSelectedService}
            setSelectSubItem={setSelectSubItem}
          />
        }
      />
      <Route
        path="/club-app/important-notice"
        element={
          <ImportantNotice
            setSelectedService={setSelectedService}
            setSelectSubItem={setSelectSubItem}
          />
        }
      />
      <Route
        path="/club-app/important-notice-detail/:market"
        element={<ImportantNoticeDetail />}
      />
      <Route
        path="/club-app/waiting-for-review"
        element={
          <WaitingForReview
            setSelectedService={setSelectedService}
            setSelectSubItem={setSelectSubItem}
          />
        }
      />
      <Route
        path="/club-app/review-graduation-cap/:id"
        element={<ReviewGraduationCap />}
      />
      <Route
        path="/club-app/all-applications"
        element={
          <GraduationReviewed
            setSelectedService={setSelectedService}
            setSelectSubItem={setSelectSubItem}
          />
        }
      />
      <Route
        path="/club-app/kids-info"
        element={
          <KidsInfo
            setSelectedService={setSelectedService}
            setSelectSubItem={setSelectSubItem}
          />
        }
      />
      <Route
        path="/club-app/release-version"
        element={
          <ReleaseVersion
            setSelectedService={setSelectedService}
            setSelectSubItem={setSelectSubItem}
          />
        }
      />
      <Route path="/check-in-app/dashboard" element={<CheckInDashboard />} />
      <Route
        path="/club-service/dashboard"
        element={<ClubServiceDashboard />}
      />
      <Route path="/sales188/dashboard" element={<Sales188Dashboard />} />
      <Route
        path="/sales188/release-version"
        element={
          <Sales188ReleaseVersion
            setSelectedService={setSelectedService}
            setSelectSubItem={setSelectSubItem}
          />
        }
      />
      <Route
        path="/sales188/push-notification"
        element={
          <SalesPushNotification
            setSelectedService={setSelectedService}
            setSelectSubItem={setSelectSubItem}
          />
        }
      />
      <Route
        path="/sales188/push-notification/:id"
        element={
          <ViewNotificationDetail
            setSelectedService={setSelectedService}
            setSelectSubItem={setSelectSubItem}
          />
        }
      />
      <Route
        path="/sales188/create-notification"
        element={
          <CreateNotification
            setSelectedService={setSelectedService}
            setSelectSubItem={setSelectSubItem}
          />
        }
      />
      <Route path="/mkt/dashboard" element={<MktDashboard />} />
      <Route
        path="/mkt/vendor-reports"
        element={
          <VendorReports
            setSelectedService={setSelectedService}
            setSelectSubItem={setSelectSubItem}
          />
        }
      />
      <Route
        path="/mkt/ad-exclusion-list"
        element={
          <AdExclusionList
            setSelectedService={setSelectedService}
            setSelectSubItem={setSelectSubItem}
          />
        }
      />
      <Route path="/techpresso/dashboard" element={<TechpressoDashboard />} />
      <Route path="/trial-mode/dashboard" element={<TrialModeDashboard />} />
      <Route
        path="/trial-mode/leads-detail-download"
        element={
          <LeadsDetailDownload
            setSelectedService={setSelectedService}
            setSelectSubItem={setSelectSubItem}
          />
        }
      />
      <Route
        path="/trial-mode/campaign-id"
        element={
          <CampaignId
            setSelectedService={setSelectedService}
            setSelectSubItem={setSelectSubItem}
          />
        }
      />
      <Route
        path="/trial-mode/local-event"
        element={
          <LocalEvent
            setSelectedService={setSelectedService}
            setSelectSubItem={setSelectSubItem}
          />
        }
      />
      <Route
        path="/trial-mode/caring-cta"
        element={
          <CaringCTA
            setSelectedService={setSelectedService}
            setSelectSubItem={setSelectSubItem}
          />
        }
      />
      <Route
        path="/trial-mode/journey-card"
        element={
          <JourneyCard
            setSelectedService={setSelectedService}
            setSelectSubItem={setSelectSubItem}
          />
        }
      />
      <Route
        path="/trial-mode/trial-content"
        element={
          <TrialContent
            setSelectedService={setSelectedService}
            setSelectSubItem={setSelectSubItem}
          />
        }
      />
      <Route
        path="/trial-mode/trial-content-upload"
        element={
          <TrialContentUpload
            setSelectedService={setSelectedService}
            setSelectSubItem={setSelectSubItem}
          />
        }
      />
      <Route
        path="/trial-mode/push-notification"
        element={
          <PushNotification
            setSelectedService={setSelectedService}
            setSelectSubItem={setSelectSubItem}
          />
        }
      />
      <Route path="/permission/user-management" element={<UserManagement />} />
      <Route path="/permission/program-setting" element={<ProgramSetting />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default AllRoutes;
