import { Menu } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

import getMenuItems from "./utils/getMenuItems";
import getSubMenuKeys from "./utils/getSubMenuKeys";
import getMenuItemRoute from "./utils/getMenuItemRoute";
import getKeyByRouteName from "./utils/getKeyByRouteName";
import { subMenuWidth } from "./constants";
import { ServiceItemsType } from "./types";
import "./SubMenu.css";

export default function SubMenu({
  serviceData,
}: {
  serviceData: ServiceItemsType;
}) {
  const navigate = useNavigate();
  const items = getMenuItems(serviceData.items);
  const subMenuKeys = getSubMenuKeys(items);
  const { pathname } = useLocation();
  const values = pathname.split("/").filter((value) => value !== "");
  const defaultSelectedKey = getKeyByRouteName(serviceData.items, values[1]);

  return (
    <Menu
      key={values[0]}
      id="custom-submenu"
      style={{ width: subMenuWidth }}
      defaultSelectedKeys={[defaultSelectedKey]}
      defaultOpenKeys={subMenuKeys}
      items={items}
      mode="inline"
      inlineIndent={12}
      onClick={({ keyPath }) => {
        navigate(getMenuItemRoute(keyPath, serviceData));
      }}
    />
  );
}
