// @ts-nocheck
/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useReactiveVar } from "@apollo/client";
import { Button, DatePicker, Form, Radio } from "antd";
import dayjs from "dayjs";
import fileDownload from "js-file-download";
import axios from "axios";
import "./VendorReports.scss";
import {
  __currentUser__,
  __jwtToken__,
  __projectKey__,
} from "../../../graphql/policies";
import { useGetUserProgramsByServiceQuery } from "../../../graphql/operations/get-user-program-by-service";
import { renderDecodedId } from "../../../utils/renderDecodedId";
import Spinner from "../../../components/layout/spinner/Spinner";
import NoPermission from "../../../components/others/NoPermission";
import { Colors } from "../../../constants/Colors";
import { ServiceTypes } from "../../../types/types";

const host = window.location.host;

const renderApiHost = () => {
  if (
    host.startsWith("localhost") ||
    host === "adminfirst-web-dev.worldfamilyenglish.com"
  ) {
    return "https://global-service-dev.worldfamily.com/Af/MktVendorReport";
  } else if (
    host === "adminfirst-web.worldfamilyenglish.com" ||
    host === "admin-first-fe-preview.azurewebsites.net"
  ) {
    return "https://global-service.worldfamily.com/Af/MktVendorReport";
  }
};

interface VendorReportsProps {
  setSelectedService: Dispatch<SetStateAction<ServiceTypes>>;
  setSelectSubItem: Dispatch<SetStateAction<string | undefined>>;
}

const { RangePicker } = DatePicker;

const VendorReports = ({
  setSelectedService,
  setSelectSubItem,
}: VendorReportsProps) => {
  const currentUser = useReactiveVar(__currentUser__);
  const jwtToken = useReactiveVar(__jwtToken__);
  const projectKey = useReactiveVar(__projectKey__);
  const [isLoading, setIsLoading] = useState(false);
  const {
    data: userPrograms,
    loading: userProgramsLoading,
    error: userProgramsError,
  } = useGetUserProgramsByServiceQuery({
    variables: {
      userId: parseInt(renderDecodedId(currentUser?.id)),
      serviceId: 4,
    },
  });
  const [form] = Form.useForm();

  const renderTypeName = (typeNo: string) => {
    switch (typeNo) {
      case "1":
        return "oeya";
      case "2":
        return "wf-af";
      case "3":
        return "wf-im";
      case "4":
        return "wf-lf0";
      default:
        break;
    }
  };

  const handleFinish = async (values: any) => {
    setIsLoading(true);
    const apiHost = renderApiHost();
    const startDate = dayjs(values.dateRange[0]).format("YYYY-MM-DD");
    const endDate = dayjs(values.dateRange[1]).format("YYYY-MM-DD");
    const fileName = renderTypeName(values.type);
    try {
      const { data } = await axios.get(
        `${apiHost}/${startDate}/${endDate}/${values.type}/${values.kind}/${fileName}`,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "X-Project-Id": "AF",
            "X-Authorization-Provider": "Anonymous",
            "X-Project-Key": projectKey,
          },
          responseType: "blob",
        }
      );
      fileDownload(data, `${fileName}.xlsx`);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setSelectedService(ServiceTypes.MKT);
    setSelectSubItem("Vendor Reports");
    form.setFieldValue("type", "1");
    form.setFieldValue("kind", "list");
  }, []);

  if (userProgramsError) {
    message.error(
      renderAntDMessageConfig(
        "Something went wrong. Please contact your system administrator."
      )
    );
  }

  if (userProgramsLoading) {
    return <Spinner />;
  }

  if (
    userPrograms &&
    !userPrograms.wf_adminFirst_afUserProgram?.edges?.find(
      (item) => item.node.afProgram?.programName === "Vendor Reports"
    )
  ) {
    return <NoPermission />;
  }

  return (
    <div>
      <div className="header" style={{ backgroundColor: Colors.white }}>
        <p className="header-title" style={{ color: Colors.darkGrey }}>
          Vendor Reports
        </p>
      </div>
      <div className="content-container">
        <Form
          name="report-form"
          form={form}
          onFinish={handleFinish}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <Form.Item
            name="type"
            label="Report type"
            rules={[{ required: true, message: "This field is required!" }]}
          >
            <Radio.Group
              options={[
                { label: "通路王", value: "1" },
                { label: "聯盟網", value: "2" },
                { label: "發票存摺", value: "3" },
                { label: "發票特務", value: "4" },
              ]}
              optionType="button"
            />
          </Form.Item>
          <Form.Item
            name="kind"
            label="Data type"
            rules={[{ required: true, message: "This field is required!" }]}
          >
            <Radio.Group
              options={[
                { label: "名單 (List)", value: "list" },
                {
                  label: "資料不全或重覆申請已寄FFP兩次以上 (Remove list)",
                  value: "removeList",
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            name="dateRange"
            label="Data date range "
            rules={[{ required: true, message: "This field is required!" }]}
          >
            <RangePicker
              placeholder={["Start date", "End date"]}
              style={{ width: 480 }}
            />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <div className="button-container">
              <Button
                htmlType="submit"
                type="primary"
                style={{ marginRight: 8 }}
                loading={isLoading}
              >
                Download report
              </Button>
              <p>選擇完報表類型後送出，將會自動下載該報表之Excel檔</p>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default VendorReports;
