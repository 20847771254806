import { Dispatch, SetStateAction } from "react";
import axios from "axios";

export default async function getEmbedToken(
  accessToken: string,
  data: any,
  setEmbedToken: Dispatch<SetStateAction<string>>,
  setError: Dispatch<SetStateAction<any>>
) {
  const groupId = process.env.REACT_APP_TRIAL_MODE_DASHBOARD_GROUP_ID;
  const reportId = process.env.REACT_APP_TRIAL_MODE_DASHBOARD_REPORT_ID;
  const { datasetId } = data;

  try {
    const response = await axios.post(
      `https://api.powerbi.com/v1.0/myorg/groups/${groupId}/reports/${reportId}/GenerateToken`,
      {
        datasets: [
          {
            id: datasetId,
          },
        ],
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-type": "application/json",
        },
      }
    );

    setEmbedToken(response.data.token);
  } catch (error: any) {
    console.error("getEmbedToken error", error);
    setError(error);
  }
}
