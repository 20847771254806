import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateJourneyCardMutationVariables = Types.Exact<{
  journeyCardId: Types.Scalars['Int']['input'];
  journeyExperienceTypeId: Types.Scalars['Int']['input'];
  greeting: Types.Scalars['String']['input'];
  experiencePromotionWording: Types.Scalars['String']['input'];
  experienceNavigationWording: Types.Scalars['String']['input'];
  experienceCharacter: Types.Scalars['String']['input'];
  isDisplayVideo: Types.Scalars['Boolean']['input'];
  videoUrl?: Types.InputMaybe<Types.Scalars['String']['input']>;
  videoWording?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type UpdateJourneyCardMutation = { __typename?: 'Mutation', wf_trialModeLeads: { __typename?: 'WF_TrialModeLeads_Mutation_Payload', count: number } };


export const UpdateJourneyCardDocument = gql`
    mutation UpdateJourneyCard($journeyCardId: Int!, $journeyExperienceTypeId: Int!, $greeting: String!, $experiencePromotionWording: String!, $experienceNavigationWording: String!, $experienceCharacter: String!, $isDisplayVideo: Boolean!, $videoUrl: String, $videoWording: String) {
  wf_trialModeLeads(
    update: {journeyCard: {journeyCardId: $journeyCardId, journeyExperienceTypeId: $journeyExperienceTypeId, greeting: $greeting, experiencePromotionWording: $experiencePromotionWording, experienceNavigationWording: $experienceNavigationWording, experienceCharacter: $experienceCharacter, isDisplayVideo: $isDisplayVideo, videoUrl: $videoUrl, videoWording: $videoWording}}
  ) {
    count
  }
}
    `;
export type UpdateJourneyCardMutationFn = Apollo.MutationFunction<UpdateJourneyCardMutation, UpdateJourneyCardMutationVariables>;

/**
 * __useUpdateJourneyCardMutation__
 *
 * To run a mutation, you first call `useUpdateJourneyCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJourneyCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJourneyCardMutation, { data, loading, error }] = useUpdateJourneyCardMutation({
 *   variables: {
 *      journeyCardId: // value for 'journeyCardId'
 *      journeyExperienceTypeId: // value for 'journeyExperienceTypeId'
 *      greeting: // value for 'greeting'
 *      experiencePromotionWording: // value for 'experiencePromotionWording'
 *      experienceNavigationWording: // value for 'experienceNavigationWording'
 *      experienceCharacter: // value for 'experienceCharacter'
 *      isDisplayVideo: // value for 'isDisplayVideo'
 *      videoUrl: // value for 'videoUrl'
 *      videoWording: // value for 'videoWording'
 *   },
 * });
 */
export function useUpdateJourneyCardMutation(baseOptions?: Apollo.MutationHookOptions<UpdateJourneyCardMutation, UpdateJourneyCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateJourneyCardMutation, UpdateJourneyCardMutationVariables>(UpdateJourneyCardDocument, options);
      }
export type UpdateJourneyCardMutationHookResult = ReturnType<typeof useUpdateJourneyCardMutation>;
export type UpdateJourneyCardMutationResult = Apollo.MutationResult<UpdateJourneyCardMutation>;
export type UpdateJourneyCardMutationOptions = Apollo.BaseMutationOptions<UpdateJourneyCardMutation, UpdateJourneyCardMutationVariables>;