import * as Types from './@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetFaceCallPerformersQueryVariables = Types.Exact<{
  isZippy?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetFaceCallPerformersQuery = { __typename?: 'Query', afFaceCallPerformer: Array<{ __typename?: 'Club_WorldFamilyClub_FaceCallPerformer', id: string, performerName?: string | null, avatarFileName?: string | null, zippyFlag?: boolean | null, market?: string | null, publishdSongs?: number | null, faceCallSongAndPerformers?: Array<{ __typename?: 'Club_WorldFamilyClub_FaceCallSongAndPerformer', faceCallSong?: { __typename?: 'Club_WorldFamilyClub_FaceCallSong', id: string } | null } | null> | null }> };


export const GetFaceCallPerformersDocument = gql`
    query GetFaceCallPerformers($isZippy: Boolean) {
  afFaceCallPerformer(
    filter: {zippyFlag: {eq: $isZippy}}
    order: {performerName: ASC}
  ) {
    id
    performerName
    avatarFileName
    zippyFlag
    market
    faceCallSongAndPerformers {
      faceCallSong {
        id
      }
    }
    publishdSongs
  }
}
    `;

/**
 * __useGetFaceCallPerformersQuery__
 *
 * To run a query within a React component, call `useGetFaceCallPerformersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFaceCallPerformersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFaceCallPerformersQuery({
 *   variables: {
 *      isZippy: // value for 'isZippy'
 *   },
 * });
 */
export function useGetFaceCallPerformersQuery(baseOptions?: Apollo.QueryHookOptions<GetFaceCallPerformersQuery, GetFaceCallPerformersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFaceCallPerformersQuery, GetFaceCallPerformersQueryVariables>(GetFaceCallPerformersDocument, options);
      }
export function useGetFaceCallPerformersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFaceCallPerformersQuery, GetFaceCallPerformersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFaceCallPerformersQuery, GetFaceCallPerformersQueryVariables>(GetFaceCallPerformersDocument, options);
        }
export type GetFaceCallPerformersQueryHookResult = ReturnType<typeof useGetFaceCallPerformersQuery>;
export type GetFaceCallPerformersLazyQueryHookResult = ReturnType<typeof useGetFaceCallPerformersLazyQuery>;
export type GetFaceCallPerformersQueryResult = Apollo.QueryResult<GetFaceCallPerformersQuery, GetFaceCallPerformersQueryVariables>;