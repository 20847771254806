import * as Types from '../../@types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetJourneyCardsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetJourneyCardsQuery = { __typename?: 'Query', wf_trialModeLeads_journeyCard?: { __typename?: 'WF_TrialModeLeads_JourneyCardConnection', nodes?: Array<{ __typename?: 'WF_TrialModeLeads_JourneyCard', id: string, journeyExperienceTypeId?: number | null, market?: string | null, segmentation?: string | null, greeting?: string | null, experiencePromotionWording?: string | null, experienceNavigationWording?: string | null, experienceCharacter?: string | null, isDisplayVideo?: boolean | null, videoUrl?: string | null, videoWording?: string | null, journeyExperienceType?: { __typename?: 'WF_TrialModeLeads_JourneyExperienceType', experienceName?: string | null } | null }> | null } | null };


export const GetJourneyCardsDocument = gql`
    query GetJourneyCards {
  wf_trialModeLeads_journeyCard(first: 100) {
    nodes {
      id
      journeyExperienceTypeId
      market
      segmentation
      journeyExperienceType {
        experienceName
      }
      greeting
      experiencePromotionWording
      experienceNavigationWording
      experienceCharacter
      isDisplayVideo
      videoUrl
      videoWording
    }
  }
}
    `;

/**
 * __useGetJourneyCardsQuery__
 *
 * To run a query within a React component, call `useGetJourneyCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJourneyCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJourneyCardsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetJourneyCardsQuery(baseOptions?: Apollo.QueryHookOptions<GetJourneyCardsQuery, GetJourneyCardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJourneyCardsQuery, GetJourneyCardsQueryVariables>(GetJourneyCardsDocument, options);
      }
export function useGetJourneyCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJourneyCardsQuery, GetJourneyCardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJourneyCardsQuery, GetJourneyCardsQueryVariables>(GetJourneyCardsDocument, options);
        }
export type GetJourneyCardsQueryHookResult = ReturnType<typeof useGetJourneyCardsQuery>;
export type GetJourneyCardsLazyQueryHookResult = ReturnType<typeof useGetJourneyCardsLazyQuery>;
export type GetJourneyCardsQueryResult = Apollo.QueryResult<GetJourneyCardsQuery, GetJourneyCardsQueryVariables>;