import { Tabs, TabsProps } from "antd";

export default function TabsCustom({ items }: { items: TabsProps["items"] }) {
  return (
    <Tabs
      defaultActiveKey="1"
      items={items}
      size="large"
      tabBarStyle={{
        paddingLeft: 24,
        backgroundColor: "#ffffff",
        height: 56,
        marginBottom: 0,
      }}
    />
  );
}
